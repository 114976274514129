const CheckListTemplate = (CheckListRedux, totalObtainedScore, totalScore, Basic, Adv) => {
    // Ensure CheckListRedux is an array before mapping

    const today = new Date();
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;

    const formattedToday = dd + "/" + mm + "/" + yyyy;

    const data = {
        date: formattedToday,
        fssaiLicenseNo: Adv.FSSAI_LicenseNumber,
        nameOfOrganisation: Basic.companyName,
        outletName: Basic.companyName,
        outletLocation: Basic?.city || Basic?.Address,
        fssName: Basic?.contactPerson,
        fssCertificateCode: Adv?.uniqueCertificateNo,
        courseCode: Adv?.courseCode
    };

    if (!Array.isArray(CheckListRedux)) {
        throw new Error("CheckListRedux should be an array");
    }

    // Map through CheckListRedux and create table rows
    const tableRows = CheckListRedux.map((row, index) => `
        <tr>
            <td>${index + 1}</td>
            <td>${row.question}</td>
            <td>${row.score}</td>
            <td>${row.obtainedScore}</td>
        </tr>
    `).join('');

    // Return the complete HTML template
    return `
    <!DOCTYPE html PUBLIC "-//W3C//DTD XHTML 1.0 Transitional//EN" "http://www.w3.org/TR/xhtml1/DTD/xhtml1-transitional.dtd">
    <html xmlns="http://www.w3.org/1999/xhtml" xml:lang="en" lang="en">
    <head>
        <meta charset="UTF-8">
        <meta name="viewport" content="width=device-width, initial-scale=1.0">
        <title>Hygiene Rating Checklist Form</title>
        <style>
            body {
                text-align: center;
                padding: 20px;
            }
            table {
                width: 100%;
                border-collapse: collapse;
                margin: 20px 0;
            }
            th, td {
                border: 1px solid #ddd;
                padding: 8px;
                text-align: left;
            }
            th {
                background-color: #f2f2f2;
            }
            .form-group {
                margin-bottom: 15px;
            }
            .form-label {
                display: block;
                margin-bottom: 5px;
                font-weight: bold;
            }
            .form-input {
                width: 100%;
                padding: 8px;
                box-sizing: border-box;
            }
            .form-button {
                padding: 10px 20px;
                background-color: #4CAF50;
                color: white;
                border: none;
                cursor: pointer;
            }
            .form-button-disabled {
                background-color: #ccc;
            }
            .d-flex{
                display: flex;
                justify-content: space-between;
                align-items: center
            }
        </style>
    </head>
    <body>
        <header>
            <h1>Hygiene Rating Checklist Form</h1>
        </header>
        <main>
            <form>
                <div class="d-flex justify-content-center">
                    <table style="width: 100%;">
                        <tr>
                            <th>Date</th>
                            <td>${data.date}</td>
                            <th>FSSAI License No.</th>
                            <td>${data.fssaiLicenseNo}</td>
                        </tr>
                        <tr>
                            <th>Name of Organisation</th>
                            <td>${data.nameOfOrganisation}</td>
                            <th>Outlet Name</th>
                            <td>${data.outletName}</td>
                        </tr>
                        <tr>
                            <th>Outlet Location</th>
                            <td>${data.outletLocation}</td>
                            <th>FSS Name</th>
                            <td>${data.fssName}</td>
                        </tr>
                        <tr>
                            <th>FSS Certificate Code</th>
                            <td>${data.fssCertificateCode}</td>
                            <th>Course Code</th>
                            <td>${data.courseCode}</td>
                        </tr>
                    </table>
                </div>
                <h2>Audit Questions</h2>
                <table>
                    <thead>
                        <tr>
                            <th>S. No.</th>
                            <th>Audit Question</th>
                            <th>Score</th>
                            <th>Obtained Score</th>
                        </tr>
                    </thead>
                    <tbody id="audit-questions-body">
                        ${tableRows}
                    </tbody>

                    </table>
                    <div class="d-flex">
                        <p>Total Score: ${totalScore}</p>
                        <p>Total Obtained Score: ${totalObtainedScore}</p>
                    </div>
            </form>
        </main>
    </body>
    </html>`;
};

// Example usage:



export default CheckListTemplate;
