import { Helmet } from "react-helmet-async";
import React, { useEffect, useState } from "react";
// @mui
import { Button, Container } from "@mui/material";
import ReactbigCalendar from "src/components/Calendar/ReactbigCalendar";
import Activity_details from "src/components/Auditor/Activity_details";
import Corrective from "src/components/Auditor/Corrective";
import Documents from "src/components/Auditor/Documents";
import Event_tracking from "src/components/Auditor/Event_tracking";
import HRA_Checklist from "src/components/Auditor/CheckList";
import HRA_NC from "src/components/Auditor/NC";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Iconify from "src/components/iconify";
import { getData, patchData } from "src/webService/webService";
import { setAdvance, setBasic } from "src/redux/slices/SpecificClient";
import { useDispatch, useSelector } from "react-redux";
import CLIENTROUTES from "src/API_Routes/Client/Client_Routers";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import AUDITROUTES from "src/API_Routes/Audit/Audit_routes";
import CheckListTab from "./CheckListHRATab/CheckListHRATab";
import { getFormattedIndiaTime } from "../../utils/timeutils";

// ----------------------------------------------------------------------
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function AuditorCalendarSkillSetHRA() {
  const scheme_session = sessionStorage.getItem("scheme");
  const Role = sessionStorage.getItem("isAuthenticated")
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const dispatch = useDispatch();
  const accessToken = sessionStorage.getItem("Token");
  const [showmoredata, setshowmoredata] = useState(false);
  const [ClientBasic, setClientBasic] = useState(null);
  const [ClientAdvance, setClientAdvance] = useState(null);
  const [AuditId, setAuditId] = useState();

  const NC_Id = useSelector(
    (state) => state?.NC.Nc._id
  );
  const handleScheduleClick = async (auditId, clientId) => {
    // Handle the click event of a scheduled date here
    console.log("Clicked on schedule with Audit ID:", auditId);
    sessionStorage.setItem("selectedAuditId", auditId);
    console.log(clientId, "clientIds");

    const advanceResponse = await getData(
      `${CLIENTROUTES.GETSPECIFICUSERADVANCE}${clientId}/advanced`,
      null,
      accessToken
    );
    const advanceData = advanceResponse.data;
    // setClientAdvance(advanceData);
    // dispatch(setAdvance(advanceData));
    sessionStorage.setItem("mainservice", advanceData.typeOfServices)

    // setAuditId(auditId);

    const basicResponse = await getData(
      `${CLIENTROUTES.GETSPECIFICUSERBASIC}${clientId}/basic`,
      null,
      accessToken
    );
    sessionStorage.setItem("basicId", clientId)
    const basicData = basicResponse.data;

    // const existingNC = await getData(
    //   AUDITROUTES.GET_NC(
    //     NC_Id
    //   ),
    //   '',
    //   accessToken
    // )


    if (basicData && basicData.status.type === "auditor assigned" ) {
      BackToCalender();

    } else {
      // Set showmoredata to true
      // setAuditId(auditId)
      // setshowmoredata(false);
      setClientBasic(basicData);
      dispatch(setBasic(basicData));
      setAuditId(auditId);
      // Set showmoredata to true
      setshowmoredata(true);



      // Fetch Advance details using the Basic details id
      const advanceResponse = await getData(
        `${CLIENTROUTES.GETSPECIFICUSERADVANCE}${clientId}/advanced`,
        null,
        accessToken
      );
      const advanceData = advanceResponse.data;
      setClientAdvance(advanceData);
      dispatch(setAdvance(advanceData));
    }


    console.log(basicData, "basicdata");


  };
  const BackToCalender = () => {
    setshowmoredata(false);
  };
  const Auditcompleted = () => {
    const formattedDate = getFormattedIndiaTime();
    const params = {
      reportStatus: {
        name: "Audit report completed",
        date: formattedDate,
      },
      activityStep: "4",
    };
    const endpoint = patchData(
      `${AUDITROUTES.SCHEDULEPATCH(AuditId)}`,
      params,
      accessToken,
      dispatch
    );
    BackToCalender();
  };

  const AuditCompletedHRA = async () => {
    const formattedDate = getFormattedIndiaTime();
    const Params = {
      status: {
        type: "audit completed",
        updatedDate: formattedDate,
      },
    }
    await patchData(
      `clients/client/${ClientBasic?._id}/basic`,
      Params,
      accessToken,
      dispatch
    );

  }

  return (
    <>
      <ToastContainer />
      <Container maxWidth="xl">
        {!showmoredata ? (
          <ReactbigCalendar
            setshowmoredata={setshowmoredata}
            onScheduleClick={handleScheduleClick}
          />
        ) : (
          Role === "Auditor-HRA" ? (
            <>
              <div className="client-list-border pt-0 pb-5 overflow-hidden">
                <div
                  className="d-flex position-relative align-items-center  justify-content-between py-3"
                  style={{ backgroundColor: "#68B4E6" }}
                >
                  <div className="d-flex px-4 col-7">
                    <Iconify className="me-3 mt-1" icon={"bxs:file"} />
                    <h4>Certification Activity</h4>
                  </div>
                  <div className="d-flex col-4 ">
                    <Iconify className="me-3 mt-1" icon={"bxs:file"} />
                    <h4>Company Name:</h4>
                    <h4 className="mx-3">{ClientBasic?.companyName}</h4>
                  </div>

                  <div className="d-flex justify-content-end align-items-center">
                    <Iconify
                      style={{ cursor: "pointer" }}
                      icon={"zondicons:close-solid"}
                      onClick={BackToCalender}
                    />
                  </div>

                  <hr className="bold" />
                </div>
                <div
                  className="d-flex justify-content-end"
                  style={{ backgroundColor: "#68B4E6" }}
                >
                  <Button className="cl-green text-white" onClick={AuditCompletedHRA}>
                    Finish the Audit
                  </Button>
                </div>

                <CheckListTab />
              </div>
            </>
          ) : (
            <div className="client-list-border pt-0 pb-5 overflow-hidden">
              <div
                className="d-flex position-relative align-items-center  justify-content-between py-3"
                style={{ backgroundColor: "#68B4E6" }}
              >
                <div className="d-flex px-4 col-7">
                  <Iconify className="me-3 mt-1" icon={"bxs:file"} />
                  <h4>Certification Activity</h4>
                </div>
                <div className="d-flex col-4 ">
                  <Iconify className="me-3 mt-1" icon={"bxs:file"} />
                  <h4>Company Name:</h4>
                  <h5 className="mx-3">{ClientBasic?.companyName}</h5>
                </div>

                <div className="d-flex justify-content-end align-items-center">
                  <Iconify
                    style={{ cursor: "pointer" }}
                    icon={"zondicons:close-solid"}
                    onClick={BackToCalender}
                  />
                </div>

                <hr className="bold" />
              </div>
              <div
                className="d-flex justify-content-end"
                style={{ backgroundColor: "#68B4E6" }}
              >
                <Button className="cl-green text-white" onClick={Auditcompleted}>
                  Audit Completed
                </Button>
              </div>

              <Box sx={{ width: "100%" }}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                  <Tabs
                    className="tab-list"
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                  >
                    <Tab label="Activity Details" {...a11yProps(0)} />
                    <Tab label="Corrective Actions" {...a11yProps(1)} />
                    <Tab label="Documents" {...a11yProps(2)} />
                    <Tab label="Event Tracking" {...a11yProps(3)} />

                    {scheme_session !== "CB" && (
                      <>
                        <Tab label="Check list" {...a11yProps(4)} />
                        <Tab label="NC" {...a11yProps(5)} />
                      </>
                    )}
                  </Tabs>
                </Box>
                <CustomTabPanel value={value} index={0}>
                  <Activity_details
                    AuditId={AuditId}
                    ClientBasic={ClientBasic}
                    ClientAdvance={ClientAdvance}
                  />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={1}>
                  <Corrective
                    AuditId={AuditId}
                    setshowmoredata={setshowmoredata}
                    BackToCalender={BackToCalender}
                    ClientBasic={ClientBasic}
                  />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={2}>
                  <Documents AuditId={AuditId} ClientBasic={ClientBasic} />
                </CustomTabPanel>
                <CustomTabPanel value={value} index={3}>
                  <Event_tracking ClientBasic={ClientBasic} />
                </CustomTabPanel>

                {scheme_session !== "CB" && (
                  <>
                    <CustomTabPanel value={value} index={4}>
                      <HRA_Checklist />
                    </CustomTabPanel>
                    <CustomTabPanel value={value} index={5}>
                      <HRA_NC />
                    </CustomTabPanel>
                  </>
                )}
              </Box>
            </div>
          )
        )}
      </Container>
    </>
  );
}
