import React, { useEffect, useState } from "react";
import "../Client/Client-list.css";
import {
  Button,
  Card,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
} from "@mui/material";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import Iconify from "../../iconify/Iconify";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getData } from "src/webService/webService";
import { useDispatch, useSelector } from "react-redux";
import REVIEWERROUTES from "src/API_Routes/Reviewer/Reviewer_Router";
import { setClientBasicandAdvance } from "src/redux/slices/ReviewerSlice";
import ClientBasicAdvance from "src/components/ClientBasic_Advance/ClientBasic_Advance";
import ReviewerClientFilter from "../../Filter/Reviewer/ReviewerClientFilter";
import EVENT_TRACKING_ROUTES from "../../../API_Routes/EventTracking/EventTracking_Routers";
import EventDialog from "../../Events/Events";
import SERVER_URL from "src/utils/serverURL";

export default function ClientList({ Role }) {
  const dispatch = useDispatch();
  const isAuthenticated = sessionStorage.getItem("isAuthenticated");
  const REVIEWERVIEWCLIENTLIST = useSelector(
    (state) => state?.Reviewer?.ClientBasicandAdvance[0]?.paginatedResults
  );
  const accessToken = sessionStorage.getItem("Token");
  const scheme_session = sessionStorage.getItem("scheme");

  // Table Conditions
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(5);
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleLimitChange = (event) => {
    setCurrentPage(1);
    setLimit(event.target.value);
  };
  // Table Conditions
  const GETReviewer = async (page) => {
    const params = {
      scheme: Role === "Reviewer" ? "CB" : "HRA",
      page: page,
      limit: limit,
    };
    try {
      const USER_ID = sessionStorage.getItem("UserId");
      let res;
      if (Role === "Reviewer") {
        res = await getData(
          REVIEWERROUTES.GETCLIENTLIST(USER_ID),
          params,
          accessToken
        );
      } else {
        res = await getData(
          `clients/client/basic/advanced/all`,
          params,
          accessToken
        );
      }

      if (res.status === 200) {
        dispatch(setClientBasicandAdvance(res.data));
        setTotalPages(res.data[0].totalPages);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  useEffect(() => {
    GETReviewer(currentPage);
  }, [currentPage, limit]);

  //view-client
  const [viewClient, setViewClient] = useState(false);
  const handleViewClient = () => {
    setViewClient(!viewClient);
    setSpecificClientId(null);
    GETReviewer(currentPage);
  };

  //specific client id
  const [specificClientId, setSpecificClientId] = useState(null);
  //pass the specific client id data to view client
  const [specificClientData, setSpecificClientData] = useState();
  useEffect(() => {
    if (specificClientId !== null) {
      // Find the specific client data based on specificClientId
      const client = REVIEWERVIEWCLIENTLIST.find(
        (client) => client._id === specificClientId
      );
      // If client is found, set it in the state
      if (client) {
        setSpecificClientData(client);
      }
      setViewClient(true);
    }
  }, [specificClientId]);

  // Export data
  // Function to handle export action
  const handleExport = () => {
    const headers = [
      "Client Id",
      "Company Name",
      "Technical Main Code",
      "Technical Sub Code",
      "Scheme",
      "Location",
      "Standards",
      "Status",
    ].filter(Boolean);
    // Filter checked items
    const checkedItems = REVIEWERVIEWCLIENTLIST.filter(
      (item) => isChecked[item._id]
    );
    let data;
    data = checkedItems.map((item) => {
      return [
        item.clientId,
        item.companyName,
        item.technicalCode.mainCode,
        item.technicalCode.subCode,
        item.standardsSchemeType,
        item.address,
        item.standardsSchemeType === "IMS"
          ? item.ClientFormIMS_Data[0]?.standards?.[0] || "-"
          : item.ClientFormFSMS_Data[0]?.standards?.[0] || "-",
        item.status.type,
      ];
    });

    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Client List Data");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    saveAs(
      new Blob([excelBuffer], { type: "application/octet-stream" }),
      "client_list_data.xlsx"
    );
  };

  const handleExportHRA = () => {
    const headers = [
      "Client Id",
      "Company Name",
      "Technical Main Code",
      "Technical Sub Code",
      "Scheme",
      "Location",
      "Status",
    ].filter(Boolean);
    // Filter checked items
    const checkedItems = REVIEWERVIEWCLIENTLIST.filter(
      (item) => isChecked[item._id]
    );
    let data;
    data = checkedItems.map((item) => {
      return [
        item.clientId,
        item.companyName,
        item.technicalCode.mainCode,
        item.technicalCode.subCode,
        item.standardsSchemeType,
        item.address,
        item.status.type,
      ];
    });

    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Client List Data");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    saveAs(
      new Blob([excelBuffer], { type: "application/octet-stream" }),
      "client_list_data.xlsx"
    );
  };

  const [isChecked, setIsChecked] = useState({});
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  // Function to handle checkbox change
  const handleCheckboxChange = (event, id) => {
    setIsChecked({ ...isChecked, [id]: event.target.checked });
  };
  // Function to handle select all checkbox change
  const handleSelectAllCheckboxChange = (event) => {
    const { checked } = event.target;
    const updatedIsChecked = {};

    // Update isChecked object with all checkboxes' states
    REVIEWERVIEWCLIENTLIST.forEach((row) => {
      updatedIsChecked[row._id] = checked;
    });

    setIsChecked(updatedIsChecked);
    setSelectAllChecked(checked);
  };

  //Filter
  const [filter, SetFilter] = useState(false);
  const ChangeFilter = () => {
    SetFilter(!filter);
  };
  useEffect(() => {
    if (!filter) {
      GETReviewer(currentPage);
    }
  }, [filter]);

  // Events
  const [Eventopen, setEventOpen] = useState(false);
  const [Events, setEvents] = useState();
  const handleEventClickOpen = async (id) => {
    const res = await getData(
      EVENT_TRACKING_ROUTES.CREATE_EVENT_HISTORY(id),
      null,
      accessToken
    );
    setEvents(res.data);
    setEventOpen(true);
  };

  const handleEventClose = () => {
    setEventOpen(false);
  };

  return (
    <>
      <ToastContainer />
      <EventDialog
        open={Eventopen}
        handleClose={handleEventClose}
        events={Events}
      />
      {!viewClient ? (
        <div className="cso-clientlist-border p-5">
          <div className="px-2 add-title">
            <span className="">Client List</span>
          </div>
          {isAuthenticated === "Admin" && (
            <div className="d-flex justify-content-end mb-3">
              {scheme_session === "CB" ? (
                <Button
                  variant="contained"
                  className="btn-orange text-dark me-0"
                  onClick={handleExport}
                >
                  Export
                </Button>
              ) : (
                <Button
                  variant="contained"
                  className="btn-orange text-dark me-0"
                  onClick={handleExportHRA}
                >
                  Export
                </Button>
              )}
              <Button className="btn-green text-white" onClick={ChangeFilter}>
                {filter ? "Close Filter" : "Show Filter"}
              </Button>
            </div>
          )}

          {filter && <ReviewerClientFilter />}
          <Card>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {isAuthenticated === "Admin" && (
                      <TableCell
                        className="cl-green text-dark"
                        style={{ padding: "5px" }}
                      >
                        <Checkbox
                          checked={selectAllChecked}
                          onChange={handleSelectAllCheckboxChange}
                        />
                      </TableCell>
                    )}

                    <TableCell className="cl-green text-dark">
                      Client Id
                    </TableCell>
                    <TableCell className="cl-green text-dark">
                      Company Name
                    </TableCell>
                    {scheme_session !== "HRA" && (
                      <>
                        <TableCell className="cl-green text-dark">
                          Technical Main Code
                        </TableCell>
                        <TableCell className="cl-green text-dark">
                          Technical Sub Code
                        </TableCell>
                      </>
                    )}
                    <TableCell className="cl-green text-dark">Scheme</TableCell>
                    <TableCell className="cl-green text-dark">
                      Location
                    </TableCell>
                    <TableCell className="cl-green text-dark" align="center">
                      Status
                    </TableCell>
                    <TableCell className="cl-green text-dark" align="center">
                      Action
                    </TableCell>
                    <TableCell className="cl-green text-dark" align="center">
                      Events
                    </TableCell>

                  </TableRow>
                </TableHead>
                <TableBody>
                  {REVIEWERVIEWCLIENTLIST &&
                    REVIEWERVIEWCLIENTLIST.length !== 0 ? (
                    REVIEWERVIEWCLIENTLIST?.map((row, index) => (
                      <TableRow key={index}>
                        {isAuthenticated === "Admin" && (
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isChecked[row._id] || false}
                              onChange={(event) =>
                                handleCheckboxChange(event, row._id)
                              }
                            />
                          </TableCell>
                        )}

                        <TableCell>{row.clientId}</TableCell>
                        <TableCell>{row.companyName}</TableCell>
                        {scheme_session !== "HRA" && (
                          <>
                            <TableCell>{row.technicalCode.mainCode}</TableCell>
                            <TableCell>{row.technicalCode.subCode}</TableCell>
                          </>
                        )}
                        <TableCell>{row.standardsSchemeType}</TableCell>
                        <TableCell>
                          {row.state} / {row.country}
                        </TableCell>
                        <TableCell align="center">
                          {typeof row.status === "object"
                            ? row.status.type
                            : row.status}
                        </TableCell>

                        <TableCell align="center">
                          {row.status !== "pending" ||
                            row.status.type !== "pending" ? (
                            <Iconify
                              style={{ cursor: "pointer" }}
                              className="cursor-pointer"
                              icon={"raphael:view"}
                              onClick={() => {
                                setSpecificClientId(row._id);
                              }}
                            />
                          ) : (
                            "-"
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <Iconify
                            style={{ cursor: "pointer" }}
                            className="cursor-pointer"
                            icon={"raphael:view"}
                            onClick={() => {
                              handleEventClickOpen(row._id);
                            }}
                          />
                        </TableCell>

                      </TableRow>
                    ))
                  ) : (
                    <h6 className="text-center my-3 w-100">No Data</h6>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="d-flex flex-wrap justify-content-end m-3">
              <FormControl variant="outlined" size="small">
                <InputLabel id="limit-label">Limit</InputLabel>
                <Select
                  labelId="limit-label"
                  id="limit-select"
                  value={limit}
                  onChange={handleLimitChange}
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={15}>15</MenuItem>
                </Select>
              </FormControl>
              <Button onClick={handlePrevPage} disabled={currentPage === 1}>
                <Iconify icon={"grommet-icons:previous"} />
              </Button>
              <Button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                <Iconify icon={"grommet-icons:next"} />
              </Button>
            </div>
          </Card>
        </div>
      ) : (
        <ClientBasicAdvance
          handleViewClient={handleViewClient}
          specificClientData={specificClientData}
        />
      )}
    </>
  );
}
