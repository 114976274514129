import React from "react";
import Upcomingclient from "../../components/UpcomingClient/Upcomingclient";

function UpcomingClients() {
  return (
    <>
      <Upcomingclient />
    </>
  );
}

export default UpcomingClients;
