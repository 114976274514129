import {
  Button,
  TextField,
  FormControl,
  RadioGroup,
  FormControlLabel,
  Radio,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CLIENTROUTES from "src/API_Routes/Client/Client_Routers";
import GETCLIENTADVANCE from "src/API_Routes/Client/Client_Routers";
import { patchData, postData } from "src/webService/webService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Iconify from "src/components/iconify";
import { useDispatch, useSelector } from "react-redux";
import FILEROUTES from "src/API_Routes/File/File_Router";
import { number } from "prop-types";
import DeleteIcon from "@mui/icons-material/Delete";
import { getData, FileUpload } from "src/webService/webService";
import EVENT_TRACKING_ROUTES from "../../API_Routes/EventTracking/EventTracking_Routers";
import EMAIL_ROUTES from "../../API_Routes/Email/Email_Routers";
import CommonEmail from "../../Config/templates/html/CommonEmailTem";
import TransferSA1Form from "../AuditTypeCategory/AuditTypeCategory";
import TransferSA2Form from "../AuditTypeCategory/TransferSA2Form";
import TransferReCertification from "../AuditTypeCategory/TransferReCertification";
import TransferRAForm from "../AuditTypeCategory/TransferReCertification";
import RAForm from "../AuditTypeCategory/ReCertification";
import { getFormattedIndiaTime } from "../../utils/timeutils";
import SERVER_URL from "../../utils/serverURL";
import { de } from "date-fns/locale";
const UserId = sessionStorage.getItem("UserId");

export default function AdvanceDetails({
  HandleAdvancedContact,
  HandleAddClient,
  action,
}) {
  const dispatch = useDispatch();
  const isAuthenticated = sessionStorage.getItem("isAuthenticated");
  // audit fee
  const [aduitfeecategory, setaduitfeeCategory] = useState("");

  const handleaduitfeeCategoryChange = (event) => {
    setaduitfeeCategory(event.target.value);
  };
  const selectedFeestype = useSelector((state) => state.Fee);
  const scheme_session = sessionStorage.getItem("scheme");
  const Client_basic = useSelector((state) => state.SpecificClient.Basic);
  const client_id = useSelector((state) => state.SpecificClient.Basic._id);
  const SpecificClientAdvance = useSelector(
    (state) => state.SpecificClient.Advance
  );
  const accessToken = sessionStorage.getItem("Token");
  // Acccount info
  const [NoOfHACCP_study, setNoOfHACCP_study] = useState();
  const [proposalDate, setproposalDate] = useState("");
  const [title, settitle] = useState("");
  const [city, setcity] = useState("");
  const [postCode, setpostCode] = useState("");
  const [billingContact, setbillingContact] = useState({
    name: "",
    designation: "",
  });
  const [PAN, setPAN] = useState("");
  const [GSTNumber, setGSTNumber] = useState("");
  const [localResource, setlocalResource] = useState("");
  const [expectedDateOfAuditPlanned, setexpectedDateOfAuditPlanned] =
    useState("");

  // Proposal Info
  const [standards, setstandards] = useState([]);
  const [certificationBody, setcertificationBody] = useState("");
  const [CAUSSA_ContractingOffice, setCAUSSA_ContractingOffice] = useState("");
  const [accreditations, setaccreditations] = useState([]);
  const [scopeOfCertification, setscopeOfCertification] = useState("");
  const [totalNoOfEmployees, settotalNoOfEmployees] = useState();
  const [noOfEffectiveFTE, setnoOfEffectiveFTE] = useState();
  const [noOfShifts, setnoOfShifts] = useState();
  const [locationMultiSiteInfo, setlocationMultiSiteInfo] = useState("");
  const [HO_or_CentralOffice, setHO_or_CentralOffice] = useState("");
  const [surveillanceFrequency, setsurveillanceFrequency] = useState("");

  //aditional details
  const [ccode, setccode] = useState("");
  const [typeOfServices, setTypeOfServices] = useState("");
  const [auditType, setauditType] = useState("");
  const [kob, setkob] = useState("");
  const [noOffoodHandlers, setnoOffoodHandlers] = useState();
  const [
    FoSTaC_TrainedFoodSafetySupervisors,
    setFoSTaC_TrainedFoodSafetySupervisors,
  ] = useState("");
  const [
    FoSTaC_TrainedFoodSafetySupervisorsArray,
    setFoSTaC_TrainedFoodSafetySupervisorsArray,
  ] = useState([]);
  const [
    uniqueCertificateNoAndCourseName,
    setuniqueCertificateNoAndCourseName,
  ] = useState("");
  const [FSSAI_LicenseNumber, setFSSAI_LicenseNumber] = useState("");
  const [validityOfLicense, setvalidityOfLicense] = useState("");
  const [
    authorisedSignatoryOfFoodServiceEstablishmentFileId,
    setauthorisedSignatoryOfFoodServiceEstablishmentFileId,
  ] = useState();
  const [
    authorisedSignatoryOfFoodServiceEstablishmentFileIdObject,
    setauthorisedSignatoryOfFoodServiceEstablishmentFileIdObject,
  ] = useState("");
  const [ASFSObject, setASFSObject] = useState(null);
  const [ASFSObjectFinal, setASFSObjectFinal] = useState(null);
  const [remarks, setRemarks] = useState();
  const [reviewPointsAndDocuments, setReviewPointsAndDocuments] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [documentFiles, setDocumentFiles] = useState([]);
  const [totalNoOfEmployees_HRA, settotalNoOfEmployees_HRA] = useState();
  const [headOffice, setHeadOffice] = useState();
  const [sites, setSites] = useState([]);
  const [tempSites, setTempSites] = useState([]);
  const [correctSts, setCorrectSts] = useState(false);
  const [clientType, setClientType] = useState("");
  const [shift, setShift] = useState({
    general: 0,
    first: 0,
    second: 0,
    third: 0,
  });
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setShift((prevShift) => ({
      ...prevShift,
      [name]: Number(value),
    }));
  };
  const [courseCode, setCourseCode] = useState("");
  const [courseName, setCourseName] = useState("");
  const [mainProduction, setMainProduction] = useState("");
  // AccountInfo
  const [years, setYears] = useState([
    {
      year: "Year 1",
      stages: [
        { onSite: "", offSite: "", fee: "" },
        { onSite: "", offSite: "", fee: "" },
      ],
    },
    {
      year: "Year 2",
      surveillanceAudits: [{ onSite: "", offSite: "", fee: "" }],
    },
    {
      year: "Year 3",
      surveillanceAudits: [{ onSite: "", offSite: "", fee: "" }],
    },
  ]);
  const [administrationFee, setadministrationFee] = useState();
  const [totalContractFee, settotalContractFee] = useState();
  const [newData, setnewData] = useState();
  const handleYearChange = (index, field, stageIndex, value) => {
    const updatedYears = [...years];
    if (field === "stage") {
      updatedYears[index].stages[stageIndex][value.target.name] =
        value.target.value;
    } else {
      updatedYears[index][field][0][value.target.name] = value.target.value;
    }
    setYears(updatedYears);
  };
  const handlebillingContact = (e) => {
    const { name, value } = e.target;
    setbillingContact({
      ...billingContact,
      [name]: value,
    });
  };
  const [CIFA_FileObjects, setCIFA_FileObjects] = useState();
  const removeCIFA_FileObjects = (id) => {
    setCIFA_FileObjects(CIFA_FileObjects.filter((file) => file._id !== id));
  };

  const [ScopeExtension_CIFA_FileObject, setScopeExtension_CIFA_FileObject] =
    useState();
  const removeScopeExtension_CIFA_FileObject = () => {
    setScopeExtension_CIFA_FileObject();
  };
  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    const uploadRes = await FileUpload(
      `${FILEROUTES.UPLOAD}${UserId}`,
      formData,
      dispatch
    );
    setScopeExtension_CIFA_FileObject(uploadRes.data);
  };
  useEffect(() => {
    const getTodayDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };
    setproposalDate(getTodayDate());
    setexpectedDateOfAuditPlanned(getTodayDate());
  }, []);
  const prepareDataForApi = () => {
    var formattedData = {
      stage: [],
      surveillanceAudit: [],
    };
    let surveillanceAuditCounter = 0;
    // Loop over each year
    years.forEach((year) => {
      // If the year has stages
      if (year.stages) {
        year.stages.forEach((stage, index) => {
          // Push data into stage array
          formattedData.stage.push({
            stageNumber: index + 1, // Assuming stages start from 1
            manDay: {
              year: 1, // Assuming year is based on stage number
              onSite: stage.onSite,
              offSite: stage.offSite,
              fee: stage.fee,
            },
            totalFee: null, // You need to calculate this or set it as per your requirement
          });
        });
      }

      // If the year has surveillance audits
      if (year.surveillanceAudits) {
        year.surveillanceAudits.forEach((audit, index) => {
          // Push data into surveillanceAudit array
          formattedData.surveillanceAudit.push({
            surveillanceAuditNumber: ++surveillanceAuditCounter, // Assuming audits start from 1
            manDay: {
              year: surveillanceAuditCounter + 1, // Assuming year is based on audit number
              onSite: audit.onSite,
              offSite: audit.offSite,
              fee: audit.fee,
            },
            totalFee: null, // You need to calculate this or set it as per your requirement
          });
        });
      }
    });
    console.log("formattedData", formattedData);
    return formattedData;
  };
  const UpdateClientAdvance = async () => {
    const formattedDate = getFormattedIndiaTime();
    const formattedData = prepareDataForApi();
    let auditCertificationActivitiesAndFees;

    if (aduitfeecategory === "Transfer Certification in SA1") {
      auditCertificationActivitiesAndFees = selectedFeestype.TransferSA1;
    } else if (aduitfeecategory === "Transfer Certification in SA2") {
      auditCertificationActivitiesAndFees = selectedFeestype.TransferSA2;
    } else if (
      aduitfeecategory === "Transfer Certification in Re-Certification"
    ) {
      auditCertificationActivitiesAndFees = selectedFeestype.TransferRA;
    } else if (aduitfeecategory === "Re-Certification") {
      auditCertificationActivitiesAndFees = selectedFeestype.ReCertification;
    } else {
      auditCertificationActivitiesAndFees = {
        administrationFee: administrationFee,
        totalContractFee: totalContractFee,
        stage: formattedData ? formattedData.stage : [],
        surveillanceAudit: formattedData ? formattedData.surveillanceAudit : [],
        auditTypeStates: [
          {
            year: 1,
            name: "Initial Certification",
          },
        ],
      };
    }
    let Params;
    if (Client_basic.standardsSchemeType !== "HRA") {
      Params = {
        status:
          Client_basic.status.type === "need revision"
            ? {
                type: "revision resolved",
                updatedDate: formattedDate,
              }
            : {
                type: "client details filled",
                updatedDate: formattedDate,
              },
        shift: shift,
        clientBasicId: client_id,
        proposalDate: proposalDate,
        title: title,
        city: city,
        postCode: postCode,
        billingContact: billingContact,
        PAN: PAN,
        GSTNumber: GSTNumber,
        expectedDateOfAuditPlanned: expectedDateOfAuditPlanned,
        localResource: localResource,

        // Proposal Info
        standards: standards,
        certificationBody: certificationBody,
        CAUSSA_ContractingOffice: CAUSSA_ContractingOffice,
        CIFA_FileObjects: CIFA_FileObjects,
        ScopeExtension_CIFA_FileObject: ScopeExtension_CIFA_FileObject,
        accreditations: accreditations,
        scopeOfCertification: scopeOfCertification,
        totalNoOfEmployees: totalNoOfEmployees,
        noOfEffectiveFTE: noOfEffectiveFTE,
        noOfShifts: noOfShifts,
        locationMultiSiteInfo: locationMultiSiteInfo,
        HO_or_CentralOffice: HO_or_CentralOffice,
        surveillanceFrequency: surveillanceFrequency,
        ...(Client_basic.standardsSchemeType === "FSMS" && {
          NoOfHACCP_study: NoOfHACCP_study,
        }),

        // AccountInfo
        auditCertificationActivitiesAndFees:
          auditCertificationActivitiesAndFees,
      };
    } else {
      Params = {
        status: {
          type: "client advance completed",
          updatedDate: formattedDate,
        },
        cCode: ccode,
        typeOfServices: typeOfServices,
        auditType: auditType,
        kob: kob,
        noOfFoodHandlers: noOffoodHandlers,
        FoSTaC_TrainedFoodSafetySupervisors:
        FoSTaC_TrainedFoodSafetySupervisors,
        uniqueCertificateNo: uniqueCertificateNoAndCourseName,
        FSSAI_LicenseNumber: FSSAI_LicenseNumber,
        validityOfLicense: validityOfLicense,
        authorisedSignatoryOfFoodServiceEstablishmentFileObject:
          ASFSObjectFinal,
        GSTNumber: GSTNumber,
        remarks: remarks,
        reviewPointsAndDocuments: reviewPointsAndDocuments,
        site: sites,
        totalNoOfEmployees: totalNoOfEmployees_HRA,
        clientType: clientType,
        headOffice: headOffice,
        mainProduction: mainProduction,
        courseName: courseName,
        courseCode: courseCode,
      };
    }

    if (action === "Edit" && SpecificClientAdvance !== "") {
      patchData(
        `${CLIENTROUTES.GETSPECIFICUSERADVANCE}${client_id}/advanced`,
        Params,
        accessToken,
        dispatch
      )
        .then((res) => {
          console.log("res", res);
          HandleAddClient();
          postData(
            EVENT_TRACKING_ROUTES.CREATE_EVENT_HISTORY(client_id),
            {
              description: `Client Advance Edited`,
              performedBy: `${sessionStorage.getItem(
                "firstName"
              )} ${sessionStorage.getItem("lastName")}`,
            },
            accessToken,
            dispatch
          );
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      postData(
        `${CLIENTROUTES.GETCLIENTADVANCE}${client_id}/advanced`,
        Params,
        accessToken,
        dispatch
      )
        .then((res) => {
          console.log("res", res);
          HandleAddClient();

          //Email
          // const HTML_DATA = {
          //   ClientName: Client_basic.companyName,
          //   ContactPerson: Client_basic.contactPerson,
          //   ClientID: Client_basic.clientId,
          //   AuditType: "INITIAL_ACCREDITATION_AND_ADMIN_COST",
          //   Message:
          //     "The above client’s CIFA & proposal are attached for the contract review",
          // };
          // console.log("HTML_DATA", HTML_DATA);
          // postData(
          //   EMAIL_ROUTES.SEND_EMAIL_TEXT,
          //   {
          //     to: "selvam0803.dt@gmail.com",
          //     subject: "Sales Client Advance updated",
          //     html: CommonEmail(HTML_DATA),
          //   },
          //   accessToken
          // );
          //Email

          postData(
            EVENT_TRACKING_ROUTES.CREATE_EVENT_HISTORY(client_id),
            {
              description: `Client Advance updated`,
              performedBy: `${sessionStorage.getItem(
                "firstName"
              )} ${sessionStorage.getItem("lastName")}`,
            },
            accessToken,
            dispatch
          );
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };
  const AdvanceClientData = () => {
    if (answers !== null) {
      if (
        answers.every((answer) => answer !== null)
        // &&
        // (documentFiles.length === 0 || documentFiles.every((file) => file !== null))
      ) {
        const newReviewPoints = questions.map((question, index) => ({
          reviewQuestion: question,
          reviewAnswer: answers[index],
          reviewEvidenceDocumentFileObject: documentFiles[index] || "",
        }));
        if (action === "Edit") {
          setReviewPointsAndDocuments(newReviewPoints);
        } else {
          setReviewPointsAndDocuments((prevReviewPoints) => [
            ...prevReviewPoints,
            ...newReviewPoints,
          ]);
        }

        setAnswers(Array(questions.length).fill(true));
        setDocumentFiles(Array(questions.length).fill(null));
        setCorrectSts(true);
      }
    }

    setSites(tempSites);
  };
  useEffect(() => {
    if (reviewPointsAndDocuments) {
      const updatedAnswers = [...answers];
      const updatedDocumentFiles = [...documentFiles];

      reviewPointsAndDocuments.forEach((item) => {
        const index = questions.indexOf(item.reviewQuestion);
        if (index !== -1) {
          updatedAnswers[index] = item.reviewAnswer;
          updatedDocumentFiles[index] = item.reviewEvidenceDocumentFileObject;
        }
      });

      setAnswers(updatedAnswers);
      setDocumentFiles(updatedDocumentFiles);
    }
    if (reviewPointsAndDocuments.length !== 0 && correctSts) {
      UpdateClientAdvance();
    }
  }, [reviewPointsAndDocuments]);

  useEffect(() => {
    if (SpecificClientAdvance) {
      if (Client_basic.standardsSchemeType !== "HRA") {
        setaduitfeeCategory(
          SpecificClientAdvance?.auditCertificationActivitiesAndFees
            ?.auditTypeStates?.[0]?.name || ""
        );
        setproposalDate(
          new Date(SpecificClientAdvance.proposalDate)
            .toISOString()
            .split("T")[0]
        );
        settitle(SpecificClientAdvance.title);
        setcity(SpecificClientAdvance.city);
        setpostCode(SpecificClientAdvance.postCode);
        setbillingContact({
          name: SpecificClientAdvance.billingContact.name,
          designation: SpecificClientAdvance.billingContact.designation,
        });
        setPAN(SpecificClientAdvance.PAN);
        setGSTNumber(SpecificClientAdvance.GSTNumber);
        setlocalResource(SpecificClientAdvance.localResource);
        setexpectedDateOfAuditPlanned(
          new Date(SpecificClientAdvance.expectedDateOfAuditPlanned)
            .toISOString()
            .split("T")[0]
        );
        setstandards(SpecificClientAdvance.standards[0]);
        setcertificationBody(SpecificClientAdvance.certificationBody);
        setCAUSSA_ContractingOffice(
          SpecificClientAdvance.CAUSSA_ContractingOffice
        );
        setaccreditations(SpecificClientAdvance.accreditations[0]);
        setscopeOfCertification(SpecificClientAdvance.scopeOfCertification);
        settotalNoOfEmployees(SpecificClientAdvance.totalNoOfEmployees);
        setnoOfEffectiveFTE(SpecificClientAdvance.noOfEffectiveFTE);
        setnoOfShifts(SpecificClientAdvance.noOfShifts);
        setlocationMultiSiteInfo(SpecificClientAdvance.locationMultiSiteInfo);
        setHO_or_CentralOffice(SpecificClientAdvance.HO_or_CentralOffice);
        setCIFA_FileObjects(SpecificClientAdvance.CIFA_FileObjects);
        setScopeExtension_CIFA_FileObject(
          SpecificClientAdvance?.ScopeExtension_CIFA_FileObject
        );
        setsurveillanceFrequency(SpecificClientAdvance.surveillanceFrequency);
        if (
          SpecificClientAdvance.auditCertificationActivitiesAndFees
            .auditTypeStates
        ) {
          setadministrationFee(
            SpecificClientAdvance.auditCertificationActivitiesAndFees
              .administrationFee
          );
          settotalContractFee(
            SpecificClientAdvance.auditCertificationActivitiesAndFees
              .totalContractFee
          );
          updateYearsState(
            SpecificClientAdvance.auditCertificationActivitiesAndFees
          );
        }
        setShift(SpecificClientAdvance?.shift);
        setNoOfHACCP_study(SpecificClientAdvance?.NoOfHACCP_study);
      } else {
        setccode(SpecificClientAdvance.cCode);
        setTypeOfServices(SpecificClientAdvance.typeOfServices);
        setauditType(SpecificClientAdvance.auditType);
        setkob(SpecificClientAdvance.kob);
        setnoOffoodHandlers(SpecificClientAdvance.noOfFoodHandlers);
        // setFoSTaC_TrainedFoodSafetySupervisors(SpecificClientAdvance.FoSTaC_TrainedFoodSafetySupervisors);
        setFoSTaC_TrainedFoodSafetySupervisors(
          SpecificClientAdvance?.FoSTaC_TrainedFoodSafetySupervisors[0]
        );
        setuniqueCertificateNoAndCourseName(
          SpecificClientAdvance.uniqueCertificateNo
        );
        setFSSAI_LicenseNumber(SpecificClientAdvance.FSSAI_LicenseNumber);
        setvalidityOfLicense(SpecificClientAdvance.validityOfLicense);
        setGSTNumber(SpecificClientAdvance.GSTNumber);
        // setauthorisedSignatoryOfFoodServiceEstablishmentFileId(SpecificClientAdvance.authorisedSignatoryOfFoodServiceEstablishmentFileObject);
        // setauthorisedSignatoryOfFoodServiceEstablishmentFileIdObject(SpecificClientAdvance.authorisedSignatoryOfFoodServiceEstablishmentFileObject);
        // setASFSObject(SpecificClientAdvance.authorisedSignatoryOfFoodServiceEstablishmentFileObject[0]);
        setASFSObjectFinal(
          SpecificClientAdvance
            .authorisedSignatoryOfFoodServiceEstablishmentFileObject[0]
        );
        setRemarks(SpecificClientAdvance.remarks);

        setReviewPointsAndDocuments(
          SpecificClientAdvance.reviewPointsAndDocuments
        );
        // setAnswers(SpecificClientAdvance.reviewPointsAndDocuments.reviewAnswer);
        // setDocumentFiles(SpecificClientAdvance.reviewPointsAndDocuments.reviewEvidenceDocumentFileObject);
        settotalNoOfEmployees_HRA(SpecificClientAdvance.totalNoOfEmployees);
        setClientType(SpecificClientAdvance.clientType);
        setHeadOffice(SpecificClientAdvance.headOffice);
        // setSites(SpecificClientAdvance.site);
        setTempSites(SpecificClientAdvance.site);
        setCourseCode(SpecificClientAdvance.courseCode);
        setCourseName(SpecificClientAdvance.courseName);
        setMainProduction(SpecificClientAdvance.mainProduction);
      }
    }
  }, [SpecificClientAdvance]);

  const updateYearsState = (newData) => {
    if (
      SpecificClientAdvance &&
      SpecificClientAdvance.auditCertificationActivitiesAndFees &&
      SpecificClientAdvance.auditCertificationActivitiesAndFees
        .auditTypeStates &&
      SpecificClientAdvance.auditCertificationActivitiesAndFees
        .auditTypeStates[0]?.name !== "Transfer Certification in SA1" &&
      SpecificClientAdvance.auditCertificationActivitiesAndFees
        .auditTypeStates[0]?.name !== "Transfer Certification in SA2" &&
      SpecificClientAdvance.auditCertificationActivitiesAndFees
        .auditTypeStates[0]?.name !==
        "Transfer Certification in Re-Certification" &&
      SpecificClientAdvance.auditCertificationActivitiesAndFees
        .auditTypeStates[0]?.name !== "Re-Certification"
    ) {
      // Create a copy of the current state
      const updatedYears = [...years];

      // Iterate over the stage data and update the corresponding entries in the years state
      newData.stage.forEach((stageData) => {
        updatedYears[stageData.manDay.year - 1].stages[
          stageData.stageNumber - 1
        ] = {
          onSite: stageData.manDay.onSite,
          offSite: stageData.manDay.offSite,
          fee: stageData.manDay.fee,
        };
      });

      // Iterate over the surveillance audit data and update the corresponding entries in the years state
      newData.surveillanceAudit.forEach((surveillanceAuditData) => {
        updatedYears[
          surveillanceAuditData.manDay.year - 1
        ].surveillanceAudits[0] = {
          onSite: surveillanceAuditData.manDay.onSite,
          offSite: surveillanceAuditData.manDay.offSite,
          fee: surveillanceAuditData.manDay.fee,
        };
      });

      // Update the state with the modified data
      setYears(updatedYears);
    } else {
      console.log("no data");
    }
  };

  const handleTickClick = () => {
    if (FoSTaC_TrainedFoodSafetySupervisors.trim() !== "") {
      setFoSTaC_TrainedFoodSafetySupervisorsArray((prevValues) => [
        ...prevValues,
        FoSTaC_TrainedFoodSafetySupervisors,
      ]);
      setFoSTaC_TrainedFoodSafetySupervisors(""); // Clear input field after adding value
    }
  };

  const removeLanguage = (index) => {
    setFoSTaC_TrainedFoodSafetySupervisorsArray((prevArray) =>
      prevArray.filter((_, i) => i !== index)
    );
  };

  const handleFileChange = (event, data) => {
    const file = event.target.files[0];
    if (data === "Authorised Signature") {
      setauthorisedSignatoryOfFoodServiceEstablishmentFileId(file);
    }
    event.target.value = null;
  };

  useEffect(() => {
    const uploadFile = async (
      file,
      setFileObject,
      setFileObjectVal,
      setFile
    ) => {
      if (file) {
        try {
          const formData = new FormData();
          formData.append("file", file);
          const res = await FileUpload(
            FILEROUTES.UPLOAD + UserId,
            formData,
            dispatch
          );
          setFileObject(res.data);
          setFileObjectVal(res.data);
          setFile("");
        } catch (error) {
          setFile("");
          console.error("Error:", error);
        }
      }
    };
    uploadFile(
      authorisedSignatoryOfFoodServiceEstablishmentFileId,
      setASFSObject,
      setASFSObjectFinal,
      setauthorisedSignatoryOfFoodServiceEstablishmentFileId
    );
  }, [authorisedSignatoryOfFoodServiceEstablishmentFileId]);

  useEffect(() => {
    if (answers.length === 0 && documentFiles.length === 0) {
      // Initialize answers state with default values
      setAnswers(Array(questions.length).fill(true)); // Default all answers to true
      // Initialize documentFiles state with default values
      setDocumentFiles(Array(questions.length).fill(null)); // Default all files to null
    }
  }, []);

  const questions = [
    "Whether the Client is having valid FSSAI License?",
    "Whether the Client has FSS for the premises?",
    "Whether the FSS has done the appropriate course pertaining to the products mentioned in the FSSAI?",
    "Food Safety Display Boards are displayed at the premises?",
    "Whether the water testing is periodically done for potability?",
    "Whether the Food Samples testing is periodically done as FSSAI norms?",
  ];

  const handleAnswerChange = (index, value) => {
    const newAnswers = [...answers];
    newAnswers[index] = value;
    setAnswers(newAnswers);
  };

  const handleQuesandAns = async (index, file) => {
    const formData = new FormData();
    formData.append("file", file);
    const res = await FileUpload(
      FILEROUTES.UPLOAD + UserId,
      formData,
      dispatch
    );
    const updatedFile = res.data;
    const newDocumentFiles = [...documentFiles];
    newDocumentFiles[index] = updatedFile;
    setDocumentFiles(newDocumentFiles);
  };

  const totalSites = Client_basic && Client_basic.noOfSites;
  const handleSiteNameChange = (index, value) => {
    const updatedTempSites = [...tempSites];
    updatedTempSites[index] = { ...updatedTempSites[index], name: value };
    setTempSites(updatedTempSites);
  };

  const handleSiteLocationChange = (index, value) => {
    const updatedTempSites = [...tempSites];
    updatedTempSites[index] = { ...updatedTempSites[index], location: value };
    setTempSites(updatedTempSites);
  };

  const renderSiteFields = () => {
    const fields = [];
    for (let i = 0; i < totalSites; i++) {
      fields.push(
        // <div >
        <div key={i} className="col-4 px-2">
          <div className="col-12 px-2 py-3">
            <TextField
              className="w-100"
              name={`name_${i + 1}`}
              label={`Site Name ${i + 1}`}
              value={tempSites[i]?.name || ""}
              size="small"
              onChange={(e) => handleSiteNameChange(i, e.target.value)}
              required
            />
          </div>
          <div className="col-12 px-2 py-3">
            <TextField
              className="w-100"
              name={`location_${i + 1}`}
              label={`Site Location ${i + 1}`}
              value={tempSites[i]?.location || ""}
              size="small"
              onChange={(e) => handleSiteLocationChange(i, e.target.value)}
              required
            />
          </div>
        </div>
      );
    }
    return fields;
  };

  const edituser = () => {
    console.log("submitted the editted data");
  };

  const handleClientType = (e) => {
    setClientType(e.target.value);
  };

  const handleTypeOfServices = (e) => {
    setTypeOfServices(e.target.value);
  };

  return (
    <>
      <ToastContainer />

      {Client_basic.standardsSchemeType !== "HRA" ? (
        <>
          <h6 className="mb-3 fw-bold">Account Information</h6>
          <div className="d-flex flex-wrap justify-content-start">
            <div className="col-4 px-2 py-3">
              <TextField
                className="w-100"
                type="date"
                name="proposalDate"
                label="Proposal Date"
                value={proposalDate}
                size="small"
                onChange={(e) => setproposalDate(e.target.value)}
                required
              />
            </div>
            <div className="col-4 px-2 py-3">
              <TextField
                className="w-100"
                name="title"
                label="Title"
                value={title}
                size="small"
                onChange={(e) => settitle(e.target.value)}
                required
              />
            </div>
            <div className="col-4 px-2 py-3">
              <TextField
                className="w-100"
                name="city"
                label="City"
                value={city}
                size="small"
                onChange={(e) => setcity(e.target.value)}
                required
              />
            </div>
            <div className="col-4 px-2 py-3">
              <TextField
                className="w-100"
                name="postCode"
                label="Post Code"
                value={postCode}
                size="small"
                onChange={(e) => setpostCode(e.target.value)}
                required
              />
            </div>
            <div className="col-4 px-2 py-3">
              <TextField
                className="w-100"
                name="name"
                label="Billing Contact Name"
                value={billingContact.name}
                size="small"
                onChange={handlebillingContact}
                required
              />
            </div>
            <div className="col-4 px-2 py-3">
              <TextField
                className="w-100"
                name="designation"
                label="Billing Contact Designation"
                value={billingContact.designation}
                size="small"
                onChange={handlebillingContact}
                required
              />
            </div>
            <div className="col-4 px-2 py-3">
              <TextField
                className="w-100"
                name="PAN"
                label="PAN"
                value={PAN}
                size="small"
                onChange={(e) => setPAN(e.target.value)}
                required
              />
            </div>
            <div className="col-4 px-2 py-3">
              <TextField
                className="w-100"
                name="GSTNumber"
                label="GST Number"
                value={GSTNumber}
                size="small"
                onChange={(e) => setGSTNumber(e.target.value)}
                required
              />
            </div>
            <div className="col-4 px-2 py-3">
              <TextField
                className="w-100"
                name="localResource"
                label="local Resource"
                value={localResource}
                size="small"
                onChange={(e) => setlocalResource(e.target.value)}
                required
              />
            </div>
            <div className="col-4 px-2 py-3">
              <TextField
                type="date"
                className="w-100"
                name="expectedDateOfAuditPlanned"
                label="Expected Date Of Audit Planned"
                value={expectedDateOfAuditPlanned}
                size="small"
                onChange={(e) => setexpectedDateOfAuditPlanned(e.target.value)}
                required
              />
            </div>
            {Client_basic.standardsSchemeType === "FSMS" ? (
              <div className="col-4 px-2 py-3">
                <TextField
                  type="number"
                  className="w-100"
                  name="NoOfHACCP_study"
                  label="No Of HACCP_study"
                  value={NoOfHACCP_study}
                  size="small"
                  onChange={(e) => setNoOfHACCP_study(e.target.value)}
                  required
                />
              </div>
            ) : (
              ""
            )}
          </div>
          <h6 className="my-3 fw-bold">Proposal Information</h6>
          <div className="d-flex flex-wrap justify-content-start">
            <div className="col-4 px-2 py-3">
              <TextField
                className="w-100"
                name="standards"
                label="Standards"
                value={standards}
                size="small"
                onChange={(e) => setstandards(e.target.value)}
                required
              />
            </div>
            <div className="col-4 px-2 py-3">
              <TextField
                className="w-100"
                name="certificationBody"
                label="Certification Body"
                value={certificationBody}
                size="small"
                onChange={(e) => setcertificationBody(e.target.value)}
                required
              />
            </div>
            <div className="col-4 px-2 py-3">
              <TextField
                className="w-100"
                name="CAUSSA_ContractingOffice"
                label="CAUSSA Contracting Office"
                value={CAUSSA_ContractingOffice}
                size="small"
                onChange={(e) => setCAUSSA_ContractingOffice(e.target.value)}
                required
              />
            </div>
            <div className="col-4 px-2 py-3">
              <TextField
                className="w-100"
                name="accreditations"
                label="Accreditations"
                value={accreditations}
                size="small"
                onChange={(e) => setaccreditations(e.target.value)}
                required
              />
            </div>
            <div className="col-4 px-2 py-3">
              <TextField
                className="w-100"
                name="scopeOfCertification"
                label="Scope Of Certification"
                value={scopeOfCertification}
                size="small"
                onChange={(e) => setscopeOfCertification(e.target.value)}
                required
              />
            </div>
            <div className="col-4 px-2 py-3">
              <TextField
                type="number"
                className="w-100"
                name="totalNoOfEmployees"
                label="Total No Of Employees"
                value={totalNoOfEmployees}
                size="small"
                onChange={(e) => settotalNoOfEmployees(e.target.value)}
                required
              />
            </div>
            <div className="col-4 px-2 py-3">
              <TextField
                type="number"
                className="w-100"
                name="noOfEffectiveFTE"
                label="No Of Effective FTE"
                value={noOfEffectiveFTE}
                size="small"
                onChange={(e) => setnoOfEffectiveFTE(e.target.value)}
                required
              />
            </div>
            <div className="col-4 px-2 py-3">
              <TextField
                type="number"
                className="w-100"
                name="noOfShifts"
                label="No Of Shifts"
                value={noOfShifts}
                size="small"
                onChange={(e) => setnoOfShifts(e.target.value)}
                required
              />
            </div>
            {/* New Field */}
            <div className="col-4 px-2 py-3">
              <TextField
                label="General Shift - FTE"
                name="general"
                value={shift?.general}
                onChange={handleInputChange}
                type="number"
                size="small"
                fullWidth
              />
            </div>
            <div className="col-4 px-2 py-3">
              <TextField
                label="First Shift - FTE"
                name="first"
                value={shift?.first}
                onChange={handleInputChange}
                type="number"
                size="small"
                fullWidth
              />
            </div>
            <div className="col-4 px-2 py-3">
              <TextField
                label="Second Shift - FTE"
                name="second"
                value={shift?.second}
                onChange={handleInputChange}
                type="number"
                size="small"
                fullWidth
              />
            </div>
            <div className="col-4 px-2 py-3">
              <TextField
                label="Third Shift - FTE"
                name="third"
                value={shift?.third}
                onChange={handleInputChange}
                type="number"
                size="small"
                fullWidth
              />
            </div>

            <div className="col-4 px-2 py-3">
              <TextField
                className="w-100"
                name="locationMultiSiteInfo"
                label="Location"
                value={locationMultiSiteInfo}
                size="small"
                onChange={(e) => setlocationMultiSiteInfo(e.target.value)}
                required
              />
            </div>
            <div className="col-4 px-2 py-3">
              <TextField
                className="w-100"
                name="HO_or_CentralOffice"
                label="HO/CentralOffice"
                value={HO_or_CentralOffice}
                size="small"
                onChange={(e) => setHO_or_CentralOffice(e.target.value)}
                required
              />
            </div>
            <div className="col-4 px-2 py-3">
              <TextField
                className="w-100"
                name="surveillanceFrequency"
                label="Surveillance Frequency"
                value={surveillanceFrequency}
                size="small"
                onChange={(e) => setsurveillanceFrequency(e.target.value)}
                required
              />
            </div>
          </div>
          {(isAuthenticated === "Sales" ||
            isAuthenticated === "Admin" ||
            isAuthenticated === "Account") && (
            <>
              <h6 className="my-3 fw-bold">Audit Fees</h6>
              <div className="col-4">
                <FormControl fullWidth>
                  <InputLabel id="category-label-audit">
                    Select Category
                  </InputLabel>
                  <Select
                    labelId="category-label-audit"
                    id="category-audit"
                    // disabled = {action === "Edit" ? true : false}
                    value={aduitfeecategory}
                    onChange={handleaduitfeeCategoryChange}
                  >
                    <MenuItem value="Transfer Certification in SA1">
                      Transfer SA 1
                    </MenuItem>
                    <MenuItem value="Transfer Certification in SA2">
                      Transfer SA 2
                    </MenuItem>
                    <MenuItem value="Transfer Certification in Re-Certification">
                      Transfer in RA
                    </MenuItem>
                    <MenuItem value="Re-Certification">
                      Re-Certification
                    </MenuItem>
                    <MenuItem value="Initial Certification">
                      Initial Certification
                    </MenuItem>
                  </Select>
                </FormControl>
              </div>
              {aduitfeecategory === "Initial Certification" && (
                <>
                  <div>
                    {years.map((year, index) => (
                      <div key={index}>
                        <p className="my-3 year-title">{year.year}</p>
                        {year.stages &&
                          year.stages.map((stage, stageIndex) => (
                            <div
                              key={stageIndex}
                              className="d-flex flex-wrap mb-3"
                            >
                              <div className="col-5">
                                <p>Stage {stageIndex + 1}</p>
                              </div>
                              <div className="col-7 d-flex flex-wrap">
                                <div className="col-4 mx-auto">
                                  <TextField
                                    name="onSite"
                                    label="OnSite"
                                    size="small"
                                    value={stage.onSite}
                                    onChange={(e) =>
                                      handleYearChange(
                                        index,
                                        "stage",
                                        stageIndex,
                                        e
                                      )
                                    }
                                    required
                                  />
                                </div>
                                <div className="col-4 mx-auto">
                                  <TextField
                                    name="offSite"
                                    label="OffSite"
                                    size="small"
                                    value={stage.offSite}
                                    onChange={(e) =>
                                      handleYearChange(
                                        index,
                                        "stage",
                                        stageIndex,
                                        e
                                      )
                                    }
                                    required
                                  />
                                </div>
                                <div className="col-4 mx-auto">
                                  <TextField
                                    name="fee"
                                    label="Fee"
                                    size="small"
                                    type="number"
                                    value={stage.fee}
                                    onChange={(e) =>
                                      handleYearChange(
                                        index,
                                        "stage",
                                        stageIndex,
                                        e
                                      )
                                    }
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                        {year.surveillanceAudits &&
                          year.surveillanceAudits.map((audit, auditIndex) => (
                            <div
                              key={auditIndex}
                              className="d-flex flex-wrap mb-3"
                              id={auditIndex}
                            >
                              <div className="col-5">
                                {index === 1
                                  ? "Surveillance Audit 1"
                                  : index === 2
                                  ? "Surveillance Audit 2"
                                  : ""}
                              </div>
                              <div className="col-7 d-flex flex-wrap">
                                <div className="col-4 mx-auto">
                                  <TextField
                                    name="onSite"
                                    label="OnSite"
                                    size="small"
                                    value={audit.onSite}
                                    onChange={(e) =>
                                      handleYearChange(
                                        index,
                                        "surveillanceAudits",
                                        auditIndex,
                                        e
                                      )
                                    }
                                    required
                                  />
                                </div>
                                <div className="col-4 mx-auto">
                                  <TextField
                                    name="offSite"
                                    size="small"
                                    label="OffSite"
                                    value={audit.offSite}
                                    onChange={(e) =>
                                      handleYearChange(
                                        index,
                                        "surveillanceAudits",
                                        auditIndex,
                                        e
                                      )
                                    }
                                    required
                                  />
                                </div>
                                <div className="col-4 mx-auto">
                                  <TextField
                                    type="number"
                                    name="fee"
                                    label="Fee"
                                    size="small"
                                    value={audit.fee}
                                    onChange={(e) =>
                                      handleYearChange(
                                        index,
                                        "surveillanceAudits",
                                        auditIndex,
                                        e
                                      )
                                    }
                                    required
                                  />
                                </div>
                              </div>
                            </div>
                          ))}
                      </div>
                    ))}
                  </div>
                  <div className="d-flex flex-wrap justify-content-between my-4 align-items-center">
                    <div className="col-4">
                      <p className="mb-0 fw-bold">Total Contract</p>
                      <span>(3 year's Total)</span>
                    </div>
                    <div className="col-4 px-4">
                      <TextField
                        type="number"
                        className="w-100"
                        name="administrationFee"
                        label="Administration Fee"
                        value={administrationFee}
                        size="small"
                        onChange={(e) => setadministrationFee(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-4 px-4">
                      <TextField
                        type="number"
                        className="w-100"
                        name="totalContractFee"
                        label="Total Fee"
                        value={totalContractFee}
                        size="small"
                        onChange={(e) => settotalContractFee(e.target.value)}
                        required
                      />
                    </div>
                  </div>
                </>
              )}
              {aduitfeecategory === "Transfer Certification in SA1" && (
                <TransferSA1Form />
              )}
              {aduitfeecategory === "Transfer Certification in SA2" && (
                <TransferSA2Form />
              )}
              {aduitfeecategory ===
                "Transfer Certification in Re-Certification" && (
                <TransferRAForm />
              )}
              {aduitfeecategory === "Re-Certification" && <RAForm />}
            </>
          )}
        </>
      ) : (
        <>
          <h6 className="my-3 fw-bold">Aditional Details</h6>
          <div className="d-flex flex-wrap justify-content-start">
            {/* <div className="col-4 px-2 py-3">
              <TextField
                className="w-100"
                name="ccode"
                label="cCode"
                value={ccode}
                size="small"
                onChange={(e) => setccode(e.target.value)}
                required
              />
            </div> */}
            <div className="col-4 px-2 py-3">
              <FormControl variant="outlined" className="w-100">
                <InputLabel id="standardsSchemeType-label">
                  Type of sector
                </InputLabel>
                <Select
                  style={{ padding: "8px!important" }}
                  labelId="services-label"
                  id="services"
                  value={typeOfServices}
                  onChange={handleTypeOfServices}
                  label="Type of services"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="Food Service Establishment">
                    Food Service Establishment
                  </MenuItem>
                  <MenuItem value="Bakery">Bakery</MenuItem>
                  <MenuItem value="Meat Retail">Meat Retail</MenuItem>
                  <MenuItem value="Sweet Shop">Sweet Shop</MenuItem>
                </Select>
              </FormControl>
            </div>
            <div className="col-4 px-2 py-3">
              <TextField
                className="w-100"
                name="auditType"
                label="AuditType"
                value={auditType}
                size="small"
                onChange={(e) => setauditType(e.target.value)}
                required
              />
            </div>
            <div className="col-4 px-2 py-3">
              <TextField
                className="w-100"
                name="kob"
                label="Kob"
                value={kob}
                size="small"
                onChange={(e) => setkob(e.target.value)}
                required
              />
            </div>
            <div className="col-4 px-2 py-3">
              <TextField
                className="w-100"
                name="noOffoodHandlers"
                label="No Of FoodHandlers"
                value={noOffoodHandlers}
                size="small"
                type="number"
                onChange={(e) => setnoOffoodHandlers(e.target.value)}
                required
              />
            </div>
            <div className="col-4 px-2 py-3">
              <TextField
                className="w-100"
                name="FoSTaC_TrainedFoodSafetySupervisors"
                label="FoSTaC_TrainedFoodSafetySupervisors"
                value={FoSTaC_TrainedFoodSafetySupervisors}
                size="small"
                onChange={(e) => setFoSTaC_TrainedFoodSafetySupervisors(e.target.value)}
                required
              />
            </div>
            {/* <div className="col-4 px-2 py-3 position-relative">
              <TextField
                type="text"
                className="w-100"
                name="FoSTaC_TrainedFoodSafetySupervisors"
                label="FoSTaC_TrainedFoodSafetySupervisors"
                value={FoSTaC_TrainedFoodSafetySupervisors}
                size="small"
                onChange={(e) =>
                  setFoSTaC_TrainedFoodSafetySupervisors(e.target.value)
                }
                required
              />
              {FoSTaC_TrainedFoodSafetySupervisors === "" ? (
                ""
              ) : (
                <div className="verify-button" onClick={handleTickClick}>
                  <Iconify className="" icon={"teenyicons:tick-outline"} />
                </div>
              )}

              {FoSTaC_TrainedFoodSafetySupervisorsArray.length > 0 && (
                <ul className="d-flex flex-wrap list-language">
                  {FoSTaC_TrainedFoodSafetySupervisorsArray.map(
                    (value, index) => (
                      <li key={index} className="me-2">
                        {value}
                        {action !== "View" ? (
                          <Iconify
                            className="delete-button"
                            onClick={() => removeLanguage(index)}
                            icon={"streamline:delete-1-solid"}
                          />
                        ) : (
                          ""
                        )}
                      </li>
                    )
                  )}
                </ul>
              )}
            </div> */}
            <div className="col-4 px-2 py-3">
              <TextField
                type="text"
                className="w-100"
                name="uniqueCertificateNo"
                label="uniqueCertificateNo"
                value={uniqueCertificateNoAndCourseName}
                size="small"
                onChange={(e) =>
                  setuniqueCertificateNoAndCourseName(e.target.value)
                }
                required
              />
            </div>

            <div className="col-4 px-2 py-3">
              <TextField
                type="text"
                className="w-100"
                name="CourseName"
                label="CourseName"
                value={courseName}
                size="small"
                onChange={(e) => setCourseName(e.target.value)}
                required
              />
            </div>
            <div className="col-4 px-2 py-3">
              <TextField
                type="text"
                className="w-100"
                name="CourseCode"
                label="CourseCode"
                value={courseCode}
                size="small"
                onChange={(e) => setCourseCode(e.target.value)}
                required
              />
            </div>
            <div className="col-4 px-2 py-3">
              <TextField
                type="text"
                className="w-100"
                name="Main production / service"
                label="Main production / service"
                value={mainProduction}
                size="small"
                onChange={(e) => setMainProduction(e.target.value)}
                required
              />
            </div>
            <div className="col-4 px-2 py-3">
              <TextField
                type="number"
                className="w-100"
                name="FSSAI_LicenseNumber"
                label="FSSAI_LicenseNumber"
                value={FSSAI_LicenseNumber}
                size="small"
                onChange={(e) => setFSSAI_LicenseNumber(e.target.value)}
                required
              />
            </div>
            <div className="col-4 px-2 py-3">
              <TextField
                className="w-100"
                name="validityOfLicense"
                label="ValidityOfLicense"
                value={validityOfLicense}
                size="small"
                onChange={(e) => setvalidityOfLicense(e.target.value)}
                required
              />
            </div>
            <div className="col-4 px-2 py-3">
              <TextField
                className="w-100"
                name="GSTNumber"
                label="GSTNumber"
                value={GSTNumber}
                size="small"
                onChange={(e) => setGSTNumber(e.target.value)}
                required
              />
            </div>
            <div className="col-4 px-2 py-3">
              <TextField
                className="w-100"
                name="remark"
                label="Remark"
                value={remarks}
                size="small"
                onChange={(e) => setRemarks(e.target.value)}
                required
              />
            </div>
            <div className="col-4 px-2 py-3">
              <TextField
                className="w-100"
                name="totalNoOfEmployees"
                label="totalNoOfEmployees"
                value={totalNoOfEmployees_HRA}
                size="small"
                onChange={(e) => settotalNoOfEmployees_HRA(e.target.value)}
                required
              />
            </div>
            <div className="col-4 px-2 py-3">
              <FormControl variant="outlined" className="w-100">
                <InputLabel id="standardsSchemeType-label">
                  Client Type (Private / Government )
                </InputLabel>
                <Select
                  style={{ padding: "8px!important" }}
                  labelId="clientType-label"
                  id="clientType"
                  value={clientType}
                  onChange={handleClientType}
                  label="Client Type (Private / Government )"
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value="Private">Private</MenuItem>
                  <MenuItem value="Government">Government</MenuItem>
                </Select>
              </FormControl>
            </div>
            {/* <div className="col-4 px-2 py-3">
              <TextField
                className="w-100"
                name="headOffice"
                label="headOffice"
                value={headOffice}
                size="small"
                onChange={(e) => setHeadOffice(e.target.value)}
                required
              />
            </div> */}

            {renderSiteFields()}

            <div className="col-4 px-2 py-3" style={{ height: "31vh" }}>
              <div className="inputs p-2">
                <label
                  htmlFor="file-input-11"
                  className="d-flex justify-content-between w-100"
                >
                  Authorised Signature <span>+</span>
                </label>
                <input
                  id="file-input-11"
                  type="file"
                  className="Upload-file"
                  onChange={(event) =>
                    handleFileChange(event, "Authorised Signature")
                  }
                />
              </div>
              {ASFSObjectFinal ? (
                <img
                  src={
                    ASFSObjectFinal
                      ? `${SERVER_URL}/${ASFSObjectFinal.uri}`
                      : ""
                  }
                  alt="profile"
                  height="60%"
                  width="100%"
                />
              ) : (
                <img
                  src="https://static.vecteezy.com/system/resources/previews/032/176/017/original/business-avatar-profile-black-icon-man-of-user-symbol-in-trendy-flat-style-isolated-on-male-profile-people-diverse-face-for-social-network-or-web-vector.jpg"
                  alt="profile"
                  height="60%"
                  width="100%"
                />
              )}
            </div>
            {questions.map((question, index) => (
              <div className="row" style={{ width: "100%" }} key={index}>
                <div className="col-4 px-2 py-3">
                  <p>{question}</p>
                </div>
                <div className="col-4 px-2 py-3">
                  <FormControl>
                    <RadioGroup
                      row
                      aria-labelledby="demo-row-radio-buttons-group-label"
                      name="row-radio-buttons-group"
                    >
                      <FormControlLabel
                        value="true"
                        control={<Radio />}
                        checked={answers[index] === true}
                        onChange={() => handleAnswerChange(index, true)}
                        label="Yes"
                      />
                      <FormControlLabel
                        value="false"
                        control={<Radio />}
                        checked={answers[index] === false}
                        onChange={() => handleAnswerChange(index, false)}
                        label="No"
                      />
                    </RadioGroup>
                  </FormControl>
                </div>
                <div className="col-4 px-2 py-3">
                  <div className="inputs">
                    <label
                      htmlFor={`file-input-${index}`}
                      className="d-flex justify-content-between w-100"
                    >
                      Add Your Document <span>+</span>
                    </label>
                    <input
                      id={`file-input-${index}`}
                      type="file"
                      className="Upload-file"
                      onChange={(e) =>
                        handleQuesandAns(index, e.target.files[0])
                      }
                    />
                  </div>
                  {documentFiles[index] && (
                    <a
                      href={`${SERVER_URL}/${documentFiles[index].uri}`}
                      target="/blank"
                    >
                      File URI: {documentFiles[index].uri}
                    </a>
                  )}
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      {(isAuthenticated === "Planner" || isAuthenticated === "CSO") &&
        action !== "Edit" && (
          <div>
            <h5 className="my-3 fw-bold">Cifa</h5>
            <ul>
              {CIFA_FileObjects &&
                CIFA_FileObjects.map((file) => (
                  <li key={file._id}>
                    <a
                      href={`${SERVER_URL}/${file.uri}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {file.originalname}
                    </a>
                  </li>
                ))}
            </ul>
          </div>
        )}
      {(isAuthenticated === "Planner" || isAuthenticated === "CSO") &&
        action !== "Edit" && (
          <div>
            <h5 className="my-3 fw-bold">ScopeExtension CIFA</h5>
            <ul>
              <li>
                <a
                  href={`${SERVER_URL}/${SpecificClientAdvance?.ScopeExtension_CIFA_FileObject?.uri}`}
                >
                  {
                    SpecificClientAdvance?.ScopeExtension_CIFA_FileObject
                      ?.filename
                  }
                </a>
              </li>
            </ul>
          </div>
        )}
      {isAuthenticated === "Admin" && (
        <div>
          <h5 className="my-3 fw-bold">ScopeExtension CIFA</h5>
          {action === "Edit" && (
            <div className="col-4">
              <TextField
                variant="outlined"
                margin="normal"
                fullWidth
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  accept: ".pdf,.doc,.docx,.txt", // Adjust the accepted file types as needed
                }}
                type="file"
                onChange={handleFileUpload}
              />
            </div>
          )}

          <ul>
            <li>
              <a href={`${SERVER_URL}/${ScopeExtension_CIFA_FileObject?.uri}`}>
                {ScopeExtension_CIFA_FileObject?.filename}
              </a>
              <Button
                color="error"
                onClick={() => removeScopeExtension_CIFA_FileObject()}
              >
                <DeleteIcon />
              </Button>
            </li>
          </ul>
        </div>
      )}

      {isAuthenticated === "Admin" && (
        <div>
          <h3 className="my-3 fw-bold">Cifa</h3>
          <ul>
            {CIFA_FileObjects &&
              CIFA_FileObjects.map((file) => (
                <li key={file._id}>
                  <a
                    href={`${SERVER_URL}/${file.uri}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {file.originalname}
                  </a>
                  <Button
                    color="error"
                    onClick={() => removeCIFA_FileObjects(file._id)}
                  >
                    <DeleteIcon />
                  </Button>
                </li>
              ))}
          </ul>
        </div>
      )}

      {action !== "View" ? (
        <div className="d-flex flex-wrap justify-content-end">
          {action !== "Edit" ? (
            <Button
              variant="contained"
              className="btn-orange"
              onClick={HandleAdvancedContact}
            >
              Cancel
            </Button>
          ) : (
            ""
          )}
          {scheme_session !== "HRA" ? (
            <Button
              variant="contained"
              className="btn-green"
              onClick={UpdateClientAdvance}
            >
              Submit
            </Button>
          ) : (
            <Button
              variant="contained"
              className="btn-green demo12323"
              onClick={AdvanceClientData}
            >
              Submit
            </Button>
          )}
        </div>
      ) : isAuthenticated === "Sales" || isAuthenticated === "Admin" ? (
        <div className="d-flex justify-content-end">
          <Button
            variant="contained"
            className="btn-orange"
            onClick={HandleAddClient}
          >
            Cancel
          </Button>
        </div>
      ) : (
        ""
      )}
    </>
  );
}
