import React from 'react';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    CircularProgress,
    Box
} from '@mui/material';

export function CircularLoaderPopup({ loading }) {
    return (
        <Dialog
            open={loading && loading === 'true'}
            fullScreen
            PaperProps={{ style: { backgroundColor: 'rgba(0, 0, 0, 0.5)' } }}
        >
            <Box sx={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100vh'
            }}>
                <CircularProgress size={80} thickness={3} />
            </Box>
        </Dialog>
    );
};

export function DeleteConfirmationDialog({ open, onClose, onConfirm }) {
    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>Confirm Delete</DialogTitle>
            <DialogContent>
                Are you sure you want to delete this item?
            </DialogContent>
            <Box display="flex" flexDirection="column">
                <Button onClick={onConfirm} variant="contained" className="btn-orange text-dark me-0">
                    Delete
                </Button>
                <Button onClick={onClose} className="btn-light text-dark me-0">
                    Cancel
                </Button>
            </Box>
            <DialogContent>
                
            </DialogContent>
        </Dialog>
    );
}
