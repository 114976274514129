import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";

// routes
import Router from "./routes";
// theme
import ThemeProvider from "./theme";
// components
import { StyledChart } from "./components/chart";
import "bootstrap/dist/css/bootstrap.min.css";
import "../src/css/common.css";
import ScrollToTop from "./components/scroll-to-top";
import { useEffect, useState } from "react";
import USERSROUTES from "src/API_Routes/User/User_Routers";
import { getData } from "./webService/webService";
import Loader from "./utils/CommonLoader/Loader";
import { useSelector } from "react-redux";

// ----------------------------------------------------------------------

export default function App() {
  const LoaderView = useSelector((state) => state?.Loader.View);
  console.log("LoaderView", LoaderView);
  const accessToken = sessionStorage.getItem("Token");

  const [isAuthenticated, setIsAuthenticated] = useState(
    sessionStorage.getItem("isAuthenticated") || ""
  );

  useEffect(() => {
    if (!sessionStorage.getItem("isAuthenticated")) {
      sessionStorage.setItem("isAuthenticated", isAuthenticated);
    }
  }, [isAuthenticated]);
  const handleLogin = (data) => {
    setIsAuthenticated(data);
    sessionStorage.setItem("isAuthenticated", data);
  };

  const handleLogout = () => {
    getData(USERSROUTES.SIGNOUT, null, accessToken)
      .then((res) => {
        console.log("Logout successfully");
        setIsAuthenticated(false);
        sessionStorage.clear();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
    setIsAuthenticated(false);
    sessionStorage.clear();
  };
  return (
    <>
      {LoaderView ? <Loader /> : ""}

      <HelmetProvider>
        <BrowserRouter>
          <ThemeProvider>
            <ScrollToTop />
            <StyledChart />
            <Router
              isAuthenticated={isAuthenticated}
              onLogin={handleLogin}
              onLogout={handleLogout}
            />
          </ThemeProvider>
        </BrowserRouter>
      </HelmetProvider>
    </>
  );
}
