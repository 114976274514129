import React, { useEffect, useState } from "react";
import {
  TextField,
  Input,
  Radio,
  RadioGroup,
  InputLabel,
  FormControlLabel,
  FormControl,
  FormLabel,
  Button,
  Stack,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { getData, postData, patchData } from "src/webService/webService";
import AUDITROUTES from "src/API_Routes/Audit/Audit_routes";
import checkListObject from "src/Config/HRA/CheckList/CheckListObject";
import { useDispatch } from "react-redux";

export default function HRA_Checklist() {
  const dispatch = useDispatch();
  const [checkListData, setCheckListData] = useState();
  const [auditCheckListData, setAuditCheckListData] = useState();
  const selectedAuditId = sessionStorage.getItem("selectedAuditId");
  const [editMode, setEditMode] = useState(false);

  const handleObtainedScoreChange = (event, index1) => {
    console.log("event.target", event.target, index1);
    const { name, value } = event.target;
    checkListData?.checkList?.forEach((item, index2) => {
      if (index1 === index2) {
        item[name] = Number(value);
        console.log("item, index2", item, index2);
      }
    });
    setCheckListData({ ...checkListData });
  };

  const handleTotalObtainedScoreChange = (event) => {
    console.log("event.target", event.target);
    const { name, value } = event.target;
    checkListData[name] = Number(value);
    setCheckListData({ ...checkListData });
  };


  const handleRadioChange = (category, answerName, indexFromRadioButton) => {
    checkListData[category].forEach((checkListItem, indexFromCheckListData) => {
      if (indexFromRadioButton === indexFromCheckListData) {
        // sets the chosen answer to true
        checkListItem.answer[answerName] = true;

        // gets all the options name other than the chosen answer name
        const checkListArray = Object.keys(checkListItem.answer).filter(
          (key) => key !== answerName
        );

        // sets the options other than the chosen answer to false
        checkListArray.forEach((checkListItem2) => {
          checkListItem.answer[checkListItem2] = false;
        });

        // Set obtained score based on selected answer
        switch (answerName) {
          case "compliance":
            checkListItem.obtainedScore = checkListItem.score;
            break;
          case "nonCompliance":
            checkListItem.obtainedScore = 0;
            checkListItem.remark = ""; // Initialize remark field
            break;
          case "partialCompliance":
          case "notApplicable":
            checkListItem.obtainedScore = 0;
            break;
          default:
            checkListItem.obtainedScore = 0;
            break;
        }
      }
    });

    // new copy created to make the state change observed in useEffect
    const checkListDataCopy = { ...checkListData };
    setCheckListData(checkListDataCopy);
  };

  const [totalObtainedScore, setTotalObtainedScore] = useState(0);

  useEffect(() => {
    if (checkListData) {
      const total = checkListData.checkList.reduce(
        (sum, item) => sum + item.obtainedScore,
        0
      );
      setTotalObtainedScore(total);
      // Ensure the totalObtainedScore is updated in checkListData
      setCheckListData((prevData) => ({
        ...prevData,
        totalObtainedScore: total,
      }));
    }
  }, [checkListData]);


  useEffect(() => {
    console.log("checkListData", checkListData);
  }, [checkListData]);

  const SubmitOrEditModeCheck = async () => {
    const accessToken = sessionStorage.getItem("Token");
    const existingCheckList = await getData(
      AUDITROUTES.GET_CHECKLISTS(selectedAuditId),
      "",
      accessToken
    );
    if (existingCheckList?.data?.length > 0) {
      setCheckListData(existingCheckList?.data[0]?.audit);
      setAuditCheckListData(existingCheckList?.data[0]);
      setEditMode(true);
    } else {
      setCheckListData(checkListObject);
      setEditMode(false);
    }
    console.log("existingCheckList", existingCheckList);
  };

  useEffect(() => {
    SubmitOrEditModeCheck();
  }, []);


  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const accessToken = sessionStorage.getItem("Token");
      let response;

      // Ensure totalObtainedScore is included in checkListData
      const updatedCheckListData = {
        ...checkListData,
        totalObtainedScore: totalObtainedScore,
      };

      if (editMode) {
        response = await patchData(
          AUDITROUTES.UPDATE_CHECKLIST(auditCheckListData?._id),
          {
            audit: updatedCheckListData,
          },
          accessToken,
          dispatch
        );
      } else {
        response = await postData(
          AUDITROUTES.CREATE_CHECKLIST,
          {
            audit: updatedCheckListData,
            auditId: selectedAuditId,
          },
          accessToken,
          dispatch
        );
      }

      switch (response?.data?.status || response?.status) {
        case 200:
          toast.success("CHECKLIST Submitted Successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          break;
        case 500:
          toast.error("Error while submitting CREATE CHECKLIST in server", {
            position: toast.POSITION.TOP_RIGHT,
          });
          break;
        case 400:
          toast.error("CREATE CHECKLIST error", {
            position: toast.POSITION.TOP_RIGHT,
          });
          break;
        case 404:
          toast.error("CREATE CHECKLIST not found", {
            position: toast.POSITION.TOP_RIGHT,
          });
          break;
        default:
          toast.error("Unexpected Error occurred", {
            position: toast.POSITION.TOP_RIGHT,
          });
      }
    } catch (error) {
      console.error(error);
      toast.error("Unexpected Error occurred", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };


  return (
    // <Stack
    //   direction="row"
    //   justifyContent="center"
    //   alignItems="center"
    //   spacing={2}
    // >
    //   <ToastContainer />
    //   <form onSubmit={handleSubmit}>
    //     <h6 className="fw-bold">Audit Checklist</h6>

    //     {/* Hygiene Rating Checklist Food Service Establishments/Bakery/Restaurant */}
    //     <p>
    //       Hygiene Rating Checklist Food Service Establishments/Bakery/Restaurant
    //     </p>
    //     <InputLabel>Total Score: {checkListData?.totalScore}</InputLabel>
    //     <br></br>
    //     {editMode && (
    //       <>
    //         <InputLabel htmlFor="totalObtainedScore">
    //           Total Obtained Score
    //         </InputLabel>
    //         <TextField
    //           name="totalObtainedScore"
    //           defaultValue={checkListData?.totalObtainedScore}
    //           onChange={handleTotalObtainedScoreChange}
    //         />
    //       </>
    //     )}

    //     {!editMode && (
    //       <>
    //         <InputLabel htmlFor="totalObtainedScore">
    //           Total Obtained Score
    //         </InputLabel>
    //         <TextField
    //           name="totalObtainedScore"
    //           onChange={handleTotalObtainedScoreChange}
    //         />
    //       </>
    //     )}

    //     <br></br>
    //     {checkListData &&
    //       checkListData?.checkList?.map((item, index) => (
    //         <div key={index}>
    //           <FormControl component="fieldset">
    //             <FormLabel component="legend" id={"question" + index}>
    //               {item.question}
    //             </FormLabel>
    //             <RadioGroup
    //               aria-labelledby={"question" + index}
    //               value={
    //                 Object.keys(item.answer).find((key) => item.answer[key]) || ""
    //               }
    //               onChange={(event) =>
    //                 handleRadioChange("checkList", event.target.value, index)
    //               }
    //             >
    //               <FormControlLabel
    //                 value="nonCompliance"
    //                 control={<Radio />}
    //                 label="Non-Compliance"
    //               />
    //               <FormControlLabel
    //                 value="compliance"
    //                 control={<Radio />}
    //                 label="Compliance"
    //               />
    //               <FormControlLabel
    //                 value="partialCompliance"
    //                 control={<Radio />}
    //                 label="Partial Compliance"
    //               />
    //               <FormControlLabel
    //                 value="notApplicable"
    //                 control={<Radio />}
    //                 label="Not Applicable"
    //               />
    //             </RadioGroup>
    //           </FormControl>
    //           <br />
    //           <InputLabel>Score: {item?.score}</InputLabel>
    //           <br />
    //           {editMode && (
    //             <>
    //               <InputLabel>Obtained Score: {item?.obtainedScore}</InputLabel>
    //             </>
    //           )}
    //           {!editMode && (
    //             <>
    //               <InputLabel>Obtained Score</InputLabel>
    //               <TextField
    //                 name="obtainedScore"
    //                 value={item?.obtainedScore}
    //                 onChange={(e) => handleObtainedScoreChange(e, index)}
    //                 disabled={item.answer.compliance || item.answer.notApplicable}
    //               />
    //             </>
    //           )}
    //           <br />
    //           {item.answer.nonCompliance && (
    //             <>
    //               <InputLabel>Remark</InputLabel>
    //               <TextField
    //                 name="remark"
    //                 value={item?.remark}
    //                 onChange={(e) => {
    //                   item.remark = e.target.value;
    //                   setCheckListData({ ...checkListData });
    //                 }}
    //               />
    //             </>
    //           )}
    //           <br />
    //         </div>
    //       ))}


    //     {editMode && (
    //       <>
    //         <Button
    //           variant="contained"
    //           className="btn-orange"
    //           size="large"
    //           type="submit"
    //         >
    //           Update
    //         </Button>
    //       </>
    //     )}

    //     {!editMode && (
    //       <>
    //         <Button
    //           variant="contained"
    //           className="btn-orange"
    //           size="large"
    //           type="submit"
    //         >
    //           Submit
    //         </Button>
    //       </>
    //     )}
    //   </form>
    // </Stack>

    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <ToastContainer />
      <form onSubmit={handleSubmit}>
        <h6 className="fw-bold">Audit Checklist</h6>

        {/* Hygiene Rating Checklist Food Service Establishments/Bakery/Restaurant */}
        <p>
          Hygiene Rating Checklist Food Service Establishments/Bakery/Restaurant
        </p>
        <InputLabel>Total Score: {checkListData?.totalScore}</InputLabel>
        <br />
        {editMode && (
          <>
            <InputLabel htmlFor="totalObtainedScore">
              Total Obtained Score
            </InputLabel>
            <TextField
              name="totalObtainedScore"
              value={totalObtainedScore}
              disabled
            />
          </>
        )}

        {!editMode && (
          <>
            <InputLabel htmlFor="totalObtainedScore">
              Total Obtained Score
            </InputLabel>
            <TextField
              name="totalObtainedScore"
              value={totalObtainedScore}
              disabled
            />
          </>
        )}

        <br />
        {checkListData &&
          checkListData?.checkList?.map((item, index) => (
            <div key={index}>
              <FormControl component="fieldset">
                <FormLabel component="legend" id={"question" + index}>
                  {item.question}
                </FormLabel>
                <RadioGroup
                  aria-labelledby={"question" + index}
                  value={
                    Object.keys(item.answer).find((key) => item.answer[key]) || ""
                  }
                  onChange={(event) =>
                    handleRadioChange("checkList", event.target.value, index)
                  }
                >
                  <FormControlLabel
                    value="nonCompliance"
                    control={<Radio />}
                    label="Non-Compliance"
                  />
                  <FormControlLabel
                    value="compliance"
                    control={<Radio />}
                    label="Compliance"
                  />
                  <FormControlLabel
                    value="partialCompliance"
                    control={<Radio />}
                    label="Partial Compliance"
                  />
                  <FormControlLabel
                    value="notApplicable"
                    control={<Radio />}
                    label="Not Applicable"
                  />
                </RadioGroup>
              </FormControl>
              <br />
              <InputLabel>Score: {item?.score}</InputLabel>
              <br />
              {editMode && (
                <>
                  <InputLabel>Obtained Score: {item?.obtainedScore}</InputLabel>
                </>
              )}
              {!editMode && (
                <>
                  <InputLabel>Obtained Score</InputLabel>
                  <TextField
                    name="obtainedScore"
                    value={item?.obtainedScore}
                    onChange={(e) => handleObtainedScoreChange(e, index)}
                    disabled={item.answer.compliance || item.answer.notApplicable}
                  />
                </>
              )}
              <br />
              {item.answer.nonCompliance && (
                <>
                  <InputLabel>Remark</InputLabel>
                  <TextField
                    name="remark"
                    value={item?.remark}
                    onChange={(e) => {
                      item.remark = e.target.value;
                      setCheckListData({ ...checkListData });
                    }}
                  />
                </>
              )}
              <br />
            </div>
          ))}

        {/* Display the dynamically calculated total obtained score */}
        <InputLabel>Total Obtained Score: {totalObtainedScore}</InputLabel>

        {editMode && (
          <>
            <Button
              variant="contained"
              className="btn-orange"
              size="large"
              type="submit"
            >
              Update
            </Button>
          </>
        )}

        {!editMode && (
          <>
            <Button
              variant="contained"
              className="btn-orange"
              size="large"
              type="submit"
            >
              Submit
            </Button>
          </>
        )}
      </form>
    </Stack>


  );
}
