import React, { useEffect, useState } from "react";
import {
  InputLabel,
  Button,
  TextField,
  MenuItem,
  Select,
} from "@mui/material";
import { getData } from "../../webService/webService";
import { useDispatch } from "react-redux";
import { setAllCertificate } from "../../redux/slices/CertificateSlice";

import CERTIFICATE_ROUTES from "src/API_Routes/Certificate/Certificate_Routers";
import MASTERS_ROUTES from "src/API_Routes/Masters/Masters_Routers";

const CertificateFilter = () => {
  const dispatch = useDispatch();
  const scheme_session = sessionStorage.getItem("scheme");
  const accessToken = sessionStorage.getItem("Token");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [clientId, setClientId] = useState("");
  const [limit, setlimit] = useState("");
  const [clientData, setClientData] = useState(null);

  const getClientData = async () => {
    try{
      const queryParams = {
        scheme: 'CB'
      };
      const res = await getData(
        MASTERS_ROUTES.FETCH_CLIENT_NAME_AND_ID,
        queryParams,
        accessToken
      );
      setClientData( res.data ); 
      console.log( 'clientData', clientData ) 
    }
    catch( error ){
      console.log( error )
    }
  }

  useEffect( () => { getClientData() }, [])

  const handleStartDateChange = (e) => {
    const selectedStartDate = e.target.value;
    if (endDate && endDate < selectedStartDate) {
      setEndDate("");
    }
    setStartDate(selectedStartDate);
  };

  const handleEndDateChange = (e) => {
    const selectedEndDate = e.target.value;
    if (startDate && startDate > selectedEndDate) {
      setStartDate("");
    }
    setEndDate(selectedEndDate);
  };

  const handleClientId = (e) => {
    setClientId( 
      e.target.value
    );
  }

  const filterCertificates = async () => {
    try{
      const queryParams = {
        page: 1,
        limit: limit,
        expirystartdate: startDate,
        expiryenddate: endDate,
        clientid: clientId
      };
      const certificateFilterRes = await getData(
        CERTIFICATE_ROUTES.ALL,
        queryParams,
        accessToken
      );
      dispatch(
        setAllCertificate(
          certificateFilterRes.data
        )
      );
    }
    catch( error ){
      console.error("Error:", error);
    }
  };

  return (
    <div
      style={{
        width: "100%",
        justifyContent: "between",
        display: "flex",
        margin: "10px 0px",
        alignItems: "center",
      }}
    >
      <TextField
        style={{ width: "30%" }}
        size="small"
        label="Expiry Start Date"
        type="date"
        className="m-2"
        value={startDate}
        onChange={handleStartDateChange}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        style={{ width: "30%" }}
        size="small"
        label="Expiry End Date"
        type="date"
        className="m-2"
        value={endDate}
        onChange={handleEndDateChange}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <InputLabel id="Client-Id-label" sx={{ width: '10%' }}>
        Client Id:
      </InputLabel>
      <Select
        labelId="Client-Id"
        name="year"
        value={clientId}
        onChange={handleClientId}
        sx={{ width: '20%' }}
      >
        <MenuItem disabled>
          Select Client Id
        </MenuItem>
        {clientData?.map((client) => (
            <MenuItem key={client._id} value={client.clientId}>
                {client.clientId}
            </MenuItem>
        ))}
      </Select>
      <TextField
        style={{ width: "25%" }}
        size="small"
        label="Limit"
        className="m-2"
        type="number"
        onChange={(e) => setlimit(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
      />

      <Button
        style={{ width: "15%" }}
        className="btn-green m-2"
        onClick={filterCertificates}
      >
        Search
      </Button>
    </div>
  );
};

export default CertificateFilter;
