import React, { useEffect, useState } from "react";
import { useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import TextField from "@mui/material/TextField";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormLabel from "@mui/material/FormLabel";
import DialogActions from "@mui/material/DialogActions";
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Card,
  Button,
} from "@mui/material";
import {
  FileUpload,
  deleteData,
  getData,
  patchData,
  postData,
} from "src/webService/webService";
import FILEROUTES from "src/API_Routes/File/File_Router";
import AUDITROUTES from "src/API_Routes/Audit/Audit_routes";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EVENT_TRACKING_ROUTES from "src/API_Routes/EventTracking/EventTracking_Routers";
import EMAIL_ROUTES from "src/API_Routes/Email/Email_Routers";
import AuditConfirmationEmail from "src/Config/templates/html/AuditConfirmationEmail";
import Iconify from "../iconify";
import set from "date-fns/set/index";
import CommonEmail from "../../Config/templates/html/CommonEmailTem";
import SERVER_URL from "../../utils/serverURL";
import { useDispatch } from "react-redux";
const generateRandomNumber = () => {
  const randomNumber = Math.floor(1000000 + Math.random() * 9000000); // Generates a random number between 1000000 and 9999999
  return randomNumber.toString(); // Convert the number to a string
};
function Corrective({ BackToCalender, AuditId, ClientBasic, auditData }) {
  const dispatch = useDispatch();
  const date = new Date().toLocaleDateString();
  const UserId = sessionStorage.getItem("UserId");
  const accessToken = sessionStorage.getItem("Token");
  const firstname = sessionStorage.getItem("firstName");
  const [Correctiveactionval, setCorrectiveActionVal] = useState();
  const [editCorrectiveactionval, seteditCorrectiveactionval] = useState();
  // Auditor's findings starts
  const [findingNumberPrefix, setfindingNumberPrefix] = useState();
  const [findingNumberSubfix, setfindingNumberSubfix] = useState();
  const [objectiveEvidence, setObjectiveEvidence] = useState("");
  const [auditorIssuedTheFinding, setAuditorIssuedTheFinding] = useState("");
  const [finding, setFinding] = useState("");
  const [requirementClause, setRequirementClause] = useState([]);
  const [findingDueDate, setFindingDueDate] = useState(null);
  const [certificationExpiryDate, setCertificationExpiryDate] = useState(null);
  const [classification, setClassification] = useState("");
  // const [applicableLaws, setApplicableLaws] = useState([]);
  const [verifyStatus, setverifyStatus] = useState();
  const [siteName, setSiteName] = useState("");
  const [
    managementSystemDocumentationReference,
    setManagementSystemDocumentationReference,
  ] = useState("");
  const [correctiveActionPlanDueDate, setCorrectiveActionPlanDueDate] =
    useState(null);
  const [correctiveActionPlan, setcorrectiveActionPlan] = useState();
  const [responsible, setresponsible] = useState();
  const [
    planForVerificationOfEffectiveness,
    setplanForVerificationOfEffectiveness,
  ] = useState();
  const [correctiveActionPlanComments, setcorrectiveActionPlanComments] =
    useState();
  const [verificationOfCorrectiveTaken, setverificationOfCorrectiveTaken] =
    useState();
  const [acceptedBy, setacceptedBy] = useState();
  const [acceptedDate, setacceptedDate] = useState();
  const [auditDate, setauditDate] = useState();
  const [correctiveActionDate, setcorrectiveActionDate] = useState();
  // Auditor's findings end

  // corrective action starts
  const [rootCause, setRootCause] = useState("");
  const [correction, setCorrection] = useState("");
  const [correctiveAction, setCorrectiveAction] = useState("");
  const [
    correctiveActionImplementationDate,
    setCorrectiveActionImplementationDate,
  ] = useState(null);
  const [verificationData, setVerificationData] = useState("");

  // corrective action end

  // State variables for file objects
  const [attachments, setAttachments] = useState([]);
  const [auditorCommentsFileObjects, setauditorCommentsFileObjects] = useState(
    []
  );

  // State variables for comments
  // const [auditorCommentsText, setAuditorCommentsText] = useState("");
  const [auditorCommentsFiles, setAuditorCommentsFiles] = useState([]);

  // State variable for status
  const [status, setStatus] = useState("");

  // FileUpload
  const handleFileChange = async (event, data) => {
    const file = event.target.files[0];
    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await FileUpload(
        FILEROUTES.UPLOAD + UserId,
        formData,
        dispatch
      );
      if (data === "Attachments") {
        if (Array.isArray(response.data)) {
          setAttachments((prevAttachments) => [
            ...prevAttachments,
            ...response.data,
          ]);
        } else if (
          typeof response.data === "object" &&
          response.data !== null
        ) {
          setAttachments((prevAttachments) => [
            ...prevAttachments,
            response.data,
          ]);
        } else {
          console.error("Invalid response data format:", response.data);
        }
      } else {
        if (Array.isArray(response.data)) {
          setauditorCommentsFileObjects((prevAttachments) => [
            ...prevAttachments,
            ...response.data,
          ]);
        } else if (
          typeof response.data === "object" &&
          response.data !== null
        ) {
          setauditorCommentsFileObjects((prevAttachments) => [
            ...prevAttachments,
            response.data,
          ]);
        } else {
          console.error("Invalid response data format:", response.data);
        }

        // if (Array.isArray(response.data)) {
        //   setAuditorCommentsFiles((prevAttachments) => [
        //     ...prevAttachments,
        //     ...response.data,
        //   ]);
        // } else if (
        //   typeof response.data === "object" &&
        //   response.data !== null
        // ) {
        //   setAuditorCommentsFiles((prevAttachments) => [
        //     ...prevAttachments,
        //     response.data,
        //   ]);
        // } else {
        //   console.error("Invalid response data format:", response.data);
        // }
      }
      console.log("File upload successful:", response);
    } catch (error) {
      console.error("File upload error:", error);
    }
  };
  // Function to remove an attachment
  const removeAttachment = (indexToRemove) => {
    setAttachments((prevAttachments) => {
      const updatedAttachments = [...prevAttachments];
      updatedAttachments.splice(indexToRemove, 1);
      return updatedAttachments;
    });
  };
  const removeAuditorCommentsFile = (indexToRemove) => {
    setAuditorCommentsFiles((prevAttachments) => {
      const updatedAttachments = [...prevAttachments];
      updatedAttachments.splice(indexToRemove, 1);
      return updatedAttachments;
    });
  };

  useEffect(() => {
    const getTodayDate = () => {
      const today = new Date();
      const year = today.getFullYear();
      const month = String(today.getMonth() + 1).padStart(2, "0");
      const day = String(today.getDate()).padStart(2, "0");
      return `${year}-${month}-${day}`;
    };
    setCorrectiveActionImplementationDate(getTodayDate);
    setFindingDueDate(getTodayDate);
    setVerificationData(getTodayDate);
    setCorrectiveActionPlanDueDate(getTodayDate);
    setCertificationExpiryDate(getTodayDate);
    setauditDate(getTodayDate);
    setcorrectiveActionDate(getTodayDate);
    setacceptedDate(getTodayDate);
  }, []);

  const UpdateCorrectiveAction = async (data) => {
    try {
      const params = {
        findingNumberPrefix: findingNumberPrefix,
        findingNumberSubfix: findingNumberSubfix,
        auditId: AuditId,
        auditDate: auditDate,
        auditorIssuedTheFinding: auditorIssuedTheFinding,
        finding: finding,
        requirementClause: requirementClause,
        objectiveEvidence: objectiveEvidence,
        siteName: siteName,
        classification: classification,
        managementSystemDocumentationReference:
          managementSystemDocumentationReference,

        rootCause: rootCause,
        correction: correction,
        correctiveActionPlan: correctiveActionPlan,
        correctiveActionImplementationDate: correctiveActionImplementationDate,
        responsible: responsible,
        correctiveActionDate: correctiveActionDate,
        planForVerificationOfEffectiveness: planForVerificationOfEffectiveness,

        correctiveActionPlanComments: correctiveActionPlanComments,
        verificationOfCorrectiveTaken: verificationOfCorrectiveTaken,
        acceptedBy: acceptedBy,
        acceptedDate: acceptedDate,
        attachments: attachments,
        auditorCommentsFileObjects: auditorCommentsFileObjects,
        status: status,

        // findingDueDate: findingDueDate,
        // certificationExpiryDate: certificationExpiryDate,
        // applicableLaws: applicableLaws,
        // verifyStatus: verifyStatus,
        // correctiveActionPlanDueDate: correctiveActionPlanDueDate,
        // correctiveAction: correctiveAction,
        // verificationData: verificationData,
        // auditorCommentsWithFiles: {
        //   // text: auditorCommentsText,
        //   files: auditorCommentsFiles,
        // },
      };
      if (data === "Mail") {
        params.closed = {
          by: firstname,
          on: date,
        };
      }

      let res;
      if (editCorrectiveactionval) {
        res = await patchData(
          `${AUDITROUTES.EDITCORRECTIVEACTION(editCorrectiveactionval)}`,
          params,
          accessToken,
          dispatch
        );
        postData(
          EVENT_TRACKING_ROUTES.CREATE_EVENT_HISTORY(ClientBasic._id),
          {
            description: `${findingNumberPrefix}-${findingNumberSubfix} - Corrective Action Edited`,
            performedBy: `${sessionStorage.getItem(
              "firstName"
            )} ${sessionStorage.getItem("lastName")}`,
          },
          accessToken,
          dispatch
        );
      } else {
        res = await postData(
          `${AUDITROUTES.UPDATECORRECTIVEACTION}`,
          params,
          accessToken,
          dispatch
        );
        postData(
          EVENT_TRACKING_ROUTES.CREATE_EVENT_HISTORY(ClientBasic._id),
          {
            description: `New ${findingNumberPrefix}-${findingNumberSubfix} Corrective Action updated`,
            performedBy: `${sessionStorage.getItem(
              "firstName"
            )} ${sessionStorage.getItem("lastName")}`,
          },
          accessToken,
          dispatch
        );
      }
      if (data === "Mail") {
        if (status === "Pending") {
          const HTML_DATA = {
            date: date,
            ClientName: ClientBasic.companyName,
            ContactPerson: ClientBasic.contactPerson,
            ClientID: ClientBasic.clientId,
          };
          var filenamePathArray = [];
          var auditorfilenamePathArray = [];
          // Push attachments
          attachments.forEach((attachment) => {
            const { filename, path } = attachment;
            filenamePathArray.push({ filename, path });
          });
          // Push auditor comments files
          auditorCommentsFileObjects.forEach((auditorComments) => {
            const { filename, path } = auditorComments;
            auditorfilenamePathArray.push({ filename, path });
          });
          const combinedArray = [
            ...filenamePathArray,
            ...auditorfilenamePathArray,
          ];
          // Convert each object to a JSON string and join them with a newline
          const jsonString = combinedArray
            .map((obj) => JSON.stringify(obj, null, 4))
            .join("\n");
          const emailRes = await postData(
            EMAIL_ROUTES.SEND_EMAIL_FILE,
            {
              to: ClientBasic.email,
              subject: "NC corrective actions",
              html: AuditConfirmationEmail(HTML_DATA),
              attachments: combinedArray,
            },
            accessToken,
            dispatch
          );
        }
        if (status === "Accepted") {
          const HTML_DATA = {
            ClientName: ClientBasic.companyName,
            ContactPerson: ClientBasic.contactPerson,
            ClientID: ClientBasic.clientId,
            AuditType: auditData.auditType,
            Message: `
             Finding ID : ${findingNumberPrefix} - ${findingNumberSubfix}
             This correspondence is intended as an acknowledgement of your submitted Corrective Action Report (CAR).
             Your corrective acions for the  identified NC’s during the audit have been reviewed and accepted for further processing.
             Corrective actions taken will be verified for continued implementation and effectiveness during a future audit.
             This is an automatically-generated customer service response from Caussatec. Please do not reply to this email. If you require further assistance please contact your local Caussatec office
             `,
          };
          await postData(
            EMAIL_ROUTES.SEND_EMAIL_TEXT,
            {
              to: ClientBasic.email,
              subject: "Corrective Action Accepted",
              html: CommonEmail(HTML_DATA),
            },
            accessToken,
            dispatch
          );
        }

        postData(
          EVENT_TRACKING_ROUTES.CREATE_EVENT_HISTORY(ClientBasic._id),
          {
            description: `NC Corrective Actions Mail Send successfully`,
            performedBy: `${sessionStorage.getItem(
              "firstName"
            )} ${sessionStorage.getItem("lastName")}`,
          },
          accessToken,
          dispatch
        );
      }
      // BackToCalender();
      fetchdata();
      setaddfinding(false);
      seteditCorrectiveactionval();
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const CloseCorrectiveAction = () => {
    // BackToCalender();
    setaddfinding(false);
    seteditCorrectiveactionval();
  };

  const fetchdata = async () => {
    const res = await getData(
      `${AUDITROUTES.GETCORRECTIVEACTION(AuditId)}`,
      null,
      accessToken
    );
    setCorrectiveActionVal(res.data);
    if (res.data && res.data.length > 0) {
      const lastObject = res.data[res.data.length - 1];
      const {
        findingNumberPrefix: lastPrefix,
        findingNumberSubfix: lastSubfix,
      } = lastObject;
      setfindingNumberPrefix(lastPrefix);
      const nextSubfix = parseInt(lastSubfix) + 1;
      setfindingNumberSubfix(nextSubfix.toString());
    } else {
      const randomNumber = generateRandomNumber();
      setfindingNumberPrefix(randomNumber);
      setfindingNumberSubfix("1");
    }
    console.log(res);
  };

  useEffect(() => {
    fetchdata();
  }, []);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  useEffect(() => {
    if (editCorrectiveactionval) {
      setaddfinding(true);
      // Find the object with the matching ID
      const correctiveAction = Correctiveactionval.find(
        (item) => item._id === editCorrectiveactionval
      );

      if (correctiveAction) {
        setfindingNumberPrefix(correctiveAction?.findingNumberPrefix);
        setfindingNumberSubfix(correctiveAction?.findingNumberSubfix);
        setObjectiveEvidence(correctiveAction?.objectiveEvidence);
        setAuditorIssuedTheFinding(correctiveAction?.auditorIssuedTheFinding);
        setFinding(correctiveAction?.finding);
        setCertificationExpiryDate(
          formatDate(correctiveAction?.certificationExpiryDate)
        );
        setFindingDueDate(formatDate(correctiveAction?.findingDueDate));
        setClassification(correctiveAction?.classification);
        setRequirementClause(correctiveAction?.requirementClause);
        // setApplicableLaws(correctiveAction.applicableLaws);
        setverifyStatus(correctiveAction?.verifyStatus);
        setSiteName(correctiveAction?.siteName);
        setManagementSystemDocumentationReference(
          correctiveAction?.managementSystemDocumentationReference
        );
        setCorrectiveActionPlanDueDate(
          formatDate(correctiveAction?.correctiveActionPlanDueDate)
        );
        setcorrectiveActionPlan(correctiveAction?.correctiveActionPlan);
        setresponsible(correctiveAction?.responsible);
        setplanForVerificationOfEffectiveness(
          correctiveAction?.planForVerificationOfEffectiveness
        );
        setRootCause(correctiveAction?.rootCause);
        setCorrection(correctiveAction?.correction);
        setCorrectiveAction(correctiveAction?.correctiveAction);
        setCorrectiveActionImplementationDate(
          formatDate(correctiveAction?.correctiveActionImplementationDate)
        );
        setVerificationData(formatDate(correctiveAction?.verificationData));
        setAttachments(correctiveAction?.attachments);
        setauditorCommentsFileObjects(
          correctiveAction?.auditorCommentsFileObjects
        );
        // setAuditorCommentsText(correctiveAction.auditorCommentsWithFiles.text);
        // setAuditorCommentsFiles(
        //   correctiveAction?.auditorCommentsWithFiles?.files
        // );
        setStatus(correctiveAction?.status);
        setcorrectiveActionPlanComments(
          correctiveAction?.correctiveActionPlanComments
        );
        setverificationOfCorrectiveTaken(
          correctiveAction?.verificationOfCorrectiveTaken
        );
        setacceptedBy(correctiveAction?.acceptedBy);
        setacceptedDate(formatDate(correctiveAction?.acceptedDate));
        setauditDate(formatDate(correctiveAction?.auditDate));
      }
    }
  }, [editCorrectiveactionval]);

  //finding
  const [addfinding, setaddfinding] = useState(false);
  const openFinding = () => {
    setaddfinding(true);
    seteditCorrectiveactionval();
  };
  const DeleteId = (val) => {
    const confirmDelete = window.confirm("Are you sure you want to delete?");
    if (confirmDelete) {
      deleteData(`${AUDITROUTES.EDITCORRECTIVEACTION(val)}`, accessToken)
        .then((res) => {
          fetchdata();
        })
        .catch((error) => {
          console.error("Error:", error);
          toast.error("An error occurred. Please try again later.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [editCorrectiveactionval]);

  return (
    <div>
      <ToastContainer />
      <div className="TrainingCertifications-border p-5">
        <div className="px-2 add-title">
          <span className="">Auditor Findings</span>
        </div>
        {!addfinding ? (
          <>
            <div className="d-flex justify-content-end mb-3">
              <Button className="cl-green text-white " onClick={openFinding}>
                {" "}
                Add Finding
              </Button>
            </div>
            <Card>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="cl-green text-dark">
                        Finding Id
                      </TableCell>
                      <TableCell className="cl-green text-dark">
                        Status
                      </TableCell>
                      <TableCell className="cl-green text-dark">
                        Site Name
                      </TableCell>
                      <TableCell className="cl-green text-dark">
                        Auditor Findings
                      </TableCell>
                      <TableCell className="cl-green text-dark">
                        Attachments
                      </TableCell>
                      {/* <TableCell className="cl-green text-dark">
                        Finding Due Date
                      </TableCell>
                      <TableCell className="cl-green text-dark">
                        Certificate Expiry Date
                      </TableCell> */}
                      <TableCell className="cl-green text-dark">
                        Closed By/On
                      </TableCell>
                      <TableCell className="cl-green text-dark">Edit</TableCell>
                      <TableCell className="cl-green text-dark">
                        Delete
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Correctiveactionval &&
                      Correctiveactionval.map((finding, index) => (
                        <TableRow key={finding._id}>
                          <TableCell>
                            {finding.findingNumberPrefix} -{" "}
                            {finding.findingNumberSubfix}
                          </TableCell>
                          <TableCell>{finding.status}</TableCell>
                          <TableCell>{finding.siteName}</TableCell>
                          <TableCell>{finding.finding}</TableCell>
                          <TableCell>
                            {finding.attachments.map((attachment) => (
                              <>
                                <a
                                  href={attachment.uri}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                >
                                  {attachment.originalname}
                                </a>
                                <br />
                              </>
                            ))}
                          </TableCell>
                          {/* <TableCell>
                            {formatDate(finding.findingDueDate)}
                          </TableCell>
                          <TableCell>
                            {formatDate(finding.certificationExpiryDate)}
                          </TableCell> */}
                          <TableCell>
                            {finding.closed ? (
                              <>
                                {finding.closed.by} /{" "}
                                {formatDate(finding.closed.on)}
                              </>
                            ) : (
                              "-"
                            )}
                          </TableCell>
                          <TableCell>
                            <Iconify
                              icon={"eva:edit-fill"}
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                seteditCorrectiveactionval(finding._id);
                              }}
                              sx={{ mr: 2 }}
                            />
                          </TableCell>
                          <TableCell>
                            {index !== 0 && (
                              <Iconify
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  DeleteId(finding._id);
                                }}
                                icon={"eva:trash-2-outline"}
                                sx={{ mr: 2 }}
                              />
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </>
        ) : (
          <>
            <p className="fw-bold mb-0 my-3">Section - 1 - Finding Details</p>
            <div className="d-flex flex-wrap justify-content-start">
              <div className="col-4 mt-2 px-4">
                <TextField
                  label="FindingId"
                  value={`${findingNumberPrefix} - ${findingNumberSubfix}`}
                  disabled
                  fullWidth
                />
              </div>
              <div className="col-4 mt-2 px-4">
                <TextField
                  type="date"
                  label="Audit Date"
                  value={auditDate}
                  onChange={(e) => setauditDate(e.target.value)}
                  fullWidth
                />
              </div>
              <div className="col-4 mt-2 px-4">
                <TextField
                  label="Auditor Issued The Finding"
                  value={auditorIssuedTheFinding}
                  onChange={(e) => setAuditorIssuedTheFinding(e.target.value)}
                  fullWidth
                />
              </div>
              <div className="col-4 mt-2 px-4">
                <TextField
                  label="Finding"
                  value={finding}
                  onChange={(e) => setFinding(e.target.value)}
                  fullWidth
                />
              </div>
              <div className="col-4 mt-2 px-4">
                <TextField
                  label="Requirement Clause"
                  value={requirementClause.join(", ")}
                  onChange={(e) =>
                    setRequirementClause(e.target.value.split(", "))
                  }
                  fullWidth
                />
              </div>
              <div className="col-4 mt-2 px-4">
                <TextField
                  label="Objective Evidence"
                  value={objectiveEvidence}
                  onChange={(e) => setObjectiveEvidence(e.target.value)}
                  fullWidth
                />
              </div>
              <div className="col-4 mt-2 px-4">
                <TextField
                  label="Site Name"
                  value={siteName}
                  onChange={(e) => setSiteName(e.target.value)}
                  fullWidth
                />
              </div>
              <div className="col-4 mt-2 px-4">
                <TextField
                  label="Classification"
                  select
                  value={classification}
                  onChange={(e) => setClassification(e.target.value)}
                  fullWidth
                >
                  <MenuItem value="Major">Major</MenuItem>
                  <MenuItem value="Minor">Minor</MenuItem>
                </TextField>
              </div>
              <div className="col-4 mt-2 px-4">
                <TextField
                  label="Management System Documentation Reference"
                  value={managementSystemDocumentationReference}
                  onChange={(e) =>
                    setManagementSystemDocumentationReference(e.target.value)
                  }
                  fullWidth
                />
              </div>
            </div>

            <p className="fw-bold mb-0 my-3">
              Section - 2 - Corrective Action Plan
            </p>
            <div className="d-flex flex-wrap justify-content-start">
              <div className="col-4 mt-2 px-4">
                <TextField
                  label="Root Cause"
                  value={rootCause}
                  onChange={(e) => setRootCause(e.target.value)}
                  fullWidth
                />
              </div>
              <div className="col-4 mt-2 px-4">
                <TextField
                  label="Correction"
                  value={correction}
                  onChange={(e) => setCorrection(e.target.value)}
                  fullWidth
                />
              </div>
              <div className="col-4 mt-2 px-4">
                <TextField
                  label="corrective Action Plan"
                  value={correctiveActionPlan}
                  onChange={(e) => setcorrectiveActionPlan(e.target.value)}
                  fullWidth
                />
              </div>
              <div className="col-4 mt-2 px-4">
                <TextField
                  label="Corrective Action Implementation Date"
                  type="date"
                  value={correctiveActionImplementationDate}
                  onChange={(e) =>
                    setCorrectiveActionImplementationDate(e.target.value)
                  }
                  fullWidth
                />
              </div>
              <div className="col-4 mt-2 px-4">
                <TextField
                  label="Responsible"
                  value={responsible}
                  onChange={(e) => setresponsible(e.target.value)}
                  fullWidth
                />
              </div>
              <div className="col-4 mt-2 px-4">
                <TextField
                  type="date"
                  label="Received Date"
                  value={correctiveActionDate}
                  onChange={(e) => setcorrectiveActionDate(e.target.value)}
                  fullWidth
                />
              </div>
              <div className="col-4 mt-2 px-4">
                <TextField
                  label="Plan For Verification Of Effectiveness"
                  value={planForVerificationOfEffectiveness}
                  onChange={(e) =>
                    setplanForVerificationOfEffectiveness(e.target.value)
                  }
                  fullWidth
                />
              </div>
            </div>

            <p className="fw-bold mb-0 my-3">
              Section - 3 - Acceptance Of Corrective Action Plan
            </p>
            <div className="d-flex flex-wrap justify-content-start">
              <div className="col-4 mt-2 px-4">
                <TextField
                  label="Corrective Action Plan Comments"
                  value={correctiveActionPlanComments}
                  onChange={(e) =>
                    setcorrectiveActionPlanComments(e.target.value)
                  }
                  fullWidth
                />
              </div>
              <div className="col-4 mt-2 px-4">
                <TextField
                  label="Verification Of Corrective Taken"
                  value={verificationOfCorrectiveTaken}
                  onChange={(e) =>
                    setverificationOfCorrectiveTaken(e.target.value)
                  }
                  fullWidth
                />
              </div>
              <div className="col-4 mt-2 px-4">
                <TextField
                  label="Accepted By"
                  value={acceptedBy}
                  onChange={(e) => setacceptedBy(e.target.value)}
                  fullWidth
                />
              </div>
              <div className="col-4 mt-2 px-4">
                <TextField
                  type="date"
                  label="Accepted Date"
                  value={acceptedDate}
                  onChange={(e) => setacceptedDate(e.target.value)}
                  fullWidth
                />
              </div>
            </div>

            {/* <div className="d-flex flex-wrap justify-content-start">
              <div className="col-4 mt-2 px-4">
                <TextField
                  label="Finding Due Date"
                  type="date"
                  value={findingDueDate}
                  onChange={(e) => setFindingDueDate(e.target.value)}
                  fullWidth
                />
              </div>
              <div className="col-4 mt-2 px-4">
                <TextField
                  label="Certification Expiry Date"
                  type="date"
                  value={certificationExpiryDate}
                  onChange={(e) => setCertificationExpiryDate(e.target.value)}
                  fullWidth
                />
              </div>

              <div className="col-4 mt-2 px-4">
                <TextField
                  label="Applicable Laws"
                  value={applicableLaws.join(", ")}
                  onChange={(e) =>
                    setApplicableLaws(e.target.value.split(", "))
                  }
                  fullWidth
                />
              </div>
              <div className="col-4 mt-2 px-4">
                <TextField
                  label="Verify Status"
                  value={verifyStatus}
                  onChange={(e) => setverifyStatus(e.target.value)}
                  fullWidth
                />
              </div>

              <div className="col-4 mt-2 px-4">
                <TextField
                  label="Corrective Action Plan Due Date"
                  type="date"
                  value={correctiveActionPlanDueDate}
                  onChange={(e) =>
                    setCorrectiveActionPlanDueDate(e.target.value)
                  }
                  fullWidth
                />
              </div>
            </div>
            <p className="fw-bold mb-0 my-3">Corrective Action</p>
            <div className="d-flex flex-wrap">
              <div className="col-4 mt-2 px-4">
                <TextField
                  label="Verification Date"
                  value={verificationData}
                  onChange={(e) => setVerificationData(e.target.value)}
                  fullWidth
                />
              </div>

              <div className="col-4 mt-2 px-4">
                <TextField label="Correction Comments" fullWidth />
              </div>
              <div className="col-4 mt-2 px-4">
                <TextField type="date" label="Correction Date" fullWidth />
              </div>

              <div className="col-4 mt-2 px-4">
                <TextField
                  label="Corrective Action"
                  value={correctiveAction}
                  onChange={(e) => setCorrectiveAction(e.target.value)}
                  fullWidth
                />
              </div>
              <div className="col-4 mt-2 px-4">
                <TextField label="Corrective Action Comments" fullWidth />
              </div>
              <div className="col-4 mt-2 px-4">
                <TextField
                  type="date"
                  label="Corrective Action Date"
                  fullWidth
                />
              </div>
            </div> */}
            <div className="d-flex flex-wrap">
              {(Correctiveactionval && Correctiveactionval.length === 0) ||
              editCorrectiveactionval === Correctiveactionval?.[0]._id ? (
                <>
                  {/* Attachments */}
                  <div className="col-4 mt-2 px-4">
                    <label htmlFor="attachments">Attachments</label>
                    {/* Assuming you have a way to handle file uploads */}
                    <input
                      id="attachments"
                      type="file"
                      onChange={(event) =>
                        handleFileChange(event, "Attachments")
                      }
                    />
                    {attachments.length !== 0 &&
                      attachments.map((attachment, index) => (
                        <div key={index}>
                          <a href={`${SERVER_URL}/${attachment.uri}`}>
                            {attachment.filename}
                          </a>
                          <Button
                            className="ms-2 text-danger"
                            onClick={() => removeAttachment(index)}
                          >
                            Remove
                          </Button>
                        </div>
                      ))}
                  </div>
                  <div className="col-4 mt-2 px-4">
                    <label htmlFor="auditorCommentsFileObjects">
                      AuditorFiles
                    </label>
                    {/* Assuming you have a way to handle file uploads */}
                    <input
                      id="auditorCommentsFileObjects"
                      type="file"
                      onChange={(event) =>
                        handleFileChange(event, "AuditorFiles")
                      }
                    />
                    {auditorCommentsFileObjects.length !== 0 &&
                      auditorCommentsFileObjects.map(
                        (auditorCommentsFileObjects, index) => (
                          <div key={index}>
                            <a
                              href={`${SERVER_URL}/${auditorCommentsFileObjects.uri}`}
                            >
                              {auditorCommentsFileObjects.filename}
                            </a>
                            <Button
                              className="ms-2 text-danger"
                              onClick={() => removeAttachment(index)}
                            >
                              Remove
                            </Button>
                          </div>
                        )
                      )}
                  </div>
                  {/* Auditor Comments */}
                  {/* <div className="col-4 mt-2 px-4">
                    <label htmlFor="auditorFile">Auditor file</label>
                    <input
                      type="file"
                      onChange={(event) =>
                        handleFileChange(event, "Auditor Comments")
                      }
                      multiple
                    />
                    {auditorCommentsFiles &&
                      auditorCommentsFiles.length !== 0 &&
                      auditorCommentsFiles.map((auditorCommentsFile, index) => (
                        <div key={index}>
                          <a
                            href={`${SERVER_URL}/${auditorCommentsFile.uri}`}
                          >
                            {auditorCommentsFile.filename}
                          </a>
                          <Button
                            className="ms-2 text-danger"
                            onClick={() => removeAuditorCommentsFile(index)}
                          >
                            Remove
                          </Button>
                        </div>
                      ))}
                  </div> */}
                </>
              ) : (
                ""
              )}

              {/* <div className="col-4 mt-2 px-4">
                <TextField
                  label="Auditor Comments"
                  value={auditorCommentsText}
                  onChange={(e) => setAuditorCommentsText(e.target.value)}
                  fullWidth
                />
              </div> */}
              {/* Status */}
              <div className="col-12 mt-2 px-4 d-flex align-items-center">
                <p className="mb-0 me-4 fw-bold">Status</p>
                <RadioGroup
                  className="d-flex"
                  style={{ flexDirection: "row" }}
                  aria-label="status"
                  value={status}
                  onChange={(e) => setStatus(e.target.value)}
                >
                  <FormControlLabel
                    value="Pending"
                    control={<Radio />}
                    label="Pending"
                  />
                  <FormControlLabel
                    value="Accepted"
                    control={<Radio />}
                    label="Accepted"
                  />
                  <FormControlLabel
                    value="Rejected"
                    control={<Radio />}
                    label="Rejected"
                  />
                  <FormControlLabel
                    value="Cancelled"
                    control={<Radio />}
                    label="Cancelled"
                  />
                </RadioGroup>
              </div>
            </div>
            <div>
              <DialogActions>
                <div className="d-flex mt-5 px-4">
                  <Button
                    className="cl-green text-white"
                    onClick={() => UpdateCorrectiveAction("Save")}
                  >
                    Save
                  </Button>
                  <Button
                    className="text-white mx-4"
                    onClick={CloseCorrectiveAction}
                    style={{ color: "white", backgroundColor: "red" }}
                  >
                    Close
                  </Button>
                  <Button
                    className="cl-orange text-white px-4"
                    onClick={() => UpdateCorrectiveAction("Mail")}
                  >
                    Save & Close
                  </Button>
                </div>
              </DialogActions>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

export default Corrective;
