import {
    Select,
    MenuItem,
    Box,
    IconButton,
    Typography,
    TextField,
    Button,
    Link,
    InputLabel
} from "@mui/material";
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { TimeField } from '@mui/x-date-pickers/TimeField';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
import FileUploadOutlinedIcon from '@mui/icons-material/FileUploadOutlined';
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import FILEROUTES from "src/API_Routes/File/File_Router";
import CLIENT_ROUTES from "src/API_Routes/Client/Client_Routers";
import { FileUpload, postData } from "src/webService/webService";
import SERVER_URL from "src/utils/serverURL";
import { CircularLoaderPopup } from "src/utils/popup/loader";
import { ClientTemplateData } from "src/Config/HRA/ClientData";
import { useDispatch } from "react-redux";

function ClientDataAdd() {
    const dispatch = useDispatch();
    const scheme_session = sessionStorage.getItem("scheme");
    const [loading, setLoading] = useState(null);
    const [clientData, setClientData] = useState(null);
    const [clientBasicId, setClientBasicId] = useState(null);
    const CLIENT_STATUS_TYPES = ['pending', 'waiting for reviewer', 'reviewer assigned',
        'need revision', 'revision resolved', 'approved', 'rejected', 'auditor assigned',
        'process associate assigned auditor', 'technical review completed', 'certificate completed',
        'client advance completed', 'proposal generated', 'proposal updated', 'agreement generated',
        'agreement updated', 'auditor confirmed', 'audit rejected'];
    const navigate = useNavigate();

    useEffect(() => {
        // Deep copy the template data
        setClientData(JSON.parse(JSON.stringify(ClientTemplateData)))
        console.log('clientBasicId', clientBasicId)
    }, [])

    useEffect(() => {
        console.log('clientData', clientData);
        // this will delay the loader untill clientData is updated
        setTimeout(() => setLoading('false'), 1000);
    }, [clientData])

    const handleChange = (e, index) => {
        const { name, value } = e.target;
        switch (name) {

            case 'status':
                // Create a new copy of the status obj
                let newStatus = { ...clientData.status };
                // Create a new copy of the first object in the array
                const updatedObjectStatus = { ...newStatus };

                // Update the property
                updatedObjectStatus['type'] = value;
                updatedObjectStatus['updatedDate'] = dayjs().format();

                // Replace the old object in the new obj
                newStatus = updatedObjectStatus;
                setClientData({
                    ...clientData,
                    status: newStatus
                });
                break;

            case 'technicalCodeMainCode':
                // Create a new copy of the technicalCode obj
                let newTechnicalCodeMainCode = { ...clientData.technicalCode };
                // Create a new copy of the first object in the array
                const updatedObjectMainCode = { ...newTechnicalCodeMainCode };

                // Update the property
                updatedObjectMainCode['mainCode'] = value;

                // Replace the old object in the new obj
                newTechnicalCodeMainCode = updatedObjectMainCode;
                setClientData({
                    ...clientData,
                    technicalCode: newTechnicalCodeMainCode
                });
                break;

            case 'technicalCodeSubCode':
                // Create a new copy of the technicalCode obj
                let newTechnicalCodeSubCode = { ...clientData.technicalCode };
                // Create a new copy of the first object in the obj
                const updatedObjectSubCode = { ...newTechnicalCodeSubCode };

                // Update the property
                updatedObjectSubCode['subCode'] = value;

                // Replace the old object in the obj
                newTechnicalCodeSubCode = updatedObjectSubCode;
                setClientData({
                    ...clientData,
                    technicalCode: newTechnicalCodeSubCode
                });
                break;

            case 'reviewPointsAndDocuments':
                // Create a new copy of the technicalCode obj
                let newRev = {
                    ...clientData.ClientFormHRA_Data[0]
                        .reviewPointsAndDocuments
                };
                // Create a new copy of the first object in the obj
                const updatedObjectRev = { ...newRev };

                // Update the property
                updatedObjectRev[index].reviewAnswer = value;

                // Replace the old object in the obj
                newRev = updatedObjectRev;
                setClientData({
                    ...clientData,
                    reviewPointsAndDocuments: newRev
                });
                break;

            default:
                setClientData({
                    ...clientData,
                    [name]: value
                })
        };
    };

    const handleHRAChange = (e, index) => {
        const { name, value } = e.target;
        switch (name) {

            case 'site name' || 'site location':
                const siteFieldArray = name?.split(' ');
                console.log('siteFieldArray', siteFieldArray)
                clientData
                    .ClientFormHRA_Data[0]
                [siteFieldArray[0]]
                [index]
                [siteFieldArray[1]] = value;
                console.log('siteFieldArray', clientData
                    .ClientFormHRA_Data[0]
                [siteFieldArray[0]]
                [index]
                [siteFieldArray[1]])
                setClientData({
                    ...clientData
                });
                break;

            case 'site location':
                const siteFieldArray2 = name?.split(' ');
                console.log('siteFieldArray', siteFieldArray2)
                clientData
                    .ClientFormHRA_Data[0]
                [siteFieldArray2[0]]
                [index]
                [siteFieldArray2[1]] = value;
                console.log('siteFieldArray', clientData
                    .ClientFormHRA_Data[0]
                [siteFieldArray2[0]]
                [index]
                [siteFieldArray2[1]])
                setClientData({
                    ...clientData
                });
                break;

            case 'FoSTaC_TrainedFoodSafetySupervisors':
                clientData
                    .ClientFormHRA_Data[0]
                    .FoSTaC_TrainedFoodSafetySupervisors[index] = value;
                setClientData({
                    ...clientData
                });
                break;

            case 'serviceCharges hygieneRatingAuditFee':
                const serviceChargesFieldArray = name?.split(' ');
                clientData.ClientFormHRA_Data[0]
                [serviceChargesFieldArray[0]]
                [serviceChargesFieldArray[1]] = value;

                clientData.ClientFormHRA_Data[0]['serviceCharges']['grandTotal'] =
                    Number(clientData.ClientFormHRA_Data[0]['serviceCharges']['hygieneRatingAuditFee'])
                    +
                    Number(clientData.ClientFormHRA_Data[0]['serviceCharges']['GST_Charges']);

                setClientData({
                    ...clientData
                });
                break;

            case 'serviceCharges GST_Charges':
                const serviceChargesFieldArray2 = name?.split(' ');
                clientData.ClientFormHRA_Data[0]
                [serviceChargesFieldArray2[0]]
                [serviceChargesFieldArray2[1]] = value;

                clientData.ClientFormHRA_Data[0]['serviceCharges']['grandTotal'] =
                    Number(clientData.ClientFormHRA_Data[0]['serviceCharges']['hygieneRatingAuditFee'])
                    +
                    Number(clientData.ClientFormHRA_Data[0]['serviceCharges']['GST_Charges']);

                setClientData({
                    ...clientData
                });
                break;

            default:
                clientData.ClientFormHRA_Data[0][name] = value;
                setClientData({
                    ...clientData
                });
                break;
        }
    };

    const addSite = () => {
        clientData.ClientFormHRA_Data[0]['site'].push({ name: '', location: '' });
        console.log('...clientData.ClientFormHRA_Data[0][site]', clientData.ClientFormHRA_Data[0]['site']);
        setClientData({
            ...clientData
        });
    }

    const addFoSTaC_TrainedFoodSafetySupervisors = () => {
        clientData.ClientFormHRA_Data[0].FoSTaC_TrainedFoodSafetySupervisors.push('');
        console.log('clientData.ClientFormHRA_Data[0].FoSTaC_TrainedFoodSafetySupervisors',
            clientData.ClientFormHRA_Data[0].FoSTaC_TrainedFoodSafetySupervisors);
        setClientData({
            ...clientData
        });
    }

    const addAuthorisedSignatoryOfFoodServiceEstablishment = () => {
        clientData.ClientFormHRA_Data[0]['authorisedSignatoryOfFoodServiceEstablishmentFileObject']
            .push({
                "originalname": "",
                "mimetype": "",
                "size": "",
                "filename": "",
                "destination": "",
                "path": "",
                "uri": "",
                "createdAt": "",
                "updatedAt": "",
            });
        console.log(
            '...clientData.ClientFormHRA_Data[0][authorisedSignatoryOfFoodServiceEstablishmentFileObject]',
            clientData.ClientFormHRA_Data[0]['authorisedSignatoryOfFoodServiceEstablishmentFileObject']
        );
        setClientData({
            ...clientData
        });
    }

    const removeFoSTaC_TrainedFoodSafetySupervisors = (index) => {
        if (index < 1) {
            toast.error("Minimum 1 FoSTaC Trained Food Safety Supervisor is required", {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }
        clientData.ClientFormHRA_Data[0]['FoSTaC_TrainedFoodSafetySupervisors'] =
            clientData.ClientFormHRA_Data[0]['FoSTaC_TrainedFoodSafetySupervisors'].filter((_, i) => i !== index);
        console.log('...clientData.ClientFormHRA_Data[0][FoSTaC_TrainedFoodSafetySupervisors]',
            clientData.ClientFormHRA_Data[0]['FoSTaC_TrainedFoodSafetySupervisors']);
        setClientData({
            ...clientData
        });
    }

    const removeSite = (index) => {
        if (index < 1) {
            toast.error("Minimum 1 site is required", {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }
        clientData.ClientFormHRA_Data[0]['site'] =
            clientData.ClientFormHRA_Data[0]['site'].filter((_, i) => i !== index);
        console.log('...clientData.ClientFormHRA_Data[0][site]',
            clientData.ClientFormHRA_Data[0]['site']);
        setClientData({
            ...clientData
        });
    }

    const removeAuthorisedSignatory = (index) => {
        if (index < 1) {
            toast.error("Minimum 1 Authorised Signatory is required", {
                position: toast.POSITION.TOP_RIGHT,
            });
            return;
        }
        clientData.ClientFormHRA_Data[0]['authorisedSignatoryOfFoodServiceEstablishmentFileObject'] =
            clientData.ClientFormHRA_Data[0]['authorisedSignatoryOfFoodServiceEstablishmentFileObject'].filter((_, i) => i !== index);
        console.log('...clientData.ClientFormHRA_Data[0][authorisedSignatoryOfFoodServiceEstablishmentFileObject]',
            clientData.ClientFormHRA_Data[0]['authorisedSignatoryOfFoodServiceEstablishmentFileObject']);
        setClientData({
            ...clientData
        });
    }

    const handleBasicSubmit = async (e) => {
        e.preventDefault();
        try {
            const accessToken = sessionStorage.getItem("Token");
            const clientBasicData = JSON.parse(JSON.stringify(clientData));
            delete clientBasicData.ClientFormHRA_Data;
            console.log('clientBasicData', clientBasicData, clientData);
            const ClientBasicAddRes = await postData(
                CLIENT_ROUTES.ADD_CLIENT_BASIC,
                clientBasicData,
                accessToken
            );
            const CLIENT_BASIC_ID = ClientBasicAddRes?.data?._id;
            setClientBasicId(CLIENT_BASIC_ID);
            toast.success("Client basic data added", {
                position: toast.POSITION.TOP_RIGHT,
            });
        } catch (error) {
            console.error("Failed to add client data", error);
        }
    };

    const handleAdvancedSubmit = async (e) => {
        e.preventDefault();
        try {
            const accessToken = sessionStorage.getItem("Token");
            const clientAdvancedData = JSON.parse(JSON.stringify(clientData));
            console.log('clientAdvancedData', clientAdvancedData.ClientFormHRA_Data[0], clientData);
            const ClientAdvancedAddRes = await postData(
                CLIENT_ROUTES.ADD_CLIENT_ADVANCED(clientBasicId),
                clientAdvancedData.ClientFormHRA_Data[0],
                accessToken
            );

            toast.success("Client Advanced data added", {
                position: toast.POSITION.TOP_RIGHT,
            });
            setTimeout(
                () => navigate('/operationmanager/dashboard'),
                1000
            );
        } catch (error) {
            console.error("Failed to add client data", error);
        }
    };

    const handleFileInputChange = async (event, keyName, depthObject, index) => {
        const file = event?.target?.files[0];
        const formData = new FormData();
        formData.append("file", file);
        try {
            setLoading('true');
            const response = await FileUpload(
                `${FILEROUTES.UPLOAD}${sessionStorage.getItem('UserId')}`,
                formData,
                dispatch
            );
            switch (depthObject) {
                case 'ClientFormHRA_Data':
                    clientData.ClientFormHRA_Data[0][keyName] = response.data;
                    setClientData({ ...clientData });
                    break;
                case 'ClientFormHRA_Data.reviewPointsAndDocuments':
                    clientData.ClientFormHRA_Data[0]
                        .reviewPointsAndDocuments[index].reviewEvidenceDocumentFileObject = response.data
                    setClientData({ ...clientData });
                    break;
                case 'ClientFormHRA_Data.authorisedSignatoryOfFoodServiceEstablishmentFileObject':
                    clientData.ClientFormHRA_Data[0]
                        .authorisedSignatoryOfFoodServiceEstablishmentFileObject[index] = response.data
                    setClientData({ ...clientData });
                    break;
            }
        }
        catch (error) {
            console.error("File upload error:", error);
            toast.error("File upload error", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };


    const renderTextField = (label, value, name, disable = false) => (
        <div className="col-4 px-2 py-3">
            <TextField
                className="w-100"
                label={label}
                value={value}
                name={name}
                size="small"
                onChange={handleChange}
                disabled={disable}
            />
        </div>
    );

    const renderHRATextField = (label, value, name) => (
        <div className="col-4 px-2 py-3">
            <TextField
                className="w-100"
                label={label}
                defaultValue={value}
                name={name}
                size="small"
                onChange={handleHRAChange}
            />
        </div>
    );

    return (
        <>
            <ToastContainer />

            <CircularLoaderPopup loading={loading} />

            <div className="clientbasicadvance-border p-5">
                <div className="px-2 add-title">
                    <span className="">Add Client</span>
                </div>
                <>
                    <form onSubmit={handleBasicSubmit}>
                        <Typography className="my-3 fw-bold">Basic Details</Typography>
                        <div className="d-flex flex-wrap">
                            {renderTextField("Company Name", clientData?.companyName, "companyName")}
                            {renderTextField("Email", clientData?.email, "email")}
                            {renderTextField("Mobile Number", clientData?.phoneNumber, "phoneNumber")}
                            {scheme_session !== "HRA" && (
                                <>
                                    {renderTextField("Technical Main Code", clientData?.technicalCode.mainCode, "technicalCodeMainCode")}
                                    {renderTextField("Technical Sub Code", clientData?.technicalCode.subCode, "technicalCodeSubCode")}
                                </>
                            )}
                            {renderTextField("Scheme", clientData?.standardsSchemeType, "standardsSchemeType", true)}
                            {scheme_session !== "HRA" && (
                                <>
                                    {renderTextField("Address", clientData?.address, "address")}
                                </>
                            )}
                            {scheme_session === "HRA" && (
                                <>
                                    {renderTextField("DoorNo", clientData?.doorNo, "doorNo")}
                                    {renderTextField("StreetName", clientData?.streetName, "streetName")}
                                    {renderTextField("City", clientData?.city, "city")}
                                    {renderTextField("District", clientData?.district, "district")}
                                    {renderTextField("Pincode", clientData?.pincode, "pincode")}

                                </>
                            )}
                            {renderTextField("State", clientData?.state, "state")}
                            {renderTextField("Country", clientData?.country, "country")}
                            {renderTextField("No Of Sites", clientData?.noOfSites, "noOfSites")}
                            {renderTextField("Contact Person Name", clientData?.contactPerson, "contactPerson")}
                            {renderTextField("Lead Generated By", clientData?.leadGeneratedBy, "leadGeneratedBy")}
                            <div>
                                <InputLabel>Status</InputLabel>
                                <Select
                                    name="status"
                                    id="status-select"
                                    value={clientData?.status?.type}
                                    onChange={handleChange}
                                >
                                    {
                                        CLIENT_STATUS_TYPES.map(item => (
                                            <MenuItem key={item} value={item}>{item}</MenuItem>
                                        ))
                                    }

                                </Select>
                            </div>
                            <InputLabel>
                                {clientData?.status?.type && clientData?.status?.updatedDate && (
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DemoContainer components={['DateCalendar', 'DateCalendar']}>
                                            <DemoItem>
                                                Status Updated Date:
                                            </DemoItem>
                                            <DemoItem>
                                                <DateCalendar
                                                    defaultValue={
                                                        clientData?.status?.updatedDate ?
                                                            dayjs(clientData.status.updatedDate) : dayjs()}
                                                    readOnly />
                                            </DemoItem>
                                            <DemoItem>
                                                <TimeField
                                                    label="Status Updated Time"
                                                    defaultValue={dayjs(clientData.status.updatedDate)}
                                                    readOnly />
                                            </DemoItem>
                                        </DemoContainer>
                                    </LocalizationProvider>
                                )}
                            </InputLabel>
                        </div>

                        {
                            clientBasicId === null ? (
                                <Button
                                    type="submit"
                                    variant="contained"
                                    className="btn-orange text-dark me-0"
                                >
                                    ADD BASIC DETAILS
                                </Button>
                            ) : (
                                <Button
                                    type="submit"
                                    variant="contained"
                                    disabled>
                                    ADD BASIC DETAILS
                                </Button>
                            )
                        }
                    </form>
                </>
                <>
                    <form onSubmit={handleAdvancedSubmit}>

                        <Typography className="my-3 fw-bold">HRA Details</Typography>
                        <div className="d-flex flex-wrap justify-content-start">
                            {scheme_session !== "HRA" && (
                                <>
                                    {renderHRATextField("c code", clientData?.ClientFormHRA_Data[0]?.cCode, "cCode")}
                                </>
                            )}
                            <InputLabel>
                                Type Of Services:
                            </InputLabel>
                            <Select
                                name="typeOfServices"
                                id="clientType-select"
                                value={clientData?.ClientFormHRA_Data[0]?.typeOfServices}
                                onChange={handleHRAChange}
                            >
                                <MenuItem value="Food Service Establishment">Food Service Establishment</MenuItem>
                                <MenuItem value="Bakery">Bakery</MenuItem>
                                <MenuItem value="Meat Retail">Meat Retail</MenuItem>
                                <MenuItem value="Sweet Shop">Sweet Shop</MenuItem>
                            </Select>
                            {renderHRATextField("Audit Type", clientData?.ClientFormHRA_Data[0]?.auditType, "auditType")}
                            {renderHRATextField("KOB", clientData?.ClientFormHRA_Data[0]?.kob, "kob")}
                            <InputLabel>
                                Client Type:
                            </InputLabel>
                            <Select
                                name="clientType"
                                id="clientType-select"
                                value={clientData?.ClientFormHRA_Data[0]?.clientType}
                                onChange={handleHRAChange}
                            >
                                <MenuItem value="Government">Government</MenuItem>
                                <MenuItem value="Private">Private</MenuItem>
                            </Select>
                            {renderHRATextField("No Of FoodHandlers", clientData?.ClientFormHRA_Data[0]?.noOfFoodHandlers, "noOfFoodHandlers")}
                            <Box>
                                <Button onClick={addFoSTaC_TrainedFoodSafetySupervisors}><AddIcon />Add FoSTaC Trained Food Safety Supervisors</Button>
                                {clientData?.ClientFormHRA_Data[0]?.FoSTaC_TrainedFoodSafetySupervisors.map(
                                    (Supervisor, SupervisorIndex) => (
                                        <Box key={SupervisorIndex} display="flex" alignItems="center" mb={2}>
                                            <TextField
                                                name="FoSTaC_TrainedFoodSafetySupervisors"
                                                label="FoSTaC Trained Food Safety Supervisors"
                                                defaultValue={Supervisor}
                                                onChange={(e) => handleHRAChange(e, SupervisorIndex)}
                                                variant="outlined"
                                                margin="normal"
                                                style={{ marginRight: 8 }}
                                            />
                                            <IconButton
                                                color="error"
                                                onClick={() => removeFoSTaC_TrainedFoodSafetySupervisors(SupervisorIndex)}
                                                disabled={SupervisorIndex !== clientData?.ClientFormHRA_Data[0]?.FoSTaC_TrainedFoodSafetySupervisors?.length - 1}>
                                                <RemoveIcon />
                                            </IconButton>
                                        </Box>
                                    ))}
                            </Box>
                            {scheme_session !== "HRA" && (
                                <>
                                    {renderHRATextField("Unique Certificate No And Course Name", clientData?.ClientFormHRA_Data[0]?.uniqueCertificateNoAndCourseName, "uniqueCertificateNoAndCourseName")}
                                </>
                            )}
                            {scheme_session === "HRA" && (
                                <>
                                    {renderTextField(
                                        "uniqueCertificateNo",
                                        clientData?.ClientFormHRA_Data[0]?.uniqueCertificateNo, "uniqueCertificateNo"
                                    )}

                                    {renderTextField(
                                        "courseCode",
                                        clientData?.ClientFormHRA_Data[0]?.courseCode, "courseCode"
                                    )}

                                    {renderTextField(
                                        "courseCode",
                                        clientData?.ClientFormHRA_Data[0]?.courseName, "courseName"
                                    )}
                                </>
                            )}
                            {renderHRATextField("FSSAI License Number", clientData?.ClientFormHRA_Data[0]?.FSSAI_LicenseNumber, "FSSAI_LicenseNumber")}
                            {renderHRATextField("GST Number", clientData?.ClientFormHRA_Data[0]?.GSTNumber, "GSTNumber")}
                            {renderHRATextField("Remark", clientData?.ClientFormHRA_Data[0]?.remarks, "remarks")}
                            {renderHRATextField("Total No Of Employees", clientData?.ClientFormHRA_Data[0]?.totalNoOfEmployees, "totalNoOfEmployees")}
                            {scheme_session !== "HRA" && (
                                <>
                                    {renderHRATextField("Head Office", clientData?.ClientFormHRA_Data[0]?.headOffice, "headOffice")}
                                </>
                            )}
                            <Box>
                                <Button onClick={addSite}><AddIcon />Add Site</Button>
                                {clientData?.ClientFormHRA_Data[0]?.site.map((site, siteIndex) => (
                                    <Box key={siteIndex} display="flex" alignItems="center" mb={2}>
                                        <TextField
                                            name="site name"
                                            label="Site Name"
                                            defaultValue={site?.name}
                                            onChange={(e) => handleHRAChange(e, siteIndex)}
                                            variant="outlined"
                                            margin="normal"
                                            style={{ marginRight: 8 }}
                                        />
                                        <TextField
                                            name="site location"
                                            label="Site Location"
                                            defaultValue={site?.location}
                                            onChange={(e) => handleHRAChange(e, siteIndex)}
                                            variant="outlined"
                                            margin="normal"
                                            style={{ marginRight: 8 }}
                                        />
                                        <IconButton
                                            color="error"
                                            onClick={() => removeSite(siteIndex)}
                                            disabled={siteIndex !== clientData?.ClientFormHRA_Data[0]?.site?.length - 1}>
                                            <RemoveIcon />
                                        </IconButton>
                                    </Box>
                                ))}
                            </Box>
                            <InputLabel>
                                Validity Of License: <input
                                    type="date"
                                    value={clientData?.ClientFormHRA_Data[0]?.validityOfLicense}
                                    name={'validityOfLicense'}
                                    size="small"
                                    onChange={handleHRAChange}
                                />
                            </InputLabel>
                        </div>
                        <div>
                            <Typography variant="h6">Account Details</Typography>
                            <InputLabel>Service Charges</InputLabel>
                            {renderHRATextField("Hygiene Rating Audit Fee in ₹", clientData?.ClientFormHRA_Data[0]?.serviceCharges?.hygieneRatingAuditFee, "serviceCharges hygieneRatingAuditFee")}
                            {renderHRATextField("GST Charges in ₹", clientData?.ClientFormHRA_Data[0]?.serviceCharges?.GST_Charges, "serviceCharges GST_Charges")}
                            <InputLabel>Grand Total: ₹{clientData?.ClientFormHRA_Data[0]?.serviceCharges?.grandTotal}</InputLabel>
                        </div>
                        <div>
                            {clientData?.ClientFormHRA_Data[0]?.reviewPointsAndDocuments.map((review, index) => (
                                <div key={index} className="mb-3">
                                    <Typography variant="h6">{review.reviewQuestion}?</Typography>
                                    <br></br>
                                    <Typography variant="body1">
                                        Answer: {
                                            review.reviewAnswer ?
                                                <><Button onClick={() => handleChange({
                                                    target: {
                                                        name: 'reviewPointsAndDocuments',
                                                        value: true
                                                    }
                                                }, index)}
                                                    variant='contained'
                                                    color='success'>Yes</Button>
                                                    <Button onClick={() => handleChange({
                                                        target: {
                                                            name: 'reviewPointsAndDocuments',
                                                            value: false
                                                        }
                                                    }, index)}
                                                        variant='outlined'>No</Button>
                                                </> :
                                                <><Button onClick={() => handleChange({
                                                    target: {
                                                        name: 'reviewPointsAndDocuments',
                                                        value: true
                                                    }
                                                }, index)}
                                                    variant='outlined'>Yes</Button>
                                                    <Button onClick={() => handleChange({
                                                        target: {
                                                            name: 'reviewPointsAndDocuments',
                                                            value: false
                                                        }
                                                    }, index)}
                                                        variant='contained'
                                                        color='success'>No</Button>
                                                </>
                                        }
                                    </Typography>
                                    <br></br>
                                    <Typography variant="h6">
                                        Review Evidence:
                                    </Typography>

                                    {review.reviewEvidenceDocumentFileObject && (
                                        <div>
                                            <Link href={`${SERVER_URL}${review.reviewEvidenceDocumentFileObject.uri}`}
                                                target="_blank" rel="noopener noreferrer">
                                                {review.reviewEvidenceDocumentFileObject.filename}
                                            </Link>
                                            <React.Fragment key={1}>
                                                <div style={{ padding: 10 + 'px' }}>
                                                    <Button
                                                        className="text-dark"
                                                        variant="outlined"
                                                        size="large"
                                                        color="success"
                                                        component="label"
                                                    >
                                                        Upload New Evidence Here
                                                        <FileUploadOutlinedIcon />

                                                        {/* Hidden input field for file upload */}
                                                        <input
                                                            type="file"
                                                            style={{ display: 'none' }} // Hide the input visually
                                                            onChange={(event) => handleFileInputChange(
                                                                event,
                                                                'reviewEvidenceDocumentFileObject',
                                                                'ClientFormHRA_Data.reviewPointsAndDocuments',
                                                                index
                                                            )}
                                                        />
                                                    </Button>
                                                </div>
                                            </React.Fragment>
                                            <iframe
                                                src={`${SERVER_URL}${review.reviewEvidenceDocumentFileObject.uri}`}
                                                style={{ width: '50%', height: '200px', border: 'none', marginBottom: '10px' }}
                                            ></iframe>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                        <Typography variant="h6">
                            Authorised Signatory Of Food Service Establishment:
                        </Typography>
                        <Button onClick={addAuthorisedSignatoryOfFoodServiceEstablishment}><AddIcon />Add Authorised Signatory</Button>
                        {clientData?.ClientFormHRA_Data[0]?.authorisedSignatoryOfFoodServiceEstablishmentFileObject.map((authorisedSignatory, authorisedSignatoryIndex) => (
                            <div key={authorisedSignatoryIndex} className="mb-3">

                                {authorisedSignatory && (
                                    <div>
                                        <Link href={`${SERVER_URL}${authorisedSignatory.uri}`}
                                            target="_blank" rel="noopener noreferrer">
                                            {authorisedSignatory.filename}
                                        </Link>
                                        <React.Fragment key={1}>
                                            <div style={{ padding: 10 + 'px' }}>
                                                <Button
                                                    className="text-dark"
                                                    variant="outlined"
                                                    size="large"
                                                    color="success"
                                                    component="label"
                                                >
                                                    Upload New Authorised Signatory Here
                                                    <FileUploadOutlinedIcon />

                                                    {/* Hidden input field for file upload */}
                                                    <input
                                                        type="file"
                                                        style={{ display: 'none' }} // Hide the input visually
                                                        onChange={(event) => handleFileInputChange(
                                                            event,
                                                            'authorisedSignatoryOfFoodServiceEstablishmentFileObject',
                                                            'ClientFormHRA_Data.authorisedSignatoryOfFoodServiceEstablishmentFileObject',
                                                            authorisedSignatoryIndex
                                                        )}
                                                    />
                                                </Button>
                                                <IconButton
                                                    color="error"
                                                    onClick={() => removeAuthorisedSignatory(authorisedSignatoryIndex)}
                                                    disabled={authorisedSignatoryIndex !== clientData?.ClientFormHRA_Data[0]?.authorisedSignatoryOfFoodServiceEstablishmentFileObject?.length - 1}>
                                                    <RemoveIcon />
                                                </IconButton>
                                            </div>
                                        </React.Fragment>
                                        <iframe
                                            src={`${SERVER_URL}${authorisedSignatory.uri}`}
                                            style={{ width: '50%', height: '200px', border: 'none', marginBottom: '10px' }}
                                        ></iframe>
                                    </div>
                                )}
                            </div>
                        ))}

                        <Typography variant="h6" className="my-3 fw-bold">
                            {clientData?.ClientFormHRA_Data[0]?.proposalFileObject ? (
                                <>
                                    <Link href={`${SERVER_URL}${clientData?.ClientFormHRA_Data[0]?.proposalFileObject.uri}`}
                                        target="_blank" rel="noopener noreferrer">
                                        Proposal.pdf
                                    </Link>
                                    <React.Fragment key={1}>
                                        <div style={{ padding: 10 + 'px' }}>
                                            <Button
                                                className="text-dark"
                                                variant="outlined"
                                                size="large"
                                                color="success"
                                                component="label"
                                            >
                                                Upload New Proposal Here
                                                <FileUploadOutlinedIcon />

                                                {/* Hidden input field for file upload */}
                                                <input
                                                    type="file"
                                                    style={{ display: 'none' }} // Hide the input visually
                                                    onChange={(event) => handleFileInputChange(
                                                        event,
                                                        'proposalFileObject',
                                                        'ClientFormHRA_Data'
                                                    )}
                                                />
                                            </Button>
                                        </div>
                                    </React.Fragment>
                                    <div style={{ padding: 10 + 'px' }}>
                                        <iframe
                                            src={`${SERVER_URL}${clientData?.ClientFormHRA_Data[0]?.proposalFileObject.uri}`}
                                            style={{ width: '50%', height: '200px', border: 'none', marginBottom: '10px' }}
                                        ></iframe>
                                    </div>
                                </>
                            ) : (
                                <div>
                                    <Typography> No Data </Typography>
                                    <React.Fragment key={1}>
                                        <div style={{ padding: 10 + 'px' }}>
                                            <Button
                                                className="text-dark"
                                                variant="outlined"
                                                size="large"
                                                color="success"
                                                component="label"
                                            >
                                                Upload New Proposal Here
                                                <FileUploadOutlinedIcon />

                                                {/* Hidden input field for file upload */}
                                                <input
                                                    type="file"
                                                    style={{ display: 'none' }} // Hide the input visually
                                                    onChange={(event) => handleFileInputChange(
                                                        event,
                                                        'proposalFileObject',
                                                        'ClientFormHRA_Data'
                                                    )}
                                                />
                                            </Button>
                                        </div>
                                    </React.Fragment>
                                </div>
                            )}
                        </Typography>
                        <Typography variant="h6" className="my-3 fw-bold">
                            {clientData?.ClientFormHRA_Data[0]?.agreementFileObject ? (
                                <>
                                    <Link href={`${SERVER_URL}${clientData?.ClientFormHRA_Data[0]?.agreementFileObject.uri}`}
                                        target="_blank" rel="noopener noreferrer">
                                        Agreement.pdf
                                    </Link>
                                    <React.Fragment key={1}>
                                        <div style={{ padding: 10 + 'px' }}>
                                            <Button
                                                className="text-dark"
                                                variant="outlined"
                                                size="large"
                                                color="success"
                                                component="label"
                                            >
                                                Upload New Agreement Here

                                                <FileUploadOutlinedIcon />
                                                {/* Hidden input field for file upload */}
                                                <input
                                                    type="file"
                                                    style={{ display: 'none' }} // Hide the input visually
                                                    onChange={(event) => handleFileInputChange(
                                                        event,
                                                        'agreementFileObject',
                                                        'ClientFormHRA_Data'
                                                    )}
                                                />
                                            </Button>
                                        </div>
                                    </React.Fragment>
                                    <div style={{ padding: 10 + 'px' }}>
                                        <iframe
                                            src={`${SERVER_URL}${clientData?.ClientFormHRA_Data[0]?.agreementFileObject?.uri}`}
                                            style={{ width: '50%', height: '250px', border: 'none', marginBottom: '10px' }}
                                        ></iframe>
                                    </div>
                                </>
                            ) : (
                                <div>
                                    <Typography> No Data </Typography>
                                    <React.Fragment key={1}>
                                        <div style={{ padding: 10 + 'px' }}>
                                            <Button
                                                className="text-dark"
                                                variant="outlined"
                                                size="large"
                                                color="success"
                                                component="label"
                                            >
                                                Upload New Agreement Here

                                                <FileUploadOutlinedIcon />
                                                {/* Hidden input field for file upload */}
                                                <input
                                                    type="file"
                                                    style={{ display: 'none' }} // Hide the input visually
                                                    onChange={(event) => handleFileInputChange(
                                                        event,
                                                        'agreementFileObject',
                                                        'ClientFormHRA_Data'
                                                    )}
                                                />
                                            </Button>
                                        </div>
                                    </React.Fragment>
                                </div>
                            )}
                        </Typography>
                        <Typography variant="h6" className="my-3 fw-bold">
                            {clientData?.ClientFormHRA_Data[0]?.signedAgreementFileObject ? (
                                <>
                                    <Link href={`${SERVER_URL}${clientData?.ClientFormHRA_Data[0]?.signedAgreementFileObject?.uri}`}
                                        target="_blank" rel="noopener noreferrer">
                                        Signed Agreement.pdf
                                    </Link>
                                    <React.Fragment key={1}>
                                        <div style={{ padding: 10 + 'px' }}>
                                            <Button
                                                className="text-dark"
                                                variant="outlined"
                                                size="large"
                                                color="success"
                                                component="label"
                                            >
                                                Upload New Signed Agreement Here

                                                <FileUploadOutlinedIcon />
                                                {/* Hidden input field for file upload */}
                                                <input
                                                    type="file"
                                                    style={{ display: 'none' }} // Hide the input visually
                                                    onChange={(event) => handleFileInputChange(
                                                        event,
                                                        'signedAgreementFileObject',
                                                        'ClientFormHRA_Data'
                                                    )}
                                                />
                                            </Button>
                                        </div>
                                    </React.Fragment>
                                    <div style={{ padding: 10 + 'px' }}>
                                        <iframe
                                            src={`${SERVER_URL}${clientData?.ClientFormHRA_Data[0]?.signedAgreementFileObject?.uri}`}
                                            style={{ width: '50%', height: '200px', border: 'none', marginBottom: '10px' }}
                                        ></iframe>
                                    </div>
                                </>
                            ) : (
                                <div>
                                    <Typography> No Data </Typography>
                                    <React.Fragment key={1}>
                                        <div style={{ padding: 10 + 'px' }}>
                                            <Button
                                                className="text-dark"
                                                variant="outlined"
                                                size="large"
                                                color="success"
                                                component="label"
                                            >
                                                Upload New Signed Agreement Here

                                                <FileUploadOutlinedIcon />
                                                {/* Hidden input field for file upload */}
                                                <input
                                                    type="file"
                                                    style={{ display: 'none' }} // Hide the input visually
                                                    onChange={(event) => handleFileInputChange(
                                                        event,
                                                        'signedAgreementFileObject',
                                                        'ClientFormHRA_Data'
                                                    )}
                                                />
                                            </Button>
                                        </div>
                                    </React.Fragment>
                                </div>
                            )}
                        </Typography>
                        <Typography variant="h6" className="my-3 fw-bold">
                            {clientData?.ClientFormHRA_Data[0]?.signedProposalFileObject ? (
                                <>
                                    <Link href={`${SERVER_URL}${clientData?.ClientFormHRA_Data[0]?.signedProposalFileObject?.uri}`}
                                        target="_blank" rel="noopener noreferrer">
                                        Signed Proposal.pdf
                                    </Link>
                                    <React.Fragment key={1}>
                                        <div style={{ padding: 10 + 'px' }}>
                                            <Button
                                                className="text-dark"
                                                variant="outlined"
                                                size="large"
                                                color="success"
                                                component="label"
                                            >
                                                Upload New Signed Proposal Here

                                                <FileUploadOutlinedIcon />
                                                {/* Hidden input field for file upload */}
                                                <input
                                                    type="file"
                                                    style={{ display: 'none' }} // Hide the input visually
                                                    onChange={(event) => handleFileInputChange(
                                                        event,
                                                        'signedProposalFileObject',
                                                        'ClientFormHRA_Data'
                                                    )}
                                                />
                                            </Button>
                                        </div>
                                    </React.Fragment>
                                    <div style={{ padding: 10 + 'px' }}>
                                        <iframe
                                            src={`${SERVER_URL}${clientData?.ClientFormHRA_Data[0]?.signedProposalFileObject.uri}`}
                                            style={{ width: '50%', height: '200px', border: 'none', marginBottom: '10px' }}
                                        ></iframe>
                                    </div>
                                </>
                            ) : (
                                <div>
                                    <Typography> No Data </Typography>
                                    <React.Fragment key={1}>
                                        <div style={{ padding: 10 + 'px' }}>
                                            <Button
                                                className="text-dark"
                                                variant="outlined"
                                                size="large"
                                                color="success"
                                                component="label"
                                            >
                                                Upload New Signed Proposal Here

                                                <FileUploadOutlinedIcon />
                                                {/* Hidden input field for file upload */}
                                                <input
                                                    type="file"
                                                    style={{ display: 'none' }} // Hide the input visually
                                                    onChange={(event) => handleFileInputChange(
                                                        event,
                                                        'signedProposalFileObject',
                                                        'ClientFormHRA_Data'
                                                    )}
                                                />
                                            </Button>
                                        </div>
                                    </React.Fragment>
                                </div>
                            )}
                        </Typography>
                        <Typography variant="h6" className="my-3 fw-bold">
                            {clientData?.ClientFormHRA_Data[0]?.signatureFileObject ? (
                                <>
                                    <Link href={`${SERVER_URL}${clientData?.ClientFormHRA_Data[0]?.signatureFileObject?.uri}`}
                                        target="_blank" rel="noopener noreferrer">
                                        Signature
                                    </Link>
                                    <React.Fragment key={1}>
                                        <div style={{ padding: 10 + 'px' }}>
                                            <Button
                                                className="text-dark"
                                                variant="outlined"
                                                size="large"
                                                color="success"
                                                component="label"
                                            >
                                                Upload New Signature Here

                                                <FileUploadOutlinedIcon />
                                                {/* Hidden input field for file upload */}
                                                <input
                                                    type="file"
                                                    style={{ display: 'none' }} // Hide the input visually
                                                    onChange={(event) => handleFileInputChange(
                                                        event,
                                                        'signatureFileObject',
                                                        'ClientFormHRA_Data'
                                                    )}
                                                />
                                            </Button>
                                        </div>
                                    </React.Fragment>
                                    <div style={{ padding: 10 + 'px' }}>
                                        <iframe
                                            src={`${SERVER_URL}${clientData?.ClientFormHRA_Data[0]?.signatureFileObject?.uri}`}
                                            style={{ width: '50%', height: '200px', border: 'none', marginBottom: '10px' }}
                                        ></iframe>
                                    </div>
                                </>
                            ) : (
                                <div>
                                    <Typography> No Data </Typography>
                                    <React.Fragment key={1}>
                                        <div style={{ padding: 10 + 'px' }}>
                                            <Button
                                                className="text-dark"
                                                variant="outlined"
                                                size="large"
                                                color="success"
                                                component="label"
                                            >
                                                Upload New Signature Here

                                                <FileUploadOutlinedIcon />
                                                {/* Hidden input field for file upload */}
                                                <input
                                                    type="file"
                                                    style={{ display: 'none' }} // Hide the input visually
                                                    onChange={(event) => handleFileInputChange(
                                                        event,
                                                        'signatureFileObject',
                                                        'ClientFormHRA_Data'
                                                    )}
                                                />
                                            </Button>
                                        </div>
                                    </React.Fragment>
                                </div>
                            )}
                        </Typography>
                        {
                            clientBasicId === null ? (
                                <Button
                                    type="submit"
                                    variant="contained"
                                    disabled>
                                    ADD ADVANCED DETAILS
                                </Button>
                            ) : (
                                <Button
                                    type="submit"
                                    variant="contained"
                                    className="btn-orange text-dark me-0"
                                >
                                    ADD ADVANCED DETAILS
                                </Button>
                            )
                        }

                    </form>
                </>
            </div>
        </>
    );
}

export default ClientDataAdd;
