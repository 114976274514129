import API_URL from "../utils/url";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { setView } from "../redux/slices/LoaderSlice";
import { useNavigate } from "react-router-dom";

// const navigate = useNavigate();

const getDataWithoutSession = async (route, params, method = "GET") => {
  try {
    const URL_ROUTE = `${API_URL}${route}`;

    let url = URL_ROUTE;
    if (params) {
      const queryParams = new URLSearchParams(params).toString();
      url += `?${queryParams}`;
    }

    const options = {
      method: method,
    };

    const response = await fetch(url, options);
    const responseData = await response.json();
    if (response.status === 200) {
      toast.success(responseData.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    return responseData;
  } catch (error) {
    console.error(error);
    toast.error(error.message || "An error occurred", {
      position: toast.POSITION.TOP_RIGHT,
    });
    throw error;
  }
};

const getData = async (route, queryParamsArg, token, method = "GET") => {
  try {
    const URL_ROUTE = `${API_URL}${route}`;
    const headers = {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    };

    let url = URL_ROUTE;
    if (queryParamsArg) {
      const queryParams = new URLSearchParams(queryParamsArg).toString();
      url += `?${queryParams}`;
    }

    const options = {
      method: method,
      headers: headers,
    };

    const response = await fetch(url, options);
    const responseData = await response.json();
    if (response.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();
    }
    // if (response.status === 200) {
    //   if (
    //     responseData.message !== "Users details fetched successfully" ||
    //     responseData.message !== "all Client basic details fetched successfully"
    //   ) {
    //     toast.success(responseData.message, {
    //       position: toast.POSITION.TOP_RIGHT,
    //     });
    //   }
    // }
    return responseData;
  } catch (error) {
    console.error(error);
    toast.error(error.message || "An error occurred", {
      position: toast.POSITION.TOP_RIGHT,
    });
    throw error;
  }
};

const postData = async (route, data, token, dispatch) => {
  try {
    dispatch(setView(true));
    const URL_ROUTE = `${API_URL}${route}`;
    const headers = {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    };
    const response = await fetch(URL_ROUTE, {
      method: "POST",
      headers: headers,
      body: JSON.stringify(data),
    });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to fetch");
    }
    const responseData = await response.json();
    if (response.status === 200) {
      toast.success(responseData.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (response.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();
    }
    dispatch(setView(false));
    return responseData;
  } catch (error) {
    toast.error(error.message || "An error occurred", {
      position: toast.POSITION.TOP_RIGHT,
    });
    console.error(error);
    dispatch(setView(false));
    throw error;
  }
};
const FileUpload = async (route, data, dispatch) => {
  try {
    dispatch(setView(true));
    const URL_ROUTE = `${API_URL}${route}`;
    const response = await fetch(URL_ROUTE, {
      method: "POST",
      body: data,
    });
    if (!response.ok) {
      const errorData = await response.json();
      throw new Error(errorData.message || "Failed to fetch");
    }
    const responseData = await response.json();
    if (response.status === 200) {
      toast.success(responseData.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (response.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();
    }
    dispatch(setView(false));
    return responseData;
  } catch (error) {
    console.error(error);
    dispatch(setView(false));
    toast.error(error.message || "An error occurred", {
      position: toast.POSITION.TOP_RIGHT,
    });
    throw error;
  }
};
const patchData = async (route, data, token, dispatch) => {
  try {
    dispatch(setView(true));
    const URL_ROUTE = `${API_URL}${route}`;
    const headers = {
      Authorization: `${token}`,
      "Content-Type": "application/json",
    };

    const response = await fetch(URL_ROUTE, {
      method: "PATCH",
      headers: headers,
      body: JSON.stringify(data),
    });
    const responseData = await response.json();
    if (response.status === 200) {
      toast.success(responseData.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (response.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();
    }
    dispatch(setView(false));
    return responseData;
  } catch (error) {
    dispatch(setView(false));
    console.error(error);
    toast.error(error.message || "An error occurred", {
      position: toast.POSITION.TOP_RIGHT,
    });
    throw error;
  }
};
const deleteData = async (route, token) => {
  try {
    const URL_ROUTE = `${API_URL}${route}`;
    const headers = {
      Authorization: `${token}`,
    };

    const response = await fetch(URL_ROUTE, {
      method: "DELETE",
      headers: headers,
    });
    const responseData = await response.json();
    if (response.status === 200) {
      toast.success(responseData.message, {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    if (response.status === 401) {
      localStorage.clear();
      sessionStorage.clear();
      window.location.reload();
    }
    return responseData;
  } catch (error) {
    console.error(error);
    toast.error(error.message || "An error occurred", {
      position: toast.POSITION.TOP_RIGHT,
    });
    throw error;
  }
};

export {
  getData,
  getDataWithoutSession,
  postData,
  patchData,
  deleteData,
  FileUpload,
};
