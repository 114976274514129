import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import HRA_Checklist from "src/components/Auditor/CheckList";
import { useDispatch, useSelector } from "react-redux";
import HRA_ChecklistView from "src/components/Auditor/CheckListView";
import HRA_NCView from "src/components/Auditor/NCView";
import { Button, Dialog, DialogTitle, TextField } from "@mui/material";
import { patchData, postData } from "src/webService/webService";
import CLIENTROUTES from "src/API_Routes/Client/Client_Routers";
import CheckListTemplate from "src/Config/templates/html/CheckListTemplate";
import EMAIL_ROUTES from "src/API_Routes/Email/Email_Routers";
import NCTemplate from "src/Config/templates/html/NCTemplate";
import ReviewComplete from "src/Config/templates/html/ReviewCompleted";
import { getFormattedIndiaTime } from "../../../utils/timeutils";
import AUDITROUTES from "src/API_Routes/Audit/Audit_routes";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

export default function CheckListTab({ onCancel, clientBasicList }) {
  const dispatch = useDispatch();
  const [value, setValue] = useState(0);
  const selectedAuditId = sessionStorage.getItem("AuditId");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  console.log(clientBasicList, "jahjsfdhkjhfkjahdsjf");
  const accessToken = sessionStorage.getItem("Token");
  const SpecificClient = useSelector((state) => state?.SpecificClient?.Basic);
  const [specificFileName1, setSpecificFileName1] = useState();
  const [Uploadopen, setUploadopen] = useState(false);
  const [remark, setRemark] = useState();

  useEffect(() => {
    const SpecificFileName = clientBasicList.find(
      (user) => user._id === selectedAuditId
    );
    setSpecificFileName1(SpecificFileName);
  }, [selectedAuditId]);

  console.log(specificFileName1, "specificFileName1");

  const GetID = async (data) => {
    const formattedDate = getFormattedIndiaTime();
    const paramsStatus = {
      status: {
        type: "technical review completed",
        updatedDate: formattedDate,
      },
    };

    await patchData(
      `${CLIENTROUTES.GETCLIENTBASIC}/${data}/basic`,
      paramsStatus,
      accessToken,
      dispatch
    );

    const status = {
      reportStatus: {
        name: "",
        date: formattedDate,
      },
    };
    await patchData(
      `${AUDITROUTES.SCHEDULEPATCH(specificFileName1._id)}`,
      status,
      accessToken,
      dispatch
    );
  };

  const handleNeedRevision = async () => {
    const formattedDate = getFormattedIndiaTime();
    const status = {
      reportStatus: {
        name: "Need Revision",
        date: formattedDate,
      },
    };
    await patchData(
      `${AUDITROUTES.SCHEDULEPATCH(specificFileName1._id)}`,
      status,
      accessToken,
      dispatch
    );

    const HTML_DATA = {
      ClientName: SpecificClient.companyName,
      ContactPerson: SpecificClient.contactPerson,
      ClientID: SpecificClient.clientId,
      AuditDate: SpecificClient.scheduledAuditorsWithDates[0]?.scheduledDate,
      Message: remark,
    };
    console.log("HTML_DATA", HTML_DATA);

    await postData(
      EMAIL_ROUTES.SEND_EMAIL_FILE,
      {
        to: specificFileName1.AuditorBasic_Data[0].email,
        subject: "Need Revision",
        html: ReviewComplete(HTML_DATA),
      },
      accessToken,
      dispatch
    );
    setRemark("");
    handleClose();
  };

  const handleSendMail = async () => {
    const HTML_DATA = {
      ClientName: SpecificClient.companyName,
      ContactPerson: SpecificClient.contactPerson,
      ClientID: SpecificClient.clientId,
      Message:
        "The Technical Review has been completed, kindly check the below click",
    };
    console.log("HTML_DATA", HTML_DATA);

    const emailRes = await postData(
      EMAIL_ROUTES.SEND_EMAIL_FILE,
      {
        to: SpecificClient.email,
        subject: "Technical Review Completed",
        html: ReviewComplete(HTML_DATA),
        attachments: [
          {
            filename: specificFileName1.checkListFileObjects[0].data.filename,
            path: specificFileName1.checkListFileObjects[0].data.path,
          },

          {
            filename: specificFileName1.NC_FileObjects[0].data.filename,
            path: specificFileName1.NC_FileObjects[0].data.path,
          },
        ],
      },
      accessToken,
      dispatch
    );
  };

  const handleClickOpen = () => {
    setUploadopen(true);
  };

  const handleClose = () => {
    setUploadopen(false);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <div className="client-list-border pt-2 pb-5 overflow-hidden">
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <Tabs
            className="tab-list"
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <Tab label="Check list" {...a11yProps(0)} />
            <Tab label="NC" {...a11yProps(1)} />
          </Tabs>
        </Box>

        <CustomTabPanel value={value} index={0}>
          <HRA_ChecklistView />
          <Dialog
            open={Uploadopen}
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
          >
            <DialogTitle>Remarks</DialogTitle>
            <div className="p-3">
              <div className="px-2 py-3">
                <TextField
                  className="w-100"
                  name="Remarks"
                  label="Remarks"
                  value={remark}
                  size="small"
                  onChange={(e) => setRemark(e.target.value)}
                  required
                />
              </div>
              <Button className="btn-green" onClick={handleNeedRevision}>
                Submit
              </Button>
            </div>
          </Dialog>
        </CustomTabPanel>
        <CustomTabPanel value={value} index={1}>
          <HRA_NCView />
        </CustomTabPanel>
        <Button
          variant="contained"
          className="btn-orange"
          // size="large"
          type="submit"
          onClick={onCancel}
        >
          Cancel
        </Button>

        <Button
          className="btn-green"
          key={SpecificClient._id}
          id={`${SpecificClient._id}`}
          onClick={handleClickOpen}
        >
          Need Revision
        </Button>

        <Button
          className={`btn ${
            SpecificClient.status.type === "audit completed"
              ? "btn-green"
              : "btn-green-disable"
          }`}
          key={SpecificClient._id}
          id={`${SpecificClient._id}`}
          onClick={
            SpecificClient.status.type === "audit completed"
              ? () => GetID(SpecificClient._id)
              : undefined
          }
          disabled={SpecificClient.status.type !== "audit completed"}
        >
          Review Completed
        </Button>

        <Button
          className={`btn ${
            SpecificClient.status.type === "technical review completed"
              ? "btn-green"
              : "btn-green-disable"
          }`}
          key={SpecificClient._id}
          id={`${SpecificClient._id}`}
          onClick={
            SpecificClient.status.type === "technical review completed"
              ? () => handleSendMail()
              : undefined
          }
          disabled={SpecificClient.status.type !== "technical review completed"}
        >
          Send mail to Client
        </Button>
      </div>
    </Box>
  );
}
