import React from "react";
import { saveAs } from "file-saver";
import * as XLSX from "xlsx";
import { Button } from "@mui/material";

const AuditTable = ({ apiResponse, auditorId }) => {
  console.log(apiResponse, "apiResponse123");
  const handleExport = () => {
    const headers = [
      "Company Name",
      "Client ID",
      "Standard",
      "Technical Main / Sub Codes",
      "On-site Man Days",
      "Off-site Man Days",
      "Total Man Days",
      "Audit Start Date",
      "Audit End Date",
      "Scope",
      "Auditor Designation",
      "Accreditation",
      "Audit Type",
    ];

    const data = apiResponse.Audit_Details.map((audit) => {
      const client = apiResponse.Client_Basic_Details.find(
        (client) => client._id === audit.clientId
      );
      console.log(client, "client");
      const IMSclient = apiResponse.Client_IMS_Details.find(
        (client) => client.clientBasicId === audit.clientId
      );
      console.log(IMSclient, "IMSclient");
      const FSMSclient = apiResponse.Client_FSMS_Details.find(
        (client) => client.clientBasicId === audit.clientId
      );
      console.log(FSMSclient, "IMSclient");
      const Standards = IMSclient?.standards[0] || FSMSclient?.standards[0];
      const TechCode = `${client?.technicalCode?.mainCode} / ${client?.technicalCode?.subCode}`;
      const Scope =
        IMSclient?.scopeOfCertification || FSMSclient?.scopeOfCertification;
      const Accreditation =
        IMSclient?.accreditations[0] || FSMSclient?.accreditations[0];

      const manDayTypeMap = new Map();
      apiResponse.User_Basic_Details[0].auditDates.forEach((auditDate) => {
        manDayTypeMap.set(auditDate.date, auditDate.manDayType);
      });

      let totalOnsiteManDay = 0;
      let totalOffsiteManDay = 0;

      const auditorDates = audit.scheduledAuditorsWithDates
        .filter((ad) => ad.auditorId === auditorId)
        .map((ad) => {
          const manDayType = manDayTypeMap.get(ad.scheduledDate);
          let onsiteManDay = 0;
          if (manDayType === "ONE_DAY") {
            onsiteManDay = 1;
          } else if (manDayType === "SECOND_HALF") {
            onsiteManDay = 0.5;
          }
          const offsiteManDay =
            ad.auditorDesignation === "Lead Auditor" ? 0.5 : 0;
          const totalManDays = onsiteManDay + offsiteManDay;

          totalOnsiteManDay += onsiteManDay;
          totalOffsiteManDay += offsiteManDay;

          return new Date(ad.scheduledDate).toLocaleDateString();
        })
        .join(", ");

      const dateArray = auditorDates.split(",").map((date) => date.trim());
      const Dates_New = dateArray;
      let minDate = null;
      let maxDate = null;

      if (Dates_New.length === 1) {
        minDate = new Date(Dates_New[0]);
        maxDate = new Date(Dates_New[0]);
      } else {
        const dates = Dates_New.map((dateStr) => new Date(dateStr));
        minDate = new Date(Math.min(...dates));
        maxDate = new Date(Math.max(...dates));
      }

      const auditorDesignations = audit.scheduledAuditorsWithDates
        .filter((ad) => ad.auditorId === auditorId)
        .map((ad) => ad.auditorDesignation)
        .join(", ");

      return [
        client?.companyName,
        client?.clientId,
        Standards,
        TechCode,
        totalOnsiteManDay,
        totalOffsiteManDay,
        totalOnsiteManDay + totalOffsiteManDay,
        minDate.toLocaleDateString(),
        maxDate.toLocaleDateString(),
        Scope,
        auditorDesignations,
        Accreditation,
        audit?.auditType,
      ];
    });

    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Audit Data");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    saveAs(
      new Blob([excelBuffer], { type: "application/octet-stream" }),
      "audit_data.xlsx"
    );
  };

  return (
    <div>
      <div className="d-flex justify-content-end">
        <Button
          variant="contained"
          className="mx-3 btn-green"
          onClick={handleExport}
        >
          Download Audit Log
        </Button>
      </div>

      <table border="1" className="d-none">
        <thead>
          <tr>
            <th>Scheme</th>
            <th>Audit Type</th>
            <th>Auditor Designation</th>
            <th>Scheduled Date</th>
            <th>Client ID</th>
            <th>Company Name</th>
            <th>Phone Number</th>
            <th>Email</th>
            <th>Lead Generated By</th>
            <th>Status Type</th>
          </tr>
        </thead>
        <tbody>
          {apiResponse.Audit_Details.map((audit) => {
            const client = apiResponse.Client_Basic_Details.find(
              (client) => client._id === audit.clientId
            );
            const auditorDates = audit.scheduledAuditorsWithDates
              .filter((ad) => ad.auditorId === auditorId)
              .map((ad) => new Date(ad.scheduledDate).toLocaleDateString())
              .join(", ");

            const auditorDesignations = audit.scheduledAuditorsWithDates
              .filter((ad) => ad.auditorId === auditorId)
              .map((ad) => ad.auditorDesignation)
              .join(", ");

            return (
              <tr key={audit._id}>
                <td>{audit?.scheme}</td>
                <td>{audit?.auditType}</td>
                <td>{auditorDesignations}</td>
                <td>{auditorDates}</td>
                <td>{client?.clientId}</td>
                <td>{client?.companyName}</td>
                <td>{client?.phoneNumber}</td>
                <td>{client?.email}</td>
                <td>{client?.leadGeneratedBy}</td>
                <td>{client?.status.type}</td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default AuditTable;
