import { createSlice } from "@reduxjs/toolkit";

const CHECKLIST = createSlice({
    name: "CHECKLIST",
    initialState: {
        CheckList: "",
    },
    reducers: {
        setCHECKLIST: (state, action) => {
            state.CheckList = action.payload;
        },
    },
});

export const { setCHECKLIST } = CHECKLIST.actions;
export default CHECKLIST.reducer;
