import { Helmet } from "react-helmet-async";

import CB_FeedbackForm from "../../components/FeedbackForm/CB_FeedbackForm";

export default function CBFeedbackFormPage() {
  return (
    <>
      <Helmet>
        <title> Feedback Form </title>
      </Helmet>
        <CB_FeedbackForm />
    </>
  );
}
