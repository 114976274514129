import { Helmet } from "react-helmet-async";
import { useState, useEffect } from "react";
// @mui
import {
  Card,
  Table,
  Stack,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  IconButton,
  TableContainer,
  TablePagination,
  TableHead,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
// components
import Iconify from "../../../components/iconify";
import Scrollbar from "../../../components/scrollbar";
// mock
import AddRole from "src/components/Admin/AddRole/AddRole";
import { useSelector } from "react-redux";
import {
  FileUpload,
  deleteData,
  getData,
  postData,
} from "../../../webService/webService";
import USERSROUTES from "src/API_Routes/User/User_Routers";
import DELETEUSER from "src/API_Routes/User/User_Routers";
import { useDispatch } from "react-redux";
import {
  setBasicData,
  setSpecificAuditor,
  setSpecificUser,
} from "src/redux/slices/UserDetailSlice";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import FILEROUTES from "src/API_Routes/File/File_Router";
import COMMONDOCUMENTSROUTES from "src/API_Routes/CommonDocument/CommonDocument";
import TrainingCertifications from "../../TrainingCertifications/TrainingCertifications";
import SERVER_URL from "../../../utils/serverURL";

export default function TableList({ Role }) {
  const dispatch = useDispatch();
  const scheme = sessionStorage.getItem("scheme");
  const UserId = sessionStorage.getItem("UserId");
  const accessToken = sessionStorage.getItem("Token");
  const USER_LIST_ARRAY = useSelector((state) => state.userDetail.BasicData);
  const USER_LIST = useSelector((state) => state.userDetail.BasicData.results);
  const [open, setOpen] = useState(null);
  const [action, setAction] = useState("");
  const [selectedId, setSelectedId] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [id, setId] = useState("");
  const [addRole, setAddRole] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(5);

  const HandlAaddRole = () => {
    setOpen(null);
    setAddRole(!addRole);
  };
  const handleOpenMenu = (event, id) => {
    setOpen(event.currentTarget);
    setSelectedId(id);
  };
  const handleCloseMenu = () => {
    setOpen(null);
  };
  const GetSale = async (page) => {
    const roleEndpoint = Role === "Sale" ? "Sales" : Role;
    const params = {
      page: page,
      limit: limit,
    };
    try {
      const res = await getData(
        USERSROUTES.ROLE + roleEndpoint,
        params,
        accessToken
      );
      if (res.status === 200) {
        dispatch(setBasicData(res.data));
        setTotalPages(res.data.totalPages);
      } else {
        dispatch(setBasicData(""));
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleLimitChange = (event) => {
    setCurrentPage(1);
    setLimit(event.target.value);
  };
  const specificUser = async () => {
    try {
      const userRes = await getData(USERSROUTES.USERID + id, null, accessToken);
      dispatch(setSpecificUser(userRes.data));
      setSelectedId(null);
      setId("");
      if (Role === "Auditor") {
        const auditorRes = await getData(
          USERSROUTES.AUDITORGET(id),
          null,
          accessToken
        );
        dispatch(setSpecificAuditor(auditorRes.data));
        setSelectedId(null);
        setId("");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const DeleteId = (val) => {
    const confirmDelete = window.confirm("Are you sure you want to delete?");
    if (confirmDelete) {
      deleteData(USERSROUTES.DELETEUSER + val, accessToken)
        .then((res) => {
          setOpen(null);
          const updatedResults = USER_LIST_ARRAY.results.filter(
            (item) => item._id !== val
          );
          const updatedResult = {
            ...USER_LIST_ARRAY,
            results: updatedResults,
          };
          console.log(updatedResult);
          dispatch(setBasicData(updatedResult));
        })
        .catch((error) => {
          setOpen(null);
          console.error("Error:", error);
          toast.error("An error occurred. Please try again later.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  useEffect(() => {
    if (id !== "") {
      specificUser();
    }
  }, [id]);

  useEffect(() => {
    GetSale(currentPage);
  }, [currentPage, limit]);

  //upload file
  const [Uploadopen, setUploadopen] = useState(false);
  const [fieldName, setFieldName] = useState("");
  const [uploadFileShow, setUploadfileShow] = useState();
  const handleClickOpen = () => {
    setUploadopen(true);
    GetReferenceDocument();
  };
  const handleClose = () => {
    setUploadopen(false);
    setUploadfileShow();
    setFieldName("");
  };
  const handleChange = (event) => {
    setFieldName(event.target.value);
  };
  const handleFileChange = async (e) => {
    if (fieldName || Role === "Admin") {
      const file = e.target.files[0];
      const formData = new FormData();
      formData.append("file", file);
      try {
        const response = await FileUpload(
          FILEROUTES.UPLOAD + UserId,
          formData,
          dispatch
        );
        console.log("File upload successful:", response);
        setUploadfileShow(response.data);
      } catch (error) {
        console.error("File upload error:", error);
      }
    } else {
      toast.error("Field Name Is Required", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  const ReferenceDocumentUpload = async () => {
    try {
      const params = {
        referenceDocumentType: fieldName,
        role: Role,
        referenceDocumentsFileObjects: uploadFileShow,
      };

      const res = await postData(
        `${COMMONDOCUMENTSROUTES.UPLOADFILE}`,
        params,
        accessToken,
        dispatch
      );
      setFieldName("");
      setUploadfileShow();
      setUploadopen(false);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  //GetReference Document
  const [Document, setDocument] = useState();
  const GetReferenceDocument = async () => {
    try {
      const res = await getData(
        `${COMMONDOCUMENTSROUTES.GETFILE}${Role}`,
        null,
        accessToken
      );
      const apiResponse = res.data;

      const sopData = apiResponse.filter(
        (item) => item.referenceDocumentType === "SOP"
      );

      const formData = apiResponse.filter(
        (item) => item.referenceDocumentType === "FORM"
      );

      const AdminData = apiResponse.filter(
        (item) => item.referenceDocumentType === ""
      );

      const filteredData = {
        SOP: sopData,
        FORM: formData,
        admin: AdminData,
      };
      setDocument(filteredData);
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  // useEffect(() => {
  //   GetReferenceDocument();
  // }, []);

  // Function to convert bytes to kilobytes
  const bytesToKB = (bytes) => {
    return (bytes / 1024).toFixed(2); // Fixed to 2 decimal places
  };
  //deleteDocument
  const DeleteDocument = (val) => {
    console.log("Document", Document);
    const confirmDelete = window.confirm("Are you sure you want to delete?");
    if (confirmDelete) {
      deleteData(`${COMMONDOCUMENTSROUTES.UPLOADFILE}${val}`, accessToken)
        .then((res) => {
          const removeFromArrayById = (dataArray, val) => {
            return dataArray.filter((obj) => obj._id !== val);
          };
          // Check if the ID belongs to the SOP array
          if (Document.SOP.some((obj) => obj._id === val)) {
            const updatedSOP = removeFromArrayById(Document.SOP, val);
            setDocument({ ...Document, SOP: updatedSOP });
          }
          // Check if the ID belongs to the FORM array
          else if (Document.FORM.some((obj) => obj._id === val)) {
            const updatedFORM = removeFromArrayById(Document.FORM, val);
            setDocument({ ...Document, FORM: updatedFORM });
          }
          // Check if the ID belongs to the FORM array
          else if (Document.admin.some((obj) => obj._id === val)) {
            const updatedadmin = removeFromArrayById(Document.admin, val);
            setDocument({ ...Document, admin: updatedadmin });
          }
          // ID doesn't belong to either SOP or FORM array
          else {
            console.log("ID not found");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };
  const handleClose_1 = () => {
    setDialogOpen(false);
  };

  return (
    <>
      <ToastContainer />
      <Dialog
        open={dialogOpen}
        onClose={handleClose_1}
        fullWidth
        maxWidth="xl"
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <TrainingCertifications usercurrentid={id} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose_1} color="primary" autoFocus>
            close
          </Button>
        </DialogActions>
      </Dialog>
      <Helmet>
        <title>{Role}</title>
      </Helmet>

      <Container>
        {addRole ? (
          <>
            <div className="d-flex justify-content-end">
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="end"
                mb={5}
              >
                <Button
                  className="cl-green text-white"
                  onClick={() => {
                    HandlAaddRole();
                    setAction("Add");
                  }}
                  startIcon={<Iconify icon="eva:plus-fill" />}
                >
                  New {Role}
                </Button>
                {scheme !== "HRA" && (
                  <Button
                    className="cl-orange text-white ms-3"
                    onClick={handleClickOpen}
                  >
                    {Role === "Admin" ? "Documents" : "Upload Document"}
                  </Button>
                )}
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="end"
                mb={5}
              >
                <Dialog
                  open={Uploadopen}
                  onClose={handleClose}
                  maxWidth="md"
                  fullWidth
                >
                  <DialogTitle>Upload Document</DialogTitle>
                  <div className="px-5">
                    <div className="d-flex col-12 mt-3">
                      {Role !== "Admin" && (
                        <FormControl className="col-4 ">
                          <InputLabel id="demo-simple-select-label">
                            FieldName
                          </InputLabel>
                          <Select
                            disabled={uploadFileShow}
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={fieldName}
                            label="fieldName"
                            onChange={handleChange}
                          >
                            <MenuItem value={"SOP"}>SOP</MenuItem>
                            <MenuItem value={"FORM"}>FORM</MenuItem>
                          </Select>
                        </FormControl>
                      )}

                      <div className="inputs ms-4 col-4">
                        <label
                          htmlFor="file-input-1"
                          className="d-flex justify-content-between w-100 py-2"
                        >
                          Upload File<span>+</span>
                        </label>
                        <input
                          disabled={uploadFileShow}
                          onChange={(e) => handleFileChange(e)}
                          id="file-input-1"
                          type="file"
                          className="Upload-file"
                        />
                      </div>
                    </div>
                    {uploadFileShow ? (
                      <a
                        rel="noreferrer"
                        href={`${SERVER_URL}/${uploadFileShow?.uri}`}
                        target="_blank"
                      >
                        {uploadFileShow?.uri.split("/").slice(-1)[0]}
                      </a>
                    ) : (
                      ""
                    )}
                    {/* You can add more fields as needed */}
                  </div>
                  <DialogActions>
                    <div className="d-flex mt-5">
                      <Button
                        className="cl-orange text-white"
                        onClick={handleClose}
                      >
                        Cancel
                      </Button>
                      <Button
                        variant="contained"
                        className="mx-3"
                        onClick={ReferenceDocumentUpload}
                      >
                        Submit
                      </Button>
                    </div>
                  </DialogActions>
                  <div className="p-5">
                    {Role !== "Admin" ? (
                      <>
                        <div className="mt-3">
                          <h6 className="fw-bold mb-3 text-underline">SOP</h6>
                          {Document?.SOP?.map((data, index) => (
                            <p key={index}>
                              <a
                                id={index}
                                rel="noreferrer"
                                href={`${SERVER_URL}/${data.referenceDocumentsFileObjects[0]?.uri}`}
                                target="_blank"
                              >
                                {
                                  data.referenceDocumentsFileObjects[0]?.originalname
                                }
                              </a>{" "}
                              <span className="fw-bold">
                                {" "}
                                -{" "}
                                {bytesToKB(
                                  data.referenceDocumentsFileObjects[0]?.size
                                )}{" "}
                                Kb{" "}
                              </span>
                              <span>
                                <Iconify
                                  style={{
                                    color: "#ff4949",
                                    cursor: "pointer",
                                  }}
                                  icon={"ant-design:delete-filled"}
                                  onClick={() => DeleteDocument(data._id)}
                                />
                              </span>
                            </p>
                          ))}
                        </div>
                        <div className="mt-3">
                          <h6 className="fw-bold mb-3">FORM</h6>
                          {Document?.FORM?.map((data, index) => (
                            <p key={index}>
                              <a
                                id={index}
                                rel="noreferrer"
                                href={`${SERVER_URL}/${data.referenceDocumentsFileObjects[0]?.uri}`}
                                target="_blank"
                              >
                                {
                                  data.referenceDocumentsFileObjects[0]?.originalname
                                }
                              </a>{" "}
                              <span className="fw-bold">
                                {" "}
                                -{" "}
                                {bytesToKB(
                                  data.referenceDocumentsFileObjects[0]?.size
                                )}{" "}
                                Kb{" "}
                              </span>
                              <span>
                                <Iconify
                                  icon={"ant-design:delete-filled"}
                                  style={{
                                    color: "#ff4949",
                                    cursor: "pointer",
                                  }}
                                  onClick={() => DeleteDocument(data._id)}
                                />
                              </span>
                            </p>
                          ))}
                        </div>
                      </>
                    ) : (
                      <div className="mt-3">
                        <h6 className="fw-bold mb-3 text-underline">Files</h6>
                        {Document?.admin?.map((data, index) => (
                          <p key={index}>
                            <a
                              id={index}
                              rel="noreferrer"
                              href={`${SERVER_URL}/${data.referenceDocumentsFileObjects[0]?.uri}`}
                              target="_blank"
                            >
                              {
                                data.referenceDocumentsFileObjects[0]?.originalname
                              }
                            </a>{" "}
                            <span className="fw-bold">
                              {" "}
                              -{" "}
                              {bytesToKB(
                                data.referenceDocumentsFileObjects[0]?.size
                              )}{" "}
                              Kb{" "}
                            </span>
                            <span>
                              <Iconify
                                style={{
                                  color: "#ff4949",
                                  cursor: "pointer",
                                }}
                                icon={"ant-design:delete-filled"}
                                onClick={() => DeleteDocument(data._id)}
                              />
                            </span>
                          </p>
                        ))}
                      </div>
                    )}
                  </div>
                </Dialog>
              </Stack>
            </div>
            <Card>
              <Scrollbar>
                <TableContainer sx={{ minWidth: 800 }}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell className="cl-green text-dark">
                          Emp ID
                        </TableCell>
                        <TableCell className="cl-green text-dark">
                          First Name
                        </TableCell>
                        <TableCell className="cl-green text-dark">
                          Last Name
                        </TableCell>
                        <TableCell className="cl-green text-dark">
                          Email
                        </TableCell>
                        <TableCell className="cl-green text-dark">
                          Status
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {USER_LIST ? (
                        USER_LIST.map((row) => {
                          const {
                            firstName,
                            lastName,
                            email,
                            employeeId,
                            _id,
                          } = row;
                          return (
                            <TableRow hover key={_id} tabIndex={-1}>
                              <TableCell
                                component="th"
                                scope="row"
                                padding="none"
                              >
                                <Stack
                                  direction="row"
                                  alignItems="center"
                                  spacing={2}
                                >
                                  <Typography
                                    variant="subtitle2"
                                    noWrap
                                    style={{ marginLeft: "10px" }}
                                  >
                                    {employeeId}
                                  </Typography>
                                </Stack>
                              </TableCell>
                              <TableCell align="left">{firstName}</TableCell>
                              <TableCell align="left">{lastName}</TableCell>
                              <TableCell align="left">{email}</TableCell>
                              <TableCell align="left">
                                <IconButton
                                  size="large"
                                  color="inherit"
                                  onClick={(event) =>
                                    handleOpenMenu(event, _id)
                                  }
                                >
                                  <Iconify icon={"eva:more-vertical-fill"} />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <h6 className="text-center my-3 w-100">No Data</h6>
                      )}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Scrollbar>
              {USER_LIST && (
                <div className="d-flex flex-wrap justify-content-end m-3">
                  <FormControl variant="outlined" size="small">
                    <InputLabel id="limit-label">Limit</InputLabel>
                    <Select
                      labelId="limit-label"
                      id="limit-select"
                      value={limit}
                      onChange={handleLimitChange}
                    >
                      <MenuItem value={5}>5</MenuItem>
                      <MenuItem value={10}>10</MenuItem>
                      <MenuItem value={15}>15</MenuItem>
                    </Select>
                  </FormControl>
                  <Button onClick={handlePrevPage} disabled={currentPage === 1}>
                    <Iconify icon={"grommet-icons:previous"} />
                  </Button>
                  <Button
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  >
                    <Iconify icon={"grommet-icons:next"} />
                  </Button>
                </div>
              )}
            </Card>
          </>
        ) : (
          <AddRole
            HandlAaddRole={HandlAaddRole}
            Role={Role}
            action={action}
            GetSale={GetSale}
          />
        )}
      </Container>
      {addRole ? (
        <Popover
          open={Boolean(open)}
          anchorEl={open}
          onClose={handleCloseMenu}
          anchorOrigin={{ vertical: "top", horizontal: "left" }}
          transformOrigin={{ vertical: "top", horizontal: "right" }}
          PaperProps={{
            sx: {
              p: 1,
              width: 140,
              "& .MuiMenuItem-root": {
                px: 1,
                typography: "body2",
                borderRadius: 0.75,
              },
            },
          }}
        >
          <MenuItem
            onClick={() => {
              HandlAaddRole();
              setAction("Edit");
              setId(selectedId);
            }}
          >
            <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
            Edit
          </MenuItem>
          <MenuItem
            onClick={() => {
              HandlAaddRole();
              setAction("View");
              setId(selectedId);
            }}
          >
            <Iconify icon={"raphael:view"} sx={{ mr: 2 }} />
            View
          </MenuItem>
          {scheme === "CB" && (
            <MenuItem
              onClick={() => {
                setId(selectedId);
                setDialogOpen(true);
              }}
            >
              <Iconify icon={"mdi:certificate"} sx={{ mr: 2 }} />
              Certification
            </MenuItem>
          )}
          <MenuItem
            sx={{ color: "error.main" }}
            onClick={() => {
              DeleteId(selectedId);
            }}
          >
            <Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
            Delete
          </MenuItem>
        </Popover>
      ) : (
        ""
      )}
    </>
  );
}
