import { createSlice } from "@reduxjs/toolkit";

const CSOSlice = createSlice({
  name: "CSO",
  initialState: {
    ClientBasicandAdvance: "",
  },
  reducers: {
    setClientBasicandAdvance: (state, action) => {
      state.ClientBasicandAdvance = action.payload;
    },
  },
});

export const { setClientBasicandAdvance } = CSOSlice.actions;
export default CSOSlice.reducer;
