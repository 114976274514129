let SERVER_URL;

const SERVER_ENV = "prod";

if (SERVER_ENV === "local") {
  SERVER_URL = "http://localhost:5000/";
}

if (SERVER_ENV === "prod") {
  SERVER_URL = "https://api.caussatec.com/";
}

if (SERVER_ENV === "dev") {
  SERVER_URL = "http://103.102.234.53:5000/";
}

if (SERVER_ENV === "staging") {
  SERVER_URL = "http://103.102.234.53:5000/";
}

export default SERVER_URL;
