import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Paper,
  Select,
  MenuItem,
} from "@mui/material";
import LibraryAddSharpIcon from "@mui/icons-material/LibraryAddSharp";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import AUDITROUTES from "src/API_Routes/Audit/Audit_routes";

import {
  getData,
  postData,
  patchData,
  deleteData,
} from "src/webService/webService";
import { useDispatch, useSelector } from "react-redux";

const HRA_NCView = () => {
  const dispatch = useDispatch();
  const [rows, setRows] = useState();
  // const selectedAuditId = sessionStorage.getItem('selectedAuditId');
  const [auditNCData, setAuditNCData] = useState();
  const [editMode, setEditMode] = useState(false);
  const accessToken = sessionStorage.getItem("Token");
  const hraId = sessionStorage.getItem("hraId");
  const selectedAuditId = sessionStorage.getItem("AuditId");

  const NC_Id = useSelector((state) => state?.NC.Nc._id);

  const types = ["Major", "Minor"];

  console.log(rows, "rows");

  // const question = getData(
  //     AUDITROUTES.GET_NC(
  //         NC_Id
  //     ),
  //     '',
  //     accessToken
  // )

  // console.log(question, "question");

  const handleAddRow = () => {
    const newRow = {
      hraClause: "",
      requirement: "",
      found: "",
      type: "",
      rootCause: "",
      correctiveActionPlan: "",
      targetDateForCompletion: "",
    };
    setRows([...rows, newRow]);
  };

  const handleInputChange = (value, index, field) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };

  const handleCategoryChange = (value, index) => {
    const updatedRows = [...rows];
    updatedRows[index]["type"] = value;
    setRows(updatedRows);
  };

  const handleDateChange = (value, index, field) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    let month = date.getMonth() + 1; // Months are zero indexed
    let day = date.getDate();

    // Pad month and day with leading zero if needed
    if (month < 10) {
      month = `0${month}`;
    }
    if (day < 10) {
      day = `0${day}`;
    }

    return `${year}-${month}-${day}`;
  };

  const SubmitOrEditModeCheck = async () => {
    const accessToken = sessionStorage.getItem("Token");
    const existingNC = await getData(
      AUDITROUTES.GET_NCS(selectedAuditId),
      "",
      accessToken
    );
    if (existingNC?.data?.length > 0) {
      setRows(existingNC?.data[0]?.nc);
      setAuditNCData(existingNC?.data[0]);
      setEditMode(true);
    } else {
      setRows([
        {
          hraClause: "",
          requirement: "",
          found: "",
          type: "",
          rootCause: "",
          correctiveActionPlan: "",
          targetDateForCompletion: "",
        },
      ]);
      setEditMode(false);
    }
    console.log("existingNC", existingNC);
  };

  useEffect(() => {
    SubmitOrEditModeCheck();
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      const accessToken = sessionStorage.getItem("Token");

      let response;
      if (editMode) {
        response = await patchData(
          AUDITROUTES.UPDATE_NC(auditNCData?._id),
          {
            auditId: selectedAuditId,
            nc: rows,
          },
          accessToken,
          dispatch
        );
      } else {
        response = await postData(
          AUDITROUTES.CREATE_NC,
          {
            nc: rows,
            auditId: selectedAuditId,
          },
          accessToken
        );
      }
      const updatedNC = await getData(
        AUDITROUTES.GET_NC(selectedAuditId),
        "",
        accessToken
      );

      sessionStorage.setItem("auditNCID", auditNCData?._id);
      switch (response?.data?.status || response?.status) {
        case 200:
          toast.success("NC Submitted Successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          break;
        case 500:
          toast.error("Error while submitting CREATE NC in server", {
            position: toast.POSITION.TOP_RIGHT,
          });
          break;
        case 400:
          toast.error("NC error", {
            position: toast.POSITION.TOP_RIGHT,
          });
          break;
        case 404:
          toast.error("NC not found", {
            position: toast.POSITION.TOP_RIGHT,
          });
          break;
        default:
          toast.error("Unexpected Error occurred", {
            position: toast.POSITION.TOP_RIGHT,
          });
      }
    } catch (error) {
      console.error(error);
      toast.error("Unexpected Error occurred", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleDelete = async () => {
    try {
      await deleteData(`audit/hra/nc/${auditNCData?._id}`, accessToken);

      await deleteData(`audit/hra/checklist/${hraId}`, accessToken);

      setRows("");

      toast.success("Successfully deleted", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      console.error(error);
      toast.error("Unexpected Error occurred", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <div>
      <ToastContainer />
      <form onSubmit={handleSubmit}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>HRA Clause</TableCell>
                <TableCell>Requirement</TableCell>
                <TableCell>Found</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Root Cause</TableCell>
                <TableCell>Corrective Action Plan</TableCell>
                <TableCell>Target Date For Completion</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows &&
                rows?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <TextField
                        disabled
                        value={row.hraClause}
                        onChange={(e) =>
                          handleInputChange(e.target.value, index, "hraClause")
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        disabled
                        value={row.requirement}
                        onChange={(e) =>
                          handleInputChange(
                            e.target.value,
                            index,
                            "requirement"
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        disabled
                        value={row.found}
                        onChange={(e) =>
                          handleInputChange(e.target.value, index, "found")
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <Select
                        disabled
                        value={row.type}
                        onChange={(e) =>
                          handleCategoryChange(e.target.value, index)
                        }
                      >
                        {types.map((type) => (
                          <MenuItem key={type} value={type}>
                            {type}
                          </MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    <TableCell>
                      <TextField
                        disabled
                        value={row.rootCause}
                        onChange={(e) =>
                          handleInputChange(e.target.value, index, "rootCause")
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        disabled
                        value={row.correctiveActionPlan}
                        onChange={(e) =>
                          handleInputChange(
                            e.target.value,
                            index,
                            "correctiveActionPlan"
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <input
                        disabled
                        type="date"
                        value={formatDate(row.targetDateForCompletion)}
                        onChange={(e) =>
                          handleDateChange(
                            e.target.value,
                            index,
                            "targetDateForCompletion"
                          )
                        }
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
        {/* <Button
                    variant="contained"
                    startIcon={<LibraryAddSharpIcon />}
                    onClick={handleAddRow}>
                    Add Row
                </Button> */}

        {/* {editMode && (
                    <>
                        <Button
                            variant="contained"
                            className="btn-orange"
                            size="large"
                            type="submit"
                        >
                            Update
                        </Button>
                    </>
                )}

                {!editMode && (
                    <>
                        <Button
                            variant="contained"
                            className="btn-orange"
                            size="large"
                            type="submit"
                        >
                            Submit
                        </Button>
                    </>
                )}

                <>
                    <Button
                        variant="contained"
                        className="btn-orange"
                        size="large"
                        onClick={handleDelete}
                    >
                        Delete
                    </Button>
                </> */}
      </form>
    </div>
  );
};

export default HRA_NCView;
