import PropTypes from "prop-types";
import { Link, Navigate, useLocation } from "react-router-dom";
// @mui
import { styled } from "@mui/material/styles";
import { Stack, AppBar } from "@mui/material";
// utils
import { bgBlur } from "../../../utils/cssStyles";
//
import "./header.css";
import AccountPopover from "./AccountPopover";
import Logo from "../../../../src/logo.jpg";

// ----------------------------------------------------------------------

const StyledRoot = styled(AppBar)(({ theme }) => ({
  ...bgBlur({ color: theme.palette.background.default }),
  boxShadow: "none",
  [theme.breakpoints.up("lg")]: {
    width: `100%`,
  },
}));

// ----------------------------------------------------------------------

Header.propTypes = {
  onOpenNav: PropTypes.func,
};

export default function Header({ onOpenNav, onLogout }) {
  const location = useLocation();
  const UserName = sessionStorage.getItem("UserName");
  const isAuthenticated = sessionStorage.getItem("isAuthenticated");
  const scheme = sessionStorage.getItem("scheme");
  console.log(location.pathname, "pathname");
  return (
    <StyledRoot className="cl-green p-3 pb-0">
      <div className="d-flex flex-wrap align-items-center justify-content-between mb-3">
        <div className="col-2">
          <img className="logo-img" src={Logo} alt="CAMS-LOGO" />
        </div>
        <div className="col-8">
          <h4 className="fw-bold text-dark mb-0 text-center">
            {scheme === "HRA" ? "HRA" : "CAMS"}-{" "}
            <span className="text-capitalize">{isAuthenticated}</span>
          </h4>
        </div>
        <div className="col-2">
          <div className="d-flex flex-wrap align-items-center justify-content-end">
            <Stack
              direction="row"
              alignItems="center"
              spacing={{
                xs: 0.5,
                sm: 1,
              }}
            >
              <AccountPopover onLogout={onLogout} />
            </Stack>
            <h6 className="mb-0 ms-2 text-dark">{UserName}</h6>
          </div>
        </div>
      </div>

      {location.pathname.includes("/admin") ? (
        <div className="d-flex align-items-center justify-content-center title ">
          <Link
            to="/admin/dashboard"
            className={location.pathname === "/admin/dashboard" ? "active" : ""}
          >
            Dashboard
          </Link>
          <Link
            to="/admin/updateadmin"
            className={
              location.pathname === "/admin/updateadmin" ? "active" : ""
            }
          >
            Admin
          </Link>
          <Link
            to="/admin/sales"
            className={location.pathname === "/admin/sales" ? "active" : ""}
          >
            Sales
          </Link>
          <Link
            to="/admin/cso"
            className={location.pathname === "/admin/cso" ? "active" : ""}
          >
            CSO
          </Link>
          <Link
            to="/admin/reviewer"
            className={location.pathname === "/admin/reviewer" ? "active" : ""}
          >
            Reviewer
          </Link>
          <Link
            to="/admin/planner"
            className={location.pathname === "/admin/planner" ? "active" : ""}
          >
            Planner
          </Link>
          <Link
            to="/admin/auditor"
            className={location.pathname === "/admin/auditor" ? "active" : ""}
          >
            Auditor
          </Link>
          <Link
            to="/admin/account"
            className={location.pathname === "/admin/account" ? "active" : ""}
          >
            Account
          </Link>
          <Link
            to="/admin/clients"
            className={location.pathname === "/admin/clients" ? "active" : ""}
          >
            Clients
          </Link>
          {/* <Link>Management</Link>
          <Link>Certificate</Link> */}
        </div>
      ) : location.pathname.includes("/sale") &&
        !location.pathname.includes("/saleshra") ? (
        <div className="d-flex align-items-center justify-content-center title ">
          <Link
            to="/sale/skillset"
            className={location.pathname === "/sale/skillset" ? "active" : ""}
          >
            Skillset
          </Link>
          <Link
            to="/sale/clients"
            className={location.pathname === "/sale/clients" ? "active" : ""}
          >
            Clients
          </Link>
          <Link
            to="/sale/documents"
            className={location.pathname === "/sale/documents" ? "active" : ""}
          >
            Document
          </Link>
          <Link
            to="/sale/training-certification"
            className={
              location.pathname === "/sale/training-certification"
                ? "active"
                : ""
            }
          >
            Training & Certificate
          </Link>
        </div>
      ) : location.pathname.includes("/cso") ? (
        <div className="d-flex align-items-center justify-content-center title ">
          <Link
            to="/cso/skillset"
            className={location.pathname === "/cso/skillset" ? "active" : ""}
          >
            Skillset
          </Link>
          <Link
            to="/cso/clients"
            className={location.pathname === "/cso/clients" ? "active" : ""}
          >
            Clients
          </Link>
          <Link
            to="/cso/audit"
            className={location.pathname === "/cso/audit" ? "active" : ""}
          >
            Audit
          </Link>
          <Link
            to="/cso/certificate"
            className={location.pathname === "/cso/certificate" ? "active" : ""}
          >
            Certificate
          </Link>
          <Link
            to="/cso/documents"
            className={location.pathname === "/cso/documents" ? "active" : ""}
          >
            Document
          </Link>
          <Link
            to="/cso/feedbackForms"
            className={
              location.pathname === "/cso/feedbackForms" ? "active" : ""
            }
          >
            Feedback Forms
          </Link>
          <Link
            to="/cso/training-certification"
            className={
              location.pathname === "/cso/training-certification"
                ? "active"
                : ""
            }
          >
            Training & Certificate
          </Link>
        </div>
      ) : location.pathname.includes("/reviewer") ? (
        <div className="d-flex align-items-center justify-content-center title ">
          <Link
            to="/reviewer/skillset"
            className={
              location.pathname === "/reviewer/skillset" ? "active" : ""
            }
          >
            Skillset
          </Link>
          <Link
            to="/reviewer/clients"
            className={
              location.pathname === "/reviewer/clients" ? "active" : ""
            }
          >
            Clients
          </Link>
          <Link
            to="/reviewer/audit"
            className={location.pathname === "/reviewer/audit" ? "active" : ""}
          >
            Audit
          </Link>
          <Link
            to="/reviewer/documents"
            className={
              location.pathname === "/reviewer/documents" ? "active" : ""
            }
          >
            Document
          </Link>
          <Link
            to="/reviewer/training-certification"
            className={
              location.pathname === "/reviewer/training-certification"
                ? "active"
                : ""
            }
          >
            Training & Certificate
          </Link>
        </div>
      ) : location.pathname.includes("/auditor") &&
        !location.pathname.includes("/auditor-hra") ? (
        <div className="d-flex align-items-center justify-content-center title ">
          <Link
            to="/auditor/skillset"
            className={
              location.pathname === "/auditor/skillset" ? "active" : ""
            }
          >
            Skillset
          </Link>
          <Link
            to="/auditor/calendar"
            className={
              location.pathname === "/auditor/calendar" ? "active" : ""
            }
          >
            Calendar
          </Link>
          {/* <Link
            to="/auditor/clients"
            className={location.pathname === "/auditor/clients" ? "active" : ""}
          >
            Clients
          </Link> */}

          <Link
            to="/auditor/documents"
            className={
              location.pathname === "/auditor/documents" ? "active" : ""
            }
          >
            Document
          </Link>
          <Link
            to="/auditor/training-certification"
            className={
              location.pathname === "/auditor/training-certification"
                ? "active"
                : ""
            }
          >
            Training & Certificate
          </Link>
        </div>
      ) : location.pathname.includes("/planner") ? (
        <div className="d-flex align-items-center justify-content-center title ">
          <Link
            to="/planner/skillset"
            className={
              location.pathname === "/planner/skillset" ? "active" : ""
            }
          >
            Skillset
          </Link>
          <Link
            to="/planner/clients"
            className={location.pathname === "/planner/clients" ? "active" : ""}
          >
            Clients
          </Link>
          <Link
            to="/planner/audit"
            className={location.pathname === "/planner/audit" ? "active" : ""}
          >
            Audit
          </Link>
          <Link
            to="/planner/upcoming-client"
            className={
              location.pathname === "/planner/upcoming-client" ? "active" : ""
            }
          >
            Upcoming Client
          </Link>
          <Link
            to="/planner/documents"
            className={
              location.pathname === "/planner/documents" ? "active" : ""
            }
          >
            Document
          </Link>
          <Link
            to="/planner/training-certification"
            className={
              location.pathname === "/planner/training-certification"
                ? "active"
                : ""
            }
          >
            Training & Certificate
          </Link>
        </div>
      ) : location.pathname.includes("/account") &&
        !location.pathname.includes("/accounthra") ? (
        <div className="d-flex align-items-center justify-content-center title ">
          <Link
            to="/account/skillset"
            className={
              location.pathname === "/account/skillset" ? "active" : ""
            }
          >
            Skillset
          </Link>
          <Link
            to="/account/clients"
            className={location.pathname === "/account/clients" ? "active" : ""}
          >
            Clients
          </Link>
          <Link
            to="/account/documents"
            className={
              location.pathname === "/account/documents" ? "active" : ""
            }
          >
            Document
          </Link>
          <Link
            to="/account/training-certification"
            className={
              location.pathname === "/account/training-certification"
                ? "active"
                : ""
            }
          >
            Training & Certificate
          </Link>
        </div>
      ) : location.pathname.includes("/operationmanager") ? (
        <div className="d-flex align-items-center justify-content-center title ">
          <Link
            to="/operationmanager/dashboard"
            className={
              location.pathname === "/operationmanager/dashboard"
                ? "active"
                : ""
            }
          >
            Dashboard
          </Link>
          <Link
            to="/operationmanager/saleshra"
            className={
              location.pathname === "/operationmanager/saleshra" ? "active" : ""
            }
          >
            Sales
          </Link>
          <Link
            to="/operationmanager/processassociate"
            className={
              location.pathname === "/operationmanager/processassociate"
                ? "active"
                : ""
            }
          >
            Process Associate / MIS
          </Link>
          <Link
            to="/operationmanager/subjectmatterexpert"
            className={
              location.pathname === "/operationmanager/subjectmatterexpert"
                ? "active"
                : ""
            }
          >
            Subject Matter Expert
          </Link>
          <Link
            to="/operationmanager/hra-auditor"
            className={
              location.pathname === "/operationmanager/hra-auditor"
                ? "active"
                : ""
            }
          >
            Auditor
          </Link>
          <Link
            to="/operationmanager/client/list"
            className={
              location.pathname === "/operationmanager/client/list"
                ? "active"
                : ""
            }
          >
            Client List
          </Link>
        </div>
      ) : location.pathname.includes("/saleshra") ? (
        <div className="d-flex align-items-center justify-content-center title ">
          <Link
            to="/saleshra/skillset"
            className={
              location.pathname === "/saleshra/skillset" ? "active" : ""
            }
          >
            Skillset
          </Link>
          <Link
            to="/saleshra/clients"
            className={
              location.pathname === "/saleshra/clients" ? "active" : ""
            }
          >
            Clients
          </Link>
          {/* <Link
            to="/saleshra/documents"
            className={
              location.pathname === "/saleshra/documents" ? "active" : ""
            }
          >
            Document
          </Link> */}
          <Link
            to="/saleshra/training-certification"
            className={
              location.pathname === "/saleshra/training-certification"
                ? "active"
                : ""
            }
          >
            Training & Certificate
          </Link>
        </div>
      ) : location.pathname.includes("/processassociate") ? (
        <div className="d-flex align-items-center justify-content-center title ">
          <Link
            to="/processassociate/skillset"
            className={
              location.pathname === "/processassociate/skillset" ? "active" : ""
            }
          >
            Skillset
          </Link>
          <Link
            to="/processassociate/clients"
            className={
              location.pathname === "/processassociate/clients" ? "active" : ""
            }
          >
            Clients
          </Link>
          <Link
            to="/processassociate/audit"
            className={
              location.pathname === "/processassociate/audit" ? "active" : ""
            }
          >
            Audit
          </Link>
          <Link
            to="/processassociate/documents"
            className={
              location.pathname === "/processassociate/documents"
                ? "active"
                : ""
            }
          >
            Document
          </Link>
          <Link
            to="/processassociate/feedbackForms"
            className={
              location.pathname === "/processassociate/feedbackForms"
                ? "active"
                : ""
            }
          >
            Feedback Forms
          </Link>
          <Link
            to="/processassociate/training-certification"
            className={
              location.pathname === "/processassociate/training-certification"
                ? "active"
                : ""
            }
          >
            Training & Certificate
          </Link>
        </div>
      ) : location.pathname.includes("/subjectmatterexpert") ? (
        <div className="d-flex align-items-center justify-content-center title ">
          <Link
            to="/subjectmatterexpert/skillset"
            className={
              location.pathname === "/subjectmatterexpert/skillset"
                ? "active"
                : ""
            }
          >
            Skillset
          </Link>
          <Link
            to="/subjectmatterexpert/clients"
            className={
              location.pathname === "/subjectmatterexpert/clients"
                ? "active"
                : ""
            }
          >
            Clients
          </Link>
          <Link
            to="/subjectmatterexpert/audit"
            className={
              location.pathname === "/subjectmatterexpert/audit" ? "active" : ""
            }
          >
            Audit
          </Link>
          <Link
            to="/subjectmatterexpert/reviewaudit"
            className={
              location.pathname === "/subjectmatterexpert/reviewaudit"
                ? "active"
                : ""
            }
          >
            Review Audit
          </Link>
          <Link
            to="/subjectmatterexpert/documents"
            className={
              location.pathname === "/subjectmatterexpert/documents"
                ? "active"
                : ""
            }
          >
            Document
          </Link>
          <Link
            to="/subjectmatterexpert/training-certification"
            className={
              location.pathname ===
                "/subjectmatterexpert/training-certification"
                ? "active"
                : ""
            }
          >
            Training & Certificate
          </Link>
        </div>
      ) : location.pathname.includes("/auditor-hra") &&
        !location.pathname.includes("/operationmanager") ? (
        <div className="d-flex align-items-center justify-content-center title ">
          <Link
            to="/auditor-hra/skillset"
            className={
              location.pathname === "/auditor-hra/skillset" ? "active" : ""
            }
          >
            Skillset
          </Link>
          <Link
            to="/auditor-hra/calendar"
            className={
              location.pathname === "/auditor-hra/calendar" ? "active" : ""
            }
          >
            Calendar
          </Link>
          {/* <Link
      to="/auditor/clients"
      className={location.pathname === "/auditor/clients" ? "active" : ""}
    >
      Clients
    </Link> */}
          <Link
            to="/auditor-hra/audit"
            className={
              location.pathname === "/auditor-hra/audit" ? "active" : ""
            }
          >
            Audit
          </Link>
          <Link
            to="/auditor-hra/documents"
            className={
              location.pathname === "/auditor-hra/documents" ? "active" : ""
            }
          >
            Document
          </Link>
          <Link
            to="/auditor-hra/training-certification"
            className={
              location.pathname === "/auditor-hra/training-certification"
                ? "active"
                : ""
            }
          >
            Training & Certificate
          </Link>
        </div>
      ) : (
        ""
      )}
    </StyledRoot>
  );
}
