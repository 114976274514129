import React from "react";
import { Container } from "@mui/material";
import { Helmet } from "react-helmet-async";
import ReviewAudit from "src/components/Subject-Matter-Expert/ReviewAudit/ReviewAudit";


export default function SubjectmatterexpertReviewAudit() {
    return (
        <>
            <Helmet>
                <title> Skill-set </title>
            </Helmet>

            <Container maxWidth="xl">
                <ReviewAudit Role="Review Audit" />
            </Container>
        </>
    )
}