import React, { useEffect, useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Iconify from "src/components/iconify/Iconify";
import { Button, Checkbox } from "@mui/material";
import { deleteData, getData, patchData, postData } from "src/webService/webService";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import {
  Table,
  TableRow,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Card,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import { setAuditList } from "src/redux/slices/PlannerSlice";
import { useDispatch, useSelector } from "react-redux";
import CLIENTROUTES from "src/API_Routes/Client/Client_Routers";
import REVIEWERROUTES from "src/API_Routes/Reviewer/Reviewer_Router";
import AUDITROUTES from "src/API_Routes/Audit/Audit_routes";
import EMAIL_ROUTES from "src/API_Routes/Email/Email_Routers";
import ReviewAssignEmail from "src/Config/templates/html/ReviewAssign";
import EVENT_TRACKING_ROUTES from "src/API_Routes/EventTracking/EventTracking_Routers";
import CommonEmail from "src/Config/templates/html/CommonEmailTem";
import { getFormattedIndiaTime } from "../../../utils/timeutils";
import USERSROUTES from "src/API_Routes/User/User_Routers";
function Audit({ Role }) {
  const dispatch = useDispatch();
  const date = new Date().toLocaleDateString();
  const scheme_session = sessionStorage.getItem("scheme");
  const isAuthenticated = sessionStorage.getItem("isAuthenticated");
  const accessToken = sessionStorage.getItem("Token");
  const UserId = sessionStorage.getItem("UserId");
  const [isChecked, setIsChecked] = useState({});
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [limit, setLimit] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [clientBasicList, setClientBasicList] = useState();
  const [selectedClientList, setSelectedClientList] = useState();
  const [id, setid] = useState("");

  console.log(clientBasicList);

  const GetID = async (data, auditorId) => {
    setid(data);
    const formattedDate = getFormattedIndiaTime();
    const paramsStatus = {
      status: {
        type: "auditor confirmed",
        updatedDate: formattedDate,
      },
      reviewHistory: [{
        reviewerName: auditorId
      }],
    };

    await patchData(
      `${CLIENTROUTES.GETCLIENTBASIC}/${data._id}/basic`,
      paramsStatus,
      accessToken,
      dispatch
    );



    const HTML_DATA = {
      ClientName: data.companyName,
      ContactPerson: data.contactPerson,
      ClientID: data.clientId,
      AuditType: "INITIAL_ACCREDITATION_AND_ADMIN_COST",
      Message: "Audit assigned",
    };
    console.log("HTML_DATA", HTML_DATA);
    postData(
      EMAIL_ROUTES.SEND_EMAIL_TEXT,
      {
        to: "selvam0803.dt@gmail.com",
        subject: "Audit assigned",
        html: CommonEmail(HTML_DATA),
      },
      accessToken,
      dispatch
    );

    GETProcessAssociate(currentPage);
  };

  console.log(id, "id");

  // const handleReject = async (clientId, auditId, rowId) => {

  //   const auditArray = clientBasicList.find(
  //     (client) => client._id === rowId
  //   );

  //   const removeScheduledDates = () => {
  //     auditArray.scheduledAuditorsWithDates.forEach(scheduledAuditor => {
  //       const { auditorId, scheduledDate } = scheduledAuditor;

  //       const auditor = auditArray.AuditorBasic_Data.find(auditor => auditor._id === auditorId);
  //       if (auditor) {
  //         auditor.auditDates = auditor.auditDates.filter(
  //           auditDate => auditDate.date !== scheduledDate
  //         );
  //       }
  //     });

  //     console.log("Remaining audit dates:", auditArray.AuditorBasic_Data);
  //   };

  //   useEffect(() => {
  //     removeScheduledDates();
  //   }, []);


  //   console.log(auditArray, "AuditArray")
  //   // console.log(clientId, "idddddddddd")
  //   // console.log(auditId, "auditid");
  //   // const formattedDate = getFormattedIndiaTime();
  //   // const paramsStatus = {
  //   //   status: {
  //   //     type: "auditor confirmed",
  //   //     updatedDate: formattedDate,
  //   //   },
  //   // };

  //   // await patchData(
  //   //   `${CLIENTROUTES.GETCLIENTBASIC}/${data.clientId}/basic`,
  //   //   paramsStatus,
  //   //   accessToken
  //   // );



  // }

  const handleReject = async (clientId, auditId, rowId) => {
    const auditArray = clientBasicList.find(
      (client) => client._id === rowId
    );

    const formattedDate = getFormattedIndiaTime();
    const paramsStatus = {
      status: {
        type: "agreement updated",
        updatedDate: formattedDate,
      },
    };

    await patchData(
      `clients/client/${clientId}/basic`,
      paramsStatus,
      accessToken,
      dispatch
    );

    const removeScheduledDates = async () => {
      auditArray.scheduledAuditorsWithDates.forEach(scheduledAuditor => {
        const { auditorId, scheduledDate } = scheduledAuditor;

        const auditor = auditArray.AuditorBasic_Data.find(auditor => auditor._id === auditorId);
        if (auditor) {
          auditor.auditDates = auditor.auditDates.filter(
            auditDate => auditDate.date !== scheduledDate
          );
        }
      });

      console.log("Remaining audit dates:", auditArray.AuditorBasic_Data[0].auditDates);

      const params = {
        auditDates: auditArray.AuditorBasic_Data[0].auditDates
      }

      await patchData(
        USERSROUTES.EDITUSER + auditId, params, accessToken, dispatch
      )
        .then((res) => {
          toast.success("Audit Dates Updated", {
            position: toast.POSITION.TOP_RIGHT,
          });
        })
        .catch((error) => {
          console.error("Error:", error);
        });

    };

    removeScheduledDates();

    console.log(auditArray, "AuditArray");
    console.log(clientId, "idddddddddd")
    console.log(auditId, "auditid");



    await deleteData(
      `${AUDITROUTES.SCHEDULEDELETE(rowId)}`,
      accessToken
    )

      .then((res) => {
        toast.success("Audit Deleted", {
          position: toast.POSITION.TOP_RIGHT,
        });
      })


  };

  // Function to handle checkbox change
  const handleCheckboxChange = (event, id) => {
    setIsChecked({ ...isChecked, [id]: event.target.checked });
  };
  // Function to handle select all checkbox change
  const handleSelectAllCheckboxChange = (event) => {
    const { checked } = event.target;
    const updatedIsChecked = {};

    // Update isChecked object with all checkboxes' states
    clientBasicList.forEach((row) => {
      updatedIsChecked[row._id] = checked;
    });
    setIsChecked(updatedIsChecked);
    setSelectAllChecked(checked);
  };

  const handleLimitChange = (event) => {
    setCurrentPage(1);
    setLimit(event.target.value);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const GETClientHRA = async () => {
    try {
      const res = await getData(
        `masters/audit/client/companynames?status=auditor assigned&scheme=HRA`,
        null,
        accessToken
      );
      if (res.status === 200) {
        setSelectedClientList(res.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  const GETProcessAssociate = async (page) => {
    const params = {
      page: page,
      limit: limit,
      scheme: "hra",
    };
    try {
      const res = await getData(
        `audit/all/norole/${UserId}/`,
        params,
        accessToken
      );
      if (res.status === 200) {
        setClientBasicList(res.data[0].paginatedResults);
        setTotalPages(res.data[0].totalPages);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    GETClientHRA();
    GETProcessAssociate(currentPage);
  }, [currentPage, limit]);

  return (
    <>
      <ToastContainer />
      <div className="cso-clientlist-border p-5">
        <div className="px-2 add-title">
          <span className="">{Role}</span>
        </div>
        <Card className="mt-4">
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    className="cl-green text-dark"
                    style={{ padding: "5px" }}
                  >
                    <Checkbox
                      checked={selectAllChecked}
                      onChange={handleSelectAllCheckboxChange}
                    />
                  </TableCell>
                  <TableCell className="cl-green text-dark">
                    Client Id
                  </TableCell>
                  <TableCell className="cl-green text-dark">
                    Company Name
                  </TableCell>
                  <TableCell className="cl-green text-dark">
                    Auditor Name
                  </TableCell>
                  <TableCell className="cl-green text-dark">Scheme</TableCell>
                  <TableCell className="cl-green text-dark">Location</TableCell>
                  <TableCell className="cl-green text-dark">
                    scheduledAuditorsWithDates
                  </TableCell>
                  <TableCell className="cl-green text-dark" align="center">
                    Status
                  </TableCell>
                  <TableCell className="cl-green text-dark" align="center">
                    Action
                  </TableCell>
                  <TableCell className="cl-green text-dark" align="center">
                    reject
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {clientBasicList ? (
                  clientBasicList?.map((row, index) => (
                    <TableRow key={index} hover tabIndex={-1}>
                      <TableCell padding="checkbox">
                        <Checkbox
                          checked={isChecked[row._id] || false}
                          onChange={(event) =>
                            handleCheckboxChange(event, row._id)
                          }
                        />
                      </TableCell>
                      <TableCell>{row.ClientBasic_Data[0]?.clientId}</TableCell>
                      <TableCell>
                        {row.ClientBasic_Data[0]?.companyName}
                      </TableCell>
                      <TableCell>
                        {row.AuditorBasic_Data[0].firstName}{" "}
                        {row.AuditorBasic_Data[0].lastName}
                      </TableCell>
                      <TableCell>
                        {row.ClientBasic_Data[0]?.standardsSchemeType}
                      </TableCell>
                      <TableCell>
                        {row.ClientBasic_Data[0]?.state} /{" "}
                        {row.ClientBasic_Data[0]?.country}
                      </TableCell>
                      <TableCell>
                        {new Date(
                          row.scheduledAuditorsWithDates[0].scheduledDate
                        ).toLocaleDateString("en-GB", {
                          year: "numeric",
                          month: "2-digit",
                          day: "2-digit",
                        })}
                      </TableCell>
                      <TableCell align="center">
                        {row.ClientBasic_Data[0]?.status.type}
                      </TableCell>
                      <TableCell>
                        <Button
                          className={`btn ${row.ClientBasic_Data[0]?.status.type ===
                            "auditor assigned"
                            ? "btn-green"
                            : "btn-green-disable"
                            }`}
                          key={row.ClientBasic_Data[0]?._id}
                          id={`${row.ClientBasic_Data[0]?._id}`}
                          onClick={
                            row.ClientBasic_Data[0]?.status.type ===
                              "auditor assigned"
                              ? () => GetID(row.ClientBasic_Data[0], row._id)
                              : undefined
                          }
                          disabled={
                            row.ClientBasic_Data[0]?.status.type !==
                            "auditor assigned"
                          }
                        >
                          Confirmation
                        </Button>
                      </TableCell>

                      <TableCell>
                        <Button variant="outlined" color="error" onClick={(e) => handleReject(row.ClientBasic_Data[0]._id, row.AuditorBasic_Data[0]._id, row._id)}>
                          Reject
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <h6 className="text-center my-3 w-100">No Data</h6>
                )}
              </TableBody>
            </Table>
          </TableContainer>

          <div className="d-flex flex-wrap justify-content-end m-3">
            <FormControl variant="outlined" size="small">
              <InputLabel id="limit-label">Limit</InputLabel>
              <Select
                labelId="limit-label"
                id="limit-select"
                value={limit}
                onChange={handleLimitChange}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={15}>15</MenuItem>
              </Select>
            </FormControl>
            <Button onClick={handlePrevPage} disabled={currentPage === 1}>
              <Iconify icon={"grommet-icons:previous"} />
            </Button>
            <Button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <Iconify icon={"grommet-icons:next"} />
            </Button>
          </div>
        </Card>
      </div>
    </>
  );
}

export default Audit;
