import { createSlice } from "@reduxjs/toolkit";

const AuditorNameList = createSlice({
  name: "AuditorNameList",
  initialState: {
    AuditorNameList: "",
  },
  reducers: {
    setAuditorNameList: (state, action) => {
      state.AuditorNameList = action.payload;
    },
  },
});

export const { setAuditorNameList } = AuditorNameList.actions;
export default AuditorNameList.reducer;
