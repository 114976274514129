import React, { useState, useEffect } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getData, postData } from "src/webService/webService";
import AUDITROUTES from "src/API_Routes/User/User_Routers";
import { useDispatch } from "react-redux";

moment.locale("en-GB");
const localizer = momentLocalizer(moment);

const ReactBigCalendar = ({ setshowmoredata, onScheduleClick }) => {
  const dispatch = useDispatch();
  const UserId = sessionStorage.getItem("UserId");
  const scheme = sessionStorage.getItem("scheme");
  const Role = sessionStorage.getItem("isAuthenticated");

  const [eventsData, setEventsData] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [status, setStatus] = useState("")
  const accessToken = sessionStorage.getItem("Token");

  const fetchAuditData = (start, end) => {
    const params = {
      auditorId: UserId,
    };
    let endpoint;
    if (scheme === "CB") {
      endpoint = `audit/schedules?startdate=${start.toLocaleDateString()}&enddate=${end.toLocaleDateString()}&scheme=CB`;
    } else {
      endpoint = `audit/schedules?startdate=${start.toLocaleDateString()}&enddate=${end.toLocaleDateString()}&scheme=HRA`;
    }
    postData(endpoint, params, accessToken, dispatch)
      .then((res) => {
        const Dates = res.data.results;
        Dates.forEach((item) => {
          setStatus(
            item?.reportStatus?.name
          );
        })
        console.log(res.data.results);
        let scheduledDates = [];
        Dates.forEach((item) => {
          item.scheduledAuditorsWithDates.forEach((auditor) => {
            if (auditor.auditorId === UserId) {
              const scheduledDate = new Date(auditor.scheduledDate);
              scheduledDates.push({
                start: scheduledDate,
                end: scheduledDate,
                title: "Scheduled Audit",
                auditId: item._id,
                clientId: item.clientId,
                auditorRole: auditor.auditorDesignation,
              });
            }
          });
        });
        setEventsData(scheduledDates);
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("An error occurred. Please try again later.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  const handleNavigate = (newDate) => {
    const start = new Date(newDate.getFullYear(), newDate.getMonth(), 1);
    const end = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0);
    setStartDate(start);
    setEndDate(end);
  };

  const handleScheduleClick = (event) => {
    // Trigger setshowmoredata with true when a scheduled date is clicked
    setshowmoredata(true);
    // Pass the auditId to the parent component
    onScheduleClick(event.auditId, event.clientId, event.auditorRole);
  };

  useEffect(() => {
    if (startDate && endDate) {
      fetchAuditData(startDate, endDate);
    }
  }, [startDate, endDate]);

  useEffect(() => {
    const now = new Date();
    const start = new Date(now.getFullYear(), now.getMonth(), 1);
    const end = new Date(now.getFullYear(), now.getMonth() + 1, 0);
    setStartDate(start);
    setEndDate(end);
  }, []);

  const eventStyleGetter = () => {
    let style = {
      backgroundColor: status === 'Need Revision' ? 'red' : '#3174ad', // Default color
    };
    return {
      style: style
    };
  };

  return (
    <div className="App">
      <ToastContainer />
      <Calendar
        views={["month"]}
        selectable
        localizer={localizer}
        defaultDate={new Date()}
        defaultView="month"
        events={eventsData}
        style={{ height: "100vh" }}
        onSelectEvent={handleScheduleClick} // Pass the click event handler
        onNavigate={handleNavigate}
        eventPropGetter={eventStyleGetter}
      />
    </div>
  );
};

export default ReactBigCalendar;
