import { createSlice } from "@reduxjs/toolkit";

const UserDetailSlice = createSlice({
  name: "userDetail",
  initialState: {
    BasicData: "",
    SpecificUser: "",
    SpecificAuditor: "",
  },
  reducers: {
    setBasicData: (state, action) => {
      state.BasicData = action.payload;
    },
    setSpecificUser: (state, action) => {
      state.SpecificUser = action.payload;
    },
    setSpecificAuditor: (state, action) => {
      state.SpecificAuditor = action.payload;
    },
  },
});

export const { setBasicData, setSpecificUser, setSpecificAuditor } =
  UserDetailSlice.actions;
export default UserDetailSlice.reducer;
