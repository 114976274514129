import { createSlice } from "@reduxjs/toolkit";

const LoaderSlice = createSlice({
  name: "Loader",
  initialState: {
    View: "",
  },
  reducers: {
    setView: (state, action) => {
      state.View = action.payload;
    },
  },
});

export const { setView } = LoaderSlice.actions;
export default LoaderSlice.reducer;
