import { createSlice } from "@reduxjs/toolkit";

const FeeSlice = createSlice({
  name: "Fee",
  initialState: {
    TransferSA1: "",
    TransferSA2: "",
    TransferRA: "",
    ReCertification: "",
  },
  reducers: {
    setTransferSA1Data: (state, action) => {
      state.TransferSA1 = action.payload;
    },
    setTransferSA2Data: (state, action) => {
      state.TransferSA2 = action.payload;
    },
    setTransferRAData: (state, action) => {
      state.TransferRA = action.payload;
    },
    setReCertification: (state, action) => {
      state.ReCertification = action.payload;
    },
  },
});

export const {
  setTransferSA1Data,
  setTransferSA2Data,
  setTransferRAData,
  setReCertification,
} = FeeSlice.actions;
export default FeeSlice.reducer;
