import React, { useEffect, useState } from "react";
import "../Client/Client-list.css";
import {
  Button,
  Card,
  Checkbox,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import Iconify from "../../iconify/Iconify";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getData, patchData, postData } from "src/webService/webService";
import { useDispatch, useSelector } from "react-redux";
import { setClientBasicandAdvance } from "src/redux/slices/PlannerSlice";
import PLANNERROUTES from "src/API_Routes/Planner/Planner_Router";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import { TextField } from "@mui/material";
import DialogActions from "@mui/material/DialogActions";
import AddClientForm from "../../Sales/Clients-list/Form/Form";
import SpecificUserBasic from "../../../CommonFunction/GetClientBasic";
import SpecificUserAdvance from "../../../CommonFunction/GetClientAdvance";
import PlannerFilter from "../../Filter/PlannerFilter";
import { fetchAllPlanner } from "../../../CommonFunction/GetAllPlanner";
import { fetchAllSales } from "../../../CommonFunction/GetAllSales";
import EVENT_TRACKING_ROUTES from "../../../API_Routes/EventTracking/EventTracking_Routers";
import EventDialog from "../../Events/Events";

export default function ClientList() {
  const dispatch = useDispatch();
  const isAuthenticated = sessionStorage.getItem("isAuthenticated");
  const SalesNameList = useSelector((state) => state?.SalesList.SalesNameList);
  const PlannerNameList = useSelector(
    (state) => state?.PlannerList.PlannerNameList
  );
  const UserId = sessionStorage.getItem("UserId");
  const PLANNERVIEWCLIENTLIST = useSelector(
    (state) => state?.Planner?.ClientBasicandAdvance[0]?.paginatedResults
  );
  const accessToken = sessionStorage.getItem("Token");
  // Table Conditions
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(5);
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleLimitChange = (event) => {
    setCurrentPage(1);
    setLimit(event.target.value);
  };
  // Table Conditions
  const GETPlanner = async (page) => {
    const params = {
      page: page,
      limit: limit,
    };
    try {
      const res = await getData(
        PLANNERROUTES.GETCLIENTLIST,
        params,
        accessToken
      );
      if (res.status === 200) {
        dispatch(setClientBasicandAdvance(res.data));
        setTotalPages(res.data[0].totalPages);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  useEffect(() => {
    GETPlanner(currentPage);
  }, [currentPage, limit]);

  useEffect(() => {
    fetchAllPlanner(dispatch, accessToken);
    fetchAllSales(dispatch, accessToken);
  }, []);

  // Dialog
  const [Uploadopen, setUploadopen] = useState(false);
  const handleClickOpen = () => {
    setUploadopen(true);
  };
  const handleClose = () => {
    setUploadopen(false);
  };

  //Assign Auditor
  const [dateval, setDate] = useState();
  const [selectedAuditor, setSelectedAuditor] = useState("");
  const [scheduledAuditorsWithDates, setScheduledAuditorsWithDates] = useState(
    []
  );
  const [specificUser, setspecificUser] = useState("");
  const [auditors, setauditors] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await getData(
          `masters/audit/auditors/free?date=${dateval}`,
          null,
          accessToken
        );
        if (res.status === 200) {
          setauditors(res.data);
          console.log(res);
        }
      } catch (error) {
        console.error("Error:", error);
        toast.error("An error occurred. Please try again later.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    };
    if (dateval) {
      fetchData();
    }
  }, [dateval]);

  const handleAuditorChange = (event) => {
    setSelectedAuditor(event.target.value);
  };
  const handleAddAuditor = () => {
    if (selectedAuditor && dateval) {
      // Check if the selected auditor is already assigned
      const isAuditorAssigned = scheduledAuditorsWithDates.some(
        (auditor) => auditor.auditorId === selectedAuditor
      );
      const res2 = patchData(
        `users/basic/user/${selectedAuditor}`,
        { auditDates: [dateval] },
        accessToken,
        dispatch
      );
      if (!isAuditorAssigned) {
        setScheduledAuditorsWithDates((prevScheduledAuditorsWithDates) => [
          ...prevScheduledAuditorsWithDates,
          { auditorId: selectedAuditor, scheduledDate: dateval },
        ]);
        // Clear the selected auditor and date after adding
        setSelectedAuditor("");
        setDate("");
      } else {
        toast.error("Auditor is already assigned.", {
          position: toast.POSITION.TOP_RIGHT,
        });
        // Auditor is already assigned, show an error message or handle accordingly
      }
    }
  };

  const handleRemoveAuditor = (index) => {
    setScheduledAuditorsWithDates((prevScheduledAuditorsWithDates) =>
      prevScheduledAuditorsWithDates.filter((_, i) => i !== index)
    );
  };
  const getAuditorName = (auditorId) => {
    const auditor = auditors.find((auditor) => auditor._id === auditorId);
    return auditor
      ? `${auditor.firstName} ${auditor.lastName}`
      : "Unknown Auditor";
  };

  const scheduleAuditor = async () => {
    const params = {
      plannerId: UserId,
      clientId: specificUser,
      scheduledAuditorsWithDates: scheduledAuditorsWithDates,
    };

    const res = await postData(`audit/schedule`, params, accessToken,dispatch);

    setspecificUser("");
    setUploadopen(false);
    setScheduledAuditorsWithDates([]);
    setSelectedAuditor("");
    setDate();
  };
  const [viewClient, setViewClient] = useState(false);
  const handleViewClient = (data) => {
    SpecificUserBasic(data, dispatch);
    SpecificUserAdvance(data, dispatch);
    setViewClient(true);
  };
  const CloseclientView = () => {
    setViewClient(false);
  };

  const handleExport = () => {
    const headers = [
      "Client Id",
      "Company Name",
      "Location",
      "Accredited / Unaccredited",
      "Scope",
      "Scheme",
      "Technical Main codes",
      "Technical Sub codes",
      "No. & name of site",
      "No. of MD for each scheme",
      "Order value",
      "Lead generated by",
      "Sales co-ordinator",
      "Contact person details - mail / name & mobile",
    ].filter(Boolean);
    // Filter checked items
    const checkedItems = PLANNERVIEWCLIENTLIST.filter(
      (item) => isChecked[item._id]
    );
    let data;
    data = checkedItems.map((item) => {
      const getSalesPersonNameById = (id, salesList) => {
        const person = salesList.find((salesPerson) => salesPerson._id === id);
        if (person) {
          return { firstName: person.firstName, lastName: person.lastName };
        } else {
          return null;
        }
      };
      const getPlannerPersonNameById = (id, PlannerList) => {
        const person = PlannerList.find(
          (plannerPerson) => plannerPerson._id === id
        );
        if (person) {
          return { firstName: person.firstName, lastName: person.lastName };
        } else {
          return null;
        }
      };
      const clientStatusTracker = item.clientStatusTracker;
      const SaleFind = getSalesPersonNameById(item.salesId, SalesNameList);
      const SaleName = SaleFind.firstName + SaleFind.lastName;
      const Accredited =
        item.ClientFormIMS_Data?.[0]?.auditCertificationActivitiesAndFees
          ?.auditTypeStates?.[0]?.name === "Initial Certification" ||
        item.ClientFormFSMS_Data?.[0]?.auditCertificationActivitiesAndFees
          ?.auditTypeStates?.[0]?.name === "Initial Certification"
          ? "Unaccredited"
          : "Accredited";

      const calculateOverallTotalManDays = (item) => {
        let totalManDays = 0;
        let itemScheme = {};

        if (item.ClientFormIMS_Data && item.ClientFormIMS_Data[0]) {
          itemScheme =
            item.ClientFormIMS_Data[0].auditCertificationActivitiesAndFees ||
            {};
        } else if (item.ClientFormFSMS_Data && item.ClientFormFSMS_Data[0]) {
          itemScheme =
            item.ClientFormFSMS_Data[0].auditCertificationActivitiesAndFees ||
            {};
        }

        // Calculate total man-days from surveillance audits
        if (itemScheme.surveillanceAudit) {
          itemScheme.surveillanceAudit.forEach((audit) => {
            if (audit.manDay && audit.manDay.totalManDays) {
              totalManDays += audit.manDay.totalManDays;
            }
          });
        }

        // Calculate total man-days from re-certifications
        if (itemScheme.re_Certification) {
          itemScheme.re_Certification.forEach((certification) => {
            if (certification.manDay && certification.manDay.totalManDays) {
              totalManDays += certification.manDay.totalManDays;
            }
          });
        }

        // Calculate total man-days from transfer certifications
        if (itemScheme.transferCertification) {
          itemScheme.transferCertification.forEach((certification) => {
            if (certification.totalManDays) {
              totalManDays += certification.totalManDays;
            }
          });
        }
        // Check for Initial Certification and add stage 1, stage 2, sa1, and sa2 man-days
        const hasInitialCertification =
          itemScheme.auditTypeStates &&
          itemScheme.auditTypeStates.some(
            (state) => state.name === "Initial Certification"
          );

        if (hasInitialCertification) {
          if (itemScheme.stage) {
            itemScheme.stage.forEach((stage) => {
              if (stage.manDay) {
                totalManDays +=
                  (stage.manDay.onSite || 0) + (stage.manDay.offSite || 0);
              }
            });
          }

          if (itemScheme.surveillanceAudit) {
            itemScheme.surveillanceAudit.forEach((audit) => {
              if (audit.manDay) {
                totalManDays +=
                  (audit.manDay.onSite || 0) + (audit.manDay.offSite || 0);
              }
            });
          }
        }

        return totalManDays;
      };

      const contactPerson =
        item.email + " / " + item.contactPerson + " & " + item.email;

      return [
        item.clientId,
        item.companyName,
        item.address,
        Accredited,
        item.ClientFormIMS_Data?.[0]?.scopeOfCertification ||
          item.ClientFormFSMS_Data?.[0]?.scopeOfCertification ||
          "-",
        item.standardsSchemeType === "IMS"
          ? item.ClientFormIMS_Data[0]?.standards?.[0] || "-"
          : item.ClientFormFSMS_Data[0]?.standards?.[0] || "-",
        item.technicalCode.mainCode,
        item.technicalCode.subCode,
        item.noOfSites,
        calculateOverallTotalManDays(item),
        item.ClientFormIMS_Data?.[0]?.auditCertificationActivitiesAndFees
          ?.totalContractFee ||
          item.ClientFormFSMS_Data?.[0]?.auditCertificationActivitiesAndFees
            ?.totalContractFee ||
          "-",
        item.leadGeneratedBy,
        SaleName,
        contactPerson,
      ];
    });
    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Client List Data");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    saveAs(
      new Blob([excelBuffer], { type: "application/octet-stream" }),
      "Planner_client_list_data.xlsx"
    );
  };
  //Filter
  const [filter, SetFilter] = useState(false);
  const ChangeFilter = () => {
    SetFilter(!filter);
  };
  useEffect(() => {
    if (!filter) {
      GETPlanner(currentPage);
    }
  }, [filter]);

  const [isChecked, setIsChecked] = useState({});
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  // Function to handle checkbox change
  const handleCheckboxChange = (event, id) => {
    setIsChecked({ ...isChecked, [id]: event.target.checked });
  };
  // Function to handle select all checkbox change
  const handleSelectAllCheckboxChange = (event) => {
    const { checked } = event.target;
    const updatedIsChecked = {};

    // Update isChecked object with all checkboxes' states
    PLANNERVIEWCLIENTLIST.forEach((row) => {
      updatedIsChecked[row._id] = checked;
    });

    setIsChecked(updatedIsChecked);
    setSelectAllChecked(checked);
  };

  // Events
  const [Eventopen, setEventOpen] = useState(false);
  const [Events, setEvents] = useState();
  const handleEventClickOpen = async (id) => {
    const res = await getData(
      EVENT_TRACKING_ROUTES.CREATE_EVENT_HISTORY(id),
      null,
      accessToken
    );
    setEvents(res.data);
    setEventOpen(true);
  };

  const handleEventClose = () => {
    setEventOpen(false);
  };

  return (
    <>
      <ToastContainer />
      <EventDialog
        open={Eventopen}
        handleClose={handleEventClose}
        events={Events}
      />
      <Dialog open={Uploadopen} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Assign Auditor</DialogTitle>
        <div className="py-1 px-3">
          <div className="d-flex">
            <div className="col-5 mx-auto">
              <TextField
                className="w-100"
                type="date"
                name="Date"
                value={dateval}
                size="medium"
                onChange={(e) => setDate(e.target.value)}
              />
            </div>
            <div className="col-5 mx-auto">
              <FormControl className="w-100">
                <InputLabel id="demo-simple-select-label">
                  Available Auditor
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={selectedAuditor}
                  onChange={handleAuditorChange}
                  label="Auditor"
                >
                  <MenuItem value="">Select Auditor</MenuItem>
                  {auditors &&
                    auditors.map((auditor) => (
                      <MenuItem key={auditor._id} value={auditor._id}>
                        {auditor.firstName} {auditor.lastName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            <div className="col-2 mx-auto">
              <Button
                variant="contained"
                color="primary"
                onClick={handleAddAuditor}
              >
                Add Auditor
              </Button>
            </div>
          </div>
          <div>
            <h6 className="py-3">Scheduled Auditors:</h6>
            <ul>
              {scheduledAuditorsWithDates.map((auditor, index) => (
                <li key={index}>
                  {getAuditorName(auditor.auditorId)} - {auditor.scheduledDate}
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleRemoveAuditor(index)}
                  >
                    Remove
                  </Button>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <DialogActions>
          <div className="d-flex my-3">
            <Button className="cl-orange text-white" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              className="mx-3"
              onClick={scheduleAuditor}
            >
              Submit
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <div className="cso-clientlist-border p-5">
        <div className="px-2 add-title">
          <span className="">Client List</span>
        </div>
        {isAuthenticated === "Admin" && (
          <div className="d-flex justify-content-end mb-3">
            <>
              <Button
                variant="contained"
                className="btn-orange text-dark"
                onClick={handleExport}
              >
                Export
              </Button>
              <Button className="btn-green text-white" onClick={ChangeFilter}>
                {filter ? "Close Filter" : "Show Filter"}
              </Button>
            </>
          </div>
        )}

        {filter && <PlannerFilter />}
        {viewClient ? (
          <>
            <div className="d-flex justify-content-end">
              <Button
                onClick={CloseclientView}
                variant="contained"
                className="btn-orange"
              >
                Close
              </Button>
            </div>
            <AddClientForm action="View" />
          </>
        ) : (
          <Card>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    {isAuthenticated === "Admin" && (
                      <TableCell
                        className="cl-green text-dark"
                        style={{ padding: "5px" }}
                      >
                        <Checkbox
                          checked={selectAllChecked}
                          onChange={handleSelectAllCheckboxChange}
                        />
                      </TableCell>
                    )}

                    <TableCell className="cl-green text-dark">
                      Client Id
                    </TableCell>
                    <TableCell className="cl-green text-dark">
                      Company Name
                    </TableCell>
                    <TableCell className="cl-green text-dark">
                      Technical Main Code
                    </TableCell>
                    <TableCell className="cl-green text-dark">
                      Technical Sub Code
                    </TableCell>
                    <TableCell className="cl-green text-dark">Scheme</TableCell>
                    <TableCell className="cl-green text-dark">
                      Location
                    </TableCell>
                    <TableCell className="cl-green text-dark" align="center">
                      Status
                    </TableCell>
                    <TableCell className="cl-green text-dark" align="center">
                      View
                    </TableCell>
                    <TableCell className="cl-green text-dark" align="center">
                      Events
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {PLANNERVIEWCLIENTLIST ? (
                    PLANNERVIEWCLIENTLIST?.map((row, index) => (
                      <TableRow key={index}>
                        {isAuthenticated === "Admin" && (
                          <TableCell padding="checkbox">
                            <Checkbox
                              checked={isChecked[row._id] || false}
                              onChange={(event) =>
                                handleCheckboxChange(event, row._id)
                              }
                            />
                          </TableCell>
                        )}

                        <TableCell>{row.clientId}</TableCell>
                        <TableCell>{row.companyName}</TableCell>
                        <TableCell>{row.technicalCode.mainCode}</TableCell>
                        <TableCell>{row.technicalCode.subCode}</TableCell>
                        <TableCell>{row.standardsSchemeType}</TableCell>
                        <TableCell>
                          {row.state} / {row.country}
                        </TableCell>
                        <TableCell align="center">{row.status.type}</TableCell>
                        <TableCell
                          align="center"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handleViewClient(row._id);
                          }}
                        >
                          <Iconify icon={"raphael:view"} sx={{ mr: 2 }} />
                        </TableCell>
                        <TableCell
                          align="center"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            handleEventClickOpen(row._id);
                          }}
                        >
                          <Iconify icon={"raphael:view"} sx={{ mr: 2 }} />
                        </TableCell>
                        {/* <TableCell align="center">
                         {row.status === "approved" ? (
                           <Iconify
                             style={{ cursor: "pointer" }}
                             className="cursor-pointer"
                             icon={"pajamas:assignee"}
                             onClick={() => {
                               handleClickOpen();
                               setspecificUser(row._id);
                             }}
                           />
                         ) : (
                           "-"
                         )}
                       </TableCell> */}
                      </TableRow>
                    ))
                  ) : (
                    <h6 className="text-center my-3 w-100">No Data</h6>
                  )}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="d-flex flex-wrap justify-content-end m-3">
              <FormControl variant="outlined" size="small">
                <InputLabel id="limit-label">Limit</InputLabel>
                <Select
                  labelId="limit-label"
                  id="limit-select"
                  value={limit}
                  onChange={handleLimitChange}
                >
                  <MenuItem value={5}>5</MenuItem>
                  <MenuItem value={10}>10</MenuItem>
                  <MenuItem value={15}>15</MenuItem>
                </Select>
              </FormControl>
              <Button onClick={handlePrevPage} disabled={currentPage === 1}>
                <Iconify icon={"grommet-icons:previous"} />
              </Button>
              <Button
                onClick={handleNextPage}
                disabled={currentPage === totalPages}
              >
                <Iconify icon={"grommet-icons:next"} />
              </Button>
            </div>
          </Card>
        )}
      </div>
    </>
  );
}
