import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { setAuditList } from "src/redux/slices/PlannerSlice";
import { getData } from "../../webService/webService";

const AuditFilterAdmin = () => {
  const dispatch = useDispatch();
  const accessToken = sessionStorage.getItem("Token");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [limit, setlimit] = useState("");
  const [statusType, setstatusType] = useState("");
  const [auditorDesignation, setauditorDesignation] = useState("");
  const [correctiveActionStatus, setcorrectiveActionStatus] = useState("");

  const handleStartDateChange = (e) => {
    const selectedStartDate = e.target.value;
    if (endDate && endDate < selectedStartDate) {
      setEndDate("");
    }
    setStartDate(selectedStartDate);
  };
  const handlestatusTypeChange = (event) => {
    setstatusType(event.target.value);
  };
  const handlecorrectiveActionStatusTypeChange = (event) => {
    setcorrectiveActionStatus(event.target.value);
  };
  const handleauditorDesignationTypeChange = (event) => {
    setauditorDesignation(event.target.value);
  };
  const handleEndDateChange = (e) => {
    const selectedEndDate = e.target.value;
    if (startDate && startDate > selectedEndDate) {
      setStartDate("");
    }
    setEndDate(selectedEndDate);
  };
  const UserId = sessionStorage.getItem("UserId");
  const GetAuditData = () => {
    const params = {
      page: 1,
      limit: limit,
      reportstatusstartdate: startDate,
      reportstatusenddate: endDate,
      reportstatus: statusType,
      auditordesignation: auditorDesignation,
      correctiveactionstatus: correctiveActionStatus,
      scheme: "cb",
    };
    getData(`audit/all/norole/${UserId}`, params, accessToken)
      .then((res) => {
        dispatch(setAuditList(res.data[0]));
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handleSearch = () => {
    GetAuditData();
  };

  return (
    <div
      style={{
        width: "100%",
        justifyContent: "between",
        display: "flex",
        margin: "10px 0px",
        alignItems: "center",
      }}
    >
      <TextField
        style={{ width: "14%" }}
        size="small"
        label="Start Date"
        type="date"
        className="m-2"
        value={startDate}
        onChange={handleStartDateChange}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        style={{ width: "14%" }}
        size="small"
        label="End Date"
        type="date"
        className="m-2"
        value={endDate}
        onChange={handleEndDateChange}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <FormControl
        variant="outlined"
        className="m-2"
        size="small"
        style={{ width: "14%" }}
      >
        <InputLabel id="Status">Status</InputLabel>
        <Select
          labelId="Status"
          id="Status"
          value={statusType}
          onChange={handlestatusTypeChange}
          label="Status"
        >
          <MenuItem value="Auditor Assigned & Audit Intimation Letter Sent">
            Auditor Assigned & Audit Intimation Letter Sent
          </MenuItem>
          <MenuItem value="Audit Plan Sent">Audit Plan Sent</MenuItem>
          <MenuItem value="Audit Draft Report Sent">
            Audit Draft Report Sent
          </MenuItem>
          <MenuItem value="Audit report completed">
            Audit Report completed
          </MenuItem>
          <MenuItem value="Technical Report Review Pending">
            Technical Report Review Pending
          </MenuItem>
          <MenuItem value="Need Revision Completed">
            Need Revision Completed
          </MenuItem>
          <MenuItem value="Technical Report Review Completed">
            Technical Report Review Completed
          </MenuItem>
          <MenuItem value="Activity Completed">Activity Completed</MenuItem>
        </Select>
      </FormControl>

      <FormControl
        variant="outlined"
        className="m-2"
        size="small"
        style={{ width: "14%" }}
      >
        <InputLabel id="auditorDesignation">Auditor Designation</InputLabel>
        <Select
          labelId="auditorDesignation"
          id="auditorDesignation"
          value={auditorDesignation}
          onChange={handleauditorDesignationTypeChange}
          label="auditorDesignation"
        >
          <MenuItem value="Lead Auditor">Lead Auditor</MenuItem>
          <MenuItem value="Technical Expert">Technical Expert</MenuItem>
          <MenuItem value="Surprise Auditor">Surprise Auditor</MenuItem>
          <MenuItem value="Auditor and Trainee">Auditor and Trainee</MenuItem>
          <MenuItem value="Observer">Observer</MenuItem>
        </Select>
      </FormControl>

      <FormControl
        variant="outlined"
        className="m-2"
        size="small"
        style={{ width: "14%" }}
      >
        <InputLabel id="correctiveActionStatus">
          Corrective Action Status
        </InputLabel>
        <Select
          labelId="correctiveActionStatus"
          id="correctiveActionStatus"
          value={correctiveActionStatus}
          onChange={handlecorrectiveActionStatusTypeChange}
          label="correctiveActionStatus"
        >
          <MenuItem value="Pending">Pending </MenuItem>
          <MenuItem value="Accepted">Accepted</MenuItem>
          <MenuItem value="Rejected">Rejected</MenuItem>
          <MenuItem value="Cancelled">Cancelled</MenuItem>
        </Select>
      </FormControl>

      <TextField
        style={{ width: "14%" }}
        size="small"
        label="Limit"
        className="m-2"
        type="number"
        onChange={(e) => setlimit(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <Button
        className="btn-green m-2"
        style={{ width: "14%" }}
        onClick={handleSearch}
      >
        Search
      </Button>
    </div>
  );
};

export default AuditFilterAdmin;
