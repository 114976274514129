import { Helmet } from "react-helmet-async";
// @mui
import { Container } from "@mui/material";
import CertificateTable from "src/components/Certificate/CertificateTable";

// ----------------------------------------------------------------------

export default function CSOCertificate() {
  return (
    <>
      <Helmet>
        <title> Certificate </title>
      </Helmet>

      <Container maxWidth="xl">
          <CertificateTable/>
      </Container>
    </>
  );
}
