import { createSlice } from "@reduxjs/toolkit";

const SpecificClientSlice = createSlice({
  name: "SpecificClient",
  initialState: {
    Basic: "",
    Advance: "",
    ClientBasicId:"",
  },
  reducers: {
    setBasic: (state, action) => {
      state.Basic = action.payload;
    },
    setAdvance: (state, action) => {
      state.Advance = action.payload;
    },
    setClientBasicId: (state, action) => {
      state.ClientBasicId = action.payload;
    },
  },
});

export const { setBasic, setAdvance,setClientBasicId } = SpecificClientSlice.actions;
export default SpecificClientSlice.reducer;
