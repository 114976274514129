import React, { useEffect, useState } from "react";
import { TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { setTransferSA1Data } from "../../redux/slices/FeeSlice";

const TransferSA1Form = () => {
  const dispatch = useDispatch();
  const [transferSA1, setTransferSA1] = useState({
    administrationFee: "",
    totalContractFee: "",
    surveillanceAudit: [
      {
        surveillanceAuditNumber: 1,
        manDay: {
          year: 1,
          onSite: "",
          offSite: "",
          fee: "",
          totalManDays: "",
        },
        totalFee: "",
      },
      {
        surveillanceAuditNumber: 2,
        manDay: {
          year: 2,
          onSite: "",
          offSite: "",
          fee: "",
          totalManDays: "",
        },
        totalFee: "",
      },
    ],
    transferCertification: [
      {
        year: 1,
        fee: "",
      },
    ],
    auditTypeStates: [
      {
        year: 1,
        name: "Transfer Certification in SA1",
      },
    ],
  });

  const selectedTransferSA1 = useSelector((state) => state.Fee.TransferSA1);

  const calculateTotalManDays = (onSite, offSite) => {
    return (parseInt(onSite) || 0) + (parseInt(offSite) || 0);
  };

  const sumFees = (updatedTransferSA1) => {
    const YearOne =
      parseInt(updatedTransferSA1.administrationFee) +
      parseInt(updatedTransferSA1.transferCertification[0].fee) +
      parseInt(updatedTransferSA1.surveillanceAudit[0].manDay.fee);
    const YearTwo = parseInt(
      updatedTransferSA1.surveillanceAudit[1].manDay.fee
    );

    const totalManDays1 = calculateTotalManDays(
      updatedTransferSA1.surveillanceAudit[0].manDay.onSite,
      updatedTransferSA1.surveillanceAudit[0].manDay.offSite
    );
    const totalManDays2 = calculateTotalManDays(
      updatedTransferSA1.surveillanceAudit[1].manDay.onSite,
      updatedTransferSA1.surveillanceAudit[1].manDay.offSite
    );

    const surveillanceAudit1TotalFee = YearOne;
    const surveillanceAudit2TotalFee = YearTwo;
    const totalContractFee =
      surveillanceAudit1TotalFee + surveillanceAudit2TotalFee;

    return {
      ...updatedTransferSA1,
      surveillanceAudit: [
        {
          ...updatedTransferSA1.surveillanceAudit[0],
          totalFee: surveillanceAudit1TotalFee,
          manDay: {
            ...updatedTransferSA1.surveillanceAudit[0].manDay,
            totalManDays: totalManDays1,
          },
        },
        {
          ...updatedTransferSA1.surveillanceAudit[1],
          totalFee: surveillanceAudit2TotalFee,
          manDay: {
            ...updatedTransferSA1.surveillanceAudit[1].manDay,
            totalManDays: totalManDays2,
          },
        },
      ],
      totalContractFee: totalContractFee,
    };
  };

  const handleFormDataChange = (field, value, index, subField = "") => {
    let updatedTransferSA1;

    if (field === "transferCertification") {
      updatedTransferSA1 = {
        ...transferSA1,
        transferCertification: transferSA1.transferCertification.map(
          (item, i) =>
            i === index
              ? {
                  ...item,
                  fee: value,
                }
              : item
        ),
      };
    } else if (field === "surveillanceAudit") {
      const updatedSurveillanceAudit = transferSA1.surveillanceAudit.map(
        (item, i) => {
          if (i === index) {
            const updatedManDay = {
              ...item.manDay,
              [subField]: value,
            };
            if (subField === "onSite" || subField === "offSite") {
              updatedManDay.totalManDays = calculateTotalManDays(
                updatedManDay.onSite,
                updatedManDay.offSite
              );
            }
            return {
              ...item,
              manDay: updatedManDay,
            };
          }
          return item;
        }
      );
      updatedTransferSA1 = {
        ...transferSA1,
        surveillanceAudit: updatedSurveillanceAudit,
      };
    } else {
      updatedTransferSA1 = {
        ...transferSA1,
        [field]: value,
      };
    }

    const finalTransferSA1 = sumFees(updatedTransferSA1);
    setTransferSA1(finalTransferSA1);
    dispatch(setTransferSA1Data(finalTransferSA1));
  };

  useEffect(() => {
    if (selectedTransferSA1) {
      setTransferSA1(selectedTransferSA1);
    }
  }, [selectedTransferSA1]);

  return (
    <div>
      {transferSA1.surveillanceAudit.map((audit, index) => (
        <div key={index} className="d-flex flex-wrap align-items-center my-2">
          <p className="col-5">
            Surveillance Audit - {audit.surveillanceAuditNumber}
          </p>
          <div className="col-7">
            <TextField
              className="col-4"
              size="small"
              label={`OnSite ${audit.surveillanceAuditNumber}`}
              value={audit.manDay.onSite}
              onChange={(e) =>
                handleFormDataChange(
                  "surveillanceAudit",
                  e.target.value,
                  index,
                  "onSite"
                )
              }
            />
            <TextField
              size="small"
              className="col-4"
              label={`OffSite ${audit.surveillanceAuditNumber}`}
              value={audit.manDay.offSite}
              onChange={(e) =>
                handleFormDataChange(
                  "surveillanceAudit",
                  e.target.value,
                  index,
                  "offSite"
                )
              }
            />
            <TextField
              size="small"
              className="col-4"
              label={`Fee ${audit.surveillanceAuditNumber}`}
              value={audit.manDay.fee}
              onChange={(e) =>
                handleFormDataChange(
                  "surveillanceAudit",
                  e.target.value,
                  index,
                  "fee"
                )
              }
            />
            {/* <TextField
              className="mx-3"
              label={`Total Man Days ${audit.surveillanceAuditNumber}`}
              value={audit.manDay.totalManDays}
              disabled
            /> */}
          </div>
        </div>
      ))}
      <div className="d-flex flex-wrap col-12 my-3 align-items-end">
        <div className="col-4">
          <label htmlFor="transferCertificationFee" className="d-block">
            Transfer Certification Fee
          </label>
          <input
            id="transferCertificationFee"
            type="text"
            className="w-100"
            style={{
              borderRadius: "5px",
              border: "1px solid #d6d6d6",
              padding: "3px",
              background: "#f9fafb",
            }}
            value={transferSA1.transferCertification[0].fee}
            onChange={(e) =>
              handleFormDataChange(
                "transferCertification",
                e.target.value,
                0,
                "fee"
              )
            }
          />
        </div>
        <TextField
          size="small"
          className="col-4 px-2"
          label="Administration Fee"
          value={transferSA1.administrationFee}
          onChange={(e) =>
            handleFormDataChange("administrationFee", e.target.value)
          }
        />
        <TextField
          size="small"
          className="col-4"
          label="Total Contract Fee"
          value={transferSA1.totalContractFee}
          disabled
        />
      </div>
    </div>
  );
};

export default TransferSA1Form;
