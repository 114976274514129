import React, { useEffect, useState } from "react";
import "../Audit/Audit.css";
import {
  deleteData,
  getData,
  patchData,
  postData,
} from "src/webService/webService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import COMMONDOCUMENTSROUTES from "src/API_Routes/CommonDocument/CommonDocument";
import {
  Button,
  Card,
  FormControl,
  InputLabel,
  MenuItem,
  FormControlLabel,
  Radio,
  Select,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  DialogActions,
  Popover,
  IconButton,
} from "@mui/material";
import Iconify from "../../iconify/Iconify";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import EVENT_TRACKING_ROUTES from "src/API_Routes/EventTracking/EventTracking_Routers";
import CLIENTROUTES from "src/API_Routes/Client/Client_Routers";
import { Schema } from "@mui/icons-material";
import { getFormattedIndiaTime } from "../../../utils/timeutils";
import { useDispatch } from "react-redux";

export default function AssignAudit({ Role }) {
  const dispatch = useDispatch();
  const accessToken = sessionStorage.getItem("Token");
  const UserId = sessionStorage.getItem("UserId");
  const userName = sessionStorage.getItem("UserName");
  const [selectedId, setSelectedId] = useState(null);
  const [open, setOpen] = useState(null);

  const handleOpenMenu = (event, id, AuditorId) => {
    setOpen(event.currentTarget);
    setSelectedId(id);
    setAuditorId(AuditorId);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const [Uploadopen, setUploadopen] = useState(false);
  const handleClickOpen = () => {
    setUploadopen(true);
  };
  const handleClose = () => {
    setUploadopen(false);
    setDate();
    setSelectedClient();
    setSelectedAuditorHRA();
    setSelectedValue();
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(5);
  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };
  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };
  const handleLimitChange = (event) => {
    setCurrentPage(1);
    setLimit(event.target.value);
  };

  const [selectedAuditor, setSelectedAuditor] = useState();
  const [selectedAuditorHRA, setSelectedAuditorHRA] = React.useState("");
  const [selectedClient, setSelectedClient] = useState("");
  const [selectedClientList, setSelectedClientList] = useState();
  const [dateval, setDate] = useState();
  const [selectedValue, setSelectedValue] = useState("");
  const [scheduledAuditorsWithDates, setScheduledAuditorsWithDates] = useState(
    []
  );
  const [auditorDesignation, setauditorDesignation] = useState();
  const [auditDate, setAuditDate] = useState([]);
  const [params, setParams] = useState({
    auditDates: [],
  });
  const [clientBasicList, setClientBasicList] = useState();
  const [ress1, setRess1] = useState([]);
  const [ress2, setRess2] = useState([]);
  const [selectBasicId, setSelectBasicId] = useState();
  const [auditorId, setAuditorId] = useState();

  useEffect(() => {
    // Set auditorId only if it's not already set
    if (
      clientBasicList &&
      !auditorId &&
      clientBasicList.length > 0 &&
      clientBasicList[0].AuditorBasic_Data.length > 0
    ) {
      setAuditorId(clientBasicList[0].AuditorBasic_Data[0]._id);
    }
  }, [auditorId, clientBasicList]);
  console.log(clientBasicList, "clientbasic");

  console.log(selectedAuditor, "selectedAuditor");
  const handleChange = (event) => {
    setSelectedValue(event.target.value);
  };

  const GETProcessAssociate = async (page) => {
    const params = {
      page: page,
      limit: limit,
      scheme: "hra",
    };

    if (userName === "operationmanager") {
      try {
        const res = await getData(
          `audit/all/norole/${UserId}/`,
          params,
          accessToken
        );
        if (res.status === 200) {
          setClientBasicList(res.data[0].paginatedResults);
          setTotalPages(res.data[0].totalPages);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    } else {
      try {
        const res = await getData(
          `audit/all/processassociate/${UserId}/`,
          params,
          accessToken
        );
        if (res.status === 200) {
          setClientBasicList(res.data[0].paginatedResults);
          setTotalPages(res.data[0].totalPages);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    }
  };

  console.log(clientBasicList, "clientBasicList");

  const GETClientHRA = async () => {
    try {
      const res = await getData(
        `masters/audit/client/companynames?status=agreement updated&scheme=HRA`,
        null,
        accessToken
      );
      if (res.status === 200) {
        setSelectedClientList(res.data);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };
  useEffect(() => {
    GETClientHRA();
    GETProcessAssociate(currentPage);
  }, [currentPage, limit]);

  const handleAuditorChange = async (event) => {
    setSelectedAuditorHRA(event.target.value);
    const res1 = await getData(
      `users/basic/user/${event.target.value}`,
      null,
      accessToken
    );
    setAuditDate(res1.data.auditDates);
  };
  console.log(auditDate, "auditDates");

  const handleClientChange = (event) => {
    setSelectedClient(event.target.value);
    setSelectBasicId(event.target.value);
  };

  const fetchData = async () => {
    try {
      const res = await getData(
        `masters/audit/auditors/free?mandaytype=${selectedValue}&date=${dateval}&scheme=HRA`,
        null,
        accessToken
      );
      if (res.status === 200) {
        const combinedAuditors = [
          ...res.data.AuditorsFreeOnSpecificDate,
          ...res.data.AuditorsFreeOnSpecificDateAsPerManday,
        ];
        setSelectedAuditor(combinedAuditors);
        // setSelectedAuditor(res.data.AuditorsFreeOnSpecificDate);
        // console.log(res, "res");
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };
  useEffect(() => {
    if (dateval) {
      fetchData();
    }
  }, [dateval]);

  const getTodayDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Add leading zero
    const day = String(today.getDate()).padStart(2, "0"); // Add leading zero
    return `${year}-${month}-${day}`;
  };

  const handleAddAuditor = async () => {
    if (selectedAuditor && dateval) {
      // Check if the selected auditor is already assigned
      const isAuditorAssigned = scheduledAuditorsWithDates.some(
        (auditor) => auditor.auditorId === selectedAuditor
      );
      if (!isAuditorAssigned) {
        // Update auditDate state first
        const updatedAuditDate = [
          ...auditDate,
          { date: dateval, manDayType: selectedValue },
        ];
        setAuditDate(updatedAuditDate);

        // Update params with updated auditDates array
        const updatedParams = {
          ...params,
          auditDates: updatedAuditDate,
        };
        setParams(updatedParams);

        const res2 = await patchData(
          `users/basic/user/${selectedAuditorHRA}`,
          updatedParams,
          accessToken,
          dispatch
        );

        // Handle the response (res2) as needed
      } else {
        console.log("Auditor is already assigned.");
      }

      // postData(
      //     EVENT_TRACKING_ROUTES.CREATE_EVENT_HISTORY(selectedAuditor[0]._id),
      //     {
      //         description: `Auditor assigned`,
      //         performedBy: `${sessionStorage.getItem(
      //             "firstName"
      //         )} ${sessionStorage.getItem("lastName")}`,
      //     },
      //     accessToken
      // );
    }
  };

  const scheduleAuditor = async () => {
    const params = {
      clientId: selectedClient,
      processAssociateId: UserId,
      scheduledAuditorsWithDates: [
        {
          auditorId: selectedAuditorHRA,
          auditorDesignation: auditorDesignation,
          scheduledDate: dateval,
        },
      ],
      scheme: "HRA",
    };

    const res = await postData(`audit/schedule`, params, accessToken, dispatch);

    const formattedDate = getFormattedIndiaTime();
    const paramsStatus = {
      status: {
        type: "auditor assigned",
        updatedDate: formattedDate,
      },
    };

    await patchData(
      `${CLIENTROUTES.GETCLIENTBASIC}/${selectedClient}/basic`,
      paramsStatus,
      accessToken,
      dispatch
    );

    // setSelectedClient("");
    setUploadopen(false);
    setDate();
    setSelectedClient();
    setSelectedAuditorHRA();
    setSelectedValue();
    setauditorDesignation("")
    GETProcessAssociate(currentPage);
  };

  const handleDelete = async (event, clientBasicId, id, AuditorId) => {
    const confirmDelete = window.confirm("Are you sure you want to delete?");
    if (confirmDelete) {
      const formattedDate = getFormattedIndiaTime();
      const paramsStatus = {
        status: {
          type: "agreement updated",
          updatedDate: formattedDate,
        },
      };
      await patchData(
        `${CLIENTROUTES.GETCLIENTBASIC}/${clientBasicId}/basic`,
        paramsStatus,
        accessToken,
        dispatch
      );
      // Fetch res1 data
      const res1Data = await getData(
        `users/basic/user/${AuditorId}`,
        null,
        accessToken
      );
      setRess1(res1Data.data.auditDates || []);
      // Fetch res2 data
      const res2Data = await getData(`audit/${id}/schedule`, null, accessToken);
      setRess2(res2Data.data.scheduledAuditorsWithDates || []);
      const res = deleteData(`audit/${id}/schedule`, accessToken);
    }
  };

  useEffect(() => {
    if (ress1.length > 0 && ress2.length > 0) {
      const updatedRes1 = removeMatchingDates(ress1, ress2);
      console.log(updatedRes1, "updatedRes1");

      const res2 = patchData(
        `users/basic/user/${auditorId}`,
        { auditDates: updatedRes1 },
        accessToken,
        dispatch
      );

      // const res = deleteData(`audit/${selectedId}/schedule`, accessToken)

      GETProcessAssociate(currentPage);
    }
  }, [ress1, ress2]);

  function removeMatchingDates(ress1, ress2) {
    const scheduledDates = ress2.map((item) => item.scheduledDate);
    return ress1.filter((item) => !scheduledDates.includes(item.date));
  }

  const handleauditorDesignation = (event) => {
    setauditorDesignation(event.target.value);
  };

  return (
    <>
      <ToastContainer />

      <Dialog open={Uploadopen} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle>Assign Auditor</DialogTitle>
        <div className="py-1 px-3">
          <div className="d-flex flex-wrap col-12">
            <div className="col-5 mx-auto">
              <FormControlLabel
                value="ONE_DAY"
                control={<Radio />}
                label="One Day"
                checked={selectedValue === "ONE_DAY"}
                disabled={selectedValue ? true : false}
                onChange={handleChange}
              />
              <FormControlLabel
                value="FIRST_HALF"
                control={<Radio />}
                label="First Half"
                checked={selectedValue === "FIRST_HALF"}
                disabled={selectedValue ? true : false}
                onChange={handleChange}
              />
              <FormControlLabel
                value="SECOND_HALF"
                control={<Radio />}
                label="Second Half"
                checked={selectedValue === "SECOND_HALF"}
                disabled={selectedValue ? true : false}
                onChange={handleChange}
              />
            </div>
            <div className="col-5 mx-auto">
              {/* <TextField
                className="w-100"
                type="date"
                name="Date"
                value={dateval}
                disabled={dateval ? true : false}
                size="medium"
                onChange={(e) => setDate(e.target.value)}
              /> */}
              <TextField
                className="w-100"
                type="date"
                name="Date"
                disabled={dateval ? true : false}
                value={dateval}
                min={getTodayDate()}
                size="medium"
                onChange={(e) => setDate(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
                inputProps={{
                  min: getTodayDate(),
                }}
              />
            </div>
            {/* <div className="col-5 mx-auto mt-3">
                            <FormControl className="w-100">
                                <InputLabel id="demo-simple-select-label-1">
                                    Available Auditor
                                </InputLabel>
                                <Select
                                    labelId="demo-simple-select-label-1"
                                    id="demo-simple-select-1"
                                    value={selectedAuditorHRA}
                                    disabled={selectedAuditorHRA ? true : false}
                                    onChange={handleAuditorChange}
                                    label="Auditor"
                                >
                                    <MenuItem>Select Auditor</MenuItem>
                                    {selectedAuditor &&
                                        selectedAuditor.map((auditor) => (
                                            <MenuItem key={auditor._id} value={auditor._id}>
                                                {auditor.firstName} {auditor.lastName}
                                            </MenuItem>
                                        ))}
                                </Select>
                            </FormControl>
                        </div> */}
            <div className="col-5 mx-auto mt-3">
              <select
                id="demo-simple-select-1"
                value={selectedAuditorHRA}
                disabled={selectedAuditorHRA ? true : false}
                onChange={handleAuditorChange}
              >
                <option value="">Select Auditor</option>
                {selectedAuditor &&
                  selectedAuditor.map((auditor) => (
                    <option key={auditor._id} value={auditor._id}>
                      {auditor.firstName} {auditor.lastName}
                    </option>
                  ))}
              </select>
            </div>

            <div className="col-5 mx-auto mt-3">
              <FormControl className="w-100">
                <InputLabel id="demo-simple-select-label-2">
                  Client List
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label-2"
                  id="demo-simple-select-2"
                  value={selectedClient}
                  disabled={selectedClient ? true : false}
                  onChange={handleClientChange}
                  label="Client"
                >
                  <MenuItem>Select Client</MenuItem>
                  {selectedClientList &&
                    selectedClientList.map((Client) => (
                      <MenuItem key={Client._id} value={Client._id}>
                        {Client?.companyName}
                      </MenuItem>
                    ))}
                </Select>
              </FormControl>
            </div>
            <div className="col-5 mx-auto mt-3">
              <FormControl className="w-100">
                <InputLabel id="demo-simple-select-label-3">
                  Auditor Designation
                </InputLabel>
                <Select
                  labelId="demo-simple-select-label-3"
                  id="demo-simple-select-3"
                  value={auditorDesignation}
                  onChange={handleauditorDesignation}
                  label="Client"
                >
                  <MenuItem value="">
                    Select Auditor Designation
                  </MenuItem>
                  {[
                    "Lead Auditor",
                    "Technical Expert",
                    "Surprise Auditor",
                    "Auditor and Trainee",
                    "Observer",
                  ].map((item) => (
                    <MenuItem key={item} value={item}>
                      {item}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div className="col-5 mx-auto mt-3 d-flex justify-content-end">
              <Button
                variant="contained"
                className="mx-3"
                onClick={handleAddAuditor}
              >
                Add Auditor
              </Button>
            </div>
          </div>
        </div>
        <DialogActions>
          <div className="d-flex my-3">
            <Button className="cl-orange text-white" onClick={handleClose}>
              Cancel
            </Button>
            <Button
              variant="contained"
              className="mx-3"
              onClick={scheduleAuditor}
            >
              Submit
            </Button>
          </div>
        </DialogActions>
      </Dialog>
      <div className="Document-border p-5">
        <div className="px-2 add-title">
          <span className="">{Role}</span>
        </div>
        <div className="d-flex justify-content-end my-3">
          <Button
            className="cl-green text-white"
            onClick={() => {
              handleClickOpen();
            }}
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            Add Audit
          </Button>
        </div>

        <Card>
          <TableContainer sx={{ minWidth: 800 }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell className="cl-green text-dark">
                    Client Id
                  </TableCell>
                  <TableCell className="cl-green text-dark">
                    Company Name
                  </TableCell>
                  <TableCell className="cl-green text-dark">
                    Auditor Name
                  </TableCell>
                  <TableCell className="cl-green text-dark">Scheme</TableCell>
                  <TableCell className="cl-green text-dark">Location</TableCell>
                  <TableCell className="cl-green text-dark">
                    scheduledAuditorsWithDates
                  </TableCell>
                  <TableCell className="cl-green text-dark" align="center">
                    Status
                  </TableCell>
                  <TableCell className="cl-green text-dark" align="center">
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {clientBasicList ? (
                  clientBasicList?.map((row, index) => (
                    <TableRow key={index}>
                      <TableCell>{row.ClientBasic_Data[0]?.clientId}</TableCell>
                      <TableCell>
                        {row.ClientBasic_Data[0]?.companyName}
                      </TableCell>
                      <TableCell>
                        {row.AuditorBasic_Data[0].firstName}{" "}
                        {row.AuditorBasic_Data[0].lastName}
                      </TableCell>
                      <TableCell>
                        {row.ClientBasic_Data[0]?.standardsSchemeType}
                      </TableCell>
                      <TableCell>
                        {row.ClientBasic_Data[0]?.state} /{" "}
                        {row.ClientBasic_Data[0]?.country}
                      </TableCell>
                      <TableCell>
                        {row.scheduledAuditorsWithDates[0].scheduledDate}
                      </TableCell>
                      <TableCell align="center">
                        {row.ClientBasic_Data[0]?.status.type}
                      </TableCell>
                      {/* <TableCell align="left">
                                                <IconButton
                                                    size="large"
                                                    color="inherit"
                                                    onClick={(event) =>
                                                        handleOpenMenu(event, row._id, row.AuditorBasic_Data[0]._id)
                                                    }
                                                >
                                                    <Iconify
                                                        icon={"eva:more-vertical-fill"}
                                                    />
                                                </IconButton>
                                            </TableCell> */}
                      <TableCell>
                        <Button
                          onClick={(event) =>
                            handleDelete(
                              event,
                              row.ClientBasic_Data[0]?._id,
                              row._id,
                              row.AuditorBasic_Data[0]._id
                            )
                          }
                        >
                          Delete
                        </Button>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <h6 className="text-center my-3 w-100">No Data</h6>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <div className="d-flex flex-wrap justify-content-end m-3">
            <FormControl variant="outlined" size="small">
              <InputLabel id="limit-label">Limit</InputLabel>
              <Select
                labelId="limit-label"
                id="limit-select"
                value={limit}
                onChange={handleLimitChange}
              >
                <MenuItem value={5}>5</MenuItem>
                <MenuItem value={10}>10</MenuItem>
                <MenuItem value={15}>15</MenuItem>
              </Select>
            </FormControl>
            <Button onClick={handlePrevPage} disabled={currentPage === 1}>
              <Iconify icon={"grommet-icons:previous"} />
            </Button>
            <Button
              onClick={handleNextPage}
              disabled={currentPage === totalPages}
            >
              <Iconify icon={"grommet-icons:next"} />
            </Button>
          </div>
        </Card>
        {/* <Popover
                    open={Boolean(open)}
                    anchorEl={open}
                    onClose={handleCloseMenu}
                    anchorOrigin={{ vertical: "top", horizontal: "left" }}
                    transformOrigin={{ vertical: "top", horizontal: "right" }}
                    PaperProps={{
                        sx: {
                            p: 1,
                            width: 140,
                            "& .MuiMenuItem-root": {
                                px: 1,
                                typography: "body2",
                                borderRadius: 0.75,
                            },
                        },
                    }}
                >

                    <MenuItem sx={{ color: "error.main" }} onClick={handleDelete}>
                        <Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
                        Delete
                    </MenuItem>
                </Popover> */}
      </div>
    </>
  );
}
