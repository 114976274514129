export function getFormattedIndiaTime() {
  const currentDate = new Date();
  const options = {
    timeZone: "Asia/Kolkata",
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  };

  const indiaTimeString = currentDate.toLocaleString("en-GB", options);
  const [date, time] = indiaTimeString.split(", ");
  const [day, month, year] = date.split("/");
  const formattedDate = `${year}-${month}-${day}T${time}.000Z`;

  return formattedDate;
}
