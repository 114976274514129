import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AUDITROUTES from "src/API_Routes/Audit/Audit_routes";
import CLIENTROUTES from "src/API_Routes/Client/Client_Routers";
import EVENT_TRACKING_ROUTES from "src/API_Routes/EventTracking/EventTracking_Routers";
import { getData, patchData, postData } from "src/webService/webService";
import EMAIL_ROUTES from "../../API_Routes/Email/Email_Routers";
import CommonEmail from "../../Config/templates/html/CommonEmailTem";
import { fetchAllPlanner } from "../../CommonFunction/GetAllPlanner";
import { useDispatch, useSelector } from "react-redux";
import { fetchAllAuditor } from "../../CommonFunction/GetAllAuditor";
import { getFormattedIndiaTime } from "../../utils/timeutils";
import SERVER_URL from "../../utils/serverURL";

function ViewAudit({
  specificAudit,
  AuditList,
  BackToAudit,
  specificClient,
  setViewSpecificAudit,
}) {
  const formattedDate = getFormattedIndiaTime();
  const isAuthenticated = sessionStorage.getItem("isAuthenticated");
  const PlannerNameList = useSelector(
    (state) => state?.PlannerList.PlannerNameList
  );
  const AuditorNameList = useSelector(
    (state) => state?.AuditorList.AuditorNameList
  );
  const [targetAudit_1, settargetAudit_1] = useState();
  useEffect(() => {
    const findAuditData = AuditList.find(
      (audit) => audit._id === specificAudit
    );
    settargetAudit_1(findAuditData);
  }, [specificAudit]);
  const [certificateDecision, setCertificateDecision] = useState("");
  const accessToken = sessionStorage.getItem("Token");
  const dispatch = useDispatch();
  const [auditdata, setauditdata] = useState(null);
  const [correctiveaction, setcorrectiveaction] = useState(null);
  const [ReviewHistory, setReviewHistory] = useState();
  const [auditType, setAuditType] = useState();
  const [auditStatesAndStatuses, setauditStatesAndStatuses] = useState();
  const [reportStatus, setreportStatus] = useState();
  const [auditStep, setAuditStep] = useState();
  const [plannerId, setPlannerId] = useState();
  const [leadauditorid, setLeadAuditorId] = useState();
  const GETAUDITDATA = async () => {
    const res = await getData(
      `${AUDITROUTES.SCHEDULEGET(specificAudit)}`,
      null,
      accessToken
    );
    setAuditType(res.data.auditType);
    setAuditStep(res.data.activityStep);
    setreportStatus(res.data.reportStatus.name);
    setPlannerId(res.data.plannerId);
    const leadAuditor = res.data.scheduledAuditorsWithDates.find(
      (auditor) => auditor.auditorDesignation === "Lead Auditor"
    );
    const leadAuditorId = leadAuditor ? leadAuditor.auditorId : null;
    setLeadAuditorId(leadAuditorId);
    const getBasicid = res.data.clientId;
    const getClientBasic = await getData(
      `${CLIENTROUTES.GETSPECIFICUSERBASIC}${getBasicid}/basic`,
      null,
      accessToken
    );
    setReviewHistory(getClientBasic.data.reviewHistory);
    setauditStatesAndStatuses(getClientBasic.data.auditStatesAndStatuses);
    setauditdata(res.data);
  };

  const GETCORRECTIVEACTION = async () => {
    const res = await getData(
      `${AUDITROUTES.GETCORRECTIVEACTION(specificAudit)}`,
      null,
      accessToken
    );
    setcorrectiveaction(res.data);
  };

  const getPlannerPersonNameById = (id, PlannerList) => {
    if (id === null) {
      return { email: "cso@caussatec.com" };
    } else {
      const person = PlannerList.find(
        (plannerPerson) => plannerPerson._id === id
      );
      if (person) {
        return { email: person.email };
      } else {
        return null;
      }
    }
  };

  useEffect(() => {
    GETAUDITDATA();
    GETCORRECTIVEACTION();
    fetchAllPlanner(dispatch, accessToken);
    fetchAllAuditor(dispatch, accessToken);
  }, []);
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  console.log(correctiveaction, "correctiveaction");
  console.log(auditdata, "auditdata");

  const Auditcompleted = async (data) => {
    const targetAudit = AuditList.find((audit) => audit._id === specificAudit);
    const params = {
      reportStatus: {
        name:
          data === "completed"
            ? `Technical Report Review Completed`
            : data === "specialAudit"
            ? `Special Audit Needed`
            : `Technical Report Review Need Revision`,
        date: formattedDate,
      },
      activityStep:
        data === "completed" ? "8" : data === "specialAudit" ? "1" : "6",
      isSpecialAudit: data === "specialAudit" ? "true" : "false",
    };
    const endpoint = await patchData(
      `${AUDITROUTES.SCHEDULEPATCH(specificAudit)}`,
      params,
      accessToken,
      dispatch
    );
    await postData(
      EVENT_TRACKING_ROUTES.CREATE_EVENT_HISTORY(
        targetAudit.ClientBasic_Data[0]._id
      ),
      {
        description:
          data === "completed"
            ? `${auditType} Technical Report Review Completed`
            : data === "specialAudit"
            ? `${auditType} Special Audit Needed`
            : `${auditType} Technical Report Review Need Revision`,
        performedBy: `${sessionStorage.getItem(
          "firstName"
        )} ${sessionStorage.getItem("lastName")}`,
      },
      accessToken,
      dispatch
    );
    // Prepare data for patch request

    if (data === "completed") {
      const updatedreviewHistory = ReviewHistory.map((review) => {
        if (review.reviewType === "STAGE_1") {
          review.reviewStatus = "Completed";
          review.reviewDoneOn = formattedDate;
        } else if (review.reviewType === "STAGE_2") {
          review.reviewStatus = "Completed";
          review.reviewDoneOn = formattedDate;
        } else if (review.reviewType === "SA_1") {
          review.reviewStatus = "Completed";
          review.reviewDoneOn = formattedDate;
        } else if (review.reviewType === "SA_2") {
          review.reviewStatus = "Completed";
          review.reviewDoneOn = formattedDate;
        } else if (review.reviewType === "TRANSFER_AUDIT_IN_RA") {
          review.reviewStatus = "Completed";
          review.reviewDoneOn = formattedDate;
        } else {
          review.reviewStatus = "Completed";
          review.reviewDoneOn = formattedDate;
        }
      });
      let status;
      let auditStatesAndStatusesData;
      if (auditType === "STAGE_1") {
        status = {
          type: "stage 1 completed",
          updatedDate: formattedDate,
        };
        auditStatesAndStatusesData = {
          status: "Stage 1 Completed",
          date: formattedDate,
          isCurrentState: "true",
        };
      } else if (auditType === "STAGE_2") {
        status = {
          type: "stage 2 completed",
          updatedDate: formattedDate,
        };
        auditStatesAndStatusesData = {
          status: "Stage 2 Completed",
          date: formattedDate,
          isCurrentState: "true",
        };
      } else if (auditType === "SA_1") {
        status = {
          type: "SA 1 Completed",
          updatedDate: formattedDate,
        };
        auditStatesAndStatusesData = {
          status: "SA1 Completed",
          date: formattedDate,
          isCurrentState: "true",
        };
      } else if (auditType === "SA_2") {
        status = {
          type: "SA 2 Completed",
          updatedDate: formattedDate,
        };
        auditStatesAndStatusesData = {
          status: "SA2 Completed",
          date: formattedDate,
          isCurrentState: "true",
        };
      } else if (auditType === "TRANSFER_AUDIT_IN_RA") {
        status = {
          type: "transfer in RA completed",
          updatedDate: formattedDate,
        };
        auditStatesAndStatusesData = {
          status: "Transfer in RA Completed",
          date: formattedDate,
          isCurrentState: "true",
        };
      } else if (auditType === "RECERTIFICATION") {
        status = {
          type: "re-certification completed",
          updatedDate: formattedDate,
        };
        auditStatesAndStatusesData = {
          status: "Re Certification Completed",
          date: formattedDate,
          isCurrentState: "true",
        };
      } else {
        auditStatesAndStatusesData = {
          status: "Scope Extension Completed",
          date: formattedDate,
          isCurrentState: "true",
        };
      }
      const updatedauditStatesAndStatuses = [
        ...auditStatesAndStatuses,
        auditStatesAndStatusesData,
      ];
      let Params;
      if (auditType === "Scope Extension") {
        Params = {
          reviewHistory: ReviewHistory,
          auditStatesAndStatuses: updatedauditStatesAndStatuses,
        };
      } else {
        Params = {
          reviewHistory: ReviewHistory,
          status: status,
          auditStatesAndStatuses: updatedauditStatesAndStatuses,
        };
      }
      // Make the patch request
      const clientstatus = `${CLIENTROUTES.GETCLIENTADVANCE}${targetAudit.ClientBasic_Data[0]._id}/basic`;
      await patchData(clientstatus, Params, accessToken, dispatch);

      const HTML_DATA = {
        ClientName: targetAudit.ClientBasic_Data[0].companyName,
        ContactPerson: targetAudit.ClientBasic_Data[0].contactPerson,
        ClientID: targetAudit.ClientBasic_Data[0].clientId,
        AuditType: auditType,
        Message:
          "The above client report review is completed and the comments for the certification decision",
      };
      const emailRes = await postData(
        EMAIL_ROUTES.SEND_EMAIL_TEXT,
        {
          to: `cso@caussatec.com`,
          subject: "Technical Report Review Completed",
          html: CommonEmail(HTML_DATA),
        },
        accessToken,
        dispatch
      );

      // const HTML_DATA_1 = {
      //   ClientName: targetAudit.ClientBasic_Data[0].companyName,
      //   ContactPerson: targetAudit.ClientBasic_Data[0].contactPerson,
      //   ClientID: targetAudit.ClientBasic_Data[0].clientId,
      //   AuditType: auditType,
      //   Message: `
      //     This correspondence is to confirm that the audit activity has been finalized and approved. Attached is the final report from the audit conducted at your facility.
      //     We would like to thank you for using Intertek as your certification body and hope you have been pleased with the process.
      //     We welcome your feedback for our continual improvement.
      //     Should you wish to further expand your knowledge around management systems and certifications, please do access our resources and training site at www.caussatec.com
      //     `,
      // };
      // await postData(
      //   EMAIL_ROUTES.SEND_EMAIL_TEXT,
      //   {
      //     to: targetAudit.ClientBasic_Data[0].email,
      //     subject: "Reviewer Review Completed",
      //     html: CommonEmail(HTML_DATA_1),
      //   },
      //   accessToken
      // );
    } else if (data === "specialAudit") {
      const PlannerEmail = getPlannerPersonNameById(plannerId, PlannerNameList);
      const HTML_DATA = {
        ClientName: targetAudit.ClientBasic_Data[0].companyName,
        ContactPerson: targetAudit.ClientBasic_Data[0].contactPerson,
        ClientID: targetAudit.ClientBasic_Data[0].clientId,
        AuditType: auditType,
        Message: "The above client need special audit",
      };
      const emailRes = await postData(
        EMAIL_ROUTES.SEND_EMAIL_TEXT,
        {
          to: PlannerEmail.email,
          subject: "Special Audit Needed",
          html: CommonEmail(HTML_DATA),
        },
        accessToken,
        dispatch
      );
    } else if (data === "NeedRevision") {
      const AuditorEmail = getPlannerPersonNameById(
        leadauditorid,
        AuditorNameList
      );
      const HTML_DATA = {
        ClientName: targetAudit.ClientBasic_Data[0].companyName,
        ContactPerson: targetAudit.ClientBasic_Data[0].contactPerson,
        ClientID: targetAudit.ClientBasic_Data[0].clientId,
        AuditType: auditType,
        Message: `The above audit is need revision.
        Need Revision Comments : ${revisionMessage}`,
      };
      const emailRes = await postData(
        EMAIL_ROUTES.SEND_EMAIL_TEXT,
        {
          to: AuditorEmail.email,
          subject: "Need Revision",
          html: CommonEmail(HTML_DATA),
        },
        accessToken,
        dispatch
      );
    } else {
      console.log("No Data");
    }
    BackToAudit();
  };

  const imsData = specificClient && specificClient.ClientFormIMS_Data[0];
  const fsmsData = specificClient && specificClient.ClientFormFSMS_Data[0];
  const lastcifa =
    fsmsData?.CIFA_FileObjects[fsmsData?.CIFA_FileObjects?.length - 1] ||
    imsData?.CIFA_FileObjects[imsData?.CIFA_FileObjects?.length - 1];
  const renderTextField = (label, value) => (
    <div className="col-4 px-2">
      <p className="mb-0 fw-bold">{label}</p>
      <p>{value}</p>
    </div>
  );

  //Need Revision
  const [openDialog, setOpenDialog] = useState(false);
  const [revisionMessage, setRevisionMessage] = useState("");

  const handleDialogOpen = () => {
    setOpenDialog(true);
  };
  const handleDialogClose = (event, reason) => {
    if (reason !== "backdropClick") {
      setOpenDialog(false);
    }
  };
  const handleRevisionMessageChange = (event) =>
    setRevisionMessage(event.target.value);
  const handleRevisionSubmit = async () => {
    Auditcompleted("NeedRevision");
    handleDialogClose();
  };
  const handleDecisionChange = async (event) => {
    const decision = event.target.value;
    const userConfirmed = window.confirm(
      `Are you sure you want to certify the decision status - ${decision}?`
    );
    if (userConfirmed) {
      setCertificateDecision(decision);
      const params = {
        certificateDecisionStatus: decision,
        reportStatus: {
          name: "Certificate Decision Completed",
          date: formattedDate,
        },
        activityStep: "9",
      };
      const endpoint = await patchData(
        `${AUDITROUTES.SCHEDULEPATCH(specificAudit)}`,
        params,
        accessToken,
        dispatch
      );
      BackToAudit();
    }
  };
  return (
    <>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        maxWidth="xl"
        fullWidth
        BackdropProps={{
          onClick: handleDialogClose,
        }}
      >
        <DialogTitle>Need Revision</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the revision message below:
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="revisionMessage"
            label="Revision Message"
            type="text"
            fullWidth
            value={revisionMessage}
            onChange={handleRevisionMessageChange}
          />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleDialogClose}
            variant="contained"
            className="btn-orange"
          >
            Close
          </Button>
          <Button
            onClick={handleRevisionSubmit}
            variant="contained"
            className="btn-green"
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
      {auditdata?.isSpecialAudit && (
        <div className="mb-3">
          <span className="special-audit ms-0">Special Audit</span>
        </div>
      )}

      <p>
        <span className="fw-bold pe-4">Audit Step</span> : {auditStep}
      </p>
      <p className="mb-0">
        <span className="fw-bold pe-3">Audit Status</span> : {reportStatus}
      </p>

      <h6 className="my-3 fw-bold">Client Details</h6>
      <div className="d-flex flex-wrap">
        {renderTextField(
          "company Name",
          specificClient?.ClientBasic_Data[0]?.companyName
        )}
        {renderTextField("Email", specificClient?.ClientBasic_Data[0]?.email)}
        {renderTextField(
          "Mobile Number",
          specificClient?.ClientBasic_Data[0]?.phoneNumber
        )}
        {renderTextField(
          "ClientId",
          specificClient?.ClientBasic_Data[0]?.clientId
        )}
        {renderTextField(
          "Technical Main Code",
          specificClient?.ClientBasic_Data[0]?.technicalCode?.mainCode
        )}
        {renderTextField(
          "Technical Sub Code",
          specificClient?.ClientBasic_Data[0]?.technicalCode?.subCode
        )}
        {renderTextField(
          "Scheme",
          specificClient?.ClientBasic_Data[0]?.standardsSchemeType
        )}
        {renderTextField(
          "Address",
          specificClient?.ClientBasic_Data[0]?.address
        )}
        {renderTextField("State", specificClient?.ClientBasic_Data[0]?.state)}
        {renderTextField(
          "Country",
          specificClient?.ClientBasic_Data[0]?.country
        )}
        {renderTextField(
          "No Of Sites",
          specificClient?.ClientBasic_Data[0]?.noOfSites
        )}
        {renderTextField(
          "Contact Person Name",
          specificClient?.ClientBasic_Data[0]?.contactPerson
        )}
      </div>

      <div className="d-flex flex-wrap justify-content-start">
        {renderTextField(
          "standards",
          imsData
            ? imsData.standards[0]
            : fsmsData
            ? fsmsData.standards[0]
            : "No Data"
        )}
        {renderTextField(
          "Certification Body",
          imsData
            ? imsData.certificationBody
            : fsmsData
            ? fsmsData.certificationBody
            : "No Data"
        )}
        {renderTextField(
          "CAUSSA Contracting Office",
          imsData
            ? imsData.CAUSSA_ContractingOffice
            : fsmsData
            ? fsmsData.CAUSSA_ContractingOffice
            : "No Data"
        )}
        {renderTextField(
          "Accreditations",
          imsData
            ? imsData.accreditations[0]
            : fsmsData
            ? fsmsData.accreditations[0]
            : "No Data"
        )}
        {renderTextField(
          "Scope Of Certification",
          imsData
            ? imsData.scopeOfCertification
            : fsmsData
            ? fsmsData.scopeOfCertification
            : "No Data"
        )}
        {fsmsData
          ? renderTextField("No of HACCP Study", fsmsData.NoOfHACCP_study)
          : ""}

        {renderTextField(
          "Total No Of Employees",
          imsData
            ? imsData.totalNoOfEmployees
            : fsmsData
            ? fsmsData.totalNoOfEmployees
            : "No Data"
        )}
        {renderTextField(
          "No Of Effective FTE",
          imsData
            ? imsData.noOfEffectiveFTE
            : fsmsData
            ? fsmsData.noOfEffectiveFTE
            : "No Data"
        )}
        {renderTextField(
          "No Of Shifts",
          imsData
            ? imsData.noOfShifts
            : fsmsData
            ? fsmsData.noOfShifts
            : "No Data"
        )}
        {renderTextField(
          "Location",
          imsData
            ? imsData.locationMultiSiteInfo
            : fsmsData
            ? fsmsData.locationMultiSiteInfo
            : "No Data"
        )}
        {renderTextField(
          "HO/CentralOffice",
          imsData
            ? imsData.HO_or_CentralOffice
            : fsmsData
            ? fsmsData.HO_or_CentralOffice
            : "No Data"
        )}
        {renderTextField(
          "Surveillance Frequency",
          imsData
            ? imsData.surveillanceFrequency
            : fsmsData
            ? fsmsData.surveillanceFrequency
            : "No Data"
        )}
      </div>
      <div>
        <span className="fw-bold">CIFA</span> -{" "}
        <a href={`${SERVER_URL}/${lastcifa?.uri}`} target="_blank">
          {lastcifa?.uri}
        </a>
      </div>
      {auditType === "Scope Extension" && (
        <div>
          <span className="fw-bold">Scope Extension CIFA</span> -{" "}
          {fsmsData ? (
            <a
              href={`${SERVER_URL}/${fsmsData?.ScopeExtension_CIFA_FileObject?.uri}`}
              target="_blank"
            >
              {fsmsData?.ScopeExtension_CIFA_FileObject?.uri}
            </a>
          ) : (
            <a
              href={`${SERVER_URL}/${imsData?.ScopeExtension_CIFA_FileObject?.uri}`}
              target="_blank"
            >
              {imsData?.ScopeExtension_CIFA_FileObject?.uri}
            </a>
          )}
        </div>
      )}

      <hr />
      {correctiveaction &&
        correctiveaction.map((item, index) => (
          <>
            {/* <h4 className="my-3 fw-bold">Auditor Finding {index + 1}</h4>
            <div className="d-flex flex-wrap">
              <div className="col-6">
                <p>
                  <span className="fw-bold me-3"> Objective Evidence : </span>
                  {item.objectiveEvidence}
                </p>
                <p>
                  <span className="fw-bold me-3">
                    Auditor Issued The Finding :
                  </span>
                  {item?.auditorIssuedTheFinding}
                </p>
                <p>
                  <span className="fw-bold me-3">Finding :</span>
                  {item?.finding}
                </p>
                <p>
                  <span className="fw-bold me-3">Requirement Clause :</span>
                  {item?.requirementClause}{" "}
                </p>
                <p>
                  <span className="fw-bold me-3">Finding Due Date :</span>
                  {formatDate(item?.findingDueDate)}
                </p>
                <p>
                  <span className="fw-bold me-3">
                    Certification Expiry Date :
                  </span>
                  {formatDate(item?.certificationExpiryDate)}
                </p>
              </div>
              <div className="col-6">
                <p>
                  <span className="fw-bold me-3"> Classification :</span>
                  {item?.classification}
                </p>
                <p>
                  <span className="fw-bold me-3">Applicable Laws :</span>
                  {item?.applicableLaws}
                </p>
                <p>
                  <span className="fw-bold me-3"> Site Name : </span>
                  {item?.siteName}
                </p>
                <p>
                  <span className="fw-bold me-3">
                    Management System Documentation Reference :
                  </span>
                  {item?.managementSystemDocumentationReference}
                </p>
                <p>
                  <span className="fw-bold me-3">
                    Corrective Action Plan Due Date :
                  </span>
                  {formatDate(item?.correctiveActionPlanDueDate)}
                </p>
              </div>
            </div>

            <h4 className="my-3 fw-bold">Corrective Action </h4>
            <div className="d-flex">
              <div className="col-6">
                <p>
                  <span className="fw-bold me-3">Root Cause :</span>{" "}
                  {item?.rootCause}
                </p>
                <p>
                  <span className="fw-bold me-3">Correction : </span>
                  {item?.correction}
                </p>
                <p>
                  <span className="fw-bold me-3">Corrective Action</span>
                  {item?.correctiveAction}
                </p>
                <p>
                  <span className="fw-bold me-3">
                    Corrective Action Implementation Date
                  </span>
                  {item?.correctiveActionImplementationDate}
                </p>
                <p>
                  <span className="fw-bold me-3">Verification Data</span>
                  {item?.verificationData}
                </p>
              </div>
              <div className="col-6">
                {item.attachments &&
                  item.attachments.map((attachment, attachmentIndex) => (
                    <p key={attachmentIndex}>
                      <span className="fw-bold me-3">
                        Attachment {attachmentIndex + 1}
                      </span>
                      <a
                        target="_blank"
                        href={`${SERVER_URL}/${attachment.uri}`}
                      >
                        {attachment.uri}
                      </a>
                    </p>
                  ))}
                {item.auditorCommentsFileObjects &&
                  item.auditorCommentsFileObjects.map((file, fileIndex) => (
                    <p key={fileIndex}>
                      <span className="fw-bold me-3">
                        Auditor File {fileIndex + 1}
                      </span>
                      <a
                        target="_blank"
                        href={`${SERVER_URL}/${file.uri}`}
                      >
                        {file.uri}
                      </a>
                    </p>
                  ))}

                <p>
                  {" "}
                  <span className="fw-bold me-3">Auditor Comments</span>
                  {item?.auditorCommentsWithFiles?.text}
                </p>
              </div>
            </div> */}

            <h4 className="my-3 fw-bold">Auditor Finding {index + 1}</h4>
            <h5 className="my-3 fw-bold" style={{ color: "#89b72b" }}>
              Section - 1 - Finding Details
            </h5>
            <div className="d-flex flex-wrap justify-content-between">
              <div className="col-4">
                <p>
                  <span className="fw-bold me-3"> Finding Id : </span>
                  {item.findingNumberPrefix}-{item.findingNumberSubfix}
                </p>
              </div>
              <div className="col-4">
                <p>
                  <span className="fw-bold me-3"> Audit Date : </span>
                  {formatDate(item.auditDate)}
                </p>
              </div>
              <div className="col-4">
                <p>
                  <span className="fw-bold me-3">
                    {" "}
                    Auditor Issued The Finding :{" "}
                  </span>
                  {item.auditorIssuedTheFinding}
                </p>
              </div>
              <div className="col-4">
                <p>
                  <span className="fw-bold me-3"> Finding : </span>
                  {item.finding}
                </p>
              </div>
              <div className="col-4">
                <p>
                  <span className="fw-bold me-3"> Requirement Clause : </span>
                  {item.requirementClause[0]}
                </p>
              </div>
              <div className="col-4">
                <p>
                  <span className="fw-bold me-3"> Objective Evidence : </span>
                  {item.objectiveEvidence}
                </p>
              </div>
              <div className="col-4">
                <p>
                  <span className="fw-bold me-3"> Site Name : </span>
                  {item.siteName}
                </p>
              </div>
              <div className="col-4">
                <p>
                  <span className="fw-bold me-3"> Classification : </span>
                  {item.classification}
                </p>
              </div>
              <div className="col-4">
                <p>
                  <span className="fw-bold me-3">
                    {" "}
                    Management System Documentation Reference :{" "}
                  </span>
                  {item.managementSystemDocumentationReference}
                </p>
              </div>
              <div className="col-4">
                <p>
                  <span className="fw-bold me-3">
                    {" "}
                    Management System Documentation Reference :{" "}
                  </span>
                  {item.managementSystemDocumentationReference}
                </p>
              </div>
            </div>
            <h5 className="my-3 fw-bold" style={{ color: "#89b72b" }}>
              Section - 2 - Corrective Action Plan
            </h5>
            <div className="d-flex flex-wrap justify-content-between">
              <div className="col-4">
                <p>
                  <span className="fw-bold me-3">Root Cause :</span>
                  {item.rootCause}
                </p>
              </div>
              <div className="col-4">
                <p>
                  <span className="fw-bold me-3">Correction :</span>
                  {item.correction}
                </p>
              </div>
              <div className="col-4">
                <p>
                  <span className="fw-bold me-3">Corrective Action Plan :</span>
                  {item.correctiveActionPlan}
                </p>
              </div>
              <div className="col-4">
                <p>
                  <span className="fw-bold me-3">
                    Corrective Action Implementation Date :
                  </span>
                  {formatDate(item.correctiveActionImplementationDate)}
                </p>
              </div>
              <div className="col-4">
                <p>
                  <span className="fw-bold me-3">Responsible :</span>
                  {item.responsible}
                </p>
              </div>
              <div className="col-4">
                <p>
                  <span className="fw-bold me-3">Received Date :</span>
                  {formatDate(item.correctiveActionDate)}
                </p>
              </div>
              <div className="col-4">
                <p>
                  <span className="fw-bold me-3">
                    Plan For Verification Of Effectiveness :
                  </span>
                  {item.planForVerificationOfEffectiveness}
                </p>
              </div>
            </div>
            <h5 className="my-3 fw-bold" style={{ color: "#89b72b" }}>
              Section - 3 - Acceptance Of Corrective Action Plan
            </h5>
            <div className="d-flex flex-wrap justify-content-between">
              <div className="col-4">
                <p>
                  <span className="fw-bold me-3">
                    Corrective Action Plan Comments :
                  </span>
                  {item.correctiveActionPlanComments}
                </p>
              </div>
              <div className="col-4">
                <p>
                  <span className="fw-bold me-3">
                    Verification Of Corrective Taken :
                  </span>
                  {item.verificationOfCorrectiveTaken}
                </p>
              </div>
              <div className="col-4">
                <p>
                  <span className="fw-bold me-3">Accepted By :</span>
                  {item.acceptedBy}
                </p>
              </div>
              <div className="col-4">
                <p>
                  <span className="fw-bold me-3">Accepted Date:</span>
                  {formatDate(item.acceptedDate)}
                </p>
              </div>
            </div>
            <div className="d-flex flex-wrap justify-content-between">
              <div className="col-6">
                {item.attachments &&
                  item.attachments.map((attachment, attachmentIndex) => (
                    <p key={attachmentIndex}>
                      <span className="fw-bold me-3">
                        Attachment {attachmentIndex + 1}
                      </span>
                      <a
                        target="_blank"
                        href={`${SERVER_URL}/${attachment.uri}`}
                      >
                        {attachment.uri}
                      </a>
                    </p>
                  ))}
              </div>
              <div className="col-6">
                {item.auditorCommentsFileObjects &&
                  item.auditorCommentsFileObjects.map((file, fileIndex) => (
                    <p key={fileIndex}>
                      <span className="fw-bold me-3">
                        Auditor File {fileIndex + 1}
                      </span>
                      <a target="_blank" href={`${SERVER_URL}/${file.uri}`}>
                        {file.uri}
                      </a>
                    </p>
                  ))}
              </div>
            </div>

            <hr />
          </>
        ))}
      <div className="d-flex flex-wrap">
        <div className="col-6">
          <h4 className="my-4 fw-bold">Audit Draft & Other Audit Documents</h4>
          {auditdata &&
            auditdata?.auditDocuments.map((document, index) => (
              <p key={index}>
                <a target="_blank" href={`${SERVER_URL}/${document.uri}`}>
                  {document.filename}
                </a>
              </p>
            ))}
          {auditdata && auditdata?.auditDocuments.length === 0 ? (
            <p>No Data</p>
          ) : (
            ""
          )}
          <h4 className="my-4 fw-bold">Auditor List</h4>
          {targetAudit_1 &&
            targetAudit_1?.AuditorBasic_Data.map((Auditor, index) => (
              <div key={index}>
                <p>
                  <span className="fw-bold">Auditor Name : </span>{" "}
                  {Auditor.firstName}
                  {Auditor.lastName}
                </p>
                <p>
                  <span className="fw-bold">Auditor Mail :</span>{" "}
                  {Auditor.email}
                </p>
              </div>
            ))}
        </div>
        {reportStatus === "Technical Report Review Completed" && (
          <div className="col-6">
            <h4 className="my-4 fw-bold">Certificate Decision Status</h4>
            <select
              className="form-select"
              value={certificateDecision}
              onChange={handleDecisionChange}
            >
              <option value="">Select an option</option>
              <option value="Certify">Certify</option>
              <option value="Certified Rejected">Certified Rejected</option>
              <option value="Continued Maintenance Of Certification">
                Continued Maintenance Of Certification
              </option>
              <option value="Certificate Suspension">
                Certificate Suspension
              </option>
              <option value="Certificate Withdraw">Certificate Withdraw</option>
              <option value="Certificate Cancellation">
                Certificate Cancellation
              </option>
            </select>
          </div>
        )}
      </div>

      <div className="d-flex justify-content-end">
        <Button
          className="text-white mx-2"
          onClick={BackToAudit}
          style={{ color: "white", backgroundColor: "red", cursor: "pointer" }}
        >
          Close
        </Button>
        {(reportStatus === "Technical Report Review Pending" ||
          reportStatus === "Need Revision Completed" ||
          reportStatus === "Audit report completed" ||
          isAuthenticated === "Admin") && (
          <>
            <Button
              variant="contained"
              className="text-white mx-2"
              disabled={
                reportStatus === "Technical Report Review Pending" ||
                reportStatus === "Need Revision Completed"
                  ? false
                  : true
              }
              onClick={() => Auditcompleted("specialAudit")}
              style={{
                color: "white",
                backgroundColor: "#42591b",
                cursor: "pointer",
              }}
            >
              Need Special Audit
            </Button>
            <Button
              className="text-white mx-2"
              variant="contained"
              disabled={
                reportStatus === "Technical Report Review Pending" ||
                reportStatus === "Need Revision Completed"
                  ? false
                  : true
              }
              onClick={() => handleDialogOpen()}
              style={{
                color: "white",
                backgroundColor: "#1c290a",
                cursor: "pointer",
              }}
            >
              Need Revision
            </Button>
            <Button
              className="text-white mx-2"
              onClick={() => Auditcompleted("completed")}
              disabled={
                reportStatus === "Technical Report Review Pending" ||
                reportStatus === "Need Revision Completed" ||
                reportStatus === "Audit report completed"
                  ? false
                  : true
              }
              style={{
                color: "white",
                backgroundColor: "green",
                cursor: "pointer",
              }}
            >
              Technical Review Completed
            </Button>
          </>
        )}
      </div>
    </>
  );
}

export default ViewAudit;
