import React, { useEffect, useState } from "react";
import "../../Clients-list/Form/Form.css";
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import AdvanceDetails from "src/components/AdvanceDetails/AdvanceDetails";
import { getData, patchData, postData } from "src/webService/webService";
import CLIENTROUTES from "src/API_Routes/Client/Client_Routers";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { setBasic } from "src/redux/slices/SpecificClient";
import EVENT_TRACKING_ROUTES from "../../../../API_Routes/EventTracking/EventTracking_Routers";
export default function AddClientForm({ HandleAddClient, action }) {
  const isAuthenticated = sessionStorage.getItem("isAuthenticated");
  const scheme_session = sessionStorage.getItem("scheme");
  const USER_ID = sessionStorage.getItem("UserId");
  const dispatch = useDispatch();
  const SpecificClientBasic = useSelector(
    (state) => state.SpecificClient.Basic
  );
  const accessToken = sessionStorage.getItem("Token");
  const [leadGeneratedBy, setleadGeneratedBy] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [clientId, setClientId] = useState("");
  const [technicalCode, setTechnicalCode] = useState("");
  const [scheme, setScheme] = useState("");
  const [address, setAddress] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [noOfSites, setNoOfSites] = useState(1);
  const [contactPerson, setcontactPerson] = useState("");
  const [standardsSchemeType, setstandardsSchemeType] = useState("");
  const [code, setcode] = useState({
    mainCode: "",
    subCode: "",
  });
  const [advancedContact, setAdvancedContact] = useState(false);
  const [doorNo, setDoorNo] = useState('');
  const [streetName, setStreetName] = useState("");
  const [district, setDistrict] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [city, setCity] = useState('');

  const handlecode = (e) => {
    const { name, value } = e.target;
    setcode({
      ...code,
      [name]: value,
    });
  };
  const UpdateClientBasic = async (actionType) => {
    const Params = {
      companyName: companyName,
      email: email,
      phoneNumber: phoneNumber,
      technicalCode: code,
      address: address,
      state: state,
      country: country,
      noOfSites: noOfSites,
      contactPerson: contactPerson,
      standardsSchemeType:
        scheme_session === "CB" ? standardsSchemeType : "HRA",
      leadGeneratedBy: leadGeneratedBy,
      clientId: clientId,
      doorNo: doorNo,
      streetName: streetName,
      city: city,
      district: district,
      pincode: pinCode
    };

    if (action !== "Edit" && isAuthenticated !== "Admin") {
      Params.salesId = USER_ID;
    }

    if (action === "Edit") {
      await patchData(
        `${CLIENTROUTES.GETCLIENTBASIC}/${SpecificClientBasic._id}/basic`,
        Params,
        accessToken,
        dispatch
      )
        .then((res) => {
          dispatch(setBasic(res.data));
          setAdvancedContact(!advancedContact);
          postData(
            EVENT_TRACKING_ROUTES.CREATE_EVENT_HISTORY(SpecificClientBasic._id),
            {
              description: `Client Basic Edited`,
              performedBy: `${sessionStorage.getItem(
                "firstName"
              )} ${sessionStorage.getItem("lastName")}`,
            },
            accessToken,
            dispatch
          );
          console.log("res", res);
          if (actionType === "Edit") {
            HandleAddClient();
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      postData(CLIENTROUTES.GETCLIENTBASICUPDATE, Params, accessToken,dispatch)
        .then((res) => {
          dispatch(setBasic(res.data));
          setAdvancedContact(!advancedContact);
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    }
  };
  const HandleNextAdvancedContact = () => {
    UpdateClientBasic("Edit");
  };
  const HandleAdvancedContact = () => {
    UpdateClientBasic("");
  };
  const handleSchemeTypeChange = (e) => {
    setstandardsSchemeType(e.target.value);
  };

  useEffect(() => {
    if (SpecificClientBasic) {
      setCompanyName(SpecificClientBasic.companyName);
      setEmail(SpecificClientBasic.email);
      setClientId(SpecificClientBasic.clientId);
      setphoneNumber(SpecificClientBasic.phoneNumber);
      setcode({
        mainCode: SpecificClientBasic.technicalCode.mainCode,
        subCode: SpecificClientBasic.technicalCode.subCode,
      });
      setScheme(SpecificClientBasic.scheme);
      setAddress(SpecificClientBasic.address);
      setState(SpecificClientBasic.state);
      setCountry(SpecificClientBasic.country);
      setNoOfSites(SpecificClientBasic.noOfSites);
      setcontactPerson(SpecificClientBasic.contactPerson);
      setstandardsSchemeType(SpecificClientBasic.standardsSchemeType);
      setleadGeneratedBy(
        SpecificClientBasic.leadGeneratedBy
          ? SpecificClientBasic.leadGeneratedBy
          : ""
      );
      setDoorNo(SpecificClientBasic.doorNo);
      setStreetName(SpecificClientBasic.streetName);
      setDistrict(SpecificClientBasic.district);
      setCity(SpecificClientBasic.city);
      setPinCode(SpecificClientBasic.pincode)
    }
  }, [SpecificClientBasic]);

  return (
    <>
      <ToastContainer />
      <div className="py-5">
        <div className="add-Client-Form-border p-5">
          <div className="px-2 add-title">
            <span className="">
              {advancedContact
                ? ` ${action} Client - Advance`
                : ` ${action} Client - Basic`}
            </span>
          </div>
          {advancedContact ? (
            <AdvanceDetails
              HandleAdvancedContact={HandleAdvancedContact}
              HandleAddClient={HandleAddClient}
              action={action}
            />
          ) : (
            <>
              <div className="d-flex flex-wrap justify-content-start align-items-center">
                <div className="col-4 px-2 py-3">
                  <TextField
                    className="w-100"
                    name="ClientId"
                    label="Client Id"
                    value={clientId}
                    size="small"
                    onChange={(e) => setClientId(e.target.value)}
                    required
                  />
                </div>

                <div className="col-4 px-2 py-3">
                  <TextField
                    className="w-100"
                    name="CompanyName"
                    label="Company Name"
                    value={companyName}
                    size="small"
                    onChange={(e) => setCompanyName(e.target.value)}
                    required
                  />
                </div>
                <div className="col-4 px-2 py-3">
                  <TextField
                    className="w-100"
                    name="Email"
                    label="Email"
                    value={email}
                    size="small"
                    onChange={(e) => setEmail(e.target.value)}
                    required
                  />
                </div>
                {/* <div className="col-4 px-2 py-3">
                  <TextField
                    className="w-100"
                    name="TechnicalCode"
                    label="Technical Code"
                    value={technicalCode}
                    size="small"
                    onChange={(e) => setTechnicalCode(e.target.value)}
                    required
                  />
                </div> */}
                {/* <div className="col-4 px-2 py-3">
                  <TextField
                    className="w-100"
                    name="Scheme"
                    label="Scheme"
                    value={scheme}
                    size="small"
                    onChange={(e) => setScheme(e.target.value)}
                    required
                  />
                </div> */}
                {scheme_session === "HRA" ? (
                  <></>
                ) : (
                  // <div className="col-4 px-2 py-3">
                  //   <TextField
                  //     className="w-100"
                  //     name="Standards SchemeType"
                  //     label="Standards SchemeType"
                  //     value="HRA"
                  //     size="small"
                  //     onChange={(e) => setstandardsSchemeType("HRA")}
                  //     required
                  //   />
                  // </div>
                  <>
                    <div className="col-4 px-2 py-3">
                      <FormControl variant="outlined" className="w-100">
                        <InputLabel id="standardsSchemeType-label">
                          Standards SchemeType (FSMS / IMS )
                        </InputLabel>
                        <Select
                          style={{ padding: "8px!important" }}
                          labelId="standardsSchemeType-label"
                          id="standardsSchemeType"
                          value={standardsSchemeType}
                          onChange={handleSchemeTypeChange}
                          label="Standards SchemeType (FSMS / IMS)"
                        >
                          <MenuItem value="">
                            <em>None</em>
                          </MenuItem>
                          <MenuItem value="IMS">IMS</MenuItem>
                          <MenuItem value="FSMS">FSMS</MenuItem>
                        </Select>
                      </FormControl>
                    </div>

                    <div className="col-4 px-2 py-3">
                      <TextField
                        className="w-100"
                        name="mainCode"
                        label="Main Code"
                        value={code.mainCode}
                        size="small"
                        onChange={handlecode}
                        required
                      />
                    </div>

                    <div className="col-4 px-2 py-3">
                      <TextField
                        className="w-100"
                        name="subCode"
                        label="Sub Code"
                        value={code.subCode}
                        size="small"
                        onChange={handlecode}
                        required
                      />
                    </div>

                    <div className="col-12 px-2 py-3">
                      <TextField
                        className="w-100"
                        name="Address"
                        label="Address"
                        value={address}
                        size="small"
                        onChange={(e) => setAddress(e.target.value)}
                        required
                      />
                    </div>
                  </>
                )}

                {scheme_session === "HRA" ? (
                  <>
                    <div className="col-4 px-2 py-3">
                      <TextField
                        className="w-100"
                        name="DoorNo"
                        label="DoorNo"
                        value={doorNo}
                        size="small"
                        onChange={(e) => setDoorNo(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-4 px-2 py-3">
                      <TextField
                        className="w-100"
                        name="Street Name"
                        label="Street Name"
                        value={streetName}
                        size="small"
                        onChange={(e) => setStreetName(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-4 px-2 py-3">
                      <TextField
                        className="w-100"
                        name="City"
                        label="City"
                        value={city}
                        size="small"
                        onChange={(e) => setCity(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-4 px-2 py-3">
                      <TextField
                        className="w-100"
                        name="District"
                        label="District"
                        value={district}
                        size="small"
                        onChange={(e) => setDistrict(e.target.value)}
                        required
                      />
                    </div>
                    <div className="col-4 px-2 py-3">
                      <TextField
                        className="w-100"
                        name="PinCode"
                        label="PinCode"
                        value={pinCode}
                        type="number"
                        size="small"
                        onChange={(e) => setPinCode(e.target.value)}
                        required
                      />
                    </div>
                  </>) : (
                  <></>
                )}
                <div className="col-4 px-2 py-3">
                  <TextField
                    className="w-100"
                    name="State"
                    label="State"
                    value={state}
                    size="small"
                    onChange={(e) => setState(e.target.value)}
                    required
                  />
                </div>
                <div className="col-4 px-2 py-3">
                  <TextField
                    className="w-100"
                    name="Country"
                    label="Country"
                    value={country}
                    size="small"
                    onChange={(e) => setCountry(e.target.value)}
                    required
                  />
                </div>

                {scheme_session === "HRA" ? (
                  <></>
                ) : (
                  <div className="col-4 px-2 py-3">
                    <TextField
                      className="w-100"
                      name="Noofsites"
                      label="No Of Sites"
                      type="number"
                      value={noOfSites}
                      size="small"
                      onChange={(e) => setNoOfSites(e.target.value)}
                      required
                    />
                  </div>
                )}

                <div className="col-4 px-2 py-3">
                  <TextField
                    className="w-100"
                    name="Phone Number"
                    label="Phone Number"
                    value={phoneNumber}
                    size="small"
                    onChange={(e) => setphoneNumber(e.target.value)}
                    required
                  />
                </div>
                <div className="col-4 px-2 py-3">
                  <TextField
                    className="w-100"
                    name="contact Person Name"
                    label="contact Person Name"
                    value={contactPerson}
                    size="small"
                    onChange={(e) => setcontactPerson(e.target.value)}
                    required
                  />
                </div>
                <div className="col-4 px-2 py-3">
                  <TextField
                    className="w-100"
                    name="Lead Generated By"
                    label="lead Generated By"
                    value={leadGeneratedBy}
                    size="small"
                    onChange={(e) => setleadGeneratedBy(e.target.value)}
                    required
                  />
                </div>
              </div>
              {action !== "View" ? (
                <div className="d-flex flex-wrap justify-content-end">
                  <Button
                    variant="contained"
                    className="btn-orange"
                    onClick={HandleAddClient}
                  >
                    Cancel
                  </Button>
                  {/* {action !== "Edit" ? (
                    <Button
                      variant="contained"
                      className="btn-green"
                      onClick={HandleAdvancedContact}
                    >
                      Save & Exit
                    </Button>
                  ) : (
                    ""
                  )} */}
                  {action === "Edit" ? (
                    <Button
                      variant="contained"
                      className="btn-linear"
                      onClick={HandleAdvancedContact}
                    >
                      Next
                    </Button>
                  ) : (
                    <Button
                      variant="contained"
                      className="btn-linear"
                      onClick={HandleNextAdvancedContact}
                    >
                      Next
                    </Button>
                  )}
                </div>
              ) : (
                <div className="d-flex justify-content-end">
                  {isAuthenticated === "Sales" && (
                    <Button
                      variant="contained"
                      className="btn-orange"
                      onClick={HandleAddClient}
                    >
                      Cancel
                    </Button>
                  )}

                  <Button
                    variant="contained"
                    className="btn-linear"
                    onClick={() => {
                      setAdvancedContact(!advancedContact);
                    }}
                  >
                    Next
                  </Button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
}
