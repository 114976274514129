import { setReviewerNameList } from "../redux/slices/ReviewerListSlice";
import { getData } from "../webService/webService";
export const fetchAllReviewer = async (dispatch, accessToken) => {
  try {
    const res = await getData(
      "masters/user/basic/nameandid/Reviewer",
      null,
      accessToken
    );
    if (res.status === 200) {
      dispatch(setReviewerNameList(res.data));
      console.log(res.data);
    }
  } catch (error) {
    console.error("Error:", error);
  }
};
