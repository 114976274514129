import { Helmet } from "react-helmet-async";
// @mui
import { Container } from "@mui/material";
import ClientList from "src/components/Client/Client-list";
import ClientBasicAdvance from "src/components/ClientBasic_Advance/ClientBasic_Advance";
// ----------------------------------------------------------------------

export default function AuditorClients() {
  return (
    <>
      <Helmet>
        <title> Clients </title>
      </Helmet>

      <Container maxWidth="xl">
        {/* <ClientBasicAdvance/> */}
        <ClientList Role="Auditor" />
      </Container>
    </>
  );
}
