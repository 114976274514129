const data = [
  {
    id: "1",
    EmpID: "29034",
    Firstname: "Aswin",
    Lastname: "Kumar",
    Email: "Aswin@gmail.com",
  },
  {
    id: "2",
    EmpID: "29035",
    Firstname: "Ram",
    Lastname: "kk",
    Email: "Ram@gmail.com",
  },
  {
    id: "2",
    EmpID: "29036",
    Firstname: "Kannan",
    Lastname: "kv",
    Email: "Kannan@gmail.com",
  },
];
const users = data.map((item, index) => ({
  // id: item.Address,
  // name: item.id,
  // company: item.name,
  // isVerified: item.License,
  // status: item.Address,
  // role: item.contactNo,
  id: item.id,
  EmpID: item.EmpID,
  Firstname: item.Firstname,
  Lastname: item.Lastname,
  Email: item.Email,
}));

export default users;
