import { createSlice } from "@reduxjs/toolkit";

const Certificate = createSlice({
  name: "Certificate",
  initialState: {
    SpecificCertificate: "",
    AllCertificate: ""
  },
  reducers: {
    setSpecificCertificate: (state, action) => {
      state.SpecificCertificate = action.payload;
    },

    setAllCertificate: (state, action) => {
      state.AllCertificate = action.payload;
    },
  },
});

export const { setSpecificCertificate, setAllCertificate } = Certificate.actions;
export default Certificate.reducer;
