import { Helmet } from "react-helmet-async";
// @mui
import { Container } from "@mui/material";
import TrainingCertifications from "src/components/TrainingCertifications/TrainingCertifications";
// ----------------------------------------------------------------------

export default function AuditorTrainingCertificationHRA() {
  return (
    <>
      <Helmet>
        <title> Training Certification </title>
      </Helmet>

      <Container maxWidth="xl">
        <TrainingCertifications />
      </Container>
    </>
  );
}
