import { createSlice } from "@reduxjs/toolkit";

const UpcomingClient = createSlice({
  name: "UpcomingClient",
  initialState: {
    UpcomingClient: "",
  },
  reducers: {
    setUpcomingClient: (state, action) => {
      state.UpcomingClient = action.payload;
    },
  },
});

export const { setUpcomingClient } = UpcomingClient.actions;
export default UpcomingClient.reducer;
