import React, { useEffect, useState } from "react";
import {
    TextField,
    Radio,
    RadioGroup,
    InputLabel,
    FormControlLabel,
    FormControl,
    FormLabel,
    Button,
    Stack,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import { getData, postData, patchData } from "src/webService/webService";
import AUDITROUTES from "src/API_Routes/Audit/Audit_routes";
import checkListObject from "src/Config/HRA/CheckList/CheckListObject";
import CLIENTROUTES from "src/API_Routes/Client/Client_Routers";
import checkListMeat from "src/Config/HRA/CheckList/CheckListMeat";
import checkListSweet from "src/Config/HRA/CheckList/CheckListSweet";
import { useDispatch, useSelector } from "react-redux";
import { setNC } from "src/redux/slices/NC";

export default function HRA_ChecklistView() {
    const [checkListData, setCheckListData] = useState();
    const [auditCheckListData, setAuditCheckListData] = useState();
    const selectedAuditId = sessionStorage.getItem("AuditId");
    const [editMode, setEditMode] = useState(false);
    const [totalObtainedScore, setTotalObtainedScore] = useState(0);
    const [loading, setLoading] = useState(true); // Loading state
    const mainservice = sessionStorage.getItem("serviceType");
    const hraCheckList = sessionStorage.getItem('existingNCId')
    const auditId = sessionStorage.getItem("auditNCID")
    const dispatch = useDispatch();
    const hraId = sessionStorage.setItem("hraId", auditCheckListData?._id);


    const NC_Id = useSelector(
        (state) => state?.NC.Nc._id
    );

    const handleObtainedScoreChange = (event, index1) => {
        const { name, value } = event.target;
        const updatedCheckListData = { ...checkListData };
        updatedCheckListData.checkList[index1][name] = Number(value);
        setCheckListData(updatedCheckListData);
    };

    const handleTotalObtainedScoreChange = (event) => {
        const { name, value } = event.target;
        const updatedCheckListData = { ...checkListData };
        updatedCheckListData[name] = Number(value);
        setCheckListData(updatedCheckListData);
    };

    const handleRadioChange = (category, answerName, indexFromRadioButton) => {
        const updatedCheckListData = { ...checkListData };
        updatedCheckListData[category] = updatedCheckListData[category].map((item, index) => {
            if (index === indexFromRadioButton) {
                const newAnswers = {
                    majorNC: false,
                    yes: false,
                    minorNC: false,
                    notApplicable: false,
                    [answerName]: true,
                };

                return {
                    ...item,
                    answer: newAnswers,
                    obtainedScore: answerName === 'yes' ? item.score : 0,
                };
            }
            return item;
        });

        setCheckListData(updatedCheckListData);
    };

    useEffect(() => {
        if (checkListData) {
            const total = checkListData.checkList.reduce(
                (sum, item) => sum + (item.obtainedScore || 0),
                0
            );
            setTotalObtainedScore(total);
            setCheckListData((prevData) => ({
                ...prevData,
                totalObtainedScore: total,
            }));
        }
    }, [checkListData]);

    const SubmitOrEditModeCheck = async () => {
        const accessToken = sessionStorage.getItem("Token");
        const existingCheckList = await getData(
            AUDITROUTES.GET_CHECKLISTS(selectedAuditId),
            "",
            accessToken
        );
        // const updatedNC = await getData(
        //     AUDITROUTES.GET_NC(
        //         auditId

        //     ),
        //     '',
        //     accessToken
        // )

        if (existingCheckList?.data?.length > 0) {
            setCheckListData(existingCheckList?.data[0]?.audit);
            setAuditCheckListData(existingCheckList?.data[0]);
            setEditMode(true);
        } else {
            if (mainservice && mainservice === "Food Service Establishment" || mainservice === "Bakery") {
                setCheckListData(checkListObject);
                setEditMode(false);
            } else if (mainservice && mainservice === "Meat Retail") {
                setCheckListData(checkListMeat);
                setEditMode(false);
            } else if (mainservice && mainservice === "Sweet Shop") {
                setCheckListData(checkListSweet);
                setEditMode(false);
            } else {
                console.log("");
            }
        }
        setLoading(false); // Set loading to false after fetching data
    };

    useEffect(() => {
        SubmitOrEditModeCheck();
    }, [mainservice]); // Ensure this runs when mainService is set



    const handleSubmit = async (event) => {
        event.preventDefault();
        try {
            var auditData;
            const accessToken = sessionStorage.getItem("Token");
            let response;

            const updatedCheckListData = {
                ...checkListData,
                totalObtainedScore: totalObtainedScore,
            };
            const filterNC = updatedCheckListData.checkList.filter(item => item.answer.majorNC || item.answer.minorNC).map(item => item.question);
            console.log(filterNC, "filterNC");

            if (editMode) {
                response = await patchData(
                    AUDITROUTES.UPDATE_CHECKLIST(auditCheckListData?._id),
                    {
                        auditId: selectedAuditId,
                        audit: updatedCheckListData,
                    },
                    accessToken,
                    dispatch
                );

                auditData = response.data
                console.log(auditData, "auditData")
                dispatch(setNC(auditData));


                // console.log(updatedNC, "updatedNC");


                response = await patchData(
                    AUDITROUTES.UPDATE_NC(
                        hraCheckList
                    ),
                    {
                        auditId: selectedAuditId,
                        nc: filterNC.map(item => ({ requirement: item }))
                    },
                    accessToken,
                    dispatch
                )

            } else {
                response = await postData(
                    AUDITROUTES.CREATE_CHECKLIST,
                    {
                        audit: updatedCheckListData,
                        auditId: selectedAuditId,
                    },
                    accessToken,
                    dispatch
                );
                // const auditData = response.data
                // dispatch(setNC(auditData));
                const params = {
                    auditId: selectedAuditId,
                    nc: filterNC.map(item => ({ requirement: item }))
                };
                response = await postData(
                    AUDITROUTES.CREATE_NC,
                    params,
                    accessToken,
                    dispatch
                );

                auditData = response.data
                dispatch(setNC(auditData));

                const existingNC = await getData(
                    AUDITROUTES.GET_NC(
                        auditData._id
                    ),
                    '',
                    accessToken
                )

                sessionStorage.setItem('existingNCId', existingNC.data[0]._id);
            }



            switch (response?.data?.status || response?.status) {
                case 200:
                    toast.success("CHECKLIST Submitted Successfully", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    break;
                case 500:
                    toast.error("Error while submitting CREATE CHECKLIST in server", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    break;
                case 400:
                    toast.error("CREATE CHECKLIST error", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    break;
                case 404:
                    toast.error("CREATE CHECKLIST not found", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
                    break;
                default:
                    toast.error("Unexpected Error occurred", {
                        position: toast.POSITION.TOP_RIGHT,
                    });
            }
        } catch (error) {
            console.error(error);
            toast.error("Unexpected Error occurred", {
                position: toast.POSITION.TOP_RIGHT,
            });
        }
    };



    return (

        <Stack
            direction="row"
            justifyContent="center"
            alignItems="center"
            spacing={2}
        >
            <ToastContainer />
            {loading ? (
                <p>Loading...</p>
            ) : (
                <form onSubmit={handleSubmit}>
                    <h6 className="fw-bold">Audit Checklist</h6>

                    {/* Hygiene Rating Checklist Food Service Establishments/Bakery/Restaurant */}
                    <p>
                        Hygiene Rating Checklist Food Service Establishments/Bakery/Restaurant
                    </p>
                    <InputLabel>Total Score: {checkListData?.totalScore}</InputLabel>
                    <br />
                    {editMode && (
                        <>
                            <InputLabel htmlFor="totalObtainedScore">
                                Total Obtained Score
                            </InputLabel>
                            <TextField
                                name="totalObtainedScore"
                                value={totalObtainedScore}
                                disabled
                            />
                        </>
                    )}

                    {!editMode && (
                        <>
                            <InputLabel htmlFor="totalObtainedScore">
                                Total Obtained Score
                            </InputLabel>
                            <TextField
                                name="totalObtainedScore"
                                value={totalObtainedScore}
                                disabled
                            />
                        </>
                    )}

                    <br />
                    {checkListData &&
                        checkListData?.checkList?.map((item, index) => (
                            <div key={index}>
                                <FormControl component="fieldset">
                                    <FormLabel component="legend" id={"question" + index}>
                                        {item.question}
                                    </FormLabel>
                                    <RadioGroup
                                        aria-labelledby={"question" + index}
                                        value={
                                            Object.keys(item.answer).find((key) => item.answer[key]) || ""
                                        }
                                        onChange={(event) =>
                                            handleRadioChange("checkList", event.target.value, index)
                                        }
                                    >
                                        <div className="my-3">
                                            <FormControlLabel
                                                value="majorNC"
                                                control={<Radio />}
                                                label="Non-Compliance"
                                                disabled
                                            />
                                            <FormControlLabel
                                                value="yes"
                                                control={<Radio />}
                                                label="yes"
                                                disabled
                                            />
                                            <FormControlLabel
                                                value="minorNC"
                                                control={<Radio />}
                                                label="Partial Compliance"
                                                disabled
                                            />
                                            <FormControlLabel
                                                value="notApplicable"
                                                control={<Radio />}
                                                label="Not Applicable"
                                                disabled
                                            />
                                        </div>
                                    </RadioGroup>
                                </FormControl>
                                <br />
                                <InputLabel>Score: {item?.score}</InputLabel>
                                <br />
                                {editMode && (
                                    <>
                                        <InputLabel>Obtained Score: {item?.obtainedScore}</InputLabel>
                                    </>
                                )}
                                {!editMode && (
                                    <>
                                        <InputLabel>Obtained Score: {item?.obtainedScore}</InputLabel>

                                    </>
                                )}
                                <br />
                                {item.answer.majorNC && (
                                    <>
                                        <InputLabel>Remark</InputLabel>
                                        <TextField
                                            name="remark"
                                            value={item?.remark}
                                            onChange={(e) => {
                                                const updatedCheckListData = { ...checkListData };
                                                updatedCheckListData.checkList[index].remark = e.target.value;
                                                setCheckListData(updatedCheckListData);
                                            }}
                                        />
                                    </>
                                )}
                                <br />
                            </div>
                        ))}

                    <InputLabel>Total Obtained Score: {totalObtainedScore}</InputLabel>


                </form>
            )}
        </Stack>
    );
}
