import React, { useEffect, useState } from "react";
import {
  TextField,
  Radio,
  RadioGroup,
  InputLabel,
  FormControlLabel,
  FormControl,
  FormLabel,
  Button,
  Stack,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  getData,
  postData,
  patchData,
  FileUpload,
} from "src/webService/webService";
import AUDITROUTES from "src/API_Routes/Audit/Audit_routes";
import checkListObject from "src/Config/HRA/CheckList/CheckListObject";
import CLIENTROUTES from "src/API_Routes/Client/Client_Routers";
import checkListMeat from "src/Config/HRA/CheckList/CheckListMeat";
import checkListSweet from "src/Config/HRA/CheckList/CheckListSweet";
import { useDispatch, useSelector } from "react-redux";
import { setNC } from "src/redux/slices/NC";
import FILEROUTES from "src/API_Routes/File/File_Router";
import CheckListTemplate from "src/Config/templates/html/CheckListTemplate";
import { setCHECKLIST } from "src/redux/slices/CheckList";
import html2pdf from "html2pdf.js";
import SERVER_URL from "src/utils/serverURL";

export default function HRA_Checklist() {
  const [checkListData, setCheckListData] = useState();
  const [auditCheckListData, setAuditCheckListData] = useState();
  const selectedAuditId = sessionStorage.getItem("selectedAuditId");
  const [editMode, setEditMode] = useState(false);
  const [totalObtainedScore, setTotalObtainedScore] = useState(0);
  const [totalScore, setTotalScore] = useState(0);
  const [loading, setLoading] = useState(true); // Loading state
  const mainservice = sessionStorage.getItem("mainservice");
  const hraCheckList = sessionStorage.getItem("existingNCId");
  const auditId = sessionStorage.getItem("auditNCID");
  const dispatch = useDispatch();
  const hraId = sessionStorage.setItem("hraId", auditCheckListData?._id);
  const UserId = sessionStorage.getItem("UserId");
  const accessToken = sessionStorage.getItem("Token");
  const existingNC = useSelector((state) => state?.NC?.Nc?.nc)
  const existingNCID = useSelector((state) => state?.NC?.Nc?._id)
  const SpecificClientBasic = useSelector((state) => state?.SpecificClient?.Basic);
  const SpecificClientAdv = useSelector((state) => state?.SpecificClient?.Advance)

  // console.log(SpecificClientBasic, "specificClientBasic");
  // console.log(SpecificClientAdv, "SpecificClientAdv");


  // const handleRadioChange = (category, answerName, indexFromRadioButton) => {
  //   const updatedCheckListData = { ...checkListData };
  //   updatedCheckListData[category] = updatedCheckListData[category].map(
  //     (item, index) => {
  //       if (index === indexFromRadioButton) {
  //         const newAnswers = {
  //           majorNC: false,
  //           yes: false,
  //           minorNC: false,
  //           notApplicable: false,
  //           [answerName]: true,
  //         };

  //         const previousAnswer = Object.keys(item.answer).find(
  //           (key) => item.answer[key]
  //         );

  //         // Update obtained score based on the answer
  //         let obtainedScore = item.obtainedScore || 0;
  //         if (previousAnswer === "yes") {
  //           obtainedScore -= item.score;
  //         } else if (previousAnswer === "minorNC") {
  //           obtainedScore -= 1;
  //         } else if (previousAnswer === "notApplicable") {
  //           obtainedScore += item.score;
  //         }

  //         if (answerName === "yes") {
  //           obtainedScore += item.score;
  //         } else if (answerName === "minorNC") {
  //           obtainedScore += 1;
  //         } else if (answerName === "notApplicable") {
  //           obtainedScore -= item.score;
  //         }

  //         return {
  //           ...item,
  //           answer: newAnswers,
  //           obtainedScore: obtainedScore,
  //           // Preserve the existing evidenceFileObject
  //           evidenceFileObject: item.evidenceFileObject,
  //         };
  //       }
  //       return item;
  //     }
  //   );

  //   setCheckListData(updatedCheckListData);

  //   // Update total obtained score
  //   const totalObtained = updatedCheckListData.checkList.reduce(
  //     (sum, item) => sum + (item.obtainedScore || 0),
  //     0
  //   );
  //   setTotalObtainedScore(totalObtained);

  //   // Update total score
  //   const total = calculateTotalScore(updatedCheckListData.checkList);
  //   setTotalScore(total);
  // };


  // const calculateTotalScore = (checkList) => {
  //   return checkList.reduce((sum, item) => {
  //     return sum + (item.answer.notApplicable ? 0 : item.score);
  //   }, 0);
  // };

  // useEffect(() => {
  //   if (checkListData) {
  //     const totalObtained = checkListData.checkList.reduce(
  //       (sum, item) => sum + (item.obtainedScore || 0),
  //       0
  //     );
  //     setTotalObtainedScore(totalObtained);
  //     const total = calculateTotalScore(checkListData.checkList);
  //     setTotalScore(total);
  //     setCheckListData((prevData) => ({
  //       ...prevData,
  //       totalObtainedScore: totalObtained,
  //       totalScore: total,
  //     }));
  //   }
  // }, [checkListData]);

  const handleRadioChange = (category, answerName, indexFromRadioButton) => {
    const updatedCheckListData = { ...checkListData };
    updatedCheckListData[category] = updatedCheckListData[category].map(
      (item, index) => {
        if (index === indexFromRadioButton) {
          const newAnswers = {
            majorNC: false,
            yes: false,
            minorNC: false,
            notApplicable: false,
            [answerName]: true,
          };
  
          // Calculate obtained score changes only for yes and minorNC
          let obtainedScore = item.obtainedScore || 0;
          const previousAnswer = Object.keys(item.answer).find(
            (key) => item.answer[key]
          );
  
          if (previousAnswer === "yes") {
            obtainedScore -= item.score;
          } else if (previousAnswer === "minorNC") {
            obtainedScore -= 1;
          }
  
          if (answerName === "yes") {
            obtainedScore += item.score;
          } else if (answerName === "minorNC") {
            obtainedScore += 1;
          }
  
          return {
            ...item,
            answer: newAnswers,
            obtainedScore: obtainedScore,
            // Preserve the existing evidenceFileObject
            evidenceFileObject: item.evidenceFileObject,
          };
        }
        return item;
      }
    );
  
    setCheckListData(updatedCheckListData);
  
    // Update total obtained score
    const totalObtained = updatedCheckListData.checkList.reduce(
      (sum, item) => sum + (item.obtainedScore || 0),
      0
    );
    setTotalObtainedScore(totalObtained);
  
    // Update total score only, without affecting total obtained score
    const total = calculateTotalScore(updatedCheckListData.checkList);
    setTotalScore(total);
  };
  
  const calculateTotalScore = (checkList) => {
    return checkList.reduce((sum, item) => {
      return sum + (item.answer.notApplicable ? 0 : item.score);
    }, 0);
  };
  
  useEffect(() => {
    if (checkListData) {
      const totalObtained = checkListData.checkList.reduce(
        (sum, item) => sum + (item.obtainedScore || 0),
        0
      );
      setTotalObtainedScore(totalObtained);
  
      const total = calculateTotalScore(checkListData.checkList);
      setTotalScore(total);
  
      setCheckListData((prevData) => ({
        ...prevData,
        totalObtainedScore: totalObtained,
        totalScore: total,
      }));
    }
  }, [checkListData]);
  

  const SubmitOrEditModeCheck = async () => {
    const accessToken = sessionStorage.getItem("Token");
    const existingCheckList = await getData(
      AUDITROUTES.GET_CHECKLISTS(selectedAuditId),
      "",
      accessToken
    );

    if (existingCheckList?.data?.length > 0) {
      setCheckListData(existingCheckList?.data[0]?.audit);
      setAuditCheckListData(existingCheckList?.data[0]);
      setEditMode(true);
    } else {
      if (
        (mainservice && mainservice === "Food Service Establishment") ||
        mainservice === "Bakery"
      ) {
        setCheckListData(checkListObject);
        setEditMode(false);
      } else if (mainservice && mainservice === "Meat Retail") {
        setCheckListData(checkListMeat);
        setEditMode(false);
      } else if (mainservice && mainservice === "Sweet Shop") {
        setCheckListData(checkListSweet);
        setEditMode(false);
      } else {
        console.log("");
      }
    }
    setLoading(false); // Set loading to false after fetching data
  };

  useEffect(() => {
    SubmitOrEditModeCheck();
  }, [mainservice]); // Ensure this runs when mainService is set

  const convertToPDFAndSendToAPI = async (props) => {
    try {
      const pdfBlob = await html2pdf()
        .from(props)
        .set({ margin: [10, 20, 10, 20] })
        .output("blob");
      return pdfBlob;
    } catch (error) {
      console.error("Error while converting PDF or sending it to API:", error);
      throw error;
    }
  };


  // const handleFileChange = (e, index) => {
  //   const file = e.target.files[0];
  //   const updatedCheckListData = { ...checkListData };

  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       updatedCheckListData.checkList[index] = {
  //         ...updatedCheckListData.checkList[index],
  //         file,
  //         evidenceFileObject: updatedCheckListData.checkList[index].evidenceFileObject || {},
  //       };
  //       setCheckListData(updatedCheckListData);
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  const handleFileChange = async (e, index) => {
    const file = e.target.files[0];
    const updatedCheckListData = { ...checkListData };

    if (file) {
      console.log("File selected: ", file); // Log the selected file

      const formData = new FormData();
      formData.append("file", file);
      try {
        const uploadedFile = await FileUpload(
          FILEROUTES.UPLOAD + UserId,
          formData,
          dispatch
        );

        updatedCheckListData.checkList[index] = {
          ...updatedCheckListData.checkList[index],
          evidenceFileObject: uploadedFile,
        };

        setCheckListData(updatedCheckListData);
        console.log("File uploaded successfully: ", uploadedFile);
      } catch (error) {
        console.error("Error uploading file: ", error);
        toast.error("File upload failed", {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  };




  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      var auditData;
      let response;

      let updatedCheckListData = {
        ...checkListData,
        totalObtainedScore: totalObtainedScore,
      };



      console.log(updatedCheckListData, "updatedChecklistdata");

      let filterNC = [];
      updatedCheckListData.checkList
        .forEach((item, index) => {
          if (item.answer.majorNC) {
            filterNC.push({
              requirement: item.question, found: item.remark, hraClause: index + 1
            })
          }
        });

      console.log(filterNC, "filterNC");

      const fileUploadPromises = updatedCheckListData.checkList.map(
        async (item, index) => {
          if (item.file) {
            const formData = new FormData();
            formData.append("file", item.file);
            const uploadedFile = await FileUpload(
              FILEROUTES.UPLOAD + UserId,
              formData,
              dispatch
            );
            return uploadedFile;
          }
          return item.evidenceFileObject; // Preserve existing file object if no new file is uploaded
        }
      );

      const uploadedFiles = await Promise.all(fileUploadPromises);

      updatedCheckListData.checkList = updatedCheckListData.checkList.map(
        (item, index) => ({
          ...item,
          evidenceFileObject: uploadedFiles[index] || "",
        })
      );


      const checkList = CheckListTemplate(updatedCheckListData.checkList, updatedCheckListData.totalObtainedScore, updatedCheckListData.totalScore, SpecificClientBasic, SpecificClientAdv);

      const pdfBlob = await convertToPDFAndSendToAPI(checkList);
      const formData = new FormData();
      formData.append("file", pdfBlob, "generated.pdf");
      const uploadedFile = await FileUpload(
        FILEROUTES.UPLOAD + UserId,
        formData,
        dispatch
      );

      await patchData(
        `${AUDITROUTES.SCHEDULEPATCH(selectedAuditId)}`,
        {
          checkListFileObjects: [uploadedFile],
        },
        accessToken,
        dispatch
      );

      if (editMode) {

        // Convert filterNC to a Map for quick lookup
        const filterMap = new Map(filterNC?.map(item => [item.hraClause.toString(), item]));

        // Filter existingNC to only include items that are present in filterNC
        const filteredExistingList = existingNC.filter(item => filterMap.has(item.hraClause.toString()));

        // Identify new questions from filterNC that are not in filteredExistingList
        const newQuestionsList = filterNC.filter(item => !filteredExistingList.some(existingItem => existingItem.hraClause.toString() === item.hraClause.toString()));

        // Combine the filtered existing items and the new items
        const combinedQuestionsList = [...filteredExistingList, ...newQuestionsList];

        response = await patchData(
          AUDITROUTES.UPDATE_CHECKLIST(auditCheckListData?._id),
          {
            auditId: selectedAuditId,
            audit: updatedCheckListData,
          },
          accessToken,
          dispatch
        );

        auditData = response.data;


        response = await patchData(
          AUDITROUTES.UPDATE_NC(existingNCID),
          {
            auditId: selectedAuditId,
            nc: combinedQuestionsList,
          },
          accessToken,
          dispatch
        );
      } else {
        
        response = await postData(
          AUDITROUTES.CREATE_CHECKLIST,
          {
            audit: updatedCheckListData,
            auditId: selectedAuditId,
            // fileObject: uploadedFile,
          },
          accessToken,
          dispatch
        );

        auditData = response.data;
        dispatch(setCHECKLIST(auditData));

        response = await postData(
          AUDITROUTES.CREATE_NC,
          {
            auditId: selectedAuditId,
            nc: filterNC
          },
          accessToken,
          dispatch
        );
      }

      switch (response.status) {
        case 200:
        case 201:
          toast.success(
            "CHECKLIST " +
            (editMode ? "Updated" : "Submitted") +
            " Successfully",
            {
              position: toast.POSITION.TOP_RIGHT,
            }
          );
          break;
        case 500:
          toast.error("Error while submitting CREATE CHECKLIST in server", {
            position: toast.POSITION.TOP_RIGHT,
          });
          break;
        case 400:
          toast.error("CREATE CHECKLIST error", {
            position: toast.POSITION.TOP_RIGHT,
          });
          break;
        case 404:
          toast.error("CREATE CHECKLIST not found", {
            position: toast.POSITION.TOP_RIGHT,
          });
          break;
        default:
          toast.error("Unexpected Error occurred", {
            position: toast.POSITION.TOP_RIGHT,
          });
      }
    } catch (error) {
      console.error(error);
      toast.error("Unexpected Error occurred", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };


  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <ToastContainer />
      {loading ? (
        <p>Loading...</p>
      ) : (
        <form onSubmit={handleSubmit}>
          <h6 className="fw-bold">Audit Checklist</h6>

          <p>
            Hygiene Rating Checklist Food Service
            Establishments/Bakery/Restaurant
            {checkListData?.topic}
          </p>
          <InputLabel>Total Score: {totalScore}</InputLabel>
          <br />
          {editMode && (
            <>
              <InputLabel htmlFor="totalObtainedScore">
                Total Obtained Score
              </InputLabel>
              <TextField
                name="totalObtainedScore"
                value={totalObtainedScore}
                disabled
              />
            </>
          )}

          {!editMode && (
            <>
              <InputLabel htmlFor="totalObtainedScore">
                Total Obtained Score
              </InputLabel>
              <TextField
                name="totalObtainedScore"
                value={totalObtainedScore}
                disabled
              />
            </>
          )}

          <br />
          {checkListData &&
            checkListData?.checkList?.map((item, index) => (
              <div key={index}>
                <FormControl component="fieldset">
                  <FormLabel component="legend" id={"question" + index}>
                    {index + 1}. {item.question}
                  </FormLabel>
                  <RadioGroup
                    aria-labelledby={"question" + index}
                    value={
                      Object.keys(item.answer).find(
                        (key) => item.answer[key]
                      ) || ""
                    }
                    onChange={(event) =>
                      handleRadioChange("checkList", event.target.value, index)
                    }
                  >
                    <div className="my-3">
                      <FormControlLabel
                        value="majorNC"
                        control={<Radio />}
                        label="Non-Compliance"
                      />
                      <FormControlLabel
                        value="yes"
                        control={<Radio />}
                        label="yes"
                      />
                      <FormControlLabel
                        value="minorNC"
                        control={<Radio />}
                        label="Partial Compliance"
                      />
                      <FormControlLabel
                        value="notApplicable"
                        control={<Radio />}
                        label="Not Applicable"
                      />
                    </div>
                  </RadioGroup>
                </FormControl>
                <br />
                <InputLabel>Score: {item?.score}</InputLabel>
                <br />
                {editMode && (
                  <>
                    <InputLabel>
                      Obtained Score: {item?.obtainedScore}
                    </InputLabel>
                  </>
                )}
                {!editMode && (
                  <>
                    <InputLabel>
                      Obtained Score: {item?.obtainedScore}
                    </InputLabel>
                  </>
                )}
                <br />
                <>
                  <InputLabel>Remark</InputLabel>
                  <TextField
                    name="remark"
                    value={item?.remark}
                    onChange={(e) => {
                      const updatedCheckListData = { ...checkListData };
                      updatedCheckListData.checkList[index].remark =
                        e.target.value;
                      setCheckListData(updatedCheckListData);
                    }}
                  />
                </>
                <br />

                <div>
                  {/* <input
                    type="file"
                    onChange={(e) => handleFileChange(e, index)}
                  />
                  {item.evidenceFileObject && (
                    <a
                      href={`${SERVER_URL}${item?.evidenceFileObject?.data?.uri}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View uploaded file
                    </a>
                  )} */}
                  <input
                    type="file"
                    onChange={(e) => handleFileChange(e, index)}
                  />
                  {item.evidenceFileObject && (
                    <a
                      href={`${SERVER_URL}${item?.evidenceFileObject?.data?.uri}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      View uploaded file
                    </a>
                  )}

                </div>
                <br />
              </div>
            ))}

          <InputLabel>Total Obtained Score: {totalObtainedScore}</InputLabel>

          {editMode && (
            <>
              <Button
                variant="contained"
                className="btn-orange"
                size="large"
                type="submit"
              >
                Update
              </Button>
            </>
          )}

          {!editMode && (
            <>
              <Button
                variant="contained"
                className="btn-orange"
                size="large"
                type="submit"
              >
                Submit
              </Button>
            </>
          )}
        </form>
      )}
    </Stack>
  );
}
