import React, { useEffect, useState } from "react";
import "../Skillset/Skillset.css";
import Iconify from "../iconify";
import { Button, TextField } from "@mui/material";
import {
  getData,
  postData,
  FileUpload,
  patchData,
} from "src/webService/webService";
import {
  Card,
  TableContainer,
  Table,
  TableRow,
  TableHead,
  TableCell,
  TableBody,
} from "@mui/material";
import USERSROUTES from "src/API_Routes/User/User_Routers";
import {
  setSpecificAuditor,
  setSpecificUser,
} from "src/redux/slices/UserDetailSlice";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import FILEROUTES from "src/API_Routes/File/File_Router";
import Scrollbar from "../scrollbar/Scrollbar";
import SERVER_URL from "../../utils/serverURL";

export default function Skillset() {
  const dispatch = useDispatch();
  const specificUserData = useSelector(
    (state) => state.userDetail.SpecificUser
  );
  const Audit_Details =
    specificUserData && [...specificUserData.auditDates].reverse();
  const specificAuditor = useSelector(
    (state) => state.userDetail.SpecificAuditor
  );
  const accessToken = sessionStorage.getItem("Token");
  const UserId = sessionStorage.getItem("UserId");
  const Role = sessionStorage.getItem("isAuthenticated");
  const [editAble, setEditAble] = useState(false);
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [employeeId, setemployeeId] = useState("");
  const [phoneNumber, setphoneNumber] = useState("");
  const [address, setaddress] = useState("");
  const [bloodType, setbloodType] = useState("");
  const [email, setemail] = useState("");
  const [jobTitle, setjobTitle] = useState("");
  const [languagesSpoken, setLanguagesSpoken] = useState([]);

  const [CVFileObject, setCVFileObject] = useState(null);
  const [CVFileObjectFinal, setCVFileObjectFinal] = useState(null);
  const [CVFile, SetCVFile] = useState("");
  const [profilePicFileObject, setprofilePicFileObject] = useState(null);
  const [profilePicFileObjectFinal, setprofilePicFileObjectFinal] =
    useState(null);
  const [profilePic, SetprofilePic] = useState("");
  const [
    educationCertificatesFileObjects,
    seteducationCertificatesFileObjects,
  ] = useState([]);
  const [EducationCertificate, setEducationCertificate] = useState("");
  const [WorkHistory, setWorkHistory] = useState("");
  const [experienceWorkHistory, setexperienceWorkHistory] = useState([]);
  const [otherDocumentsFileObjects, setotherDocumentsFileObjects] = useState(
    []
  );
  const [otherDocuments, setotherDocuments] = useState("");

  //auditor specific
  const [alternateName, setalternateName] = useState("");
  const [managingOffice, setmanagingOffice] = useState("");
  const [supervisorInformation, setsupervisorInformation] = useState("");
  const [ID_Number, setID_Number] = useState("0");
  const [yearOfBirth, setyearOfBirth] = useState("");
  const [status, setstatus] = useState("");
  const [dateOfSignature, setdateOfSignature] = useState("");
  const [availability, setavailability] = useState("");
  const [languagesWritten, setlanguagesWritten] = useState("");
  const [comments, setcomments] = useState("");
  const [expirationDate, setexpirationDate] = useState("");

  const handleFileChange = (event, data) => {
    const file = event.target.files[0];
    if (data === "CV") {
      SetCVFile(file);
    } else if (data === "Education") {
      setEducationCertificate(file);
    } else if (data === "Profile") {
      SetprofilePic(file);
    } else {
      setotherDocuments(file);
    }
    event.target.value = null;
  };
  useEffect(() => {
    const uploadFile = async (
      file,
      setFileObject,
      setFileObjectVal,
      setFile
    ) => {
      if (file) {
        try {
          const formData = new FormData();
          formData.append("file", file);
          const res = await FileUpload(
            FILEROUTES.UPLOAD + UserId,
            formData,
            dispatch
          );
          setFileObject(res.data);
          setFileObjectVal(res.data);
          setFile("");
        } catch (error) {
          setFile("");
          console.error("Error:", error);
        }
      }
    };
    uploadFile(
      profilePic,
      setprofilePicFileObjectFinal,
      setprofilePicFileObject,
      SetprofilePic
    );
    uploadFile(CVFile, setCVFileObjectFinal, setCVFileObject, SetCVFile);
  }, [profilePic, CVFile]);
  useEffect(() => {
    const uploadFile = async (file, setFileObjects) => {
      if (file) {
        try {
          const formData = new FormData();
          formData.append("file", file);
          const res = await FileUpload(
            FILEROUTES.UPLOAD + UserId,
            formData,
            dispatch
          );
          setFileObjects((prevState) => [...prevState, res.data]);
          if (file === EducationCertificate) setEducationCertificate("");
          else setotherDocuments("");
        } catch (error) {
          if (file === EducationCertificate) setEducationCertificate("");
          else setotherDocuments("");
          console.error("Error:", error);
        }
      }
    };

    if (EducationCertificate) {
      uploadFile(EducationCertificate, seteducationCertificatesFileObjects);
    }

    if (otherDocuments) {
      uploadFile(otherDocuments, setotherDocumentsFileObjects);
    }
  }, [EducationCertificate, otherDocuments]);
  const getAuditorData = async () => {
    const auditorRes = await getData(
      USERSROUTES.AUDITORGET(UserId),
      null,
      accessToken
    );
    dispatch(setSpecificAuditor(auditorRes.data));
    setalternateName(auditorRes.data.alternateName);
    setmanagingOffice(auditorRes.data.managingOffice);
    setsupervisorInformation(auditorRes.data.supervisorInformation);
    setID_Number(auditorRes.data.ID_Number);
    setyearOfBirth(formatDate(auditorRes.data.yearOfBirth));
    setstatus(auditorRes.data.status);
    setdateOfSignature(
      formatDate(auditorRes.data.contractInformation.dateOfSignature)
    );
    setcomments(auditorRes.data.contractInformation.comments);
    setexpirationDate(
      formatDate(auditorRes.data.contractInformation.expirationDate)
    );
    setavailability(auditorRes.data.availability);
    setlanguagesWritten(auditorRes.data.languagesWritten[0]);
  };
  useEffect(() => {
    if (Role === "Auditor") {
      getAuditorData();
    }
  }, []);

  const handleEditClick = () => {
    setEditAble(!editAble);
  };
  const GetUserBasicData = () => {
    getData(USERSROUTES.USERID + UserId, null, accessToken)
      .then((res) => {
        dispatch(setSpecificUser(res.data));
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("An error occurred. Please try again later.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };
  useEffect(() => {
    GetUserBasicData();
  }, []);
  useEffect(() => {
    if (specificUserData) {
      const {
        firstName = "",
        lastName = "",
        employeeId = "",
        address = "",
        phoneNumber = "",
        bloodType = "",
        email = "",
        jobTitle = "",
        languagesSpoken = "",
        CVFileObject = "",
        educationCertificatesFileObjects = "",
        experienceWorkHistory = "",
        otherDocumentsFileObjects = "",
        profilePicFileObject = "",
      } = specificUserData;

      setfirstName(firstName);
      setlastName(lastName);
      setemployeeId(employeeId);
      setaddress(address);
      setphoneNumber(phoneNumber);
      setbloodType(bloodType);
      setemail(email);
      setjobTitle(jobTitle);
      setLanguagesSpoken(languagesSpoken);
      setCVFileObject(CVFileObject);
      seteducationCertificatesFileObjects(educationCertificatesFileObjects);
      setexperienceWorkHistory(experienceWorkHistory);
      setotherDocumentsFileObjects(otherDocumentsFileObjects);
      setprofilePicFileObject(profilePicFileObject);
    }
  }, [specificUserData]);

  const UserDateUpdate = () => {
    const params = {
      employeeId: employeeId,
      firstName: firstName,
      lastName: lastName,
      email: email,
      bloodType: bloodType,
      role: Role,
      phoneNumber: phoneNumber,
      jobTitle: jobTitle,
      address: address,
      languagesSpoken: languagesSpoken,
      CVFileObject:
        CVFileObjectFinal === null ? CVFileObject : CVFileObjectFinal,
      educationCertificatesFileObjects: educationCertificatesFileObjects,
      experienceWorkHistory: experienceWorkHistory,
      otherDocumentsFileObjects: otherDocumentsFileObjects,
      profilePicFileObject: profilePicFileObjectFinal,
    };
    patchData(USERSROUTES.EDITUSER + UserId, params, accessToken, dispatch)
      .then((res) => {
        handleEditClick();
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const updateWorkHistory = () => {
    if (WorkHistory.trim() !== "") {
      setexperienceWorkHistory((prevArray) => [...prevArray, WorkHistory]);
      setWorkHistory("");
    }
  };
  const removeFile = (type, index) => {
    switch (type) {
      case "CV":
        setCVFileObject(null);
        break;
      case "Education":
        seteducationCertificatesFileObjects((prevState) =>
          prevState.filter((_, i) => i !== index)
        );
        break;
      case "Other":
        setotherDocumentsFileObjects((prevState) =>
          prevState.filter((_, i) => i !== index)
        );
        break;
      case "Work":
        setexperienceWorkHistory((prevState) =>
          prevState.filter((_, i) => i !== index)
        );
        break;
      default:
        break;
    }
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  return (
    <>
      <ToastContainer />
      <div className="skillset-border p-5">
        <button
          className="text-end edit-btn-skill ms-auto"
          onClick={handleEditClick}
          disabled={editAble ? true : false}
        >
          <Iconify className="" icon={"bx:edit"} />
        </button>
        <h5 className="fw-bold mb-3 text-decoration-underline ">
          Profile Info :{" "}
        </h5>
        <div className="d-flex flex-wrap">
          <div className="col-6 d-flex justify-content-center">
            <div className="position-relative profile-pic">
              {profilePicFileObject ? (
                <img
                  src={
                    profilePicFileObject
                      ? `${SERVER_URL}/${profilePicFileObject.uri}`
                      : ""
                  }
                  alt="profile"
                />
              ) : (
                <img
                  src="https://static.vecteezy.com/system/resources/previews/032/176/017/original/business-avatar-profile-black-icon-man-of-user-symbol-in-trendy-flat-style-isolated-on-male-profile-people-diverse-face-for-social-network-or-web-vector.jpg"
                  alt="profile"
                />
              )}

              {editAble ? (
                <div className="profile-edit-btn">
                  <Iconify className="" icon={"bx:edit"} />
                </div>
              ) : (
                ""
              )}
              {editAble && (
                <input
                  type="file"
                  accept="image/*"
                  onChange={(event) => handleFileChange(event, "Profile")}
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    opacity: 0,
                    cursor: "pointer",
                  }}
                />
              )}
            </div>
          </div>
          <div className="col-4">
            <div className="d-flex flex-wrap justify-content-between">
              <div className="col-4">
                <p>First Name</p>
              </div>
              <div className="col-2">
                <p>:</p>
              </div>
              <div className="col-6">
                <p>{firstName === "" ? "NIL" : firstName}</p>
              </div>
            </div>
            <div className="d-flex flex-wrap justify-content-between">
              <div className="col-4">
                <p>Last Name</p>
              </div>
              <div className="col-2">
                <p>:</p>
              </div>
              <div className="col-6">
                <p>{lastName === "" ? "NIL" : lastName}</p>
              </div>
            </div>
            <div className="d-flex flex-wrap justify-content-between">
              <div className="col-4">
                <p>Emp ID</p>
              </div>
              <div className="col-2">
                <p>:</p>
              </div>
              <div className="col-6">
                <p>{employeeId === "" ? "NIL" : employeeId}</p>
              </div>
            </div>
            <div className="d-flex flex-wrap justify-content-between">
              <div className="col-4">
                <p>Phone Number</p>
              </div>
              <div className="col-2">
                <p>:</p>
              </div>
              <div className="col-6">
                <p>{phoneNumber === "" ? "NIL" : phoneNumber}</p>
              </div>
            </div>
            <div className="d-flex flex-wrap justify-content-between">
              <div className="col-4">
                <p>Address</p>
              </div>
              <div className="col-2">
                <p>:</p>
              </div>
              <div className="col-6">
                <p>{address === "" ? "NIL" : address}</p>
              </div>
            </div>
            <div className="d-flex flex-wrap justify-content-between">
              <div className="col-4">
                <p>Blood Group</p>
              </div>
              <div className="col-2">
                <p>:</p>
              </div>
              <div className="col-6">
                <p>{bloodType === "" ? "NIL" : bloodType}</p>
              </div>
            </div>
            <div className="d-flex flex-wrap justify-content-between">
              <div className="col-4">
                <p>Email</p>
              </div>
              <div className="col-2">
                <p>:</p>
              </div>
              <div className="col-6">
                <p>{email === "" ? "NIL" : email}</p>
              </div>
            </div>
          </div>
        </div>
        {/* advance details */}
        {Role === "Auditor" ? (
          <div className="d-flex flex-wrap justify-content-start">
            <div className="col-4 px-2 py-2">
              <h6 className="fw-bold">Alternate Name</h6>
              <p className="mb-0">{alternateName}</p>
            </div>
            <div className="col-4 px-2 py-2">
              <h6 className="fw-bold">Managing Office</h6>
              <p className="mb-0">{managingOffice}</p>
            </div>
            <div className="col-4 px-2 py-2">
              <h6 className="fw-bold">Supervisor Information</h6>
              <p className="mb-0">{supervisorInformation}</p>
            </div>
            <div className="col-4 px-2 py-2">
              <h6 className="fw-bold">Year Of Birth</h6>
              <p className="mb-0">{yearOfBirth}</p>
            </div>
            <div className="col-4 px-2 py-2">
              <h6 className="fw-bold">ID Number</h6>
              <p className="mb-0">{ID_Number}</p>
            </div>
            <div className="col-4 px-2 py-2">
              <h6 className="fw-bold">Status</h6>
              <p className="mb-0">{status}</p>
            </div>
            <div className="col-4 px-2 py-2">
              <h6 className="fw-bold">Availability</h6>
              <p className="mb-0">{availability}</p>
            </div>

            <div className="col-4 px-2 py-2">
              <h6 className="fw-bold">Languages Written</h6>
              <p>{languagesWritten}</p>
            </div>
            <div className="col-12 px-2">
              <h5 className="fw-bold my-2 ">Contract Information</h5>
            </div>
            <div className="col-4 px-2 py-2">
              <h6 className="fw-bold">Date Of Signature</h6>
              <p className="mb-0">{dateOfSignature}</p>
            </div>
            <div className="col-4 px-2 py-2">
              <h6 className="fw-bold">Expiration Date</h6>
              <p className="mb-0">{expirationDate}</p>
            </div>
            <div className="col-4 px-2 py-2">
              <h6 className="fw-bold">Comments</h6>
              <p className="mb-0">{comments}</p>
            </div>
          </div>
        ) : (
          ""
        )}

        <h5 className="fw-bold my-3 text-decoration-underline ">
          Skill Set :{" "}
        </h5>
        <div className="d-flex flex-wrap">
          <div className="col-12 px-5 py-3">
            {editAble ? (
              <div className="inputs">
                <label
                  htmlFor="file-input"
                  className="d-flex justify-content-between w-100"
                >
                  Add CV <span>+</span>
                </label>
                <input
                  id="file-input"
                  type="file"
                  className="Upload-file"
                  onChange={(event) => handleFileChange(event, "CV")}
                />
              </div>
            ) : (
              <h5 className="sub-title">CV</h5>
            )}
            {CVFileObject ? (
              <>
                <a
                  rel="noreferrer"
                  href={CVFileObject ? `${SERVER_URL}/${CVFileObject.uri}`: ""}
                  target="_blank"
                >
                  {CVFileObject.originalname}
                </a>
                {editAble && (
                  <Iconify
                    style={{
                      height: "10px",
                      width: "10px",
                      color: "red",
                      cursor: "pointer",
                      margin: "0px 3px",
                    }}
                    className="ms-2"
                    onClick={() => removeFile("CV")}
                    icon={"streamline:delete-1-solid"}
                  />
                )}
              </>
            ) : (
              <p>No Data</p>
            )}
          </div>
          <div className="col-12 px-5 py-3">
            {editAble ? (
              <div className="inputs">
                <label
                  htmlFor="file-input-1"
                  className="d-flex justify-content-between w-100"
                >
                  Add Education certificate <span>+</span>
                </label>
                <input
                  id="file-input-1"
                  type="file"
                  className="Upload-file"
                  onChange={(event) => handleFileChange(event, "Education")}
                />
              </div>
            ) : (
              <h5 className="sub-title">Education certificate</h5>
            )}
            {educationCertificatesFileObjects.length > 0 ? (
              <ul>
                {educationCertificatesFileObjects.map((data, index) => (
                  <li>
                    <a
                      key={index}
                      rel="noreferrer"
                      href={data ? `${SERVER_URL}/${data.uri} ` : "#"}
                      target="_blank"
                    >
                      {data.originalname}
                    </a>

                    {editAble && (
                      <Iconify
                        style={{
                          height: "10px",
                          width: "10px",
                          color: "red",
                          cursor: "pointer",
                          margin: "0px 3px",
                        }}
                        className="ms-2"
                        onClick={() => removeFile("Education", index)}
                        icon={"streamline:delete-1-solid"}
                      />
                    )}
                  </li>
                ))}
              </ul>
            ) : (
              <p>No Data</p>
            )}
          </div>
          <div className="col-12 px-5 py-3 position-relative">
            {editAble ? (
              <>
                <TextField
                  name="workExperience"
                  label="Add Work Experience "
                  value={WorkHistory}
                  size="small"
                  className="w-100 mb-3"
                  onChange={(e) => setWorkHistory(e.target.value)}
                />
                {WorkHistory === "" ? (
                  ""
                ) : (
                  <div className="verify-button" onClick={updateWorkHistory}>
                    <Iconify className="" icon={"teenyicons:tick-outline"} />
                  </div>
                )}
              </>
            ) : (
              <h5 className="sub-title">Work Experience</h5>
            )}
            {experienceWorkHistory.length > 0 ? (
              <ul>
                {experienceWorkHistory.map((data, index) => (
                  <li key={index} className="me-2">
                    {data}
                    {editAble && (
                      <Iconify
                        style={{
                          height: "10px",
                          width: "10px",
                          color: "red",
                          cursor: "pointer",
                          margin: "0px 3px",
                        }}
                        className="ms-2"
                        onClick={() => removeFile("Work", index)}
                        icon={"streamline:delete-1-solid"}
                      />
                    )}
                  </li>
                ))}
              </ul>
            ) : (
              <p>No Data</p>
            )}
          </div>
          <div className="col-12 px-5 py-3">
            {editAble ? (
              <div className="inputs">
                <label
                  htmlFor="file-input-2"
                  className="d-flex justify-content-between w-100"
                >
                  Other Document <span>+</span>
                </label>
                <input
                  id="file-input-2"
                  type="file"
                  className="Upload-file"
                  onChange={(event) => handleFileChange(event, "Other")}
                />
              </div>
            ) : (
              <h5 className="sub-title"> Other Document</h5>
            )}
            {otherDocumentsFileObjects.length > 0 ? (
              <ul>
                {otherDocumentsFileObjects.map((data, index) => (
                  <li>
                    <a
                      key={index}
                      rel="noreferrer"
                      href={data ? `${SERVER_URL}/${data.uri}` : "#"}
                      target="_blank"
                    >
                      {data.originalname}
                    </a>
                    {editAble && (
                      <Iconify
                        style={{
                          height: "10px",
                          width: "10px",
                          color: "red",
                          cursor: "pointer",
                          margin: "0px 3px",
                        }}
                        className="ms-2"
                        onClick={() => removeFile("Other", index)}
                        icon={"streamline:delete-1-solid"}
                      />
                    )}
                  </li>
                ))}
              </ul>
            ) : (
              <p>No Data</p>
            )}
          </div>
        </div>

        {/* Table */}
        {Role === "Auditor" || Role === "Auditor-HRA" ? (
          <>
            <Card>
              <h5 className="p-3"> Audit Details </h5>
              <TableContainer
                sx={{ minWidth: 800 }}
                style={{ height: "300px", overflowY: "scroll" }}
              >
                <Table stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell className="cl-green text-dark">
                        Audit Date
                      </TableCell>
                      <TableCell className="cl-green text-dark">
                        ManDay
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {Audit_Details &&
                      Audit_Details.map((data) => (
                        <TableRow key={data._id}>
                          <TableCell align="left">
                            {new Date(data.date).toLocaleDateString()}
                          </TableCell>
                          <TableCell align="left">{data.manDayType}</TableCell>
                        </TableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Card>
          </>
        ) : (
          ""
        )}
        {Role === "Auditor" && (
          <Card className="my-5">
            <h5 className="p-3"> Auditor Skillset List </h5>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="cl-green text-dark">
                        Standards
                      </TableCell>
                      <TableCell className="cl-green text-dark">
                        Grade
                      </TableCell>
                      <TableCell className="cl-green text-dark">
                        External Qualification
                      </TableCell>
                      <TableCell className="cl-green text-dark">
                        Certification Issue Date
                      </TableCell>
                      <TableCell className="cl-green text-dark">
                        Certification Expiration
                      </TableCell>
                      <TableCell className="cl-green text-dark">
                        Reminder Date
                      </TableCell>
                      <TableCell className="cl-green text-dark">
                        Next Witness Date
                      </TableCell>
                      <TableCell className="cl-green text-dark">
                        Last Witness Date
                      </TableCell>
                      <TableCell className="cl-green text-dark">
                        Last Training Date
                      </TableCell>
                      <TableCell className="cl-green text-dark">
                        Comments
                      </TableCell>
                      <TableCell className="cl-green text-dark">
                        Skillset
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {specificAuditor &&
                      specificAuditor.auditorSkillsets &&
                      specificAuditor.auditorSkillsets
                        .filter((row) => !row.isSkillSetsArchived)
                        .map((row, index) => (
                          <TableRow key={index}>
                            <TableCell align="left">
                              {/* {row.standards && row.standards.length > 0
                                ? row.standards.map((standard, index) => (
                                    <span key={index}>{standard}</span>
                                  ))
                                : ""} */}
                              {row.standards}
                            </TableCell>
                            <TableCell align="left">{row.grade}</TableCell>
                            <TableCell align="left">
                              {/* {row.externalQualification &&
                              row.externalQualification.length > 0
                                ? row.externalQualification.map(
                                    (external, index) => (
                                      <span key={index}>{external}</span>
                                    )
                                  )
                                : ""} */}
                              {row.externalQualification}
                            </TableCell>
                            <TableCell align="left">
                              {formatDate(row.certificationIssueDate)}
                            </TableCell>
                            <TableCell align="left">
                              {formatDate(row.certificationExpiration)}
                            </TableCell>
                            <TableCell align="left">
                              {formatDate(row.reminderDate)}
                            </TableCell>
                            <TableCell align="left">
                              {formatDate(row.nextWitnessDate)}
                            </TableCell>
                            <TableCell align="left">
                              {formatDate(row.lastWitnessDate)}
                            </TableCell>
                            <TableCell align="left">
                              {formatDate(row.lastTrainingDate)}
                            </TableCell>
                            <TableCell align="left">{row.comments}</TableCell>
                            <TableCell align="left">{row.skillset}</TableCell>
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
              </TableContainer>{" "}
            </Scrollbar>
          </Card>
        )}
        {Role === "Auditor" && (
          <Card>
            <h5 className="p-3"> Archived Auditor Skillset List </h5>
            <Scrollbar>
              <TableContainer sx={{ minWidth: 800 }}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell className="cl-green text-dark">
                        Standards
                      </TableCell>
                      <TableCell className="cl-green text-dark">
                        Grade
                      </TableCell>
                      <TableCell className="cl-green text-dark">
                        External Qualification
                      </TableCell>
                      <TableCell className="cl-green text-dark">
                        Certification Issue Date
                      </TableCell>
                      <TableCell className="cl-green text-dark">
                        Certification Expiration
                      </TableCell>
                      <TableCell className="cl-green text-dark">
                        Reminder Date
                      </TableCell>
                      <TableCell className="cl-green text-dark">
                        Next Witness Date
                      </TableCell>
                      <TableCell className="cl-green text-dark">
                        Last Witness Date
                      </TableCell>
                      <TableCell className="cl-green text-dark">
                        Last Training Date
                      </TableCell>
                      <TableCell className="cl-green text-dark">
                        Comments
                      </TableCell>
                      <TableCell className="cl-green text-dark">
                        Skillset
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {specificAuditor &&
                      specificAuditor.auditorSkillsets &&
                      specificAuditor.auditorSkillsets
                        .filter((row) => row.isSkillSetsArchived)
                        .map((row, index) => (
                          <TableRow key={index}>
                            <TableCell align="left">
                              {/* {row.standards && row.standards.length > 0
                                ? row.standards.map((standard, index) => (
                                    <span key={index}>{standard}</span>
                                  ))
                                : ""} */}
                              {row.standards}
                            </TableCell>
                            <TableCell align="left">{row.grade}</TableCell>
                            <TableCell align="left">
                              {/* {row.externalQualification &&
                              row.externalQualification.length > 0
                                ? row.externalQualification.map(
                                    (external, index) => (
                                      <span key={index}>{external}</span>
                                    )
                                  )
                                : ""} */}
                              {row.externalQualification}
                            </TableCell>
                            <TableCell align="left">
                              {formatDate(row.certificationIssueDate)}
                            </TableCell>
                            <TableCell align="left">
                              {formatDate(row.certificationExpiration)}
                            </TableCell>
                            <TableCell align="left">
                              {formatDate(row.reminderDate)}
                            </TableCell>
                            <TableCell align="left">
                              {formatDate(row.nextWitnessDate)}
                            </TableCell>
                            <TableCell align="left">
                              {formatDate(row.lastWitnessDate)}
                            </TableCell>
                            <TableCell align="left">
                              {formatDate(row.lastTrainingDate)}
                            </TableCell>
                            <TableCell align="left">{row.comments}</TableCell>
                            <TableCell align="left">{row.skillset}</TableCell>
                          </TableRow>
                        ))}
                  </TableBody>
                </Table>
              </TableContainer>{" "}
            </Scrollbar>
          </Card>
        )}

        {editAble ? (
          <div className="d-flex flex-wrap justify-content-end mt-3">
            <Button
              variant="contained"
              className="btn-orange"
              onClick={handleEditClick}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              className="btn-green"
              onClick={UserDateUpdate}
            >
              Submit
            </Button>
          </div>
        ) : (
          ""
        )}
      </div>
    </>
  );
}
