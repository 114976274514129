let UserId = null;

if (sessionStorage.getItem("UserId")) {
  UserId = sessionStorage.getItem("UserId");
}
const CSOROUTES = {
  GETCSOCLIENTBASICANDADVANCE: `clients/client/basic/advanced/all`,
};

export default CSOROUTES;
