import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  Checkbox,
  FormControl,
  IconButton,
  InputLabel,
  MenuItem,
  Popover,
  Select,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
} from "@mui/material";

import * as XLSX from "xlsx";
import { saveAs } from "file-saver";
import Iconify from "src/components/iconify";
import Scrollbar from "src/components/scrollbar/Scrollbar";
import CertificateForm from "./CertificateForm";
import {
  setAllCertificate,
  setSpecificCertificate,
} from "src/redux/slices/CertificateSlice";
import { deleteData, getData } from "src/webService/webService";
import { useDispatch, useSelector } from "react-redux";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import CertificateFilter from "../Filter/CertificateFilter";
import SERVER_URL from "src/utils/serverURL";

export default function CertificateTable({ Role }) {
  const dispatch = useDispatch();
  const accessToken = sessionStorage.getItem("Token");
  const scheme_session = sessionStorage.getItem("scheme");
  const [getallClient, setGetAllClient] = useState();
  const [addCertificate, setAddCertificate] = useState(false);
  const [open, setOpen] = useState(null);
  const [action, setAction] = useState("");
  const [id, setId] = useState("");
  const [selectedId, setSelectedId] = useState(null);
  const [limit, setLimit] = useState(5);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const CERTIFICATE_LIST = useSelector(
    (state) => state.certificate.AllCertificate.results
  );
  const HandlAaddCertificate = () => {
    setAddCertificate(!addCertificate);
    setOpen(null);
  };

  const handleCloseMenu = () => {
    setOpen(null);
  };

  const handleOpenMenu = (event, id) => {
    setOpen(event.currentTarget);
    setSelectedId(id);
  };

  const HandleAddCertificateClose = () => {
    setAddCertificate(false);
  };

  const handleLimitChange = (event) => {
    setCurrentPage(1);
    setLimit(event.target.value);
  };

  const handlePrevPage = () => {
    if (currentPage > 1) {
      setCurrentPage(currentPage - 1);
    }
  };

  const handleNextPage = () => {
    if (currentPage < totalPages) {
      setCurrentPage(currentPage + 1);
    }
  };

  const GetSale = async (page) => {
    const params = {
      page: page,
      limit: limit,
    };
    try {
      const res = await getData(
        "certificate/all/certificates",
        params,
        accessToken
      );
      if (res.status === 200) {
        console.log(res.data);
        dispatch(setAllCertificate(res.data));
        setTotalPages(res.data.totalPages);
      }
    } catch (error) {
      console.error("Error:", error);
      toast.error("An error occurred. Please try again later.", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const specificUser = () => {
    getData(`certificate/${id}`, null, accessToken)
      .then((res) => {
        dispatch(setSpecificCertificate(res.data));
        console.log(res.data);
        setSelectedId(null);
        setId("");
      })
      .catch((error) => {
        console.error("Error:", error);
        toast.error("An error occurred. Please try again later.", {
          position: toast.POSITION.TOP_RIGHT,
        });
      });
  };

  useEffect(() => {
    if (id !== "") {
      specificUser();
    }
  }, [id]);

  useEffect(() => {
    GetSale(currentPage);
    GetAllClient();
  }, [currentPage, limit]);

  const DeleteId = (val) => {
    const confirmDelete = window.confirm("Are you sure you want to delete?");
    if (confirmDelete) {
      deleteData(`certificate/${id}` + val, accessToken)
        .then((res) => {
          setOpen(null);
          const updatedResults = CERTIFICATE_LIST.filter(
            (item) => item._id !== val
          );
          const updatedResult = {
            ...CERTIFICATE_LIST,
            results: updatedResults,
          };
          console.log(updatedResult);
          dispatch(setAllCertificate(updatedResult));
        })
        .catch((error) => {
          setOpen(null);
          console.error("Error:", error);
          toast.error("An error occurred. Please try again later.", {
            position: toast.POSITION.TOP_RIGHT,
          });
        });
    }
  };

  // Filter options
  const [isChecked, setIsChecked] = useState({});
  const [selectAllChecked, setSelectAllChecked] = useState(false);

  // Function to handle checkbox change
  const handleCheckboxChange = (event, id) => {
    setIsChecked({ ...isChecked, [id]: event.target.checked });
  };
  // Function to handle select all checkbox change
  const handleSelectAllCheckboxChange = (event) => {
    const { checked } = event.target;
    const updatedIsChecked = {};

    // Update isChecked object with all checkboxes' states
    CERTIFICATE_LIST.forEach((row) => {
      updatedIsChecked[row._id] = checked;
    });

    setIsChecked(updatedIsChecked);
    setSelectAllChecked(checked);
  };

  //Filter
  const [filter, SetFilter] = useState(false);
  const ChangeFilter = () => {
    SetFilter(!filter);
  };
  useEffect(() => {
    if (!filter) {
      GetSale(currentPage);
    }
  }, [filter]);

  // Export data
  // Function to handle export action
  const handleExport = () => {
    const headers = [
      "Client Id",
      "Certificate",
      "Accreditation Bodies",
      "Managing Office",
      "Contracted Company",
      "site",
      "Standards",
      "Certificate Issuing Date",
      "Certificate Expiry Date",
      "Certificate File",
    ].filter(Boolean);
    // Filter checked items
    const checkedItems = CERTIFICATE_LIST.filter((item) => isChecked[item._id]);
    let data;
    data = checkedItems.map((item) => {
      const IssuingDate = item.certificateExpiryDate
        ? `${formatDate(item.certificateExpiryDate)}`
        : "-";
      const ExpiryDate = item.certificateExpiryDate
        ? `${formatDate(item.certificateExpiryDate)}`
        : "-";
      const Imageuri = item.certificateFileObjects[0]?.uri
        ? `${SERVER_URL}${item.certificateFileObjects[0]?.uri}`
        : "-";
      const ClientId = findClientId(item.clientId);

      return [
        ClientId,
        item.certificateNumber,
        item.accreditationBodies,
        item.managingOffice,
        item.contractedCompany,
        item.sites[0],
        item.standards[0],
        IssuingDate,
        ExpiryDate,
        Imageuri,
      ];
    });

    const worksheet = XLSX.utils.aoa_to_sheet([headers, ...data]);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Client List Data");
    const excelBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });
    saveAs(
      new Blob([excelBuffer], { type: "application/octet-stream" }),
      "client_list_data.xlsx"
    );
  };
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  // // Example usage:
  // const idToFind = "664b2bc996cd46eafd1290e4";
  // const clientId = findClientId(idToFind);
  // console.log(clientId); // Output: "TransferSA2 - India - 5"

  const GetAllClient = async () => {
    const getapi = await getData(
      `clients/client/basic/advanced/all?scheme=CB&page=1&limit=5`,
      null,
      accessToken
    );
    const totalcount = getapi?.data[0]?.totalCount?.totalItems;
    if (totalcount) {
      const res = await getData(
        `clients/client/basic/advanced/all?scheme=CB&page=1&limit=${totalcount}`,
        null,
        accessToken
      );
      setGetAllClient(res?.data[0]?.paginatedResults);
    }
  };
  function findClientId(id) {
    const result = getallClient && getallClient.find((item) => item._id === id);
    return result ? result.clientId : null;
  }

  return (
    <>
      <ToastContainer />

      <div className="cso-clientlist-border p-5">
        {!addCertificate ? (
          <>
            <div>
              <div className="px-2 add-title">
                <span className="">Certificate</span>
              </div>
              <div className="d-flex justify-content-end mb-3">
                <Button
                  variant="contained"
                  className="btn-orange text-dark"
                  onClick={handleExport}
                >
                  Export
                </Button>
                {scheme_session !== "HRA" && (
                  <Button
                    className="btn-green text-white"
                    onClick={ChangeFilter}
                  >
                    {filter ? "Close Filter" : "Show Filter"}
                  </Button>
                )}
              </div>
              {filter && <CertificateFilter />}

              <Card>
                <Scrollbar>
                  <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell
                            className="cl-green text-dark"
                            style={{ padding: "5px" }}
                          >
                            <Checkbox
                              checked={selectAllChecked}
                              onChange={handleSelectAllCheckboxChange}
                            />
                          </TableCell>
                          <TableCell className="cl-green text-dark">
                            Client Id
                          </TableCell>
                          <TableCell className="cl-green text-dark">
                            Certificate
                          </TableCell>
                          <TableCell className="cl-green text-dark">
                            Accreditation Bodies
                          </TableCell>
                          <TableCell className="cl-green text-dark">
                            Managing Office
                          </TableCell>
                          <TableCell className="cl-green text-dark">
                            Contracted Company
                          </TableCell>
                          <TableCell className="cl-green text-dark">
                            site(s)
                          </TableCell>
                          <TableCell className="cl-green text-dark">
                            standard(s)
                          </TableCell>
                          <TableCell className="cl-green text-dark">
                            Certificate Issuing Date
                          </TableCell>
                          <TableCell className="cl-green text-dark">
                            Certificate Expiry Date
                          </TableCell>

                          <TableCell className="cl-green text-dark">
                            Action
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      {CERTIFICATE_LIST ? (
                        CERTIFICATE_LIST.map((row) => {
                          const {
                            certificateNumber,
                            accreditationBodies,
                            managingOffice,
                            contractedCompany,
                            certificateExpiryDate,
                            certificateIssuingDate,
                            sites,
                            standards,
                            _id,
                            clientId,
                          } = row;
                          return (
                            <TableRow hover key={_id} tabIndex={-1}>
                              <TableCell padding="checkbox">
                                <Checkbox
                                  checked={isChecked[_id] || false}
                                  onChange={(event) =>
                                    handleCheckboxChange(event, _id)
                                  }
                                />
                              </TableCell>
                              <TableCell align="left">
                                {clientId}
                                {/* {findClientId(clientId)} */}
                              </TableCell>
                              <TableCell align="left">
                                {certificateNumber}
                              </TableCell>
                              <TableCell align="left">
                                {accreditationBodies}
                              </TableCell>
                              <TableCell align="left">
                                {managingOffice}
                              </TableCell>
                              <TableCell align="left">
                                {contractedCompany}
                              </TableCell>
                              <TableCell align="left">{sites}</TableCell>
                              <TableCell align="left">{standards}</TableCell>
                              <TableCell align="left">
                                {formatDate(certificateIssuingDate)}
                              </TableCell>
                              <TableCell align="left">
                                {formatDate(certificateExpiryDate)}
                              </TableCell>
                              <TableCell align="left">
                                <IconButton
                                  size="large"
                                  color="inherit"
                                  onClick={(event) =>
                                    handleOpenMenu(event, _id)
                                  }
                                >
                                  <Iconify icon={"eva:more-vertical-fill"} />
                                </IconButton>
                              </TableCell>
                            </TableRow>
                          );
                        })
                      ) : (
                        <h6 className="text-center my-3 w-100">No Data</h6>
                      )}
                    </Table>
                  </TableContainer>
                </Scrollbar>
                {CERTIFICATE_LIST && (
                  <div className="d-flex flex-wrap justify-content-end m-3">
                    <FormControl variant="outlined" size="small">
                      <InputLabel id="limit-label">Limit</InputLabel>
                      <Select
                        labelId="limit-label"
                        id="limit-select"
                        value={limit}
                        onChange={handleLimitChange}
                      >
                        <MenuItem value={5}>5</MenuItem>
                        <MenuItem value={10}>10</MenuItem>
                        <MenuItem value={15}>15</MenuItem>
                      </Select>
                    </FormControl>
                    <Button
                      onClick={handlePrevPage}
                      disabled={currentPage === 1}
                    >
                      <Iconify icon={"grommet-icons:previous"} />
                    </Button>
                    <Button
                      onClick={handleNextPage}
                      disabled={currentPage === totalPages}
                    >
                      <Iconify icon={"grommet-icons:next"} />
                    </Button>
                  </div>
                )}
              </Card>
            </div>
            <Popover
              open={Boolean(open)}
              anchorEl={open}
              onClose={handleCloseMenu}
              anchorOrigin={{ vertical: "top", horizontal: "left" }}
              transformOrigin={{ vertical: "top", horizontal: "right" }}
              PaperProps={{
                sx: {
                  p: 1,
                  width: 140,
                  "& .MuiMenuItem-root": {
                    px: 1,
                    typography: "body2",
                    borderRadius: 0.75,
                  },
                },
              }}
            >
              <MenuItem
                onClick={() => {
                  HandlAaddCertificate();
                  setAction("Edit");
                  setId(selectedId);
                }}
              >
                <Iconify icon={"eva:edit-fill"} sx={{ mr: 2 }} />
                Edit
              </MenuItem>
              <MenuItem
                onClick={() => {
                  HandlAaddCertificate();
                  setAction("View");
                  setId(selectedId);
                }}
              >
                <Iconify icon={"raphael:view"} sx={{ mr: 2 }} />
                View
              </MenuItem>

              <MenuItem
                sx={{ color: "error.main" }}
                onClick={() => {
                  DeleteId(selectedId);
                }}
              >
                <Iconify icon={"eva:trash-2-outline"} sx={{ mr: 2 }} />
                Delete
              </MenuItem>
            </Popover>
          </>
        ) : (
          // <AddRole
          //     HandlAaddCertificate={HandleAddCertificateClose}
          //     // Role={Role}
          //     action={action}
          // // GetSale={GetSale}
          // />

          <CertificateForm
            HandlAaddCertificate={HandleAddCertificateClose}
            action={action}
            GetSale={GetSale}
            currentPage={currentPage}
          />
        )}
        {/* {!addCertificate ? (
          
        ) : (
          ""
        )} */}
      </div>
    </>
  );
}
