const FEEDBACK_EMAIL_HTML = ( format ) => {
    return `
    <!DOCTYPE html>
<html lang="en">
<head>
    <meta charset="UTF-8">
    <meta http-equiv="X-UA-Compatible" content="IE=edge">
    <meta name="viewport" content="width=device-width, initial-scale=1.0">
    <title>Report Review Feedback</title>
</head>
<body>
    <table style="width: 100%; max-width: 600px; margin: 0 auto; font-family: Arial, sans-serif; border-collapse: collapse;">
        <tr>
            <td colspan="2" style="background-color: #007bff; color: #ffffff; padding: 20px; text-align: center;">
                <h2>Report Review Feedback</h2>
            </td>
        </tr>
        <tr>
            <td style="padding: 10px;"><strong>Date:</strong></td>
            <td style="padding: 10px;">${new Date().toLocaleDateString('en-US')}</td>
        </tr>
        <tr>
            <td style="padding: 10px;"><strong>Client Name:</strong></td>
            <td style="padding: 10px;">${format?.clientName}</td>
        </tr>
        <tr>
            <td style="padding: 10px;"><strong>Contact Person:</strong></td>
            <td style="padding: 10px;">${format?.contactPerson}</td>
        </tr>
        <tr>
            <td style="padding: 10px;"><strong>Client ID:</strong></td>
            <td style="padding: 10px;">${format?.clientId}</td>
        </tr>
        <tr>
            <td colspan="2" style="padding: 20px;">
            <p>Please provide your feedback using the form linked below:</p>
            <a href=${format?.link}>Feedback Form Link</a>
            </td>
        </tr>
    </table>
</body>
</html>
`
};

export default FEEDBACK_EMAIL_HTML;