import React, { useEffect, useState } from "react";
import {
  TextField, Input, Radio, RadioGroup, InputLabel,
  FormControlLabel, FormControl, FormLabel,
  Button, Stack
}
  from '@mui/material';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";

import { getDataWithoutSession, patchData } from "src/webService/webService";
import FEEDBACK_FORM_ROUTES from "src/API_Routes/FeedbackForm/FeedbackForm_Routers";
// ----------------------------------------------------------------------

export default function CB_FeedbackForm() {

  const [feedBackFormData, setFeedbackFormData] = useState(null);
  const [queryToken, setQueryToken] = useState(null);

  const location = useLocation();
  const dispatch = useDispatch();

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFeedbackFormData({ ...feedBackFormData, [name]: value });
  };

  const handleRadioChange = ( category, answerName, indexFromRadioButton ) => {

    feedBackFormData[category].forEach(( feedbackFormItem, indexFromFormData ) => {

      if ( indexFromRadioButton === indexFromFormData ) {

        // sets the choosen answer to true
        feedbackFormItem.checkList[answerName] = true;

        // gets all the options name other than the choosen answer name
        const checkListArray = Object
                               .keys( feedbackFormItem.checkList )
                               .filter(key => key !== answerName);

        // sets the options other than the choosen answer to false
        checkListArray.forEach(checkListItem => {
          feedbackFormItem.checkList[checkListItem] = false;
        });

      }   
    })

    // new copy created to make the state change observed in useEffect
    const feedBackFormDataCopy = {...feedBackFormData}
    setFeedbackFormData(feedBackFormDataCopy);
  };

  useEffect(() => {
    console.log('feedBackFormData', feedBackFormData)
  }, [feedBackFormData])

  const GetFeedbackForm = async () => {
    const searchParams = new URLSearchParams(location.search);
    const token = searchParams.get("token");
    // Now `token` contains the value of the 'token' query parameter
    console.log(token);
    setQueryToken(token);
    const feedBackFormData = await getDataWithoutSession(
      FEEDBACK_FORM_ROUTES.GET_FEEDBACK_FORM_BY_SCHEME_TOKEN(
        'cb',
        token
      )
    );
    console.log(feedBackFormData)
    if( feedBackFormData?.status === 404 ) {
      toast.error("feedback form doesn't exist", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
    setFeedbackFormData(feedBackFormData?.data)
  }

  useEffect( () => { 
    GetFeedbackForm() 
  }, [location.search]);

  const handleSubmit = async (event) => {

    event.preventDefault();
    try {
      feedBackFormData.feedbackDate = new Date().toLocaleDateString();
      const response = await patchData(
        FEEDBACK_FORM_ROUTES.UPDATE_FEEDBACK_FORM_BY_SCHEME_TOKEN(
          'CB', 
          queryToken
        ),
        feedBackFormData,
        null,
        dispatch
      )
      switch ( response?.data?.status || response?.status ) {
        case 200:
          toast.success("Feedback Form Submitted Successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          break;
        case 500:
          toast.error("Error while submitting feedback form in server", {
            position: toast.POSITION.TOP_RIGHT,
          });
          break;
        case 400:
          toast.error("feedback form data error", {
            position: toast.POSITION.TOP_RIGHT,
          });
          break;
        case 404:
          toast.error("feedback form not found", {
            position: toast.POSITION.TOP_RIGHT,
          });
          break;
        default:
          toast.error("Unexpected Error occurred", {
            position: toast.POSITION.TOP_RIGHT,
          });
      }
    }
    catch (error) {
      console.error(error);
      toast.error("Unexpected Error occurred", {
        position: toast.POSITION.TOP_RIGHT,
      });
    };
  }

  return (
    <Stack
      direction="row"
      justifyContent="center"
      alignItems="center"
      spacing={2}
    >
      <ToastContainer />
      <form onSubmit={handleSubmit}>
        <h2>Feedback Form</h2>

        <InputLabel>Client Name</InputLabel>
        <TextField
          name="clientName"
          value={feedBackFormData?.clientName}
          disabled
        />
        <InputLabel>Name Of The Contact</InputLabel>
        <TextField
          name="nameOfTheContact"
          value={feedBackFormData?.nameOfTheContact}
          disabled
        />
        <InputLabel>Standard Applied</InputLabel>
        <TextField
          name="standardApplied"
          value={feedBackFormData?.standardApplied}
          disabled
        />
        <InputLabel>Feedback Date</InputLabel>
        <TextField
          name="feedbackDate"
          defaultValue={
            feedBackFormData?.feedbackDate ? feedBackFormData?.feedbackDate : new Date().toLocaleDateString()
          }
          disabled
        />

        <InputLabel>Position</InputLabel>
        <TextField
          name="position"
          value={feedBackFormData?.position}
          onChange={handleInputChange}
        />

        {/* Comments and Suggestions of Improvement */}
        <InputLabel>Elements to Improve</InputLabel>
        <TextField
          name="elementsToImprove"
          value={feedBackFormData?.elementsToImprove}
          onChange={handleInputChange}
          multiline
        />
        <InputLabel>Strong Points</InputLabel>       
        <TextField
          name="strongPoints"
          value={feedBackFormData?.strongPoints}
          onChange={handleInputChange}
          multiline
        />
        <InputLabel>Remarks</InputLabel>   
        <TextField
          name="remarks"
          value={feedBackFormData?.remarks}
          onChange={handleInputChange}
          multiline
        />

        {/* Evaluation */}
        <h1>Evaluation</h1>
        {feedBackFormData && feedBackFormData?.evaluation.map((item, index) => (
          <div>
            <FormControl component="fieldset">
              <FormLabel component="legend" id={"evaluation" + index}>{item.question}</FormLabel>
              <RadioGroup
                aria-labelledby={"evaluation" + index}
                value={Object.keys(item.checkList).find(key => item.checkList[key]) || ''}
                onChange={(event) => handleRadioChange('evaluation', event.target.value, index)}
              >
                <FormControlLabel value="veryDissatisfied" control={<Radio />} label="Very Dissatisfied" />
                <FormControlLabel value="dissatisfied" control={<Radio />} label="Dissatisfied" />
                <FormControlLabel value="satisfied" control={<Radio />} label="Satisfied" />
                <FormControlLabel value="verySatisfied" control={<Radio />} label="Very Satisfied" />
              </RadioGroup>
            </FormControl>
          </div>
        ))}

        {/* Commercial */}
        <h3>Commercial</h3>
        {feedBackFormData && feedBackFormData?.commercial.map((item, index) => (
          <div>
            <FormControl component="fieldset">
              <FormLabel component="legend">{item.question}</FormLabel>
              <RadioGroup
                value={Object.keys(item.checkList).find(key => item.checkList[key]) || ''}
                onChange={(event) => handleRadioChange('commercial', event.target.value, index)}
              >
                <FormControlLabel value="veryDissatisfied" control={<Radio />} label="Very Dissatisfied" />
                <FormControlLabel value="dissatisfied" control={<Radio />} label="Dissatisfied" />
                <FormControlLabel value="satisfied" control={<Radio />} label="Satisfied" />
                <FormControlLabel value="verySatisfied" control={<Radio />} label="Very Satisfied" />
              </RadioGroup>
            </FormControl>
          </div>
        ))}

        {/* Complaints And Appeals */}
        <h3>Complaints And Appeals</h3>
        {feedBackFormData && feedBackFormData?.complaintsAndAppeals.map((item, index) => (
          <div>
            <FormControl component="fieldset">
              <FormLabel component="legend">{item.question}</FormLabel>
              <RadioGroup
                value={Object.keys(item.checkList).find(key => item.checkList[key]) || ''}
                onChange={(event) => handleRadioChange('complaintsAndAppeals', event.target.value, index)}
              >
                <FormControlLabel value="veryDissatisfied" control={<Radio />} label="Very Dissatisfied" />
                <FormControlLabel value="dissatisfied" control={<Radio />} label="Dissatisfied" />
                <FormControlLabel value="satisfied" control={<Radio />} label="Satisfied" />
                <FormControlLabel value="verySatisfied" control={<Radio />} label="Very Satisfied" />
              </RadioGroup>
            </FormControl>
          </div>
        ))}

        {/* Overall Satisfaction */}
        <h3>Overall Satisfaction</h3>
        {feedBackFormData && feedBackFormData?.overallSatisfaction.map((item, index) => (
          <div>
            <FormControl component="fieldset">
              <FormLabel component="legend">{item.question}</FormLabel>
              <RadioGroup
                value={Object.keys(item.checkList).find(key => item.checkList[key]) || ''}
                onChange={(event) => handleRadioChange('overallSatisfaction', event.target.value, index)}
              >
                <FormControlLabel value="veryDissatisfied" control={<Radio />} label="Very Dissatisfied" />
                <FormControlLabel value="dissatisfied" control={<Radio />} label="Dissatisfied" />
                <FormControlLabel value="satisfied" control={<Radio />} label="Satisfied" />
                <FormControlLabel value="verySatisfied" control={<Radio />} label="Very Satisfied" />
              </RadioGroup>
            </FormControl>
          </div>
        ))}

        <Button
          variant="contained"
          className="btn-orange"
          size="large"
          type="submit"
        >
          Submit
        </Button>

      </form>
    </Stack>
  );
}
