const CB_FEEDBACK_FORM_DATA = ( format ) => {
    return {
        // partialy filled by CAMS and client
        evaluation: [
            {
                question: 'Methodology used by the auditor/inspector to lead the evaluation',
                checkList: {
                    veryDissatisfied: false,
                    dissatisfied: false,
                    satisfied: false,
                    verySatisfied: false,
                }
            },
            {
                question: 'Quality of exchanges between the auditor/inspector and the staff',
                checkList: {
                    veryDissatisfied: false,
                    dissatisfied: false,
                    satisfied: false,
                    verySatisfied: false,
                }
            },
            {
                question: 'Quality of explanations of the auditor/inspector',
                checkList: {
                    veryDissatisfied: false,
                    dissatisfied: false,
                    satisfied: false,
                    verySatisfied: false,
                }
            },
            {
                question: 'Quality of the report during the closing meeting',
                checkList: {
                    veryDissatisfied: false,
                    dissatisfied: false,
                    satisfied: false,
                    verySatisfied: false,
                }
            },
            {
                question: 'Quality of the report',
                checkList: {
                    veryDissatisfied: false,
                    dissatisfied: false,
                    satisfied: false,
                    verySatisfied: false,
                }
            },
            {
                question: 'Competences of the auditor/inspector',
                checkList: {
                    veryDissatisfied: false,
                    dissatisfied: false,
                    satisfied: false,
                    verySatisfied: false,
                }
            },
            {
                question: 'Any delay in receiving the report/certificate',
                checkList: {
                    veryDissatisfied: false,
                    dissatisfied: false,
                    satisfied: false,
                    verySatisfied: false,
                }
            }
        ],
        commercial: [
            {
                question: 'Delay of treatment of your application',
                checkList: {
                    veryDissatisfied: false,
                    dissatisfied: false,
                    satisfied: false,
                    verySatisfied: false,
                }
            },
            {
                question: 'The commercial proposal was clear',
                checkList: {
                    veryDissatisfied: false,
                    dissatisfied: false,
                    satisfied: false,
                    verySatisfied: false,
                }
            },
            {
                question: 'Ease to contact your interlocutor',
                checkList: {
                    veryDissatisfied: false,
                    dissatisfied: false,
                    satisfied: false,
                    verySatisfied: false,
                }
            },
            {
                question: 'Quality of exchanges with your interlocutor',
                checkList: {
                    veryDissatisfied: false,
                    dissatisfied: false,
                    satisfied: false,
                    verySatisfied: false,
                }
            },
            {
                question: 'Respect of delays',
                checkList: {
                    veryDissatisfied: false,
                    dissatisfied: false,
                    satisfied: false,
                    verySatisfied: false,
                }
            },
            {
                question: 'Competences of your interlocutor',
                checkList: {
                    veryDissatisfied: false,
                    dissatisfied: false,
                    satisfied: false,
                    verySatisfied: false,
                }
            },
            {
                question: 'Any delay in receiving commercials/intimation about the audit activity',
                checkList: {
                    veryDissatisfied: false,
                    dissatisfied: false,
                    satisfied: false,
                    verySatisfied: false,
                }
            }
        ],
        complaintsAndAppeals: [
            {
                question: 'Delay of complaints and/or appeals solving',
                checkList: {
                    veryDissatisfied: false,
                    dissatisfied: false,
                    satisfied: false,
                    verySatisfied: false,
                }
            },
            {
                question: 'The pertinence of our answers and of corrective action implemented',
                checkList: {
                    veryDissatisfied: false,
                    dissatisfied: false,
                    satisfied: false,
                    verySatisfied: false,
                }
            },
        ],
        overallSatisfaction: [
            {
                question: 'What is your overall satisfaction?',
                checkList: {
                    veryDissatisfied: false,
                    dissatisfied: false,
                    satisfied: false,
                    verySatisfied: false,
                }
            },
        ],

        // filled by client
        commentsAndSuggestionsOfImprovement: {
            elementsToImprove: '',
            strongPoints: '',
            remarks: ''
        },
        feedbackDate: '',
        position: '',

        // filled by CAMS
        clientName: format?.clientName,
        nameOfTheContact: format?.nameOfTheContact,
        standardApplied: format?.standardApplied,
    }
};

export default CB_FEEDBACK_FORM_DATA;