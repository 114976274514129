import { useDispatch } from "react-redux";
import { getFormattedIndiaTime } from "../utils/timeutils";
import { patchData } from "../webService/webService";
// data means clientbasicId
const ClientStatusTrackerBasic = async (
  data,
  year,
  status,
  clientStatusTracker
) => {
  const dispatch = useDispatch();
  const accessToken = sessionStorage.getItem("Token");
  const formattedDate = getFormattedIndiaTime();
  // Create a new status tracking object
  const newStatusTracking = {
    year: year,
    status: status,
    date: formattedDate,
  };

  // Append the new status tracking object to the existing array
  const updatedClientStatusTracker = [
    ...clientStatusTracker,
    newStatusTracking,
  ];

  // Prepare the params object with the updated array
  const params = {
    clientStatusTracker: updatedClientStatusTracker,
  };
  await patchData(
    `clients/client/${data}/basic`,
    params,
    accessToken,
    dispatch
  );
};
export default ClientStatusTrackerBasic;
