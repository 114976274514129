import { createSlice } from "@reduxjs/toolkit";

const ReviewerNameList = createSlice({
  name: "ReviewerNameList",
  initialState: {
    ReviewerNameList: "",
  },
  reducers: {
    setReviewerNameList: (state, action) => {
      state.ReviewerNameList = action.payload;
    },
  },
});

export const { setReviewerNameList } = ReviewerNameList.actions;
export default ReviewerNameList.reducer;
