import React, { useState, useEffect } from "react";
import { TextField, Grid, Box } from "@mui/material";
import { setTransferSA2Data } from "../../redux/slices/FeeSlice";
import { useDispatch, useSelector } from "react-redux";

function TransferSA2Form() {
  const dispatch = useDispatch();
  const selectedtransferSA2 = useSelector((state) => state.Fee.TransferSA2);

  const [transferSA2, setTransferSA2] = useState({
    administrationFee: "",
    totalContractFee: "",
    surveillanceAudit: [
      {
        surveillanceAuditNumber: 1,
        manDay: {
          year: 1,
          onSite: "",
          offSite: "",
          fee: "",
          totalManDays: "",
        },
        totalFee: "",
      },
    ],
    transferCertification: [
      {
        year: 1,
        fee: "",
      },
    ],
    auditTypeStates: [
      {
        year: 1,
        name: "Transfer Certification in SA2",
      },
    ],
  });

  useEffect(() => {
    if (selectedtransferSA2) {
      setTransferSA2(selectedtransferSA2);
    }
  }, [selectedtransferSA2]);

  const calculateTotalFee = (updatedState) => {
    const administrationFee = parseFloat(updatedState.administrationFee) || 0;
    const transferCertificationFee =
      parseFloat(updatedState.transferCertification[0].fee) || 0;
    const surveillanceAuditFee =
      parseFloat(updatedState.surveillanceAudit[0].manDay.fee) || 0;

    const totalContractFee =
      administrationFee + transferCertificationFee + surveillanceAuditFee;

    return totalContractFee;
  };

  const handleChange = (e, field, index = 0) => {
    const { name, value } = e.target;
    setTransferSA2((prev) => {
      const newState = JSON.parse(JSON.stringify(prev)); // Deep copy the state

      if (field === "administrationFee" || field === "totalContractFee") {
        newState[field] = value;
      } else if (field === "surveillanceAudit") {
        newState.surveillanceAudit[index].manDay[name] = value;
        if (name === "onSite" || name === "offSite") {
          const onSite =
            parseFloat(newState.surveillanceAudit[index].manDay.onSite) || 0;
          const offSite =
            parseFloat(newState.surveillanceAudit[index].manDay.offSite) || 0;
          newState.surveillanceAudit[index].manDay.totalManDays = (
            onSite + offSite
          ).toString();
        }
      } else if (field === "transferCertification") {
        newState.transferCertification[index].fee = value;
      }

      const totalContractFee = calculateTotalFee(newState);
      newState.totalContractFee = totalContractFee.toString();
      newState.surveillanceAudit[0].totalFee = totalContractFee.toString();

      // Dispatch the updated state to Redux
      dispatch(setTransferSA2Data(newState));

      return newState;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(transferSA2);
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box sx={{ p: 2 }}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={5}>
            <p className="mb-0">Surveillance Audit - 2</p>
          </Grid>
          <Grid item xs={12} sm={7} className="d-flex">
            <Grid item xs={12} sm={4}>
              <TextField
                label="On-site"
                variant="outlined"
                fullWidth
                size="small"
                name="onSite"
                value={transferSA2.surveillanceAudit[0].manDay.onSite}
                onChange={(e) => handleChange(e, "surveillanceAudit")}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Off-site"
                variant="outlined"
                fullWidth
                size="small"
                name="offSite"
                value={transferSA2.surveillanceAudit[0].manDay.offSite}
                onChange={(e) => handleChange(e, "surveillanceAudit")}
              />
            </Grid>
            <Grid item xs={12} sm={4}>
              <TextField
                label="Fee"
                variant="outlined"
                fullWidth
                size="small"
                name="fee"
                value={transferSA2.surveillanceAudit[0].manDay.fee}
                onChange={(e) => handleChange(e, "surveillanceAudit")}
              />
            </Grid>
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Administration Fee"
              variant="outlined"
              fullWidth
              size="small"
              name="administrationFee"
              value={transferSA2.administrationFee}
              onChange={(e) => handleChange(e, "administrationFee")}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Transfer Certification Fee"
              variant="outlined"
              fullWidth
              size="small"
              name="fee"
              value={transferSA2.transferCertification[0].fee}
              onChange={(e) => handleChange(e, "transferCertification")}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Total Contract Fee"
              variant="outlined"
              fullWidth
              size="small"
              name="totalContractFee"
              value={transferSA2.totalContractFee}
              onChange={(e) => handleChange(e, "totalContractFee")}
              disabled
            />
          </Grid>
        </Grid>
      </Box>
    </form>
  );
}

export default TransferSA2Form;
