import { setSalesNameList } from "../redux/slices/SalesListSlice";
import { getData } from "../webService/webService";
export const fetchAllSales = async (dispatch, accessToken) => {
  try {
    const res = await getData(
      "masters/user/basic/nameandid/Sales",
      null,
      accessToken
    );
    if (res.status === 200) {
      dispatch(setSalesNameList(res.data));
      console.log(res.data);
    }
  } catch (error) {
    console.error("Error:", error);
  }
};
