import React, { useEffect, useState } from "react";
import {
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Paper,
  Select,
  MenuItem,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import AUDITROUTES from "src/API_Routes/Audit/Audit_routes";

import {
  getData,
  patchData,
  deleteData,
  FileUpload,
} from "src/webService/webService";
import { useDispatch, useSelector } from "react-redux";
import html2pdf from "html2pdf.js";
import FILEROUTES from "src/API_Routes/File/File_Router";
import NCTemplate from "src/Config/templates/html/NCTemplate";
import { setNC } from "src/redux/slices/NC";

const HRA_NC = () => {
  const dispatch = useDispatch();
  const [rows, setRows] = useState();
  const selectedAuditId = sessionStorage.getItem("selectedAuditId");
  const [auditNCData, setAuditNCData] = useState();
  const [editMode, setEditMode] = useState(false);
  const accessToken = sessionStorage.getItem("Token");
  const UserId = sessionStorage.getItem("UserId");
  const SpecificClientBasic = useSelector((state) => state?.SpecificClient?.Basic);
  const userDetail = useSelector((state) => state?.userDetail?.SpecificUser)
  const types = ["Major", "Minor", "Concern"];
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInputChange = (value, index, field) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };

  const handleCategoryChange = (value, index) => {
    const updatedRows = [...rows];
    updatedRows[index]["type"] = value;
    setRows(updatedRows);
  };

  const handleDateChange = (value, index, field) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    let month = date.getMonth() + 1; // Months are zero indexed
    let day = date.getDate();

    // Pad month and day with leading zero if needed
    if (month < 10) {
      month = `0${month}`;
    }
    if (day < 10) {
      day = `0${day}`;
    }

    return `${year}-${month}-${day}`;
  };

  const SubmitOrEditModeCheck = async () => {
    const existingNC = await getData(
      AUDITROUTES.GET_NCS(selectedAuditId),
      "",
      accessToken
    );
    if (existingNC?.data?.length > 0) {
      setRows(existingNC?.data[0]?.nc);
      setAuditNCData(existingNC?.data[0]);
      setEditMode(true);
    } else {
      setRows([
        {
          hraClause: "",
          requirement: "",
          found: "",
          type: "",
          rootCause: "",
          correctiveActionPlan: "",
          targetDateForCompletion: "",
        },
      ]);
      setEditMode(false);
    }
  };

  useEffect(() => {
    SubmitOrEditModeCheck();
  }, []);

  const convertToPDFAndSendToAPI = async (props) => {
    try {
      const pdfBlob = await html2pdf()
        .from(props)
        .set({
          margin: [10, 20, 10, 20],
          jsPDF: { unit: "mm", format: "a4", orientation: "landscape" },
        })
        .output("blob");
      return pdfBlob;
    } catch (error) {
      console.error("Error while converting PDF or sending it to API:", error);
      throw error;
    }
  };

  const handleUpdate = async (event) => {
    event.preventDefault();
    try {
      setIsSubmitting(true);
      const accessToken = sessionStorage.getItem("Token");

      const NC = NCTemplate(rows, userDetail, SpecificClientBasic);
      const pdfBlob = await convertToPDFAndSendToAPI(NC);
      let uploadedFile;
      const formData = new FormData();
      formData.append("file", pdfBlob, "generated.pdf");
      uploadedFile = await FileUpload(
        FILEROUTES.UPLOAD + UserId,
        formData,
        dispatch
      );

      let response = await patchData(
        `${AUDITROUTES.SCHEDULEPATCH(selectedAuditId)}`,
        {
          NC_FileObjects: [uploadedFile],
        },
        accessToken,
        dispatch
      );

      response = await patchData(
        AUDITROUTES.UPDATE_NC(auditNCData?._id),
        {
          auditId: selectedAuditId,
          nc: rows,
        },
        accessToken,
        dispatch
      );
      dispatch(setNC(response.data));

      switch (response?.data?.status || response?.status) {
        case 200:
          toast.success("NC Updated Successfully", {
            position: toast.POSITION.TOP_RIGHT,
          });
          break;
        case 500:
          toast.error("Error while updating NC in server", {
            position: toast.POSITION.TOP_RIGHT,
          });
          break;
        case 400:
          toast.error("NC error", {
            position: toast.POSITION.TOP_RIGHT,
          });
          break;
        case 404:
          toast.error("NC not found", {
            position: toast.POSITION.TOP_RIGHT,
          });
          break;
        default:
          toast.error("Unexpected Error occurred", {
            position: toast.POSITION.TOP_RIGHT,
          });
      }
    } catch (error) {
      console.error(error);
      toast.error("Unexpected Error occurred", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  const handleDelete = async () => {
    try {
      await deleteData(`audit/hra/nc/${auditNCData?._id}`, accessToken);

      // await deleteData(`audit/hra/checklist/${hraId}`, accessToken);

      setRows("");

      toast.success("Successfully deleted", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } catch (error) {
      console.error(error);
      toast.error("Unexpected Error occurred", {
        position: toast.POSITION.TOP_RIGHT,
      });
    }
  };

  return (
    <div>
      <ToastContainer />
      <form onSubmit={handleUpdate}>
        <TableContainer component={Paper}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>HRA Clause</TableCell>
                <TableCell>Requirement</TableCell>
                <TableCell>Found</TableCell>
                <TableCell>Type</TableCell>
                <TableCell>Root Cause</TableCell>
                <TableCell>Corrective Action Plan</TableCell>
                <TableCell>Target Date For Completion</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows &&
                rows?.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      <TextField
                        value={row.hraClause}
                        onChange={(e) =>
                          handleInputChange(e.target.value, index, "hraClause")
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={row.requirement}
                        onChange={(e) =>
                          handleInputChange(
                            e.target.value,
                            index,
                            "requirement"
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={row.found}
                        onChange={(e) =>
                          handleInputChange(e.target.value, index, "found")
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <Select
                        value={row.type}
                        onChange={(e) =>
                          handleCategoryChange(e.target.value, index)
                        }
                      >
                        {types.map((type) => (
                          <MenuItem key={type} value={type}>
                            {type}
                          </MenuItem>
                        ))}
                      </Select>
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={row.rootCause}
                        onChange={(e) =>
                          handleInputChange(e.target.value, index, "rootCause")
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        value={row.correctiveActionPlan}
                        onChange={(e) =>
                          handleInputChange(
                            e.target.value,
                            index,
                            "correctiveActionPlan"
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <input
                        type="date"
                        value={formatDate(row.targetDateForCompletion)}
                        onChange={(e) =>
                          handleDateChange(
                            e.target.value,
                            index,
                            "targetDateForCompletion"
                          )
                        }
                      />
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>

        <Button
          variant="contained"
          className="btn-orange"
          size="large"
          type="submit"
        >
          Update
        </Button>

        {/* <Button
          variant="contained"
          className="btn-orange"
          size="large"
          onClick={handleDelete}
        >
          Delete
        </Button> */}
      </form>
    </div>
  );
};

export default HRA_NC;
