import React, { useEffect, useState } from "react";
import "../Clients-list/Clients-list.css";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Addclient from "./AddClient/Addclient";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
export default function ClientList() {
  const [value, setValue] = useState(0);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const scheme = sessionStorage.getItem("scheme");

  return (
    <>
      <div className="client-list-border pt-0 pb-5">
        <Box sx={{ width: "100%" }}>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              className="tab-list"
              value={value}
              onChange={handleChange}
              aria-label="basic tabs example"
            >
              <Tab label="Client List" {...a11yProps(0)} />
              {/* {scheme !== "HRA" && (
                <> */}
              <Tab label="Cifa" {...a11yProps(1)} />
              <Tab
                label="Agreement & Proposal"
                {...a11yProps(scheme === "HRA" ? 1 : 2)}
              />
              <Tab
                label="Signed Agreement & Proposal"
                {...a11yProps(scheme === "HRA" ? 2 : 3)}
              />
              {/* </>
              )} */}
            </Tabs>
          </Box>
          <CustomTabPanel value={value} index={0}>
            <Addclient tab="clientList" />
          </CustomTabPanel>
          {/* {scheme !== "HRA" && (
            <> */}
          <CustomTabPanel value={value} index={1}>
            <Addclient tab="ApprovedCifa" />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={scheme === "HRA" ? 1 : 2}>
            <Addclient tab="Proposal" />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={scheme === "HRA" ? 2 : 3}>
            <Addclient tab="Signed Agreement & Proposal" />
          </CustomTabPanel>
          {/* </>
          )} */}
        </Box>
      </div>
    </>
  );
}
