import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
} from "@mui/material";
import { getData } from "../../webService/webService";
import { useDispatch } from "react-redux";
import CSOROUTES from "../../API_Routes/CSO/CSO_Routers";
import { setClientBasicandAdvance } from "../../redux/slices/CSOSlice";

const CSOFilter = () => {
  const dispatch = useDispatch();
  const scheme_session = sessionStorage.getItem("scheme");
  const accessToken = sessionStorage.getItem("Token");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [statusType, setstatusType] = useState("");
  const [limit, setlimit] = useState("");
  const [clientstatustrackerstatus, setclientstatustrackerstatus] =
    useState("");
  const [certificatedivisionstatus, setcertificatedivisionstatus] =
    useState("");
  const handleStartDateChange = (e) => {
    const selectedStartDate = e.target.value;
    if (endDate && endDate < selectedStartDate) {
      setEndDate("");
    }
    setStartDate(selectedStartDate);
  };
  const handleEndDateChange = (e) => {
    const selectedEndDate = e.target.value;
    if (startDate && startDate > selectedEndDate) {
      setStartDate("");
    }
    setEndDate(selectedEndDate);
  };
  const handlecertificatedivisionstatusTypeChange = (event) => {
    setcertificatedivisionstatus(event.target.value);
  };
  const handleclientstatustrackerstatusTypeChange = (event) => {
    setclientstatustrackerstatus(event.target.value);
  };

  const handlestatusTypeChange = (event) => {
    setstatusType(event.target.value);
  };
  const ClientBasicandadvance = () => {
    const params = {
      scheme: scheme_session === "HRA" ? "HRA" : "CB",
      page: 1,
      limit: limit,
      statusstartdate: startDate,
      statusenddate: endDate,
      statustype: statusType,
      certificatedivisionstatus: certificatedivisionstatus,
      clientstatustrackerstatus: clientstatustrackerstatus,
    };
    getData(CSOROUTES.GETCSOCLIENTBASICANDADVANCE, params, accessToken)
      .then((res) => {
        dispatch(setClientBasicandAdvance(res.data));
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  const handleSearch = () => {
    ClientBasicandadvance();
  };

  return (
    <div
      style={{
        width: "100%",
        justifyContent: "between",
        display: "flex",
        margin: "10px 0px",
        alignItems: "center",
      }}
    >
      <TextField
        style={{ width: "25%" }}
        size="small"
        label="Start Date"
        type="date"
        className="m-2"
        value={startDate}
        onChange={handleStartDateChange}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        style={{ width: "25%" }}
        size="small"
        label="End Date"
        type="date"
        className="m-2"
        value={endDate}
        onChange={handleEndDateChange}
        InputLabelProps={{
          shrink: true,
        }}
      />

      <FormControl
        style={{ width: "25%" }}
        variant="outlined"
        className="m-2"
        size="small"
      >
        <InputLabel id="Status">Status</InputLabel>
        <Select
          labelId="Status"
          id="Status"
          value={statusType}
          onChange={handlestatusTypeChange}
          label="Status"
        >
          <MenuItem value="pending">Client Basic Pending</MenuItem>
          <MenuItem value="client details filled">
            Client Details Filled
          </MenuItem>
          <MenuItem value="reviewer assigned">
            Client Basic Reviewer Assigned
          </MenuItem>
          <MenuItem value="need revision">
            Client Basic Reviewer Need Revision
          </MenuItem>
          <MenuItem value="approved">Client Basic Reviewer Approved</MenuItem>
          <MenuItem value="rejected">Client Basic Reviewer Rejected</MenuItem>
          <MenuItem value="auditor assigned">Auditor Assigned</MenuItem>
          <MenuItem value="technical review completed">
            Technical Review Completed
          </MenuItem>

          <MenuItem value="stage 1 completed">Stage 1 Completed</MenuItem>
          <MenuItem value="stage 2 completed">Stage 2 Completed</MenuItem>
          <MenuItem value="SA 1 Completed">SA 1 Completed</MenuItem>
          <MenuItem value="SA 2 Completed">SA 2 Completed</MenuItem>
          <MenuItem value="re-certification completed">
            Recertification Completed
          </MenuItem>
          <MenuItem value="cancelled">Cancelled</MenuItem>
        </Select>
      </FormControl>
      <FormControl
        style={{ width: "25%" }}
        variant="outlined"
        className="m-2"
        size="small"
      >
        <InputLabel id="certificatedivisionstatus">
          Certificate Division Status
        </InputLabel>
        <Select
          labelId="certificatedivisionstatus"
          id="certificatedivisionstatus"
          value={certificatedivisionstatus}
          onChange={handlecertificatedivisionstatusTypeChange}
          label="certificatedivisionstatus"
        >
          <MenuItem value="Certify">Certify</MenuItem>
          <MenuItem value="Certified Rejected">Certified Rejected</MenuItem>
          <MenuItem value="Continued Maintenance Of Certification">
            Continued Maintenance Of Certification
          </MenuItem>
          <MenuItem value="Certificate Suspension">
            Certificate Suspension
          </MenuItem>
          <MenuItem value="Certificate Withdraw">Certificate Withdraw</MenuItem>
          <MenuItem value="Certificate Cancellation">
            Certificate Cancellation
          </MenuItem>
        </Select>
      </FormControl>
      <FormControl
        style={{ width: "25%" }}
        variant="outlined"
        className="m-2"
        size="small"
      >
        <InputLabel id="clientstatustrackerstatus">
          Contract Review Approval Status
        </InputLabel>
        <Select
          labelId="clientstatustrackerstatus"
          id="clientstatustrackerstatus"
          value={clientstatustrackerstatus}
          onChange={handleclientstatustrackerstatusTypeChange}
          label="clientstatustrackerstatus"
        >
          <MenuItem value="Reviewer Approved">Approved</MenuItem>
        </Select>
      </FormControl>
      <TextField
        style={{ width: "25%" }}
        size="small"
        label="Limit"
        className="m-2"
        type="number"
        onChange={(e) => setlimit(e.target.value)}
        InputLabelProps={{
          shrink: true,
        }}
      />

      <Button
        style={{ width: "12%" }}
        className="btn-green m-2"
        onClick={handleSearch}
      >
        Search
      </Button>
    </div>
  );
};

export default CSOFilter;
