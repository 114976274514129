import { Helmet } from "react-helmet-async";
// @mui
import { Container } from "@mui/material";
import Audit from "src/components/Subject-Matter-Expert/Audit/Audit";

// ----------------------------------------------------------------------

export default function SubjectMatterExpertAudit() {
  return (
    <>
      <Helmet>
        <title> Clients </title>
      </Helmet>

      <Container maxWidth="xl">
        <Audit Role="Audit"/>
      </Container>
    </>
  );
}
