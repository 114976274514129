const AUDITROUTES = {
  UPDATE: "audit/certificate/",
  DELETE: "audit/certificate/:certificateid",
  ALL: (role, userId) => `audit/all/${role}/${userId}`,

  UPDATECORRECTIVEACTION: "audit/corrective/action",
  EDITCORRECTIVEACTION: (CORRECTIVEACTIONID) =>
    `audit/corrective/action/${CORRECTIVEACTIONID}`,
  GETCORRECTIVEACTION: (AUDIT_ID) => `audit/${AUDIT_ID}/corrective/action`,

  SCHEDULE: "audit/schedule",
  SCHEDULEGET: (AUDIT_ID) => `audit/${AUDIT_ID}/schedule`,
  SCHEDULEPATCH: (AUDIT_ID) => `audit/${AUDIT_ID}/schedule`,
  SCHEDULEDELETE: (AUDIT_ID) => `audit/${AUDIT_ID}/schedule`,


  CREATE_CHECKLIST: "audit/hra/checklist",
  UPDATE_CHECKLIST: (hrachecklistid) => `audit/hra/checklist/${hrachecklistid}`,
  DELETE_CHECKLIST: (hrachecklistid) => `audit/hra/checklist/${hrachecklistid}`,
  GET_CHECKLIST: (hrachecklistid) => `audit/hra/checklist/${hrachecklistid}`,
  GET_CHECKLISTS: (auditid) => `audit/${auditid}/hra/checklist`,

  CREATE_NC: "audit/hra/nc",
  UPDATE_NC: (hrancid) => `audit/hra/nc/${hrancid}`,
  DELETE_NC: (hrancid) => `audit/hra/nc/${hrancid}`,
  GET_NC: (hrancid) => `audit/hra/nc/${hrancid}`,
  GET_NCS: (auditid) => `audit/${auditid}/hra/nc`,
};

export default AUDITROUTES;
